var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-wrapper" }, [
    _c(
      "div",
      { staticClass: "main-container" },
      [
        _c(
          "div",
          { staticClass: "fixed-header" },
          [_c("navbar"), _c("FloatContainer")],
          1
        ),
        _c("app-main", { staticClass: "app-main" }),
        _c("Footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }