import { render, staticRenderFns } from "./pieTablePanel.vue?vue&type=template&id=dedfa89e&scoped=true"
import script from "./pieTablePanel.vue?vue&type=script&lang=js"
export * from "./pieTablePanel.vue?vue&type=script&lang=js"
import style0 from "./pieTablePanel.vue?vue&type=style&index=0&id=dedfa89e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dedfa89e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/artifacts/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dedfa89e')) {
      api.createRecord('dedfa89e', component.options)
    } else {
      api.reload('dedfa89e', component.options)
    }
    module.hot.accept("./pieTablePanel.vue?vue&type=template&id=dedfa89e&scoped=true", function () {
      api.rerender('dedfa89e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/browser/pieTablePanel.vue"
export default component.exports