var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [_c("el-menu", { staticClass: "demo" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }