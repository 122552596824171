<template>
  <div class="table-container">
    <el-table
      class="self-tree-table"
      size="small"
      :data="[{}]"
      :span-method="arraySpanMethod"
    >
      <!-- <template > -->
      <el-table-column prop="itemName" label="項目" min-width="30%">
        <template #header>
          <i :class="{ 'el-icon-arrow-down': showAll, 'el-icon-arrow-right': !showAll }" @click="handleShowAll()" />
          <i style="padding-right:10px" />
          <span class="table-title">{{ $t('messages.iItem') }}</span>
        </template>
        <template>
          <table
            v-if="tableData[0] && !tableData[0].detail && !tableData[0].child"
            style="width:100%;"
            border="0"
            cellpadding="0"
            cellspacing="0"
            class="sum-table"
          >
            <tr style="background-color: #000;color:#fff;height: 2rem">
              <td style="width: 30%">
                <i style="padding-right:24px" />
                合計
              </td>
              <td style="width: 20%" />
              <td style="width: 20%" />
              <td style="width: 6%; text-align: center;" />
              <td style="width: 5%" />
              <td style="width: 7%; text-align: center;">
                {{ toScientificNum(tableData[0].total)[0] }} {{ toScientificNum(tableData[0].total)[1] }}
                <sup>{{ toScientificNum(tableData[0].total)[2] }}</sup>
              </td>
              <td style="width: 5%; text-align: center;">{{ tableData[0].pre }}%</td>
              <td style="width: 7%" />
            </tr>
          </table>
          <div class="table-scroll" :style="getTableHeight">
            <span v-for="(row, index) in tableData" :key="index">
              <Tree
                v-if="row.detail || row.child"
                :show-all="showAll"
                :row="row"
                :level-num="0"
                v-bind="$attrs"
              />
            </span>
          </div>
        </template>
      </el-table-column>
      <!-- </template> -->
      <el-table-column prop="processName" :label="$t('messages.process')" min-width="20%" />
      <el-table-column prop="ideaName" :label="$t('messages.eF')" min-width="20%" />
      <el-table-column prop="quantity" :label="$t('messages.amount')" min-width="6%" />
      <el-table-column prop="unit" :label="$t('messages.unit')" min-width="5%" />
      <el-table-column prop="emissionc" :label="$t('messages.emission')" min-width="7%" />
      <el-table-column prop="pre" :label="$t('messages.ratio')" min-width="5%" />
      <el-table-column prop="budomari" :label="$t('messages.yieldRate')" min-width="7%" />
    </el-table>
  </div>
</template>
<script>
import Tree from './tree.vue'
import './referenceTree.scss'

export default {
  name: 'TreeTablePanel',
  inject: ['getOriginIdeaInfo', 'toScientificNum'],
  components: { Tree },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    tableMaxHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showAll: false
    }
  },
  computed: {
    getTableHeight() {
      if (this.tableMaxHeight) {
        const maxHeight = this.tableMaxHeight - 71
        return `max-height: ${maxHeight}px;`
      } else {
        return ''
      }
    }
  },
  watch: {
    tableData: {
      handler(newVal) {
        if (newVal) {
          this.showAll = false
        }
      },
      deep: true
    }
  },
  methods: {
    arraySpanMethod({ columnIndex }) {
      if (columnIndex === 0) {
        return [1, 8]
      }
      return [0, 0]
    },
    handleShowAll() {
      this.showAll = !this.showAll
    }
  }
}
</script>
