var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "popup-fade" } }, [
    _c("div", { staticClass: "popup-body" }, [
      _c("div", { staticClass: "popup-conten" }, [
        _c("div", { staticClass: "popup-title" }, [
          _c("span", { staticClass: "title-font" }, [_vm._v("製品情報")]),
        ]),
        _c(
          "div",
          { staticClass: "popup-seihin-content" },
          [
            _c(
              "el-form",
              { attrs: { "label-position": "left", "label-width": "150px" } },
              [
                _c(
                  "el-form-item",
                  {
                    staticStyle: {
                      width: "100%",
                      "border-bottom": "2px solid #e7e9ea",
                    },
                    attrs: { label: "主管部門名" },
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          _vm.seihinMasterData.bumonname.length > 50
                            ? "value-class"
                            : "value-class-break",
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { "word-break": "break-all" } },
                          [_vm._v(_vm._s(_vm.seihinMasterData.bumonname))]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: {
                      width: "100%",
                      "border-bottom": "2px solid #e7e9ea",
                    },
                    attrs: { label: "製品型番" },
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          _vm.seihinMasterData.productno.length > 50
                            ? "value-class"
                            : "value-class-break",
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { "word-break": "break-all" } },
                          [_vm._v(_vm._s(_vm.seihinMasterData.productno))]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: {
                      width: "100%",
                      "border-bottom": "2px solid #e7e9ea",
                    },
                    attrs: { label: "社外型番" },
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          _vm.seihinMasterData.shagaiproductno.length > 50
                            ? "value-class"
                            : "value-class-break",
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { "word-break": "break-all" } },
                          [_vm._v(_vm._s(_vm.seihinMasterData.shagaiproductno))]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: {
                      width: "100%",
                      "border-bottom": "2px solid #e7e9ea",
                    },
                    attrs: { label: "製品分類" },
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          _vm.seihinMasterData.seihinbunrui.length > 50
                            ? "value-class"
                            : "value-class-break",
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { "word-break": "break-all" } },
                          [_vm._v(_vm._s(_vm.seihinMasterData.seihinbunrui))]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: {
                      width: "100%",
                      "border-bottom": "2px solid #e7e9ea",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "label" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: _vm.columnNameData.columnName1,
                              disabled:
                                _vm.columnNameData.columnName1.length < 6,
                            },
                          },
                          [
                            _c("span", { staticClass: "label-class" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.gettextlenght(
                                    _vm.columnNameData.columnName1
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.seihinMasterData.columnvalue1.length > 50
                            ? "value-class"
                            : "value-class-break",
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { "word-break": "break-all" } },
                          [_vm._v(_vm._s(_vm.seihinMasterData.columnvalue1))]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: {
                      width: "100%",
                      "border-bottom": "2px solid #e7e9ea",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "label" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: _vm.columnNameData.columnName2,
                              disabled:
                                _vm.columnNameData.columnName2.length < 6,
                            },
                          },
                          [
                            _c("span", { staticClass: "label-class" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.gettextlenght(
                                    _vm.columnNameData.columnName2
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.seihinMasterData.columnvalue2.length > 50
                            ? "value-class"
                            : "value-class-break",
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { "word-break": "break-all" } },
                          [_vm._v(_vm._s(_vm.seihinMasterData.columnvalue2))]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: {
                      width: "100%",
                      "border-bottom": "2px solid #e7e9ea",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "label" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: _vm.columnNameData.columnName3,
                              disabled:
                                _vm.columnNameData.columnName3.length < 6,
                            },
                          },
                          [
                            _c("span", { staticClass: "label-class" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.gettextlenght(
                                    _vm.columnNameData.columnName3
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.seihinMasterData.columnvalue3.length > 50
                            ? "value-class"
                            : "value-class-break",
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { "word-break": "break-all" } },
                          [_vm._v(_vm._s(_vm.seihinMasterData.columnvalue3))]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: {
                      width: "100%",
                      "border-bottom": "2px solid #e7e9ea",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "label" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: _vm.columnNameData.columnName4,
                              disabled:
                                _vm.columnNameData.columnName4.length < 6,
                            },
                          },
                          [
                            _c("span", { staticClass: "label-class" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.gettextlenght(
                                    _vm.columnNameData.columnName4
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.seihinMasterData.columnvalue4.length > 50
                            ? "value-class"
                            : "value-class-break",
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { "word-break": "break-all" } },
                          [_vm._v(_vm._s(_vm.seihinMasterData.columnvalue4))]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
                _c(
                  "el-form-item",
                  { staticStyle: { width: "100%" } },
                  [
                    _c(
                      "template",
                      { slot: "label" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: _vm.columnNameData.columnName5,
                              disabled:
                                _vm.columnNameData.columnName5.length < 6,
                            },
                          },
                          [
                            _c("span", { staticClass: "label-class" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.gettextlenght(
                                    _vm.columnNameData.columnName5
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.seihinMasterData.columnvalue5.length > 50
                            ? "value-class"
                            : "value-class-break",
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { "word-break": "break-all" } },
                          [_vm._v(_vm._s(_vm.seihinMasterData.columnvalue5))]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "button-class" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.doClose },
              },
              [_vm._v("閉じる")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }