import Vue from "vue";
import VueI18n from "vue-i18n";
import enLocale from './language-EN'
import cnLocale from './language-JP'
Vue.use(VueI18n);
const messages  = {
    en : {
        ...enLocale
    },
    jp : {
        ...cnLocale
    }
}
const i18n = new VueI18n ({
    locale : localStorage.getItem('lang') || 'jp',
    fallbackLocale: 'en',
    silentTranslationWarn: false,
    messages
});

export default i18n;