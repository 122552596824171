import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const state = {
  user: window.sessionStorage.getItem('user'),
  role: window.sessionStorage.getItem('role'),
  passwd: window.sessionStorage.getItem('passwd'),
  seihin: window.sessionStorage.getItem('seihin'),
  returnFlag: window.sessionStorage.getItem('return'),
  temp: window.sessionStorage.getItem('temp'),
  headers: window.sessionStorage.getItem('headers')
}
const mutations = {
  // get user state
  SET_USER: (state, data) => {
    // save state user
    state.user = JSON.stringify(data)
    window.sessionStorage.setItem('user', JSON.stringify(data))
  },
  // get rsa password
  SET_RSA_PASSWD: (state, data) => {
    // save rsa password
    state.passwd = JSON.stringify(data)
    window.sessionStorage.setItem('passwd', JSON.stringify(data))
  },
  SET_SUB_ID: (state, data) => {
    // save rsa password
    state.subId = data
    window.sessionStorage.setItem('subId', JSON.stringify(data))
  },
  CHANGE_SEHIN: (state, data) => {
    // save rsa password
    state.change = data
    window.sessionStorage.setItem('change', data)
  },
  // get user role
  SET_ROLE: (state, data) => {
    // save user role
    state.role = JSON.stringify(data)
    window.sessionStorage.setItem('role', JSON.stringify(data))
  },
  SET_SEIHIN: (state, data) => {
    // save seihin
    state.seihin = JSON.stringify(data)
    window.sessionStorage.setItem('seihin', JSON.stringify(data))
  },
  SET_FROM_PAGE: (state, data) => {
    state.temp = JSON.stringify(data)
    window.sessionStorage.setItem('temp', JSON.stringify(data))
  },

  SET_HEADERS:(state, data) => {
    state.headers = JSON.stringify(data)
    window.sessionStorage.setItem('headers', JSON.stringify(data))
  },

  DELETE_SEIHIN: (state) => {
    // save seihin
    state.seihin = null
    window.sessionStorage.removeItem('seihin')
  },
  // logout
  DELETE_USER: (state) => {
    state.user = null
    window.sessionStorage.removeItem('user')
  },
  // set return login page
  SET_RETURN_LOGIN: (state, data) => {
    state.returnFlag = data
    window.sessionStorage.setItem('return', data)
  },

  DELETE_RETURN_LOGIN: (state) => {
    state.returnFlag = null
    window.sessionStorage.removeItem('return')
  }
}
const actions = {
}
export default new Vuex.Store({
  state,
  mutations,
  actions
})
