var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "popup-fade" } }, [
    _c("div", { staticClass: "popup-body" }, [
      _c("div", { staticClass: "popup-content-wrap" }, [
        _c("div", { staticClass: "popup" }, [
          _c("div", { staticClass: "title-box" }, [
            _c("div", { staticClass: "title-link" }, [
              _vm._v(_vm._s(_vm.popUpArgs.originIdeaName)),
            ]),
            _c("i", {
              staticClass: "el-icon-close",
              on: { click: _vm.doClose },
            }),
          ]),
          _c("div", { staticClass: "title-tip" }, [
            _vm.showTipFlag
              ? _c("div", [
                  _c("span", [
                    _vm._v(
                      "※自社算定原単位はCradle to Gateを前提にA1～A3を対象に計算しており、A4以降はグレーアウトしています"
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.isLoop && _vm.hasRelationOriginIdea
              ? _c("div", [
                  _c("span", [
                    _vm._v(
                      "※自社算定原単位内で循環参照が生じているため、これ以上のドリルダウン表示はできません"
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "popup-body-box" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.showLoad,
                    expression: "showLoad",
                  },
                ],
                ref: "popUpPanel",
              },
              [
                _vm.isSelfCalcOriginIdea && _vm.hasRelationOriginIdea
                  ? _c("TreeTablePanel", {
                      attrs: {
                        "table-data": _vm.tableData,
                        "table-max-height": _vm.tableMaxHeight,
                        "is-popup": _vm.isPopup,
                        "is-loop": _vm.isLoop,
                      },
                    })
                  : _vm.isSelfCalcOriginIdea && !_vm.hasRelationOriginIdea
                  ? _c("PieTablePanel", {
                      attrs: {
                        "pie-data": _vm.pieData,
                        "color-data": _vm.colorData,
                        "process-table-data": _vm.processTableData,
                        "table-max-height": _vm.tableMaxHeight,
                      },
                    })
                  : _c("OtherCalcIdeaPanel", {
                      attrs: {
                        "pie-data": _vm.pieData,
                        "color-data": _vm.colorData,
                        "process-table-data": _vm.processTableData,
                        "table-max-height": _vm.tableMaxHeight,
                      },
                    }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "tab-button-box" },
            [
              _c(
                "el-button",
                { staticClass: "back-button", on: { click: _vm.doClose } },
                [_vm._v(" " + _vm._s(_vm.$t("messages.backName")))]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }