import request from '@/utils/request'

// 保存
export function saveGenTaniAPI(csvDataList) {
  const data = {
    dataList: csvDataList
  }
  console.log('call saveGenTaniAPI')
  return request({
    url: '/api/v1/genTaniLink/save',
    method: 'post',
    data
  })
}

// 查询
export function getGenTaniLinkListAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getGenTaniLinkListAPI')
  return request({
    url: '/api/v1/genTaniLink/getGenTaniLinkList',
    method: 'post',
    data
  })
}

// 删除
export function deleteGenTaniLinkAPI(idearolecode) {
  const data = {
    idearolecode
  }
  console.log('call deleteGenTaniLinkAPI')
  return request({
    url: '/api/v1/genTaniLink/deleteGenTaniLink',
    method: 'post',
    data
  })
}

// 获取原单位下拉
export function getIdeaListAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getIdeaListAPI')
  return request({
    url: '/api/v1/genTaniLink/getIdeaList',
    method: 'post',
    data
  })
}

// 修改
export function updateGenTaniLinkAPI(dataList) {
  const data = {
    dataList
  }
  console.log('call updateGenTaniLinkAPI')
  return request({
    url: '/api/v1/genTaniLink/update',
    method: 'post',
    data
  })
}

// 获取processCode下拉
export function getGenTaniProcessCodeListAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getGenTaniProcessCodeListAPI')
  return request({
    url: '/api/v1/genTaniLink/getGenTaniProcessCodeList',
    method: 'post',
    data
  })
}

// 获取item下拉
export function getGenTaniItemListAPI(kigyouCode, itemType) {
  const data = {
    kigyouCode,
    itemType
  }
  console.log('call getGenTaniItemListAPI')
  return request({
    url: '/api/v1/genTaniLink/getGenTaniItemList',
    method: 'post',
    data
  })
}

// 获取list加过滤
export function getGenTaniLinkListSearchAPI(data) {
  console.log('call getGenTaniLinkListSearchAPI')
  return request({
    url: '/api/v1/genTaniLink/getGenTaniLinkList',
    method: 'post',
    data
  })
}

// 保存rulelist
export function saveAndUpdateGentaniLinkAPI(dataList) {
  const data = {
    dataList
  }
  console.log('call saveAndUpdateGentaniLinkAPI')
  return request({
    url: '/api/v1/genTaniLink/saveAndUpdateGentaniLink',
    method: 'post',
    data
  })
}

// add checkRule
export function checkRuleAddAPI(data) {
  console.log('call checkRuleAddAPI')
  return request({
    url: '/api/v1/genTaniLink/checkRuleAdd',
    method: 'post',
    data
  })
}

// import csv checkRule
export function checkRuleImportAPI(data) {
  console.log('call checkRuleImportAPI')
  return request({
    url: '/api/v1/genTaniLink/checkRuleImport',
    method: 'post',
    data
  })
}
