import request from '@/utils/request'

export function getTableSeihinAPI(kigyouCode, sortData = { seihinname: 0, bumonname: 0, productno: 0, shagaiproductno: 0, seihinbunrui: 0, insertuser: 0 }, filterList = { seihinname: [], bumonname: [], productno: [], shagaiproductno: [], seihinbunrui: [], insertuser: [] }) {
  const data = {
    kigyouCode,
    sortData,
    "filterData" : filterList
  }
  console.log('call getTableSeihinAPI')
  return request({
    url: '/api/v1/seihin/query',
    method: 'post',
    data
  })
}
export function getUnitAPI() {
  console.log('call getUnitAPI')
  return request({
    url: '/api/v1/seihin/getUnit',
    method: 'get'
  })
}
export function saveSeihinAPI(data) {
  console.log('call saveSeihinAPI')
  return request({
    url: '/api/v1/seihin/save',
    method: 'post',
    data
  })
}

export function removeTableSeihinAPI(kigyouCode, seihinCode) {
  const data = {
    kigyouCode,
    seihinCode
  }
  console.log('call removeTableSeihinAPI')
  return request({
    url: '/api/v1/seihin/deleteMaster',
    method: 'post',
    data
  })
}

export function checkMasterDeleteAPI(kigyouCode, seihinCode) {
  const data = {
    kigyouCode,
    seihinCode
  }
  console.log('call checkMasterDeleteAPI')
  return request({
    url: '/api/v1/seihin/checkMasterDelete',
    method: 'post',
    data
  })
}

export function getColumnNamesAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getColumnNamesAPI')
  return request({
    url: '/api/v1/column/getColumnNames',
    method: 'post',
    data
  })
}

export function updateColumnNameAPI(data) {
  console.log('call updateColumnNameAPI')
  return request({
    url: '/api/v1/column/updateColumnName',
    method: 'post',
    data
  })
}

export function getSeihinMasterAPI(kigyouCode, seihinCode) {
  const data = {
    kigyouCode,
    seihinCode
  }
  console.log('call getSeihinMasterAPI')
  return request({
    url: '/api/v1/seihin/getSeihinMaster',
    method: 'post',
    data
  })
}