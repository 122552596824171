export default {
    messages: {
        //menu begin
        dashboardTitle: 'Dashboard',
        calculationTitle: 'Calculated Emission Data',
        emissionsTitle: 'Calculate Emissions',
        registerTitle: 'Register New Data',
        temporaryEditTitle: 'Edit Temporary Data',
        finalizedEditTitle: 'Edit Finalized Data',
        lookUpTitle: 'Look up Emissions',
        simulationTitle: 'Simulation',
        reportTitle: 'Report/Collect',
        disclosureTitle: 'Disclosure mgmt',
        requestTitle: 'Request  mgmt',
        receivedTitle: 'Request Received mgmt',
        manageMasterTitle: 'Manage Master Settings',
        productMasterTitle: 'Product Master mgmt',
        originalTitle: 'Original EF list/mgmt',
        processTitle: 'Process Format mgmt',
        ruleTitle: 'EF Rule mgmt',
        guidelinesTitle: 'Guidelines',
        caltGuidelinesTitle: 'Calculation Guidelines',
        videoTitle: 'LCA Training Video',
        checkListTitle: 'Check List',
        uerManualTitle: 'User Manual',
        emissionsCompareTitle: 'Emissions Comparison',
        overviewCompareTitle: 'Overview comparison',
        detailsCompareTitle: 'Details comparison',
        logout: 'Log out',
        // end
        kpiTitle: 'KPI/Progress',
        companyAll: 'Total',
        importName: 'Target Set',
        errorTitle: 'Error',
        calculatedProducts: 'Calculated     Product',
        calculatedProduct: 'Calculated Products',
        calculatedProduct1: 'Calculated',
        calculatedProduct2: 'Products',
        target: 'Target',
        progress: 'Progress',
        progressRate: 'Progress',
        ideaUserRate: 'Utilization of Partners’ EF',
        ratio: 'Ratio',
        percentage: 'Percentage',
        usage: 'Usage',
        totalItemsA1: 'Total Items in A1',
        totalItemsA11: 'Total Items',
        totalItemsA12: 'in A1',
        checkList: 'Checklist — achievement status',
        totalNumberCalculated: 'Number of Calculations',
        averagePoints: 'Average Score',
        fullPoint: 'Calculations With Perfect Score',
        perfectScore: 'Perfect Score',
        fullPointPercentage: 'Percentage of Perfect Score',
        division: 'Division',
        responses2Partners: 'Responses to Partners',
        partnersResponses: 'Responses from Partners',
        response2Customers: 'Responses to Customers',
        response2Supliers: ' Responses to Suppliers',
        receivedNumber: 'Requests Received',
        requestNumber: 'Requests',
        upstream: 'Number of responses from upstream companies',
        checkStatus: 'Checklist - Status per Item',
        checkPerf: 'Checklist - Performance',
        unknownTip: '全社を選択してください',
        productList: 'Product List',
        productListPage: '製品一覧画面',
        //downstream
        manageSentRequests: 'Manage Sent Requests',
        responsesReceived: 'Responses Received',
        requestsSent: 'Requests Sent',
        addRequest: 'Make a Request',
        company: 'Company',
        requests: 'Requests Sent',
        responses: 'Responses Received',
        requestsDate: 'Requested Date',
        memo: 'Memo',
        cancel: 'Cancel',
        doRequest: 'Request',
        //upstream
        manageReceivedRequests: 'Manage Received Requests',
        responsesSent: 'Responses Sent',
        requestsReceived: 'Requests Received',
        received: 'Requests Received',
        responsesSent: 'Responses Sent',
        receivedDate: 'Received Date',
        requestRoute: ' Request Route',
        makeOfflineRequest: 'Make an Offline Request',
        save: 'Save',
        number: 'Number of Requests',
        //productList
        selfCalculated: 'Self Calculated',
        calculatedbyPartners: 'Calculated by Partners',
        newCalculation: 'New Calculation',
        product: 'Product',
        divisionP: 'Division',
        lastUpdatedBy: 'Last Updated by',
        saveStatus: 'Save Status',
        lastUpdate: 'Last Update',
        reports2Partners: 'Reports to Partners',
        emissionc: 'Emissions(kg-CO₂eq)',
        emissionc1:'(kg-CO₂eq)',
        checkScore: 'Check Score',
        ratioOfEF: 'Ratio Using Partners’ EF',
        menu: 'Menu',
        ownEF: 'OWN　EF',
        details: 'Show details',
        editData: 'Edit data',
        editData1: 'edit data',
        deleteData: 'Delete product data',
        deleteData1: 'delete product data',
        copyData: 'Copy product data',
        linkedOwnSystem: "Linked to Company's Own System",
        dataID: 'Data ID',
        linkageDate: 'Linkage Date',
        linkedSystem: 'Linked System',
        processCategory: 'Process Category',
        numberItems: 'Number of Managed Items',
        Memo1: 'Memo',
        confirmLinkage: 'Confirmation of API Linkage Result',
        pProduct: 'Product',
        pMaster: 'Version Registration',
        pVersion: 'Product Registration',
        pMessage: 'Please register the product as it is not registered in the product master',
        pDuplicateMessage: 'Please change the product version as there is a duplication',
        //other
        companyname: 'Company',
        disclosureDate: 'Disclosure Date',
        disclosureLevel: 'Disclosure Level',
        auth1: 'Level 1: EF Permission',
        auth5: 'Level 2: EF Permission / Limited Disclosure',
        auth4: 'Level 3: EF Permission/ Full Disclosure',
        //add
        addNewData: 'Register New Data',
        standardProcess: 'Standard process',
        originalProcess: 'Original process',
        addproductName: 'Product',
        unit: 'Unit',
        deputname: 'Deputy Unit',
        version: 'Version',
        yieldRate: 'Yield Rate',
        checkRegister: 'Register',
        viewGuidelines: 'View Guidelines',
        uploadCSV: 'Upload CSV',
        itemName: 'Item Name',
        managedItem: 'Item',
        ef: 'EF',
        efName: 'EF Name',
        a2Calc: 'A2 Calc',
        amount: 'Amount',
        intermediateProduct: 'Intermediate Product',
        addNewItem: 'Add New Item',
        addNewProcess: 'Add New Process',
        saveTemp: 'Save Temporary',
        calculate: 'Calculate',
        utilityDistribution: 'Utility Distribution',
        productTransport: 'Product Transport',
        entryAssistMenu: 'Entry Assist Menu',
        opuc: 'Overall Plant Utility Consumption(Annual)',
        overallPlantProduction: 'Overall Plant Production',
        productWeight: 'Product Weight',
        electricity: 'Electricity',
        waterSuplly: 'Water Supply',
        fuel: 'Fuel',
        item1: 'Within the city or between neighboring cities',
        item2: 'Within the prefecture/state',
        item3: 'Possible inter-prefecture/state transportation',
        item4: 'Not limited to a specific region（domestic）',
        item5: 'Overseas road transport',
        transportMeans: 'Transport Means',
        transportDistance: 'Transport Distance',
        backName: 'Back',
        tranCheck: 'Please enter transport means',
        tranSpotCheck: 'Please enter transport distance',
        roadTransportOnly: 'Road Transport Only',
        includingSeaTransport: 'Including Sea Transport',
        transportScenario: 'Transport Scenario',
        pets: 'Please Enter Transport scenario',
        petm: 'Please enter transport means',
        petd: 'Please enter transport distance',
        ccr: 'Calculate and Check Results',
        fileUploadResults: 'Check File Upload Results',
        process: 'Process',
        item: 'Item',
        itemA: 'A',
        itemB: 'B',
        itemC: 'C',
        itemD: 'D',
        itemE: 'E',
        finalized: 'Finalized',
        inputData: 'Input Data',
        afterUpdate: 'EF Name　(After update)',
        beforUpdate: 'EF Name　(Before update)',
        tfihbl2anef: 'The following items have been linked to a new EF.',
        pttiyw2urarutnef: 'Please tick the items you wish to update and register using the new EF.',
        onlyEmissions: '',
        whetherRegister: '',
        onlyEmissionsRule: '',
        reo: 'Register emissions only?',
        reo1: 'Register emissions only',
        rear: 'Register emissions and rules?',
        doregister: 'Register',
        docancel: 'Cancel',
        tsic: 'Temporary saving is complete.',
        wibo2dtri: 'Would it be OK to delete the relevant item?',
        tepvahdritfss: 'The entered product/version already has data registered in the finalized saved state.',
        potesftrsofpaeted: 'Please open the edit screen from the reference screen of the product and edit the emissions data.',
        tifiiacbr: 'The import file is incomplete and cannot be registered, please download the csv and check the error.',
        note: 'Write memo',
        uploadTitle: 'Would you like to register emissions?',
        addRuleButton: 'Emissions and Rules Registration',
        ruleCancelMessage: 'Operation data will not be saved. Do you want to return to the original screen?',
        changeButtonMessage: 'The entered data will be deleted, is this correct?',
        noParent: 'No parent-subsidiary relation',
        parentExists: 'Parent-subsidiary relation exists',
        parentItem: 'Parent-managed item',
        itemContent: 'Name of raw materials managed, type of energy used, transport mode, disposal means, etc.',
        efContent: 'Select the IDEA EF name or the product name (EF) registered by each company that corresponds to the Item Name.',
        amountContent: 'For the Item Name, enter the numerical value of the input volume, emissions volume, consumption volume, etc. The number entered should be based on the base unit. Please convert it into EF as necessary.',
        unitContent: 'When the EF Name is selected, it will automatically be displayed.',
        calQualityList: 'Calculation Quality Checklist',
        calLCAList: 'LCA Calculation Checklist',
        check: 'Check',
        sumemissionc: 'Total Emissions (kg-CO₂eq)',
        formatNoParent: 'Format download(No parent-subsidiary relation)',
        formatParent: 'Format download(Parent-subsidiary relation)',
        EFAnalogy: 'EF by analogy',
        A2Calculate: 'Calculate upstream logistics(A2)',
        lastUpdatedBy1: 'Last Updated By',
        EFUnconvertible: 'Upstream logistics calculation is not available because the EF is unconvertible to weight. Please input manually.',
        unitMismatched: 'Unit(EF) and Unit(Input Data) mismatched. Please check Amount(Finalized) and Unit(EF)',
        checkAmount: 'Please input EF and check Amount(Finalized)',
        processAfter: 'Process(after)',
        processBefore: 'Process(before)',
        EFChanged: 'This EF has been changed. Please check EF Name and Amount.',
        subunitChanged: 'The subunit of this EF has been changed. Please check EF Name and Amount.',
        EFDelete: 'This EF has been deleted. Please change EF Name and Amount.',
        subunitDelete: 'The subunit of this EF has been deleted. Please change EF Name and Amount.',
        callParts: 'Call a parts data from another product',
        copyLine: 'Copy a previous line',
        getData: 'Called data',
        firstManagedProgram: 'Called module name',
        managedProgramCount: 'Number of Managed Items',
        updateUser:'Updated By',
        updateDate:'Last Update',
        enterProduct: 'Please enter a product and version',
        productAndVersion: 'Product/Version',
        callData: 'Call data',
        callDataDelMsg :'Would you it be OK to delete the relevant item?',
        callDataPorduct: 'Called data from another product',
        //referenceIndex
        pro: 'Product Reference: Overview',
        productVersion: 'Product/ Version',
        productOverview: 'Product Overview',
        viewDetails: 'View Details',
        emission: 'Emissions',
        reduction: 'Reduction',
        cwop: 'Comparison with Other Products',
        eotpv: 'Emissions of this Product Version',
        uopef: 'Utilization of Partners’ EF',
        usedef: 'Used EF',
        rwte: 'Ratio within Total Emissions',
        items: 'items',
        car2c: 'Checklist and Reports to Customers',
        checkItems: 'Check Score',
        reported2Partners: 'Reported to Partners',
        hotspotRanking: 'Hotspot Ranking',
        comparewith: 'Compare With',
        companies: 'company',
        psp: 'Please select product',
        rmp: 'Raw Materials Procurement',
        transportation: 'Transportation',
        production: 'Production',
        disposalRecycling: 'Disposal/Recycling',
        noShow: 'Total undisclosed processes',
        other: 'Other',
        selfCompany: '———Own Products———',
        otherCompany: '———Partners Products———',
        triangleTip: 'Depending on the disclosure settings of upstream companies, there might be amounts that are not included in the emissions in the categories from raw materials procurement to disposal and recycling. The total of such amounts is included in the total for undisclosed processes.',
        ownEFRegister: 'Register to the Own  EF',
        ownEFRegComplete: 'Registration of the own EF complete',
        ownEFRegisterTip: 'The calculated results can be used to calculate another product as EF',
        ownEFRegPopUp: 'Register the Own EF',
        compare: 'Comparison',
        treeList: 'Tree View',
        referSubUnit: 'Refer to Sub-unit',
        productUnit: 'Product Unit',
        conversionCoef: 'Conversion Coefficient',
        mainUnit: 'Main Unit',
        subunit1: 'Sub-unit 1',
        subunit2: 'Sub-unit 2',
        subunit3: 'Sub-unit 3',
        //singleWithFold
        prd: 'Product Reference: Details',
        updatePage: 'Updating Data',
        totalEmissions: 'Total emissions',
        totalEmissions1: 'Total Emissions',
        totalEmissions2: 'Total',
        edit: 'Edit',
        disclosure: 'Disclosure',
        downloadcsv: 'Download CSV Data',
        total: 'Total',
        noIntermediatePro: 'No intermediate product',
        //simulation
        simulation: 'Simulation',
        simulationValue: 'Simulation value',
        actualFigure: 'Actual figure',
        processName: 'Process name',
        back: 'Back',
        done: 'Done',
        saap: 'Save as alternative plan',
        resetSim: 'Reset simulation',
        resetSimPopUp: 'Do you want to reset the simulation data?',
        resetBtn: 'YES',
        //seihin
        disclosureMgmt: 'Disclosure　Management',
        dosave: 'Save',
        companylist: 'Company list',
        selectAll: 'Select all',
        level: 'Level',
        permission: 'EF Permission',
        selectItems: 'Select Items',
        permission2: 'EF Permission',
        limited: 'Limited',
        full: 'Full',
        disclosure2: 'Disclosure',
        confirm: 'Confirm',
        disclosureMgmt2: 'Disclosure　Management',
        pstp: 'Please select the product',
        checkProductName: 'Please select the product',
        //product
        productMasterMgmt: 'Product Master Management',
        productRegistration: 'Product Registration',
        productModelNum: 'Product Model Number',
        externalModelNum: 'External Model Number',
        productName: 'Product name',
        productMasterName: 'Product Name',
        category: 'Category',
        register: 'Register',
        listOfEF: 'List of registered products',
        registeredBy: 'Registered by',
        updateBy: 'Updated by',
        registeredDate: 'Registration date',
        registeredDate1: 'Updated date',
        //original
        originalEFMgmn:'Original EF List/Management',
        efRegister:'EF Registration',
        emissionFactor:'Emission Factor',
        listOfOriginalEF:'List of Registered EF',
        ideaCode:'IDEA Code',
        ideaType: 'IDEA Type',
        remarks:'Remarks',
        CSVImport:'Excel import',
        aAll:'All',
        ideaEF:'IDEA EF',
        originalEF:'Original EF',
        oOwnEF:'Own EF',
        partnerEF:"Partner's EF",
        deleteWarn:"This operation will permanently delete data. Delete data?",
        Confirmation:"Confirmation",
        eEFRegister:"EF　Register",
        masterUnit: 'Primary unit',
        seccondUnitReg: 'Register new secondary unit',
        seccondUnitReged: 'Registered secondary unit',
        conversionRate: 'Conversion rate',
        unitCheckMsg: 'Please enter a unit',
        conversionRateCheckMsg: 'Please enter a conversion rate',
        //process
        processFormatMgmt: 'Process Format Management',
        code: 'Code',
        correspondingStandardProcess: 'Corresponding Standard Process',
        operation: 'Operation',
        cdhnbsR2ps: 'Changed data has not been saved. Return to previous screen?',
        addANewItem: 'Add new item',
        processHeaderName: 'Process Name',
        yes: 'Yes',
        //basicRule
        efRullMgmt: 'EF Rule Management',
        registeredEFRule: 'Registered EF Rule',
        addNewRule: 'add new EF rule',
        domenu: 'Menu',
        psaEF2er: 'Please set an EF to each rule.',
        originalDel: ' This original unit has been deleted.',
        updateRule: 'Update',
        repeatRuleWarningPopup1: 'The new EF rule and an existing rule are overlapping.',
        repeatRuleWarningPopup2: 'Please avoid overlapping of EF rules.',
        repeatRuleWarningPopup3: 'If you would like to edit an existing EF rule please close active window and edit existing EF rule.',        
        editEFRule: 'Edit EF rule',
        deleteEFRule: 'delete EF rule',
        registerEFRule: 'Register new EF rule',
        formatImport: 'Format import',
        formatDownload: 'format download',
        aAddNewEFRule: 'Add new EF rule',
        editExistEFRule: 'Edit existing EF rule',
        originalDel1: 'This EF has been deleted',
        //processCode
        newProcess: 'New process',
        all: 'All',
        a1ToA3: 'A1~A3:Production phase',
        a4ToA5: 'A4~A5:Construction phase',
        b1ToB7: 'B1~B7:Usage phsae',
        c1ToC4: 'C1~C4:Disaposal/recycling phase',
        dTitle: 'D:Indirect impact',
        A1: 'Raw materials/parts procurement',
        A2: 'Upstream logistics (raw materials/parts)',
        A3: 'Product manufacturing',
        A4: 'Downstream logistics (products)',
        A5: 'Construction/installation',
        B1: 'Use (other than energy/water)',
        B2: 'Maintenance',
        B3: 'Repair',
        B4: 'Exchange',
        B5: 'Remodeling',
        B6: 'Energy usage when using products',
        B7: 'Water usage when using products',
        C1: 'Removal/dismantling',
        C2: 'Transportation of used products',
        C3: 'Intermediate processing of used products',
        C4: 'Waste disposal',
        D: 'Indirect impact (recycling effect, etc.)',
        processCancel: 'Cancel',
        processAdd: 'Add Process',
        //update
        wyl2utd: 'Would you like to update the data?',
        update: 'update',
        manageName: 'Item Name',
        eFName: 'EF',
        unit1: 'Unit',
        processContent: 'Please select the process for this calculation.',
        aAmountContent: 'For the Item Name, enter the numerical value of the input volume, emissions volume, consumption volume, etc. The number entered should be based on the base unit. Please convert it into the base unit as necessary.',
        //referenceTree
        referenceTree: 'Look Up Products: Tree',
        iItem: 'Item',
        eF: 'EF',
        //compareAbstract
        compareAbstract: 'Emissions Comparison: Overview',
        tTotalEmissions: 'Total emissions (kg-CO₂eq)',
        standard: 'Standard',
        standardReduce: 'Standard comparison reduction',
        eEmissions: 'Emissions (kg-CO₂eq)',
        compareDetailedTip: 'Check the products to compare detailed emissions data for.',
        abProductVersion: 'Product/Version',
        detailComparison: 'Detail Comparison',
        //compareExplicit
        compareDetails: 'Emissions Comparison: Details',
        pProductVersion: 'Product/version',
        product1: 'Product 1',
        product2: 'Product 2',
        eEmission: 'Emissions (kg-CO₂eq)',
        pPercentage: 'Percentage',
        yYieldRate: 'Yield rate',
        tTotal: 'Total',
        declineTip: 'Products showing a decline in emissions volume are highlighted.',
        //error msg
        peapn: 'Please enter a product name',
        peapnThen: 'Please enter the product name',
        peavn: 'Please enter a version name',
        peefn: 'Please enter an EF name',
        peefnOri: 'Please enter the EF name',
        peaam: 'Please enter an amount',
        peain: 'Please enter an item name',
        penopp: 'Please enter Overall Plant Production',
        peopuca: 'Please enter Overall Plant Utility Consumption(Annual)',
        pepw: 'Please enter Product Weight',
        atiictcpinc: 'Although the item is checked, the corresponding process is not checked.',
        pctcpaw: 'Please check the correct process as well',
        targetProcess: 'Target process',
        peangt0alt100: 'Please enter a number greater than 0 and less than 100',
        successfullyRegistered: 'Successfully registered!',
        petu: 'Please enter the Unit',
        petdept: 'Please enter the Department',
        prapnsiidwtarpn: 'Please register another product name since it is duplicated with the already registered product name.',
        petef: 'Please enter the emission factor',
        petp: 'Please enter the Process',
        halfNumber: 'Please enter a half-width number',
        confirmDeleteMessage: 'Would you like to remove it?',
        buttonDeleteConfirm: 'OK',
        buttonCancel: 'Cancel',
        atLeastOneProcess: 'Please select one or more processes',
        atLeastOneProcessButton: 'OK',
        originMsg: 'Please register another EF name since it is duplicated with the already registered EF name.',
        popupTyep: 'Confirmation',
        csvTitle1: 'Subject',
        csvTitle2: 'Error Messages',
        pstsp: 'Please select the Standard Process',
        download: 'Download',
        deletButton: 'Delete',
        simulatCheckItem: 'Please enter the Item name',
        simulatCheckIdea: 'Please enter the EF name',
        simulateCheckRightIdea: 'Please enter the correct EF name',
        simulateCheckRightNumber: 'Please do not enter 0',
        simulatCheckQut: 'Please enter Amount',
        simulatCheckBuri: 'Please enter a number greater than 0 and less than 100',
        addCheckParSame: 'An item name that is the same as the managed item name cannot be specified',
        addCheckParNo: 'Please enter the managed item name of the parent row',
        oriCsvTitle1: 'Applicable cell',
        oriCsvTitle2: 'Error message'

    }
}