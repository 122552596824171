<template>
  <div class="margin-24 reference">
    <div class="card-box">
      <div class="header-layout">
        <el-row :gutter="16" class="width-33">
          <el-col :span="14">
            <div v-loading="loading4SeihinList" class="production-item">
              <div class="content-layout-start">
                <span class="margin-bot-8 margin-right-16">{{$t('messages.productVersion')}}</span>
                <el-popover v-if="seihinAuth === 0" placement="right" trigger="click">
                  <textarea v-model="simulateNote" readonly="true" cols="40" rows="10" maxlength="3000" :placeholder="$t('messages.note')" />
                  <span slot="reference" class="icon-note"><img src="@/assets/static/icon/note.svg"></span>
                </el-popover>
                <div v-show="showIcon && seihinAuth === 0">
                  <i class="el-icon-price-tag" style="margin-left:1rem; padding-top: 3px;transform: rotate(315deg); color: #1d97d8; cursor: pointer;" @click="showSeihinMasterPopup(true)" />
                </div>
                <span v-show="showFlag" class="margin-left-16" style="color: #F56C6C;font-size: 12px;line-height: 1;width: 50%">* 該当製品は製品マスタから削除されています</span>
              </div>
              <el-form ref="selectValueDefaultForm" :model="selectValueDefaultForm" :rules="selectValueDefaultRules">
                <el-form-item prop="selectValueDefault">
                  <el-select v-model="selectValueDefaultForm.selectValueDefault" :title="temptitle" :placeholder="$t('messages.checkProductName')" class="seihin-select" size="mini" filterable @change="changeSeihin">
                    <el-option
                      v-for="item in seihinList"
                      :key="item.seihincode + '/' + item.version"
                      :label="item.version ? `${item.seihinname} ${item.version}` : item.seihinname"
                      :value="item.seihincode + '/' + item.version"
                      :disabled="item.disabled"
                    />
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="production-item">
              <span class="margin-bot-8">{{$t('messages.unit')}}</span>
              <el-input :value="seihinUnit" size="mini" disabled />
            </div>
          </el-col>
          <el-col :span="7">
            <div v-show="isSelfIdea" class="production-item">
              <span class="self-idea">{{$t('messages.ownEFRegComplete')}}</span>
            </div>
          </el-col>
        </el-row>
        <div v-if="seihinAuth === 0" class="infoAndhensyu header_button_area">
          <div class="self_idea">
            <div class="button_top_block"><i slot="reference" class="el-icon-question hidden_block" /></div>
            <el-button v-show="canEdit" type="primary" size="small" @click="toReport()">レポート</el-button>
          </div>
          <el-dialog class="dialog-control"
            top="32vh"
            :visible.sync="dialog_flag"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="30%"
            center
            append-to-body
            >
            <span v-html="dialog_index == 1 ?'レポート作成機能は、管理者権限の方のみご利用できます。<br>貴社内でご確認ください。':'レポート作成機能を利用するにはご契約が必要です。<br>LCA Plus担当者へお問い合わせください。'"></span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialog_flag = false">閉じる</el-button>
              <el-button v-show="dialog_index == 2" type="primary" @click="reportPlan()">問合せ</el-button>
            </span>
          </el-dialog>
          <div class="self_idea">
            <div class="button_top_block">
              <el-popover
                placement="top-end"
                class="suggestion-icon"
                width="200"
                trigger="hover"
                :content="$t('messages.ownEFRegisterTip')"
              >
                <i slot="reference" class="el-icon-question" />
              </el-popover>
            </div>
            <el-button v-show="canEdit" type="primary" size="small" :disabled="isSelfIdea" @click="makeSelfIdea()">{{$t('messages.ownEFRegister')}}<i class="margin-left-8 img-size el-icon-refresh-right" /></el-button>
          </div>
          <div class="self_idea">
            <div class="button_top_block"><i slot="reference" class="el-icon-question hidden_block" /></div>
            <el-button v-show="canEdit" type="primary" size="small" @click="toCampare()">{{$t('messages.compare')}}<i class="margin-left-8 img-size"><img src="@/assets/static/icon/nav-09.svg"></i></el-button>
          </div>
          <div class="self_idea" style="margin-right: 0px">
            <div class="button_top_block"><i slot="reference" class="el-icon-question hidden_block" /></div>
            <el-button v-show="canEdit" type="primary" size="small" @click="toSimulate()">{{$t('messages.simulationTitle')}}<i class="margin-left-8 img-size"><img src="@/assets/static/icon/simulation.svg"></i></el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-tb-24">
      <div class="card-box">
        <div class="content-layout-noflex margin-bot-16" style="width: 100%">
          <div>
            <h5 class="about-title content-layout-center-noflex">
              <span class="left-line margin-right-4">{{$t('messages.productOverview')}}</span>
              <span>
                  <el-popover v-if="seihinAuth !== 0 && processTableData.noShow.emissionc > 0" placement="right" trigger="hover" width="230">
                    <p>{{$t('messages.triangleTip')}}</p>
                    <i slot="reference"><img class="icon-size" src="@/assets/static/icon/explanation.svg"></i>
                  </el-popover>
              </span>
            </h5>
          </div>
          <div>
            <span v-show="selectValueDefaultForm.selectValueDefault" @click="downLoadCSV()" class="btn-function margin-right-16" style="cursor: pointer;"><img class="download-icon" style="height: 1.4rem;width: 1.4rem;margin-right:0.8rem;" src="@/assets/static/icon/download.svg"><span>{{$t('messages.downloadcsv')}}</span></span>
            <el-button v-show="isTreeSeihin" type="primary" size="small" @click="toTree()">{{$t('messages.treeList')}}<i class="el-icon-s-fold margin-left-8 img-size" /></el-button>          
            <el-button v-show="selectValueDefaultForm.selectValueDefault" type="primary" size="small" @click="toSingle()">{{$t('messages.viewDetails')}}<i class="el-icon-view margin-left-8 img-size" /></el-button>
          </div>
        </div>
        <div class="content-layout">
          <div v-loading="loading4Pie" class="echart-container">
            <div id="pieChart" class="refer-chart" />
            <div v-show="seihinVersion" class="totalNum">{{ toScientificNum(processTableData.sum.sum)[0] }} {{ toScientificNum(processTableData.sum.sum)[1] }}<sup>{{ toScientificNum(processTableData.sum.sum)[2] }}</sup></div>
            <div class="popbtn" style="width: 90%;display: flex;justify-content: center;align-items: center;">
              <div v-if="seihinAuth == 0">
              <el-popover popper-class="pop" ref="popover" placement="top-start" trigger="manual" v-model="showPopover">
                <el-button type="primary" @click="cancel()" icon="el-icon-close" style="float:right;background-color: #FFF;color: #C0C4CC;border: none;"></el-button>
                <div class="pop-table" style="display: flex ;width: 100%;padding-left: 1%;">
                  <table width="100%" style="table-layout:fixed;">
                      <tr>
                      <th style="width: 6%;"><div class="table_headers"></div></th>
                      <th style="width: 8%;"><div class="table_headers_input"><span class="title">{{$t('messages.productUnit')}}</span></div></th>
                      <th style="width: 10%;"><div class="table_headers_input"><span class="title">{{$t('messages.totalEmissions1')}}</span></div></th>
                      <th style="width: 10%;"><div class="table_headers">
                        <img src="@/assets/static/process/a1.svg" height="50px" width="50px">
                        <span class="title">{{$t('messages.rmp')}}</span>
                        <h6 class="flag">（A1）</h6>
                        <span style="font-size: 1px; color: darkgrey;">(kg-CO₂eq)</span>
                      </div></th>
                      <th style="width: 10%;"><div class="table_headers">
                        <img src="@/assets/static/process/a2.svg" height="50px" width="50px">
                        <span class="title">{{$t('messages.transportation')}}</span>
                        <h6 class="flag">（A2・A4）</h6>
                        <span style="font-size: 1px; color: darkgrey;">(kg-CO₂eq)</span>
                      </div></th>
                      <th style="width: 10%;"><div class="table_headers">
                        <img src="@/assets/static/process/a3.svg" height="50px" width="50px">
                        <span class="title">{{$t('messages.production')}}</span>
                        <h6 class="flag">（A3）</h6>
                        <span style="font-size: 1px; color: darkgrey;">(kg-CO₂eq)</span>
                      </div></th>
                      <th style="width: 10%;"><div class="table_headers">
                        <img src="@/assets/static/process/b1.svg" height="50px" width="50px">
                        <span class="title">{{$t('messages.usage')}}</span>
                        <h6 class="flag">（B）</h6>
                        <span style="font-size: 1px; color: darkgrey;">(kg-CO₂eq)</span>
                      </div></th>
                      <th style="width: 10%;"><div class="table_headers">
                        <img src="@/assets/static/process/c1.svg" height="50px" width="50px">
                        <span class="title">{{$t('messages.disposalRecycling')}}</span>
                        <h6 class="flag">（C）</h6>
                        <span style="font-size: 1px; color: darkgrey;">(kg-CO₂eq)</span>
                      </div></th>
                      <th style="width: 8%;"><div class="table_headers_input"><span class="title">{{$t('messages.conversionCoef')}}</span></div></th>
                      <th style="width: 10%; white-space: nowrap;"></th>
                      </tr>
                      <tr>
                        <td style="width: 6%;"><span class="title">{{$t('messages.mainUnit')}}</span></td>
                        <td style="width: 8%;"><div class="table_input"><el-input style="height: 35px;" size="medium" :value="seihinUnit" :disabled="true" ></el-input></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.sum.sum)[0] }} {{ toScientificNum(processTableData.sum.sum)[1] }}<sup>{{ toScientificNum(processTableData.sum.sum)[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.A1.emissionc)[0] }} {{ toScientificNum(processTableData.A1.emissionc)[1] }} <sup>{{ toScientificNum(processTableData.A1.emissionc)[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.A2A4.emissionc)[0] }} {{ toScientificNum(processTableData.A2A4.emissionc)[1] }} <sup>{{ toScientificNum(processTableData.A2A4.emissionc)[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.A3.emissionc)[0] }} {{ toScientificNum(processTableData.A3.emissionc)[1] }} <sup>{{ toScientificNum(processTableData.A3.emissionc)[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.B.emissionc)[0] }} {{ toScientificNum(processTableData.B.emissionc)[1] }} <sup>{{ toScientificNum(processTableData.B.emissionc)[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.C.emissionc)[0] }} {{ toScientificNum(processTableData.C.emissionc)[1] }} <sup>{{ toScientificNum(processTableData.C.emissionc)[2] }}</sup></div></td>
                        <td style="width: 8%;"></td>
                        <td style="width: 10%; white-space:nowrap;"></td>
                      </tr>
                      <tr>
                        <td style="width: 6%;"><span class="title">{{$t('messages.subunit1')}}</span></td>
                        <td style="width: 8%;"><div class="table_input"><el-input size="medium" v-model="unit[0]" @change="getInputValue()" ></el-input></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.sum.sum * Number(percent[0]))[0] }} {{ toScientificNum(processTableData.sum.sum * Number(percent[0]))[1] }}<sup>{{ toScientificNum(processTableData.sum.sum * Number(percent[0]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.A1.emissionc * Number(percent[0]))[0] }} {{ toScientificNum(processTableData.A1.emissionc * Number(percent[0]))[1] }} <sup>{{ toScientificNum(processTableData.A1.emissionc * Number(percent[0]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.A2A4.emissionc * Number(percent[0]))[0] }} {{ toScientificNum(processTableData.A2A4.emissionc * Number(percent[0]))[1] }} <sup>{{ toScientificNum(processTableData.A2A4.emissionc * Number(percent[0]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.A3.emissionc * Number(percent[0]))[0] }} {{ toScientificNum(processTableData.A3.emissionc * Number(percent[0]))[1] }} <sup>{{ toScientificNum(processTableData.A3.emissionc * Number(percent[0]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.B.emissionc * Number(percent[0]))[0] }} {{ toScientificNum(processTableData.B.emissionc * Number(percent[0]))[1] }} <sup>{{ toScientificNum(processTableData.B.emissionc * Number(percent[0]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.C.emissionc * Number(percent[0]))[0] }} {{ toScientificNum(processTableData.C.emissionc * Number(percent[0]))[1] }} <sup>{{ toScientificNum(processTableData.C.emissionc * Number(percent[0]))[2] }}</sup></div></td>
                        <td style="width: 8%;"><div class="table_input"><el-input size="medium" v-model="percent[0]" @input="validateCoefficient(percent[0], 0)" placeholder="" :disabled="!unit[0]"></el-input></div></td>
                        <td style="width: 10%; white-space:nowrap;"><span v-show="messWarn_0" style="color: #F56C6C;font-size: 0.8rem;line-height: 1;">半角数値を入力してください</span><span v-show="messNull_0" style="color: #F56C6C;font-size: 0.8rem;line-height: 1;">係数を記入してください</span></td>
                      </tr>
                      <tr>
                        <td style="width: 6%;"><span class="title">{{$t('messages.subunit2')}}</span></td>
                        <td style="width: 8%;"><div class="table_input"><el-input size="medium" v-model="unit[1]" @change="getInputValue()"></el-input></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.sum.sum * Number(percent[1]))[0] }} {{ toScientificNum(processTableData.sum.sum * Number(percent[1]))[1] }}<sup>{{ toScientificNum(processTableData.sum.sum * Number(percent[1]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.A1.emissionc * Number(percent[1]))[0] }} {{ toScientificNum(processTableData.A1.emissionc * Number(percent[1]))[1] }} <sup>{{ toScientificNum(processTableData.A1.emissionc * Number(percent[1]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.A2A4.emissionc * Number(percent[1]))[0] }} {{ toScientificNum(processTableData.A2A4.emissionc * Number(percent[1]))[1] }} <sup>{{ toScientificNum(processTableData.A2A4.emissionc * Number(percent[1]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.A3.emissionc * Number(percent[1]))[0] }} {{ toScientificNum(processTableData.A3.emissionc * Number(percent[1]))[1] }} <sup>{{ toScientificNum(processTableData.A3.emissionc * Number(percent[1]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.B.emissionc * Number(percent[1]))[0] }} {{ toScientificNum(processTableData.B.emissionc * Number(percent[1]))[1] }} <sup>{{ toScientificNum(processTableData.B.emissionc * Number(percent[1]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.C.emissionc * Number(percent[1]))[0] }} {{ toScientificNum(processTableData.C.emissionc * Number(percent[1]))[1] }} <sup>{{ toScientificNum(processTableData.C.emissionc * Number(percent[1]))[2] }}</sup></div></td>
                        <td style="width: 8%;"><div class="table_input"><el-input size="medium" v-model="percent[1]" @input="validateCoefficient(percent[1], 1)" placeholder="" :disabled="!unit[1]"></el-input></div></td>
                        <td style="width: 10%; white-space:nowrap;"><span v-show="messWarn_1" style="color: #F56C6C;font-size: 0.8rem;line-height: 1;">半角数値を入力してください</span><span v-show="messNull_1" style="color: #F56C6C;font-size: 0.8rem;line-height: 1;">係数を記入してください</span></td>
                      </tr>
                      <tr>
                        <td style="width: 6%;"><span class="title">{{$t('messages.subunit3')}}</span></td>
                        <td style="width: 8%;"><div class="table_input"><el-input size="medium" v-model="unit[2]" @change="getInputValue()" ></el-input></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.sum.sum * Number(percent[2]))[0] }} {{ toScientificNum(processTableData.sum.sum * Number(percent[2]))[1] }}<sup>{{ toScientificNum(processTableData.sum.sum * Number(percent[2]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.A1.emissionc * Number(percent[2]))[0] }} {{ toScientificNum(processTableData.A1.emissionc * Number(percent[2]))[1] }} <sup>{{ toScientificNum(processTableData.A1.emissionc * Number(percent[2]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.A2A4.emissionc * Number(percent[2]))[0] }} {{ toScientificNum(processTableData.A2A4.emissionc * Number(percent[2]))[1] }} <sup>{{ toScientificNum(processTableData.A2A4.emissionc * Number(percent[2]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.A3.emissionc * Number(percent[2]))[0] }} {{ toScientificNum(processTableData.A3.emissionc * Number(percent[2]))[1] }} <sup>{{ toScientificNum(processTableData.A3.emissionc * Number(percent[2]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.B.emissionc * Number(percent[2]))[0] }} {{ toScientificNum(processTableData.B.emissionc * Number(percent[2]))[1] }} <sup>{{ toScientificNum(processTableData.B.emissionc * Number(percent[2]))[2] }}</sup></div></td>
                        <td style="width: 10%;"><div class="table_contents">{{ toScientificNum(processTableData.C.emissionc * Number(percent[2]))[0] }} {{ toScientificNum(processTableData.C.emissionc * Number(percent[2]))[1] }} <sup>{{ toScientificNum(processTableData.C.emissionc * Number(percent[2]))[2] }}</sup></div></td>
                        <td style="width: 8%;"><div class="table_input"><el-input size="medium" v-model="percent[2]" @input="validateCoefficient(percent[2], 2)" placeholder="" :disabled="!unit[2]"></el-input></div></td>
                        <td style="width: 10%; white-space:nowrap;"><span v-show="messWarn_2" style="color: #F56C6C;font-size: 0.8rem;line-height: 1;">半角数値を入力してください</span><span v-show="messNull_2" style="color: #F56C6C;font-size: 0.8rem;line-height: 1;">係数を記入してください</span></td>
                      </tr>
                   </table>

                </div>
                <div style="display: flex; align-items: center; justify-content: center; padding-top: 10px; ">
                  <el-button style="color: #FFF; background-color: #1D97D8; border-color: #1D97D8;" size="mini" type="primary" @click="toSave()" :disabled="btnDisabled" plain>{{$t('messages.save')}}</el-button> 
                </div>
              <el-button style="color: #FFF; background-color: #1D97D8; border-color: #1D97D8;" slot="reference" @click="queryLastData()" size="small">{{$t('messages.referSubUnit')}}</el-button>
              </el-popover>
            </div>
          </div>
          </div>
          <div class="right-content">
            <el-row :gutter="16">
              <el-col :span="8">
                <div class="block-content">
                  <div><img src="@/assets/static/process/a1.svg"></div>
                  <div class="card-right">
                    <h6 class="card-title">{{$t('messages.rmp')}}<span class="flag">（A1）</span></h6>
                    <div class="content-layout-start">
                      <div class="width-60">
                        <span class="direction-title margin-right-16">{{$t('messages.emissionc')}}</span>
                        <p>{{ toScientificNum(processTableData.A1.emissionc)[0] }} {{ toScientificNum(processTableData.A1.emissionc)[1] }} <sup>{{ toScientificNum(processTableData.A1.emissionc)[2] }}</sup></p>
                      </div>
                      <div>
                        <span class="direction-title">{{$t('messages.percentage')}}</span>
                        <p>{{ processTableData.A1.percentage }}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="block-content">
                  <div class="card-img"><img src="@/assets/static/process/a2.svg"></div>
                  <div class="card-right">
                    <h6 class="card-title">{{$t('messages.transportation')}}<span class="flag">（A2・A4）</span></h6>
                    <div class="content-layout-start">
                      <div class="width-60">
                        <span class="direction-title margin-right-16">{{$t('messages.emissionc')}}</span>
                        <p>{{ toScientificNum(processTableData.A2A4.emissionc)[0] }} {{ toScientificNum(processTableData.A2A4.emissionc)[1] }} <sup>{{ toScientificNum(processTableData.A2A4.emissionc)[2] }}</sup></p>
                      </div>
                      <div>
                        <span class="direction-title">{{$t('messages.percentage')}}</span>
                        <p>{{ processTableData.A2A4.percentage }}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="block-content">
                  <div><img src="@/assets/static/process/a3.svg"></div>
                  <div class="card-right">
                    <h6 class="card-title">{{$t('messages.production')}}<span class="flag">（A3）</span></h6>
                    <div class="content-layout-start">
                      <div class="width-60">
                        <span class="direction-title margin-right-16">{{$t('messages.emissionc')}}</span>
                        <p>{{ toScientificNum(processTableData.A3.emissionc)[0] }} {{ toScientificNum(processTableData.A3.emissionc)[1] }} <sup>{{ toScientificNum(processTableData.A3.emissionc)[2] }}</sup></p>
                      </div>
                      <div>
                        <span class="direction-title">{{$t('messages.percentage')}}</span>
                        <p>{{ processTableData.A3.percentage }}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="16" class="margin-top-16">
              <el-col :span="8">
                <div class="block-content">
                  <div><img src="@/assets/static/process/b1.svg"></div>
                  <div class="card-right">
                    <h6 class="card-title">{{$t('messages.usage')}}<span class="flag">（B）</span></h6>
                    <div class="content-layout-start">
                      <div class="width-60">
                        <span class="direction-title margin-right-16">{{$t('messages.emissionc')}}</span>
                        <p>{{ toScientificNum(processTableData.B.emissionc)[0] }} {{ toScientificNum(processTableData.B.emissionc)[1] }} <sup>{{ toScientificNum(processTableData.B.emissionc)[2] }}</sup></p>
                      </div>
                      <div>
                        <span class="direction-title">{{$t('messages.percentage')}}</span>
                        <p>{{ processTableData.B.percentage }}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="block-content">
                  <div class="card-img"><img src="@/assets/static/process/c1.svg"></div>
                  <div class="card-right">
                    <h6 class="card-title">{{$t('messages.disposalRecycling')}}<span class="flag">（C）</span></h6>
                    <div class="content-layout-start">
                      <div class="width-60">
                        <span class="direction-title margin-right-16">{{$t('messages.emissionc')}}</span>
                        <p>{{ toScientificNum(processTableData.C.emissionc)[0] }} {{ toScientificNum(processTableData.C.emissionc)[1] }} <sup>{{ toScientificNum(processTableData.C.emissionc)[2] }}</sup></p>
                      </div>
                      <div>
                        <span class="direction-title">{{$t('messages.percentage')}}</span>
                        <p>{{ processTableData.C.percentage }}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col v-if="seihinAuth !== 0" :span="8">
                <div class="block-content">
                  <div><img src="@/assets/static/process/a5.svg"></div>
                  <div class="card-right">
                    <h6 class="card-title">{{$t('messages.noShow')}}<span class="flag" /></h6>
                    <div class="content-layout-start">
                      <div class="width-60">
                        <span class="direction-title margin-right-16">{{$t('messages.emissionc')}}</span>
                        <p>{{ toScientificNum(processTableData.noShow.emissionc)[0] }} {{ toScientificNum(processTableData.noShow.emissionc)[1] }} <sup>{{ toScientificNum(processTableData.noShow.emissionc)[2] }}</sup></p>
                      </div>
                      <div>
                        <span class="direction-title">{{$t('messages.percentage')}}</span>
                        <p>{{ processTableData.noShow.percentage }}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <el-row :gutter="24">
      <el-col :span="12">
        <div class="card-box padding-16 fixedtable">
          <h5 class="about-title"><span class="left-line">{{$t('messages.hotspotRanking')}}</span></h5>
          <el-table :data="tableData" style="width: 100%" class="border-1 margin-top-20" height="261">
            <el-table-column prop="processcode" :label="$t('messages.process')" width="100" />
            <el-table-column prop="itemname" :label="$t('messages.managedItem')" width="230">
              <template slot-scope="scope">
                <span>
                  <el-tooltip class="item" effect="dark" :content="scope.row.itemname" placement="top">
                    <span class="colum-width">{{ scope.row.itemname }}</span>
                  </el-tooltip>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="name" :label="$t('messages.ef')" width="230">
              <template slot-scope="scope">
                <span class="colum-width">
                  <el-tooltip class="item" effect="dark" :content="scope.row.displayname" placement="top">
                    <span class="colum-width">{{ scope.row.displayname }}</span>
                  </el-tooltip>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="emissionc" :label="$t('messages.emission')">
              <template slot-scope="scope">
                <p>
                  {{ toScientificNum(scope.row.emissionc)[0] }}
                  {{ toScientificNum(scope.row.emissionc)[1] }}
                  <sup>{{ toScientificNum(scope.row.emissionc)[2] }}</sup>
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="pre" :label="$t('messages.ratio')">
              <template slot-scope="scope">
                <p v-if="scope.row.processcode === 'D'">-</p>
                <p v-else>{{ scope.row.pre }}%</p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="card-box min-height">
          <h5 class="about-title margin-bot-16"><span class="left-line">{{$t('messages.cwop')}}</span></h5>
          <div class="margin-bot-16">
            <span class="sub-title margin-bot-4">{{$t('messages.eotpv')}}</span>
            <div class="font-18">{{ toScientificNum(sumEmissionc)[0] }} {{ toScientificNum(sumEmissionc)[1] }} <sup>{{ toScientificNum(sumEmissionc)[2] }}</sup></div>
          </div>
          <div v-loading="loading4SeihinList" class="production-item margin-bot-24">
            <div class="content-layout">
              <span class="margin-bot-4 margin-right-16 sub-title">{{$t('messages.comparewith')}}</span>
              <el-popover v-if="otherSeihinAuth === 0" placement="right" trigger="click">
                <textarea v-model="otherSimulateNote" readonly="true" cols="40" rows="10" maxlength="3000" :placeholder="$t('messages.note')" />
                <span slot="reference" style="cursor: pointer;"><img src="@/assets/static/icon/note.svg"></span>
              </el-popover>
            </div>
            <el-select v-model="selectOtherValueDefault" :title="temptitle" :placeholder="$t('messages.checkProductName')" class="seihin-select" size="mini" @change="changeOtherSeihin">
              <el-option
                v-for="item in seihinList"
                :key="item.seihincode + '/' + item.version"
                :label="item.version ? `${item.seihinname} ${item.version}` : item.seihinname"
                :value="item.seihincode + '/' + item.version"
                :disabled="item.disabled"
              />
            </el-select>
          </div>
          <div class="margin-bot-16">
            <span class="sub-title margin-bot-8">{{$t('messages.emissionc')}}</span>
            <div v-if="sumOtherEmissionc !== ''" class="font-18">{{ toScientificNum(sumOtherEmissionc)[0] }} {{ toScientificNum(sumOtherEmissionc)[1] }} <sup>{{ toScientificNum(sumOtherEmissionc)[2] }}</sup></div>
            <!-- <div v-else class="font-18">{{ toScientificNum(sumOtherEmissioncBackup)[0] }} {{ toScientificNum(sumOtherEmissioncBackup)[1] }} <sup>{{ toScientificNum(sumOtherEmissioncBackup)[2] }}</sup></div> -->
            <div v-else class="font-18">-</div>
          </div>
          <div>
            <span class="sub-title margin-bot-8">{{$t('messages.reduction')}}</span>
            <div v-if="sumOtherEmissionc !== ''" class="font-14"><strong class="weight-600">{{ Math.abs(reduction) }}</strong>%
              <i v-if="reduction > 0" class="el-icon-bottom-right icon-size-green" />
              <i v-if="reduction < 0" class="el-icon-top-right icon-size-orange" />
            </div>
            <div v-else class="font-14">-</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="card-box margin-bot-16">
          <h5 class="about-title margin-bot-24"><span class="left-line">{{$t('messages.uopef')}}</span></h5>
          <div class="content-layout">
            <div class="margin-right-8">
              <span class="sub-title margin-bot-16">{{$t('messages.usedef')}}</span>
              <div class="font-14"><strong class="weight-600 margin-right-4">{{ originalUseData.count }}</strong>{{$t('messages.items')}}</div>
            </div>
            <div class="margin-left-8">
              <span class="sub-title margin-bot-16">{{$t('messages.rwte')}}</span>
              <div v-if="originalUseData.pre !== 0 && !isNaN(originalUseData.pre)" class="font-14"><strong class="weight-600 margin-right-4">{{ (originalUseData.pre * 100).toFixed(2) }}</strong>%</div>
              <div v-else class="font-14"><strong class="weight-600 margin-right-4">-</strong></div>
            </div>
          </div>
        </div>
        <div class="card-box">
          <h5 class="about-title  margin-bot-24"><span class="left-line">{{$t('messages.car2c')}}</span></h5>
          <div class="content-layout">
            <div class="margin-right-8">
              <span class="sub-title margin-bot-16">{{$t('messages.checkItems')}}</span>
              <div class="font-16"><strong class="weight-600 margin-right-4">{{ finishCount }}</strong>/{{ totalCount }}
                <span class="margin-left-8">
                  <span slot="reference" style="cursor: pointer;"><img src="@/assets/static/icon/17list.svg" @click="getCheckListData"></span>
                </span>
              </div>
            </div>
            <el-dialog
              :title="$t('messages.calQualityList')"
              :visible.sync="centerDialogVisible"
              :lock-scroll="false"
              :append-to-body="true"
              width="70%"
              center
            >
              <div class="popup-height">
                <div class="check-note">{{$t('messages.check')}}</div>
                <div v-for="(item, index) in checkList" :key="index" class="popup-area">
                  <div class="check-area"><el-checkbox v-model="item.checkflag" disabled /></div>
                  <div class="check-content">
                    <el-collapse>
                      <div class="check-list-title check-break-line">{{ item.checkitem.replaceAll('\\n', '\n') }}</div>
                      <el-collapse-item name="1">
                        <div class="font-color check-break-line">{{ item.checkitemComment.replaceAll('\\n', '\n') }}</div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button class="btn-plain" @click="centerDialogVisible = false">{{$t('messages.back')}}</el-button>
                <el-button class="btn-color" type="primary" @click="closeDialog">完了</el-button>
              </span>
            </el-dialog>
            <div class="margin-left-8">
              <span class="sub-title margin-bot-16">{{$t('messages.reported2Partners')}}</span>
              <div v-if="seihinAuth === 0" class="font-14"><strong class="weight-600 margin-right-4">{{ companiesNum }}</strong><span></span>{{$t('messages.companies')}}</div>
              <div v-else class="font-14"><strong class="weight-600 margin-right-4">-</strong></div>
            </div>
          </div>
        </div>
        <seihinPopup
          v-if="isShowPopup"
          v-show="isShowPopup"
          :seihinMasterDataRe="seihinMasterData"
          :columnNameDataRe="columnNameData"
          @seihinMasterCall="parentSeihinPopup"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import './reference.scss'
import * as echarts from 'echarts'
import commonJs from '@/mixins/commonJs'
import {
  getSeihinListAPI,
  removeIdeaCacheAPI,
  getVersionMasterAPI,
  getFinshCountAPI,
  checkSeihinOrgProcessAPI,
  getProcessTableAPI,
  getTopVAPI,
  getSumEmissioncAPI,
  getIdeaAndPreAPI,
  getCheckListReportAPI,
  checkTreeSeihin,
  setSelfIdea,
  checkSelfIdea,
  saveSeihinTempAPI,
  searchSeihinTempAPI,
  checkLoopQuote,
  exportExcelAPI
} from '@/api/browser'
import { getCheckListAPI } from '@/api/input'
import { getKigyouInfoAPI } from '@/api/report'
import { checkMasterDeleteAPI, getColumnNamesAPI, getSeihinMasterAPI } from '@/api/productMaster'
import { SeihinPopup } from '@/layout/components'
import {times, divide} from '../../utils/number'
export default {
  components: {
    'seihinPopup': SeihinPopup
  },
  mixins: [commonJs],
  data() {
    return {
      selectValueDefaultForm: {
        selectValueDefault: ''
      },
      selectValueDefaultRules: {
        selectValueDefault: [
          { required: true, message: this.$t('messages.checkProductName'), trigger: 'blur' }
        ]
      },
      temptitle: '',
      centerDialogVisible: false,
      simulateNote: '',
      otherSimulateNote: '',
      budomarichecked: true,
      // budomari: '',
      seihinUnit: '',
      seihinAuth: 99,
      otherSeihinAuth: 99,
      isShowLinkPopup: false,
      canEdit: true,
      kigyouCode: '',
      updUser: '',
      seihinCode: '',
      seihinVersion: '',
      seihinname: '',
      selectValueDefault: '',
      selectOtherValueDefault: '',
      loading4SeihinList: true,
      loading4Pie: true,
      isShowPopup: false,
      seihinMasterData: {},
      columnNameData: {
        kigyouCode: '',
        columnName1: '任意項目１',
        columnName2: '任意項目２',
        columnName3: '任意項目３',
        columnName4: '任意項目４',
        columnName5: '任意項目５',
        upduser: ''
      },
      seihinList: [],
      tableData: [],
      pieData: [],
      colorData: [],
      colorSorted: [],
      pieDataSorted: [],
      sortFlg: true,
      isExpansion: false,
      checkList: [],
      totalCount: '',
      finishCount: '',
      oriprocessFlg: 0,
      processTableData: {
        A1: {
          percentage: 0,
          emissionc: 0
        },
        A2A4: {
          percentage: 0,
          emissionc: 0
        },
        A3: {
          percentage: 0,
          emissionc: 0
        },
        A5: {
          percentage: 0,
          emissionc: 0
        },
        B: {
          percentage: 0,
          emissionc: 0
        },
        C: {
          percentage: 0,
          emissionc: 0
        },
        noShow: {
          percentage: 0,
          emissionc: 0
        },
        sum: {
          sum: 0
        }
      },
      sumEmissionc: 0,
      sumOtherEmissionc: '',
      sumOtherEmissioncBackup: 0,
      reduction: 0,
      originalUseData: {},
      companiesNum: 0,
      pieColorArr: [
        '#007FC3',
        '#1D97D8',
        '#54B2E3',
        '#89D1F7',
        '#BAE2F7',
        '#E4F1F8',
        '#E4F1F8',
        '#E4F1F8'
      ],
      isTreeSeihin: false,
      isSelfIdea: false,
      percent:['', '', ''],
      unit:['', '', ''],
      btnDisabled:true,
      messWarn_0: false,
      messWarn_1: false,
      messWarn_2: false,
      messNull_0: false,
      messNull_1: false,
      messNull_2: false,
      showPopover: false,
      showFlag: false,
      showIcon: false,
      dialog_flag: false,
      dialog_index: 1
    }
  },
  watch: {
    selectValueDefaultForm: {
      handler(val) {
        this.$refs['selectValueDefaultForm'].validate((valid) => {
          if (valid) {
            return true
          } else {
            return false
          }
        })
      },
      deep: true
    },
    '$i18n.locale'(){
      this.selectValueDefaultRules = {
        selectValueDefault: [
          { required: true, message: this.$t('messages.checkProductName'), trigger: 'blur' }
        ]
      },
      this.getProcessTable();
      for (var i = 0; i < this.seihinList.length; i++) {
        if (this.seihinList[i].seihincode === "0") {
          this.seihinList[i].seihinname = this.$t('messages.selfCompany')
        }
        if (this.seihinList[i].seihincode === "1") {
          this.seihinList[i].seihinname = this.$t('messages.otherCompany')
        }
      }
    }
  },
  mounted() {
    this.$refs['selectValueDefaultForm'].validate((valid) => {
      if (!valid) {
        return false
      }
    })
    removeIdeaCacheAPI()
    // console.log('this.$store.state.user::::single', this.$store.state.user)
    this.kigyouCode = JSON.parse(this.$store.state.user).kigyoucode
    this.updUser = JSON.parse(this.$store.state.user).userid
    getSeihinListAPI(this.kigyouCode)
      .then(async res => {
        if (res.code === 200) {
          if ((res.data[0].length + res.data[1].length) > 0) {
            // this.seihinList = res.data
            var seihinList4self = res.data[0]
            var seihinList4other = res.data[1]
            seihinList4self.unshift({ 'seihincode': '0', 'seihinname': this.$t('messages.selfCompany'), 'version': '', 'disabled': true })
            seihinList4other.unshift({ 'seihincode': '1', 'seihinname': this.$t('messages.otherCompany'), 'version': '', 'disabled': true })
            this.seihinList = seihinList4self.concat(seihinList4other)
            // this.seihinList.forEach(element => {
            //   if (element.version[element.version.length - 3] === '/') {
            //     element.displayVersion = element.version.replace('/', '')
            //   } else {
            //     element.displayVersion = element.version
            //   }
            // })
            if (this.seihinList.length === 0) {
              this.canEdit = false
            }
            if (this.$route.params.fromPage === 'navbar') {
              this.selectValueDefaultForm.selectValueDefault = ''
              this.seihinVersion = ''
              this.seihinCode = ''
            } else if (this.$route.params.seihinCode) {
              this.selectValueDefaultForm.selectValueDefault = this.$route.params.seihinCode + '/' + this.$route.params.version
              // this.selectOtherValueDefault = this.$route.params.seihinCode + '/' + this.$route.params.version
              this.seihinVersion = this.$route.params.version
              this.seihinCode = this.$route.params.seihinCode
            } else if (this.$store.state.seihin) {
              this.selectValueDefaultForm.selectValueDefault = JSON.parse(this.$store.state.seihin).seihinCode + '/' + JSON.parse(this.$store.state.seihin).version
              // this.selectOtherValueDefault = JSON.parse(this.$store.state.seihin).seihinCode + '/' + JSON.parse(this.$store.state.seihin).version
              this.seihinVersion = JSON.parse(this.$store.state.seihin).version
              this.seihinCode = JSON.parse(this.$store.state.seihin).seihinCode
            } else {
              this.selectValueDefaultForm.selectValueDefault = this.seihinList[1]['seihincode'] + '/' + this.seihinList[1]['version']
              // this.selectOtherValueDefault = this.seihinList[1]['seihincode'] + '/' + this.seihinList[1]['version']
              this.seihinVersion = this.seihinList[1]['version']
              this.seihinCode = this.seihinList[1]['seihincode']
            }
            // VersionMaster
            // this.seihinVersion = this.seihinList
            //   .filter(item => item['seihincode'] === this.seihinCode)[0]['version']
            this.seihinName = this.seihinList
              .filter(item => item['seihincode'] === this.seihinCode && item['version'] === this.seihinVersion)[0]['seihinname']
            this.seihinAuth = this.seihinList
              .filter(item => item['seihincode'] === this.seihinCode && item['version'] === this.seihinVersion)[0]['seihinAuth']
            this.otherSeihinAuth = this.seihinList
              .filter(item => item['seihincode'] === this.seihinCode && item['version'] === this.seihinVersion)[0]['seihinAuth']
            this.seihinUnit = this.seihinList
              .filter(item => item['seihincode'] === this.seihinCode && item['version'] === this.seihinVersion)[0]['seihinunit']
            // this.temptitle = this.seihinName + ' ver.' + this.seihinVersion
            this.temptitle = this.seihinName + ' ' + this.seihinVersion
            this.$store.commit('SET_SEIHIN', { 'seihinCode': this.seihinCode, 'version': this.seihinVersion })
            this.loading4SeihinList = false
            this.getFinishCount()
            await this.checkSeihinOrgProcess(this.seihinCode, this.seihinVersion)
            await this.checkMasterDelete(this.kigyouCode, this.seihinCode)
            this.getSeihinMasterInfo(this.kigyouCode, this.seihinCode)
            this.getColumnNames(this.kigyouCode)
            this.getProcessTable()
            this.toSearch()
            this.getTopV()
            // this.getSumEmissionc(this.seihinCode, this.seihinVersion.replace('/', ''), this.seihinAuth, this.oriprocessFlg === 1)
            getSumEmissioncAPI(this.seihinCode, this.seihinVersion, this.seihinAuth, this.oriprocessFlg === 1)
              .then(res => {
                if (res.code === 200) {
                  this.sumEmissionc = res.data
                  this.sumOtherEmissioncBackup = res.data
                  if (this.sumOtherEmissionc !== '') {
                    this.reduction = (100 - (this.sumEmissionc / this.sumOtherEmissionc * 100)).toFixed(2)
                  } else {
                    this.reduction = (100 - (this.sumEmissionc / this.sumOtherEmissioncBackup * 100)).toFixed(2)
                  }
                } else {
                  this.rejectMsg()
                }
              })
              .catch((error) => {
                console.log('error', error)
                // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
                // return this.$router.push({ path: '/' })
              })
            this.getIdeaAndPre()
            this.getCheckListReport()
          } else {
            this.loading4Pie = false
            this.loading4SeihinList = false
          }
          this.getVersionMaster(this.seihinCode, this.seihinVersion)
          if (this.seihinCode) {
            checkTreeSeihin(this.seihinCode, this.seihinVersion, this.kigyouCode)
              .then(res => {
                if (res.code === 200) {
                  this.isTreeSeihin = res.data.treeFlg
                } else {
                  this.rejectMsg()
                }
              }).catch((error) => {
                console.log('error', error)
              })
            this.checkIfSelfIdea()
          }
        } else {
          this.rejectMsg()
        }
      })
      // eslint-disable-next-line handle-callback-err
      .catch(() => {
        this.loading4SeihinList = false
        this.loading4Pie = false
      })
  },
  methods: {
    gettextlenght(str) {
      if (str.length > 14) {
        str = str.substr(0, 14) + '...'
      }
      return str
    },
    rejectMsg() {
      this.$message.error('ネットワークエラーが発生した。')
    },
    async changeSeihin(val) {
      // VersionMaster
      this.seihinVersion = this.seihinList
        .filter(item => item['seihincode'] + '/' + item['version'] === val)[0]['version']
      this.sortFlg = true
      this.sortButton = '排出量順'
      this.unit = ['','','']
      this.percent = ['','','']

      var obj = this.seihinList.find(function(item) {
        return item['seihincode'] + '/' + item['version'] === val
      })
      this.seihinName = obj.seihinname
      this.seihinAuth = obj.seihinAuth
      this.seihinUnit = obj.seihinunit
      this.seihinCode = obj.seihincode
      if (this.seihinVersion) {
        await this.checkMasterDelete(this.kigyouCode,this.seihinCode)
      } 
      this.loading4Pie = true
      this.tableData = []
      await this.checkSeihinOrgProcess(this.seihinCode, this.seihinVersion)
      this.getFinishCount()
      this.getProcessTable()
      this.getTopV()
      this.getSumEmissionc(this.seihinCode, this.seihinVersion, this.seihinAuth, this.oriprocessFlg === 1)
      this.getIdeaAndPre()
      this.toSearch()
      this.getCheckListReport()
      this.getSeihinMasterInfo(this.kigyouCode, this.seihinCode)
      this.$store.commit('SET_SEIHIN', { 'seihinCode': this.seihinCode, 'version': this.seihinVersion })
      this.getVersionMaster(this.seihinCode, this.seihinVersion)
      // this.temptitle = this.seihinName + ' ver.' + this.seihinVersion
      this.temptitle = this.seihinName + ' ' + this.seihinVersion
      checkTreeSeihin(this.seihinCode, this.seihinVersion, this.kigyouCode)
        .then(res => {
          if (res.code === 200) {
            this.isTreeSeihin = res.data.treeFlg
          } else {
            this.rejectMsg()
          }
        }).catch((error) => {
          console.log('error', error)
        })
      this.checkIfSelfIdea()
    },
    async changeOtherSeihin(val) {
      // VersionMaster
      const otherSeihinVersion = this.seihinList
        .filter(item => item['seihincode'] + '/' + item['version'] === val)[0]['version']
      var obj = this.seihinList.find(function(item) {
        return item['seihincode'] + '/' + item['version'] === val
      })
      this.otherSeihinAuth = obj.seihinAuth
      const otherSeihinCode = obj.seihincode
      checkSeihinOrgProcessAPI(otherSeihinCode, otherSeihinVersion)
        .then(res => {
          if (res.code === 200) {
            const otherOriprocessFlg = res.data ? 1 : 0
            getSumEmissioncAPI(otherSeihinCode, otherSeihinVersion, this.otherSeihinAuth, otherOriprocessFlg === 1)
              .then(res => {
                if (res.code === 200) {
                  this.sumOtherEmissionc = res.data
                  this.reduction = (100 - (this.sumEmissionc / this.sumOtherEmissionc * 100)).toFixed(2)
                } else {
                  this.rejectMsg()
                }
              })
              .catch((error) => {
                console.log('error', error)
                // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
                // return this.$router.push({ path: '/' })
              })
          } else {
            this.rejectMsg()
          }
        })
        .catch((error) => {
          console.log('error', error)
          // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
          // return this.$router.push({ path: '/' })
        })
      getVersionMasterAPI(otherSeihinCode, otherSeihinVersion)
        .then(masterResult => {
          if (masterResult.code === 200) {
            this.otherSimulateNote = masterResult.data.remark
          } else {
            console.warn('システムエラー通知')
          }
        // eslint-disable-next-line handle-callback-err
        }).catch(() => {
          console.warn('歩留まり率適用でエラー通知')
        })
    },
    toEdit() {
      this.$router.push(
        { name: '/input/update',
          params: {
            seihinCode: this.seihinCode,
            seihinName: this.seihinName,
            seihinVersion: this.seihinVersion,
            oriprocessFlg: this.oriprocessFlg
          }
        })
    },
    toSimulate() {
      this.$router.push(
        { name: '/input/simulate',
          params: {
            seihinCode: this.seihinCode,
            seihinName: this.seihinName,
            version: this.seihinVersion
          }
        })
    },
    reportPlan() {
      this.dialog_flag = false
      setTimeout(function() {  
        window.open('https://lp.lcaplus-pf.com/inquiry','_blank') 
      }, 500); 
        
    },
    toReport() {
       getKigyouInfoAPI(JSON.parse(this.$store.state.user).kigyoucode)
          .then(res => {
            if (res.code === 200) {
              if (res.data.reportplan === 0 ) {
                this.dialog_flag = true
                this.dialog_index = 2
              } else if (res.data.reportplan != 0 && JSON.parse(this.$store.state.role).userRole != 'SuperAdmin') {
                this.dialog_flag = true
                this.dialog_index = 1
              } else {
                 this.$router.push(
                  { name: '/reportCreation/report',
                    params: {
                      seihinCode: this.seihinCode,
                      version: this.seihinVersion,
                      oriprocessFlg: this.oriprocessFlg
                    }
                  })
              }
            } else {
              this.rejectMsg()
            }
          }).catch((error) => {
            console.log('error', error)
          })
    },
    toCampare() {
      this.$router.push(
        { name: '/compare/abstract',
          params: {
            seihinCode: this.seihinCode,
            seihinName: this.seihinName,
            version: this.seihinVersion
          }
        })
    },
    makeSelfIdea() {
      let msg = '自社算定原単位に登録しますか？<br>自社算定原単位化された場合、Cradle to Gateを前提に<br>A1～A3プロセスの合計値が排出係数となります。'
      this.$confirm(msg, {
        confirmButtonText: this.$t('messages.doregister'),
        cancelButtonText: this.$t('messages.cancel'),
        dangerouslyUseHTMLString: true,
        center: true
      }).then(() => {
        setSelfIdea(this.seihinVersion, this.seihinCode, this.updUser)
          .then(res => {
            if (res.code === 200) {
              this.checkIfSelfIdea()
            } else {
              this.rejectMsg()
            }
          }).catch((error) => {
            console.log('error', error)
          })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '登録されませんでした'
        })
      })
    },
    checkIfSelfIdea() {
      checkSelfIdea(this.seihinCode, this.seihinVersion)
        .then(res => {
          if (res.code === 200) {
            this.isSelfIdea = res.data
          } else {
            this.rejectMsg()
          }
        }).catch((error) => {
          console.log('error', error)
        })
    },
    toSingle() {
      this.$router.push(
        { name: '/browser/single',
          params: {
            seihinCode: this.seihinCode,
            seihinName: this.seihinName,
            version: this.seihinVersion
          }
        })
    },
    toTree() {
      this.$router.push(
        { name: '/browser/referenceTree',
          params: {
            seihinCode: this.seihinCode,
            seihinName: this.seihinName,
            version: this.seihinVersion,
            oriprocessFlg: this.oriprocessFlg === 1
          }
        })
    },
    toOperate() {
      this.$router.push(
        { name: '/operate/seihin',
          params: {
            seihinCode: this.seihinCode,
            version: this.seihinVersion,
            oriprocessFlg: this.oriprocessFlg
          }
        })
    },
    toSave() {
      const data = [{
        seihincode: this.seihinCode,
        version: this.seihinVersion,
        index: 0,
        unit: this.unit[0],
        percent: this.percent[0],
        kigyoucode: this.kigyouCode,
        upduser: this.updUser
      },
      {
        seihincode: this.seihinCode,
        version: this.seihinVersion,
        index: 1,
        unit: this.unit[1],
        percent: this.percent[1],
        kigyoucode: this.kigyouCode,
        upduser: this.updUser
      },
      {
        seihincode: this.seihinCode,
        version: this.seihinVersion,
        index: 2,
        unit: this.unit[2],
        percent: this.percent[2],
        kigyoucode: this.kigyouCode,
        upduser: this.updUser
      }]
      saveSeihinTempAPI(data)
          .then(res => {
            if (res.code === 200) {
              this.checkIfSelfIdea()
              this.toSearch()
              this.openMessage()
            } else {
              this.rejectMsg()
            }
          }).catch((error) => {
            console.log('error', error)
          })
    },
    toSearch(){
        const data = {
        seihincode: this.seihinCode,
        version: this.seihinVersion,
        }
        searchSeihinTempAPI(data)
          .then(res => {
              if (res.code === 200) {
                this.unit[0] = res.data[0] ? res.data[0].unit : ''
                this.unit[1] = res.data[1] ? res.data[1].unit : ''
                this.unit[2] = res.data[2] ? res.data[2].unit : ''
                this.percent[0] = res.data[0] ? res.data[0].percent : ''
                this.percent[1] = res.data[1] ? res.data[1].percent : ''
                this.percent[2] = res.data[2] ? res.data[2].percent : ''
                this.checkIfSelfIdea()
              } else {
                this.rejectMsg()
              }
            }).catch((error) => {
              console.log('error', error)
          })
    },
    cancel(){
      this.$refs.popover.showPopper = false
      const data = {
        seihincode: this.seihinCode,
        version: this.seihinVersion,
        }
        searchSeihinTempAPI(data)
          .then(res => {
              if (res.code === 200) {
                this.unit[0] = res.data[0].unit || ''
                this.unit[1] = res.data[1].unit || ''
                this.unit[2] = res.data[2].unit || ''
                this.percent[0] = res.data[0].percent || ''
                this.percent[1] = res.data[1].percent || ''
                this.percent[2] = res.data[2].percent || ''
                this.messNull = false
                this.messWarn = false
                
              } else {
                this.rejectMsg()
              }
            }).catch((error) => {
              console.log('error', error)
        })
    },
    getInputValue(){
      // debugger
      // console.log(this.unit)
      // console.log(this.percent)
      // if((this.unit[0].length >= 1 && this.percent[0].length >= 1) || (this.unit[1].length >= 1 && this.percent[1].length >= 1) || (this.unit[2].length >= 1 && this.percent[2].length >= 1)){
      //   this.btnDisabled = false
      // }else{
      //   this.btnDisabled = true
      // }

    },
    initChart() {
      const pie_chart = echarts.init(document.getElementById('pieChart'))
      const option = {
        tooltip: {
          trigger: 'item',
          position: 'right',
          // formatter: '{a} <br/>{b}: {c} ({d}%)'
          formatter: function(params) {
            // return `
            // ${params.data.processcode} </br>
            // ${params.name}: ${params.data.emissionc} (${params.data.percentage}%)
            // `
            return `
            ${params.data.processcode} ${params.name}: ${params.data.emissionc} (${params.data.percentage}%)
            `
          }
        },
        color: this.colorData,
        legend: {
          show: true,
          top: '70%',
          left: 'center',
          itemHeight: 10,
          itemWidth: 10,
          itemGap: 15,
          icon: 'circle',
          selectedMode: false,
          formatter: function(name) {
            return '{a|' + name + '}'
          },
          textStyle: {
            backgroundColor: 'transparent',
            rich: {
              a: {
                width: 10
              }
            }
          }
        },
        title: {
          text: ' ',
          subtext: 'kg-CO₂eq',
          left: 'center',
          top: '28%',
          textStyle: {
            textAlign: 'center',
            // lineHeight: 30,
            fill: '#161616',
            fontSize: 26,
            fontWeight: 400
          },
          subtextStyle: {
            fontSize: 12,
            lineHeight: 0
          }
        },
        graphic: {
          type: 'text',
          left: 'center',
          top: '23%',
          silent: true,
          invisible: false,
          style: {
            text: this.$t('messages.totalEmissions1'),
            textAlign: 'center',
            fill: '#333',
            fontSize: 12
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['32%', '50%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            top: '-30%',
            emphasis: {
              label: {
                show: false,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.pieData
          }
        ]
      }
      pie_chart.setOption(option)
      window.addEventListener('resize', function() {
        pie_chart.resize()
      })
    },
    toScientificNum(num) {
      if (!num) {
        return 0
      } else {
        var eformat = num.toExponential()
        var tmpArray = eformat.match(/(.?\d\.?\d*)e(.?\d*)/)
        tmpArray[1] = Math.round(times(tmpArray[1],100))/100
        tmpArray[1] = parseFloat(tmpArray[1]).toFixed(2)
        tmpArray[2] = tmpArray[2].replace('+', '')
        var ScientificNum
        if (tmpArray[2] !== '0') {
          ScientificNum = [tmpArray[1], 'x10', tmpArray[2]]
        } else {
          ScientificNum = [tmpArray[1]]
        }
        return ScientificNum
      }
    },
    async getVersionMaster(seihinCode, seihinVersion) {
      getVersionMasterAPI(seihinCode, seihinVersion)
        .then(masterResult => {
          if (masterResult.code === 200) {
            // if (masterResult.data.budomari) {
            //   // this.budomari = parseFloat(masterResult.data.budomari) * 100
            //   this.budomari = this.accMul(parseFloat(masterResult.data.budomari), 100)
            // } else {
            //   this.budomari = ''
            // }
            this.simulateNote = masterResult.data.remark
          } else {
            console.warn('システムエラー通知')
          }
        // eslint-disable-next-line handle-callback-err
        }).catch(() => {
          console.warn('歩留まり率適用でエラー通知')
        })
    },
    accMul(arg1, arg2) {
      var m = 0; var s1 = arg1.toString(); var s2 = arg2.toString()
      // eslint-disable-next-line no-empty
      try { m += s1.split('.')[1].length } catch (e) {}
      // eslint-disable-next-line no-empty
      try { m += s2.split('.')[1].length } catch (e) {}
      return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
    },
    checkSeihinOrgProcess(seihinCode, seihinVersion) {
      return checkSeihinOrgProcessAPI(seihinCode, seihinVersion)
        .then(res => {
          if (res.code === 200) {
            this.oriprocessFlg = res.data ? 1 : 0
          } else {
            this.rejectMsg()
          }
        })
        .catch((error) => {
          console.log('error', error)
          // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
          // return this.$router.push({ path: '/' })
        })
    },
    async getFinishCount() {
      getFinshCountAPI(this.seihinCode, this.seihinVersion)
        .then(res => {
          if (res.data.length > 0) {
            this.totalCount = res.data[0].totalcount
            this.finishCount = res.data[0].finshtotal
          } else {
            this.totalCount = 17
            this.finishCount = 0
          }
        })
        .catch((error) => {
          console.log('error', error)
          // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
          // return this.$router.push({ path: '/' })
        })
    },
    getProcessTable() {
      getProcessTableAPI(this.seihinCode, this.seihinVersion, this.seihinAuth, this.oriprocessFlg === 1)
        .then(res => {
          if (res.code === 200) {
            this.processTableData = res.data[0]
            const _pieData = []
            for (const key in this.processTableData) {
              if (key === 'A2A4') {
                _pieData.push({
                  processcode: 'A2・A4',
                  name: this.getProcessName(key),
                  value: this.processTableData[key].emissionc,
                  ...this.processTableData[key]
                })
              } else {
                _pieData.push({
                  processcode: key,
                  name: this.getProcessName(key),
                  value: this.processTableData[key].emissionc,
                  ...this.processTableData[key]
                })
              }
            }
            _pieData.sort((a, b) => {
              return b.emissionc - a.emissionc
            })
            _pieData.forEach((item, index) => {
              if (item.processcode === 'A5') {
                item.color = '#DBDBDB'
              } else if (item.processcode === 'noShow') {
                item.color = '#B8B8B8'
              } else {
                item.color = this.pieColorArr[index]
              }
            })
            if (this.seihinAuth === 0) {
              for (let i = 0; i < _pieData.length; i++) {
                if (_pieData[i].processcode === 'noShow' || _pieData[i].processcode === 'sum' || _pieData[i].emissionc === 0) {
                  _pieData.splice(i, 1)
                  i--
                }
              }
            } else {
              for (let j = 0; j < _pieData.length; j++) {
                if (_pieData[j].processcode === 'sum' || _pieData[j].emissionc === 0) {
                  _pieData.splice(j, 1)
                  j--
                } else if (_pieData[j].processcode === 'noShow') {
                  _pieData[j].processcode = ''
                }
              }
            }
            const _colorData = []
            _pieData.forEach(element => {
              _colorData.push(element.color)
            })
            this.pieData = _pieData
            this.colorData = _colorData
            this.$nextTick(() => {
              this.initChart()
            })
            this.loading4Pie = false
          } else {
            this.rejectMsg()
          }
        })
        .catch((error) => {
          console.log('error', error)
          // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
          // return this.$router.push({ path: '/' })
        })
    },
    getProcessName(processKey) {
      switch (processKey) {
        case 'A1':
          return this.$t('messages.rmp')
        case 'A2A4':
          return this.$t('messages.transportation')
        case 'A3':
          return this.$t('messages.production')
        case 'B':
          return this.$t('messages.usage')
        case 'C':
        return this.$t('messages.disposalRecycling')
        case 'A5':
          return this.$t('messages.other')
        case 'noShow':
          return this.$t('messages.noShow')
      }
    },
    getTopV() {
      getTopVAPI(this.seihinCode, this.seihinVersion, this.seihinAuth, this.oriprocessFlg === 1)
        .then(res => {
          if (res.code === 200) {
            this.tableData = res.data
            this.tableData.forEach(element => {
              if (element.name.indexOf('a3b523ac16674572848091098e') > -1) {
                element.displayname = element.name.replace('a3b523ac16674572848091098e', '')
              } else {
                element.displayname = element.name
              }
            })
          } else {
            this.rejectMsg()
          }
        })
        .catch((error) => {
          console.log('error', error)
          // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
          // return this.$router.push({ path: '/' })
        })
    },
    getSumEmissionc(seihinCode, seihinVersion, seihinAuth, oriprocessFlg) {
      getSumEmissioncAPI(seihinCode, seihinVersion, seihinAuth, oriprocessFlg)
        .then(res => {
          if (res.code === 200) {
            this.sumEmissionc = res.data
            // this.sumOtherEmissioncBackup = res.data
            if (this.sumOtherEmissionc !== '') {
              this.reduction = (100 - (this.sumEmissionc / this.sumOtherEmissionc * 100)).toFixed(2)
            } else {
              this.reduction = (100 - (this.sumEmissionc / this.sumOtherEmissioncBackup * 100)).toFixed(2)
            }
          } else {
            this.rejectMsg()
          }
        })
        .catch((error) => {
          console.log('error', error)
          // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
          // return this.$router.push({ path: '/' })
        })
    },
    getIdeaAndPre() {
      getIdeaAndPreAPI(this.seihinCode, this.seihinVersion, this.oriprocessFlg === 1)
        .then(res => {
          if (res.code === 200) {
            this.originalUseData = res.data
          } else {
            this.rejectMsg()
          }
        })
        .catch((error) => {
          console.log('error', error)
          // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
          // return this.$router.push({ path: '/' })
        })
    },
    getCheckListReport() {
      getCheckListReportAPI(this.seihinCode, this.seihinVersion)
        .then(res => {
          if (res.code === 200) {
            this.companiesNum = res.data.authCount
          } else {
            this.rejectMsg()
          }
        })
        .catch((error) => {
          console.log('error', error)
          // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
          // return this.$router.push({ path: '/' })
        })
    },
    getCheckListData() {
      getCheckListAPI(this.seihinCode, this.seihinVersion, this.$i18n.locale)
        .then(res => {
          if (res.code === 200) {
            this.checkList = res.data[0].checkListData.filter(item => {
              return item.checkitem !== ''
            })
            this.centerDialogVisible = true
          } else {
            this.rejectMsg()
          }
        }).catch((error) => {
          console.log('error', error)
        })
    },
    closeDialog() {
      this.centerDialogVisible = false
    },
    isNumber(val){
      var regNeg =
        /^(\-|\+)?\d+(\.\d+)?$/
      if (!regNeg.test(val)) {
        return false
      } 
        return true
    },

    validateCoefficient(value, index){
      if (value === '') {
        // this.messNull = true
        this.messWarn_0 = false
        this.messWarn_1 = false
        this.messWarn_2 = false
        if (index === 0) {
          this.messNull_0 = true
        } else if (index === 1) {
          this.messNull_1 = true
        } else {
          this.messNull_2 = true
        }
      } else {
        this.messNull_0 = false
        this.messNull_1 = false
        this.messNull_2 = false
        if (!this.isNumber(value)) {
          // this.messWarn = true
            if (index === 0) {
            this.messWarn_0 = true
          } else if (index === 1) {
            this.messWarn_1 = true
          } else {
            this.messWarn_2 = true
          }
        }else{
          this.messWarn_0 = false
          this.messWarn_1 = false
          this.messWarn_2 = false
        }
      }
      if (this.messWarn_0 || this.messWarn_1 || this.messWarn_2 || this.messNull_0 || this.messNull_1 || this.messNull_2) {
        this.btnDisabled = true
      } else {
        if((this.unit[0].length >= 1 && this.percent[0].length >= 1) || (this.unit[1].length >= 1 && this.percent[1].length >= 1) || (this.unit[2].length >= 1 && this.percent[2].length >= 1)){
          this.btnDisabled = false
        }
      }
    },
    queryLastData() {
      this.showPopover = true
    },
    openMessage(){
      this.$message({
          message: '保存しました',
          type: 'success'
        });
    },
    checkMasterDelete(kigyouCode,seihinCode) {
      checkMasterDeleteAPI(kigyouCode, seihinCode)
       .then( res => {
        if (res.code === 200){
          this.showFlag = res.data
          this.showIcon = !res.data
        } else {
          this.rejectMsg
        }
       }).catch((error) => {
        console.log('error', error)
       })
    },
    parentSeihinPopup(valueFromChild) {
      this.isShowPopup = valueFromChild['isShowPopup']
    },
    showSeihinMasterPopup() {
      this.isShowPopup = true
    },
    getSeihinMasterInfo(kigyouCode, seihinCode) {
      getSeihinMasterAPI(kigyouCode, seihinCode).then(res => {
          if (res.code == 200) {
              if (res.data) {
                this.seihinMasterData = res.data
              }
          } else {
              this.rejectMsg()
          }
      }).catch((error) => {
          console.log('error', error)
      })
    },
    getColumnNames(kigyouCode) {
      getColumnNamesAPI(kigyouCode).then(res => {
          if (res.code == 200) {
              if (res.data) {
                this.columnNameData = res.data
              }
          } else {
              this.rejectMsg()
          }
      }).catch((error) => {
          console.log('error', error)
      })
    },
    downLoadCSV() {
      checkLoopQuote(this.seihinCode, this.seihinVersion)
      .then(res=>{
        if (!res.data) {
          this.downLoadExcel(this.seihinCode, this.seihinVersion, this.kigyouCode, this.seihinName)
        } else {
          this.$message({
            message: 'この製品バージョンのデータでは自社算定原単位を循環利用しているため、タウンロードできません。',
            type: 'warning'
          });
        }
      })
    },
    downLoadExcel() {
      exportExcelAPI(this.seihinCode, this.seihinVersion, this.kigyouCode, this.seihinName)
      .then(res => {
        const headers = JSON.parse(this.$store.state.headers)
        let fileName = headers['content-disposition']
        let regFileNames = decodeURI(fileName.match(/=(.*)$/)[1])
        const blob = new Blob([res],{
          type: 'application/x-msdownload;charset=utf-8',
          });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = regFileNames;//设置下载文件名
        link.click();
        window.URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
          console.log('error', error)
      })
    }
  }

}
</script>
<style lang="scss" scoped>
.dialog-control {
  ::v-deep .el-dialog--center .el-dialog__body {
    text-align: center;
    padding: 25px 25px 30px;
  }
}
.totalNum {
  width: 32%;
  text-align: center;
  // padding-right: 9%;
  position: absolute;
  top:7.44rem;
  font-size: 2.2rem;
  font-weight: 400;
  margin-left: 29%;
}
.check-break-line {
  white-space: pre-line;
}
.width-33 {
  width:50%;
}
.icon-size {
  width: 1.8rem;
  height: 1.8rem;
  margin-top:0.8rem;
}
.min-height {
  min-height: 34.8rem;
}
.width-60 {
  width:57%;
}
.self-idea {
  margin-top: 3rem;
}
.suggestion-icon {
  color: #52C433;
}
.self_idea {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-right: 1.6rem;
}
.header_button_area {
  display: flex;
  flex-direction: row;
}
.hidden_block {
  visibility: hidden;
}
.button_top_block {
  margin-bottom: 0.5rem;
}
.table_headers{
  display:flex; 
  justify-content: flex-end;
  align-items: center;
  flex-direction:column;
  height:20%; 
  // width:200%;
}
.table_headers_input{
  display:flex; 
  justify-content: flex-end;
  align-items: center;
  flex-direction:column;
  height:100px; 
  // width:40%;
}
.table_contents{
  text-align: center;

  height: 36px;
  // width: 10%;
  padding: 8px 20px;
  font-size: 1.4rem;
  color: #C0C4CC;
  border: 1px solid #E4E7ED ;
  background-color: #F5F7FA;
  border-radius: 4px;
  font-weight: 100;
}
.table_input{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  // width: 40%;
}
.title{
  font-size: 1.4rem;
}

</style>
<style>
.pop{
  width: 90%;
  /* height: 50%; */
}

</style>