<template>
  <transition name="popup-fade">
    <div
      class="popup-body"
    >
      <div class="popup-content-wrap">
        <div class="popup">
          <div class="title-box">
            <div class="title-link" @click="goReferenceIndex">{{ seihinname4child }}</div>
            <i class="el-icon-close" @click="doClose" />
          </div>
          <div class="popup-body-box">
            <div v-loading="pieLoad" class="pieChart-container">
              <div id="linkPopupPieChart" class="linkPopup-chart" />
            </div>
            <div v-loading="tableLoad" class="table-container">
              <template>
                <el-table
                  ref="ChildDataTable"
                  :data="tableData"
                  style="width: 90%;"
                  size="small"
                  row-key="processcode"
                  border
                  default-expand-all
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                  :header-cell-style="{background:'rgba(51, 51, 51, 0.05)'}"
                  :row-class-name="tableRow"
                  :cell-class-name="cellStyle"
                >
                  <el-table-column
                    prop="processname4display"
                    min-width="40%"
                  >
                    <template slot="header">
                      <div class="processName-title">
                        <i v-show="!isExpansion && seihinAuth4child > 2" class="el-icon-arrow-down" @click="toggleRowExpansion()" />
                        <i v-show="isExpansion && seihinAuth4child > 2" class="el-icon-arrow-right" @click="toggleRowExpansion()" />
                        <i style="padding-right: 35%;" />
                        <span>{{$t('messages.process')}}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="emissionc"
                    min-width="30%"
                  >
                    <template slot="header">
                      <div class="table-title" v-html="$t('messages.emission') + '&nbsp;(kg-CO<sub>2</sub>eq)'" />
                    </template>
                    <template slot-scope="scope">
                      <span>{{ scope.row.emissionc[0] }} {{ scope.row.emissionc[1] }} <sup>{{ scope.row.emissionc[2] }}</sup></span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="percentage"
                    label="割合"
                    min-width="30%"
                  >
                    <template slot="header">
                      <div class="table-title">{{$t('messages.percentage')}}</div>
                    </template>
                    <template slot-scope="scope">
                      <span v-if="String(scope.row.processcode).includes('D')">-</span>
                      <span v-else>{{ scope.row.percentage }}%</span>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </div>
          <div class="tab-button-box">
            <el-button class="back-button" @click="doClose"> {{$t('messages.backName')}}</el-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script type="text/ecmascript-6">
import * as echarts from 'echarts'
export default {
  name: 'Popup2',
  props: {
    isShowComfirmPopup: Boolean,
    isComfirm: Boolean,
    piedata: {
      type: Array,
      default: () => []
    },
    colordata: {
      type: Array,
      default: () => []
    },
    tabledata: {
      type: Array,
      default: () => []
    },
    seihinname4child: {
      type: String,
      default: ''
    },
    seihincode4child: {
      type: String,
      default: ''
    },
    seihinversion4child: {
      type: String,
      default: ''
    },
    seihinAuth4child: {
      type: Number,
      default: () => {}
    },
    piedataloadstatus4pop: {
      type: String,
      default: ''
    },
    tabledataloadstatus4pop: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      pieData: [],
      colorData: [],
      tableData: [],
      tableLoad: true,
      pieLoad: true,
      isExpansion: false
    }
  },
  watch: {
    piedataloadstatus4pop: {
      handler(newVal, oldVal) {
        if (newVal === 'completed') {
          this.pieLoad = false
          this.pieData = this.piedata
          for (let i = 0; i < this.pieData.length; i++) {
            if (this.pieData[i].processcode != '') {
              this.pieData[i].name = this.getProcessName(this.pieData[i].processcode) + '(' + this.pieData[i].processcode + ')';
            } else {
              this.pieData[i].name = this.getProcessName(this.pieData[i].processcode);
            }
          }
          this.colorData = this.colordata
          this.initChart()
        } else {
          this.pieLoad = true
        }
      },
      deep: true
    },
    tabledataloadstatus4pop: {
      handler(newVal, oldVal) {
        if (newVal === 'completed') {
          this.tableLoad = false
          this.tableData = this.tabledata
        } else {
          this.tableLoad = true
        }
      },
      deep: true
    }
  },
  methods: {
    doClose() {
      this.pieData = []
      this.colorData = []
      this.tableData = []
      this.isExpansion = false
      this.$emit('childFn', { 'isComfirm': false, 'isShowLinkPopup': false })
    },
    goReferenceIndex() {
      this.$router.push(
        { name: '/browser/referenceIndex',
          params: {
            seihinCode: this.seihincode4child,
            version: this.seihinversion4child
          }
        })
    },
    initChart() {
      const pie_chart = echarts.init(document.getElementById('linkPopupPieChart'))
      const option = {
        tooltip: {
          trigger: 'item',
          position: 'right',
          // formatter: '{a} <br/>{b}: {c} ({d}%)'
          formatter: function(params) {
            // return `
            // ${params.data.processcode} </br>
            // ${params.name}: ${params.data.emissionc} (${params.data.percentage}%)
            // `
            return `
            ${params.data.processcode} ${params.name}: ${params.data.emissionc} (${params.data.percentage}%)
            `
          }
        },
        color: this.colorData,
        legend: {
          show: true,
          top: '60%',
          left: 'center',
          itemHeight: 8,
          itemWidth: 8,
          fontSize: 4,
          itemGap: 15,
          icon: 'circle',
          selectedMode: false,
          formatter: function(name) {
            return '{a|' + name + '}'
          },
          textStyle: {
            backgroundColor: 'transparent',
            rich: {
              a: {
                width: 90
              }
            }
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '45%',
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            top: '-40%',
            left: '-10%',
            emphasis: {
              label: {
                show: false,
                fontSize: '8',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.pieData
          }
        ]
      }
      pie_chart.setOption(option)
      window.addEventListener('resize', function() {
        pie_chart.resize()
      })
    },
    getProcessName(processKey) {
      switch (processKey) {
        case 'A1':
          return this.$t('messages.rmp')
        case 'A2・A4':
          return this.$t('messages.transportation')
        case 'A3':
          return this.$t('messages.production')
        case 'B':
          return this.$t('messages.usage')
        case 'C':
        return this.$t('messages.disposalRecycling')
        case 'A5':
          return this.$t('messages.other')
        case '':
          return this.$t('messages.noShow')
      }
    },
    toggleRowExpansion() {
      this.tableData.forEach(item => {
        this.$refs.ChildDataTable.toggleRowExpansion(item, this.isExpansion)
      })
      this.isExpansion = !this.isExpansion
    },
    headerStyle({ row, rowIndex }) {
      return 'headerStyle'
    },
    tableRow({ row }) {
      if (row.processname4display === '合 計') {
        row.processname4display = this.$t('messages.total')
        return 'last-link-row'
      }
      return 'table-row'
    },
    cellStyle(row) {
      if (row.columnIndex === 0) {
        return 'link-first-column'
      }
      return 'link-other-column'
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .popup-content-wrap {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    height: 75%;
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    z-index: 999;
    user-select: none;

    .popup {
      height: 100%;
      width: 100%;

      .title-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 2%;
        margin-bottom: 1%;
        margin-left: 1%;
        margin-right: 1%;
        height: 10%;
        font-family: NotoSansCJKjp-Medium;
        font-size: 22px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        font-weight: 500;
        .title-link {
          cursor: pointer;
        }
        .el-icon-close {
          cursor: pointer;
        }
      }

      .popup-body-box {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        height: 66%;
        width: 100%;

        .pieChart-container {
          height: 100%;
          width: 25%;
          // overflow: auto;
          .linkPopup-chart {
            height: 340px;
            width: 280px;
          }
        }

        .table-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          overflow-y: scroll;
          height: 100%;
          width: 73%;
          font-family: NotoSansCJKjp-Regular;
          font-size: 14px;
          color: #333333;
          letter-spacing: 0;
          font-weight: 400;

          .processName-title {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            color: #333333;
          }

          .table-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #333333;
          }
        }
        // .table-container::-webkit-scrollbar {
        //   height: 16px;
        //   overflow: visible;
        //   width: 16px;
        //   display: none;
        // }
      }

      .tab-button-box{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // opacity: 0.85;
        background: #FFFFFF;
        height: 10%;

        .back-button {
          font-family: 'NotoSansCJKjp-Regular';
          font-size: 12px;
          // color: #333333;
          letter-spacing: 0;
          font-weight: 400;
          // border: 1px solid #333333;
          border-radius: 4px;
          margin-top: 24px;
        }

        .calculator-button {
          font-family: 'NotoSansCJKjp-Regular';
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          font-weight: 400;
          background: #333333;
          border-radius: 4px;
          margin-top: 24px;
        }
      }
    }
  }
}
::v-deep .el-table .last-link-row {
  background-color: #333333!important;
  color: #FFFFFF;
  &:hover > td {
    background-color: #333333!important;
  }
}


.el-table .headerStyle{
  background-color: rgba(51, 51, 51, 1.0);
}

.el-table .first-column {
    text-align: left;
}

.el-table .other-column {
    text-align: center;
}

/* 进入和出去的动画 */
.popup-fade-enter-active {
  animation: opacity 0.3s;
}
.popup-fade-enter-active .popup-content-wrap {
  animation: scale 0.3s;
}
.popup-fade-leave-active {
  animation: outOpacity 0.2s;
}


/* 进来的动画 */
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scale {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes outOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
