var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "popup-fade" } }, [
    _c("div", { staticClass: "popup-body" }, [
      _c("div", { staticClass: "popup-content-wrap" }, [
        _c("div", { staticClass: "popup" }, [
          _c("div", { staticClass: "title-box" }, [
            _c("i", {
              staticClass: "el-icon-close",
              on: { click: _vm.doClose },
            }),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.backFlag,
                  expression: "!backFlag",
                },
              ],
              staticClass: "title-text",
            },
            [_vm._v(_vm._s(_vm.$t("messages.enterProduct")))]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.backFlag,
                  expression: "backFlag",
                },
              ],
              staticClass: "title-text",
            },
            [_vm._v(_vm._s(_vm.$t("messages.callData")))]
          ),
          _c(
            "div",
            { staticClass: "header-layout" },
            [
              _c(
                "el-row",
                { staticClass: "width-33", attrs: { gutter: 16 } },
                [
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "div",
                      { staticClass: "production-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "content-layout" },
                          [
                            _c("span", { staticClass: "margin-right-16" }, [
                              _vm._v(
                                _vm._s(_vm.$t("messages.productAndVersion"))
                              ),
                            ]),
                            _c(
                              "el-popover",
                              {
                                attrs: { placement: "right", trigger: "click" },
                              },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.seihinCallNote,
                                      expression: "seihinCallNote",
                                    },
                                  ],
                                  attrs: {
                                    readonly: "true",
                                    cols: "40",
                                    rows: "10",
                                    maxlength: "3000",
                                    placeholder: "メモを記載",
                                  },
                                  domProps: { value: _vm.seihinCallNote },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.seihinCallNote = $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "icon-note",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/static/icon/note.svg"),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form",
                          {
                            ref: "selectValueDefaultForm",
                            staticStyle: { width: "100%" },
                            attrs: {
                              model: _vm.selectValueDefaultForm,
                              rules: _vm.selectValueDefaultRules,
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.backFlag,
                                    expression: "!backFlag",
                                  },
                                ],
                                attrs: { prop: "selectValueDefault" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "seihin-select",
                                    attrs: {
                                      title: _vm.temptitle,
                                      placeholder: "製品を選択してください",
                                      size: "mini",
                                      filterable: "",
                                    },
                                    on: { change: _vm.changeSeihin },
                                    model: {
                                      value:
                                        _vm.selectValueDefaultForm
                                          .selectValueDefault,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selectValueDefaultForm,
                                          "selectValueDefault",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "selectValueDefaultForm.selectValueDefault",
                                    },
                                  },
                                  _vm._l(_vm.seihinCallList, function (item) {
                                    return _c("el-option", {
                                      key: item.seihincode + " " + item.version,
                                      attrs: {
                                        label: item.version
                                          ? item.seihinname + " " + item.version
                                          : item.seihinname,
                                        value:
                                          item.seihincode + " " + item.version,
                                        disabled: item.disabled,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.backFlag,
                                    expression: "backFlag",
                                  },
                                ],
                                attrs: { prop: "selectValueDefault" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "seihin-select",
                                    attrs: {
                                      title: _vm.temptitle,
                                      placeholder: "製品を選択してください",
                                      size: "mini",
                                      disabled: "",
                                    },
                                    on: { change: _vm.changeSeihin },
                                    model: {
                                      value:
                                        _vm.selectValueDefaultForm
                                          .selectValueDefault,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selectValueDefaultForm,
                                          "selectValueDefault",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "selectValueDefaultForm.selectValueDefault",
                                    },
                                  },
                                  _vm._l(_vm.seihinCallList, function (item) {
                                    return _c("el-option", {
                                      key: item.seihincode + " " + item.version,
                                      attrs: {
                                        label: item.version
                                          ? item.seihinname + " " + item.version
                                          : item.seihinname,
                                        value:
                                          item.seihincode + " " + item.version,
                                        disabled: item.disabled,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "production-item" },
                      [
                        _c("div", { staticStyle: { height: "20px" } }, [
                          _vm._v(_vm._s(_vm.$t("messages.unit"))),
                        ]),
                        _c("el-input", {
                          staticStyle: { "line-height": "4.3rem" },
                          attrs: {
                            value: _vm.seihinCallUnit,
                            size: "mini",
                            disabled: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-container" },
            [
              _c(
                "el-table",
                {
                  staticClass: "self-tree-table",
                  attrs: {
                    size: "small",
                    data: [{}],
                    "span-method": _vm.arraySpanMethod,
                  },
                },
                [
                  _c("el-table-column", {
                    staticStyle: { padding: "0rem 0rem 0rem 8rem" },
                    attrs: {
                      prop: "itemName",
                      label: "項目",
                      "min-width": "30%",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("i", {
                              class: {
                                "el-icon-arrow-down": _vm.showAll,
                                "el-icon-arrow-right": !_vm.showAll,
                              },
                              staticStyle: { "padding-left": "6%" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleShowAll()
                                },
                              },
                            }),
                            _c("i", {
                              staticStyle: { "padding-right": "10px" },
                            }),
                            _c("span", { staticClass: "table-title" }, [
                              _vm._v(_vm._s(_vm.$t("messages.iItem"))),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.tableData[0] &&
                            !_vm.tableData[0].detail &&
                            !_vm.tableData[0].child
                              ? _c(
                                  "table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      border: "0",
                                      cellpadding: "0",
                                      cellspacing: "0",
                                    },
                                  },
                                  [
                                    _c(
                                      "tr",
                                      {
                                        staticStyle: {
                                          "background-color": "#000",
                                          color: "#fff",
                                          height: "4rem",
                                        },
                                      },
                                      [
                                        _c("td", {
                                          staticStyle: {
                                            "background-color":
                                              "white !important",
                                            width: "2%",
                                          },
                                        }),
                                        _c(
                                          "td",
                                          { staticStyle: { width: "30%" } },
                                          [
                                            _c("i", {
                                              staticStyle: {
                                                "padding-right": "24px",
                                              },
                                            }),
                                            _vm._v(" 合計 "),
                                          ]
                                        ),
                                        _c("td", {
                                          staticStyle: { width: "18%" },
                                        }),
                                        _c("td", {
                                          staticStyle: { width: "20%" },
                                        }),
                                        _c("td", {
                                          staticStyle: {
                                            width: "6%",
                                            "text-align": "center",
                                          },
                                        }),
                                        _c("td", {
                                          staticStyle: { width: "5%" },
                                        }),
                                        _c(
                                          "td",
                                          { staticStyle: { width: "7%" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.toScientificNum(
                                                  _vm.tableData[0].total
                                                )[0]
                                              ) +
                                                " " +
                                                _vm._s(
                                                  _vm.toScientificNum(
                                                    _vm.tableData[0].total
                                                  )[1]
                                                ) +
                                                " "
                                            ),
                                            _c("sup", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.toScientificNum(
                                                    _vm.tableData[0].total
                                                  )[2]
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              width: "5%",
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.tableData[0].pre) + "%"
                                            ),
                                          ]
                                        ),
                                        _c("td", {
                                          staticStyle: { width: "7%" },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "table-scroll" },
                              _vm._l(_vm.tableData, function (row, index) {
                                return _c(
                                  "span",
                                  { key: index },
                                  [
                                    row.detail || row.child
                                      ? _c("callTree", {
                                          ref: "backCallTree",
                                          refInFor: true,
                                          attrs: {
                                            "show-all": _vm.showAll,
                                            row: row,
                                            "level-num": 0,
                                            checkSubassyId:
                                              row.detail[0].subassyId,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "processName",
                      label: _vm.$t("messages.process"),
                      "min-width": "20%",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ideaName",
                      label: _vm.$t("messages.eF"),
                      "min-width": "20%",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "quantity",
                      label: _vm.$t("messages.amount"),
                      "min-width": "6%",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "unit",
                      label: _vm.$t("messages.unit"),
                      "min-width": "5%",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "emissionc",
                      label: _vm.$t("messages.emission"),
                      "min-width": "7%",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pre",
                      label: _vm.$t("messages.ratio"),
                      "min-width": "5%",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "budomari",
                      label: _vm.$t("messages.yieldRate"),
                      "min-width": "7%",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-button-box" },
            [
              _c(
                "el-button",
                { staticClass: "back-button", on: { click: _vm.doClose } },
                [_vm._v(_vm._s(_vm.$t("messages.backName")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.backFlag,
                      expression: "!backFlag",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.doComfirm },
                },
                [_vm._v(_vm._s(_vm.$t("messages.confirm")))]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }