import request from '@/utils/request'

// 保存
export function saveIraiAuthAPI(from, to, iraiNum, iraiMemo, iraiPath, updUser) {
  const data = {
    from,
    to,
    iraiNum,
    iraiMemo,
    iraiPath,
    updUser
  }
  console.log('call saveIraiAuthAPI')
  return request({
    url: '/api/v1/iraiAuth/save',
    method: 'post',
    data
  })
}

// 修改
export function updateIraiAuthAPI(iraiId, from, to, iraiNum, iraiMemo, iraiPath, updUser) {
  const data = {
    iraiId,
    from,
    to,
    iraiNum,
    iraiMemo,
    iraiPath,
    updUser
  }
  console.log('call updateIraiAuthAPI')
  return request({
    url: '/api/v1/iraiAuth/update',
    method: 'post',
    data
  })
}

// 获取总数
export function getTotalBoardAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getTotalBoardAPI')
  return request({
    url: '/api/v1/iraiAuth/getTotalBoard',
    method: 'post',
    data
  })
}

// 获取总数下方list  true //依赖list， false为另一个
export function getIraiAuthListAPI(kigyouCode, dependenceFlag) {
  const data = {
    kigyouCode,
    dependenceFlag
  }
  console.log('call getIraiAuthListAPI')
  return request({
    url: '/api/v1/iraiAuth/getIraiAuthList',
    method: 'post',
    data
  })
}

// 删除
export function deleteIraiAuthAPI(iraiid) {
  const data = {
    iraiid
  }
  console.log('call deleteIraiAuthAPI')
  return request({
    url: '/api/v1/iraiAuth/deleteIraiAuth',
    method: 'post',
    data
  })
}

// 企业名list
export function getKigyouListAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getKigyouListAPI')
  return request({
    url: '/api/v1/iraiAuth/getKigyouList',
    method: 'post',
    data
  })
}

