var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pie-table-panel" }, [
    _vm._m(0),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTable,
            expression: "showTable",
          },
        ],
        staticClass: "table-container",
      },
      [
        _c(
          "el-table",
          {
            ref: "OtherCalcChildDataTable",
            attrs: {
              data: _vm.processTableData,
              size: "small",
              "row-key": "processcode",
              border: "",
              "default-expand-all": "",
              "max-height": _vm.maxHeight,
              "tree-props": {
                children: "children",
                hasChildren: "hasChildren",
              },
              "header-cell-style": { background: "rgba(51, 51, 51, 0.05)" },
              "row-class-name": _vm.firstRowStyle,
              "span-method": _vm.arraySpanMethod,
            },
          },
          [
            _c(
              "el-table-column",
              { attrs: { prop: "processname4display", "min-width": "34%" } },
              [
                _c("template", { slot: "header" }, [
                  _c("div", { staticClass: "processName-title" }, [
                    _c("i", {
                      class: {
                        "el-icon-arrow-down": !_vm.isExpansion,
                        "el-icon-arrow-right": _vm.isExpansion,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleRowExpansion()
                        },
                      },
                    }),
                    _c("i", { staticStyle: { "padding-right": "20%" } }),
                    _c("span", [_vm._v(" プロセス・項目 ")]),
                  ]),
                ]),
              ],
              2
            ),
            _c(
              "el-table-column",
              {
                attrs: { prop: "name", "min-width": "28%" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatIdeaName(scope.row.name))),
                        ]),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("div", { staticClass: "table-title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("messages.eFName")) + " "),
                  ]),
                ]),
              ],
              2
            ),
            _c(
              "el-table-column",
              {
                attrs: { prop: "quantity", "min-width": "12%" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatQuantity(scope.row.quantity)) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("div", { staticClass: "table-title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("messages.amount")) + " "),
                  ]),
                ]),
              ],
              2
            ),
            _c(
              "el-table-column",
              { attrs: { prop: "unit", "min-width": "12%" } },
              [
                _c("template", { slot: "header" }, [
                  _c("div", { staticClass: "table-title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("messages.unit")) + " "),
                  ]),
                ]),
              ],
              2
            ),
            _c(
              "el-table-column",
              {
                attrs: { prop: "emissionc", "min-width": "25%" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(scope.row.emissionc[0]) +
                              " " +
                              _vm._s(scope.row.emissionc[1]) +
                              " "
                          ),
                          _c("sup", [_vm._v(_vm._s(scope.row.emissionc[2]))]),
                        ]),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("div", {
                    staticClass: "table-title",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("messages.emission") +
                          "&nbsp;(kg-CO<sub>2</sub>eq)"
                      ),
                    },
                  }),
                ]),
              ],
              2
            ),
            _c(
              "el-table-column",
              {
                attrs: {
                  prop: "percentage",
                  label: "割合",
                  "min-width": "12%",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        String(scope.row.processcode).includes("D")
                          ? _c("span", [_vm._v("-")])
                          : _c("span", [
                              _vm._v(_vm._s(scope.row.percentage) + "%"),
                            ]),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("div", { staticClass: "table-title" }, [
                    _vm._v(_vm._s(_vm.$t("messages.percentage"))),
                  ]),
                ]),
              ],
              2
            ),
            _c(
              "el-table-column",
              {
                attrs: {
                  prop: "budomari",
                  label: "歩留まり率",
                  "min-width": "14%",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.budomari
                          ? _c("span", [
                              _vm._v(_vm._s(scope.row.budomari) + "%"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("div", { staticClass: "table-title" }, [
                    _vm._v("歩留まり率"),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pie-container" }, [
      _c("div", {
        staticClass: "pie-chart",
        attrs: { id: "OtherCalcPieChart" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }