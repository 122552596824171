<template>
  <div class="pie-table-panel">
    <div class="pie-container">
      <div id="SelfCalcPieChart" class="pie-chart" />
    </div>
    <div v-show="showTable" class="table-container">
      <el-table
        ref="SelfCalcChildDataTable"
        :data="processTableData"
        size="small"
        row-key="processcode"
        border
        default-expand-all
        :max-height="maxHeight"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :header-cell-style="{ background: 'rgba(51, 51, 51, 0.05)' }"
        :row-class-name="firstRowStyle"
        :span-method="arraySpanMethod"
      >
        <el-table-column prop="processname4display" min-width="32%">
          <template slot="header">
            <div class="processName-title">
              <i
                :class="{'el-icon-arrow-down': !isExpansion, 'el-icon-arrow-right': isExpansion}"
                @click="toggleRowExpansion()"
              />
              <i style="padding-right: 20%;" />
              <span> プロセス・項目 </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" min-width="28%">
          <template slot="header">
            <div class="table-title"> {{ $t('messages.eFName') }} </div>
          </template>
          <template slot-scope="scope">
            <span>{{ formatIdeaName(scope.row.name) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="quantity" min-width="12%">
          <template slot="header">
            <div class="table-title"> {{ $t('messages.amount') }} </div>
          </template>
          <template slot-scope="scope">
            <span> {{ formatQuantity(scope.row.quantity) }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="unit" min-width="12%">
          <template slot="header">
            <div class="table-title"> {{ $t('messages.unit') }} </div>
          </template>
        </el-table-column>
        <el-table-column prop="emissionc" min-width="25%">
          <template slot="header">
            <div class="table-title" v-html="$t('messages.emission') + '&nbsp;(kg-CO<sub>2</sub>eq)'" />
          </template>
          <template slot-scope="scope">
            <span>
              {{ scope.row.emissionc[0] }} {{ scope.row.emissionc[1] }}
              <sup>{{ scope.row.emissionc[2] }}</sup>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="percentage" label="割合" min-width="12%">
          <template slot="header">
            <div class="table-title">{{ $t('messages.percentage') }}</div>
          </template>
          <template slot-scope="scope">
            <span v-if="String(scope.row.processcode).includes('D')">-</span>
            <span v-else>{{ scope.row.percentage }}%</span>
          </template>
        </el-table-column>
        <el-table-column prop="budomari" label="歩留まり率" min-width="14%">
          <template slot="header">
            <div class="table-title">歩留まり率</div>
          </template>
          <template slot-scope="scope">
            <span v-if="scope.row.budomari">{{ scope.row.budomari }}%</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import * as echarts from 'echarts'
import { formatQuantity } from '../../utils/index'
import './referenceTree.scss'

export default {
  name: 'PieTablePanel',
  inject: ['formatIdeaName'],
  props: {
    pieData: {
      type: Array,
      default: () => []
    },
    colorData: {
      type: Array,
      default: () => []
    },
    processTableData: {
      type: Array,
      default: () => []
    },
    tableMaxHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isExpansion: false,
      maxHeight: 0,
      showTable: false
    }
  },
  watch: {
    pieData: {
      handler(newVal) {
        this.showTable = true
        if (newVal.length > 0) {
          this.initChart()
        }
      },
      deep: true
    },
    tableMaxHeight: {
      handler(newVal) {
        this.$nextTick(() => {
          this.maxHeight = newVal
        })
      }
    }
  },
  mounted() {
    if (this.tableMaxHeight) {
      this.$nextTick(() => {
        this.maxHeight = this.tableMaxHeight
      })
    }
  },
  methods: {
    formatQuantity,
    initChart() {
      const pie_chart = echarts.init(document.getElementById('SelfCalcPieChart'))
      const option = {
        tooltip: {
          trigger: 'item',
          position: 'right',
          formatter: function(params) {
            return `
            ${params.data.processcode} ${params.name}: ${params.data.emissionc} (${params.data.percentage}%)
            `
          }
        },
        color: this.colorData,
        legend: {
          show: true,
          top: '60%',
          left: 'center',
          itemHeight: 8,
          itemWidth: 8,
          fontSize: 4,
          itemGap: 15,
          icon: 'circle',
          selectedMode: false,
          formatter: function(name) {
            return '{a|' + name + '}'
          },
          textStyle: {
            backgroundColor: 'transparent',
            rich: {
              a: {
                width: 90
              }
            }
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '45%',
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            top: '-40%',
            left: '-10%',
            emphasis: {
              label: {
                show: false,
                fontSize: '8',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.pieData
          }
        ]
      }
      pie_chart.setOption(option)
      window.addEventListener('resize', function() {
        pie_chart.resize()
      })
    },
    toggleRowExpansion() {
      this.processTableData.forEach(item => {
        this.$refs.SelfCalcChildDataTable.toggleRowExpansion(item, this.isExpansion)
      })
      this.$nextTick(() => {
        this.$refs.SelfCalcChildDataTable.doLayout()
      })
      this.isExpansion = !this.isExpansion
    },
    firstRowStyle({ rowIndex }) {
      if (rowIndex === 0) {
        return 'fixed-sum-row'
      }
    },
    arraySpanMethod({ rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        if (columnIndex === 0) {
          return [1, 4]
        } else if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
          return [0, 0]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pie-table-panel {
  display: flex;
  width: 100%;

  .pie-container {
    height: 100%;
    width: 25%;

    .pie-chart {
      height: 340px;
      width: 280px;
    }
  }

  .table-container {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    font-family: NotoSansCJKjp-Regular;
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
    font-weight: 400;

    .processName-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: #333333;
    }

    .table-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #333333;
    }
  }
  ::v-deep .el-table__body {
    user-select: text;
    width: 100% !important;
  }
}
</style>
