var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "popup-fade" } }, [
    _c("div", { staticClass: "popup-body" }, [
      _c("div", { staticClass: "popup-content-wrap" }, [
        _c("div", { staticClass: "popup" }, [
          _c("div", { staticClass: "title-box" }, [
            _c("i", {
              staticClass: "el-icon-close",
              on: { click: _vm.doClose },
            }),
          ]),
          _c(
            "div",
            { staticClass: "traffic-body-box" },
            [
              _c(
                "el-form",
                {
                  ref: "utilityItem",
                  staticClass: "traffic-form",
                  attrs: { model: _vm.utilityItem, rules: _vm.rules },
                },
                [
                  _c(
                    "div",
                    { staticClass: "traffic-div" },
                    [
                      _c("div", { staticClass: "traffic-label" }, [
                        _vm._v("工場全体のユーティリティ消費量"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "allUtility" } },
                        [
                          _c("el-input", {
                            staticClass: "traffic-input",
                            attrs: {
                              size: "small",
                              placeholder:
                                "工場全体のユーティリティ消費量を入力してください",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.utilityItem.allUtility =
                                  value
                                    .replace(
                                      /(\-?)\D*(\d*)(\.?)(\d*)\d*/,
                                      "$1$2$3$4"
                                    )
                                    .match(/^\-?\d*(\.?\d*)/g)[0] || "")
                              },
                            },
                            model: {
                              value: _vm.utilityItem.allUtility,
                              callback: function ($$v) {
                                _vm.$set(_vm.utilityItem, "allUtility", $$v)
                              },
                              expression: "utilityItem.allUtility",
                            },
                          }),
                          _vm._v(" kWh "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "traffic-div" },
                    [
                      _c("div", { staticClass: "traffic-label" }, [
                        _vm._v("工場全体の生産量"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "allProduction" } },
                        [
                          _c("el-input", {
                            staticClass: "traffic-input",
                            attrs: {
                              size: "small",
                              placeholder: "工場全体の生産量を入力してください",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.utilityItem.allProduction =
                                  value
                                    .replace(/\D*(\d*)(\.?)(\d*)\d*/, "$1$2$3")
                                    .match(/^\d*(\.?\d*)/g)[0] || "")
                              },
                            },
                            model: {
                              value: _vm.utilityItem.allProduction,
                              callback: function ($$v) {
                                _vm.$set(_vm.utilityItem, "allProduction", $$v)
                              },
                              expression: "utilityItem.allProduction",
                            },
                          }),
                          _vm._v(" Kg "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "traffic-div" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("messages.amount")) +
                        " " +
                        _vm._s(
                          _vm.utilityItem.allUtility /
                            _vm.utilityItem.allProduction
                            ? _vm.utilityItem.allUtility /
                                _vm.utilityItem.allProduction
                            : ""
                        ) +
                        " kWh "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-button-box" },
            [
              _c(
                "el-button",
                { staticClass: "back-button", on: { click: _vm.doClose } },
                [_vm._v(" " + _vm._s(_vm.$t("messages.back")))]
              ),
              _c(
                "el-button",
                {
                  staticClass: "calculator-button",
                  on: {
                    click: function ($event) {
                      return _vm.doComfirm("utilityItem")
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.buttonName) + " "),
                  _c("i", { staticClass: "el-icon-check" }),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }