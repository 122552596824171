var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "popup-fade" } }, [
    _c("div", { staticClass: "popup-body" }, [
      _c("div", { staticClass: "popup-content-wrap" }, [
        _c("div", { staticClass: "popup" }, [
          _c(
            "div",
            { staticClass: "title-box" },
            [
              _c("i", {
                staticClass: "el-icon-close",
                on: { click: _vm.doClose },
              }),
              _c("span", { staticClass: "title-font" }, [
                _vm._v(_vm._s(_vm.$t("messages.newProcess"))),
              ]),
              _c(
                "el-popover",
                {
                  staticClass: "suggestion-icon",
                  attrs: {
                    placement: "right-start",
                    width: "200",
                    trigger: "hover",
                    content:
                      _vm.processlist4select.length > 0 &&
                      _vm.processlist4select[0].icon
                        ? _vm.content
                        : _vm.originalContent,
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-question",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  }),
                ]
              ),
            ],
            1
          ),
          _vm.processlist4select.length > 0 && _vm.processlist4select[0].icon
            ? _c(
                "div",
                { staticClass: "process-list-box" },
                [
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    _vm._l(_vm.elTabsData, function (item) {
                      return _c(
                        "el-tab-pane",
                        {
                          key: item.name,
                          attrs: { label: item.title, name: item.name },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "process-list" },
                            _vm._l(
                              _vm.processlist4select,
                              function (subProcess, subProcessIndex) {
                                return _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: subProcess.isShow,
                                        expression: "subProcess.isShow",
                                      },
                                    ],
                                    key: subProcessIndex,
                                    staticClass: "process-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.activeBtn(subProcess)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "process-icon",
                                        style: _vm.getStyle(subProcess),
                                      },
                                      [
                                        subProcess.isActive
                                          ? _c("i", {
                                              staticClass: "el-icon-success",
                                            })
                                          : _vm._e(),
                                        subProcess && subProcess.icon
                                          ? _c("img", {
                                              attrs: {
                                                src: _vm.getIconUrl(subProcess),
                                              },
                                            })
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "icon-font" },
                                          [
                                            _vm._v(
                                              _vm._s(subProcess.processCode) +
                                                ": " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "messages." +
                                                      subProcess.processCode
                                                  )
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "process-list-box" },
                [
                  _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    _vm._l(
                      [{ title: this.$t("messages.all"), name: "all" }],
                      function (item) {
                        return _c(
                          "el-tab-pane",
                          {
                            key: item.name,
                            attrs: { label: item.title, name: item.name },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "process-list" },
                              _vm._l(
                                _vm.processlist4select,
                                function (subProcess, subProcessIndex) {
                                  return _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: subProcess.isShow,
                                          expression: "subProcess.isShow",
                                        },
                                      ],
                                      key: subProcessIndex,
                                      staticClass: "process-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.activeBtn(subProcess)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "process-icon",
                                          style: _vm.getStyle(subProcess),
                                        },
                                        [
                                          subProcess.isActive
                                            ? _c("i", {
                                                staticClass: "el-icon-success",
                                              })
                                            : _vm._e(),
                                          subProcess && subProcess.icon
                                            ? _c("img", {
                                                attrs: {
                                                  src: _vm.getIconUrl(
                                                    subProcess
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "icon-font" },
                                            [
                                              _vm._v(
                                                _vm._s(subProcess.processCode) +
                                                  ": " +
                                                  _vm._s(subProcess.processName)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
          _c(
            "div",
            { staticClass: "tab-button-box" },
            [
              _c(
                "el-button",
                { staticClass: "back-button", on: { click: _vm.doClose } },
                [_vm._v(" " + _vm._s(_vm.$t("messages.processCancel")))]
              ),
              _c(
                "el-button",
                {
                  staticClass: "calculator-button",
                  attrs: { type: "primary" },
                  on: { click: _vm.sendProcess2update },
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("messages.processAdd")) + " "),
                  _c("i", { staticClass: "el-icon-check" }),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }