<template>
  <div>
    <div class="update-body">
      <el-card v-loading="loading">
        <div class="button-flag">
          <div style="display: flex;margin-bottom: 2rem;">
            <div class="button-group">
              <span :class="buttonFlag == 1 ? 'active': ''" @click="toProcessRelationship(1)">{{$t('messages.standardProcess')}}</span>
              <span :class="buttonFlag == 2 ? 'active': ''" @click="toProcessRelationship(2)">{{$t('messages.originalProcess')}}</span>
            </div>
            <div class="vertical-line">|</div>
            <div class="button-group">
              <span :class="(parentFlag == 1 || noTreeFromCsvOrApi) ? 'active': ''" @click="toParentchildRelationship(1)">{{$t('messages.noParent')}}</span>
              <span :class="parentFlag == 2 ? 'active': ''" @click="toParentchildRelationship(2)">{{$t('messages.parentExists')}}</span>
            </div>
          </div>
          <div v-if="parentFlag == 2 && copyFlag" style="height: 22px; border-left: 5px solid #BFEBFA; margin-left: 20px; display: flex; align-items: center; font-size: 14px;">
            <span>{{$t('messages.callDataPorduct')}}</span>
          </div>
        </div>
        <div>
          <div class="top-seihin-box">
            <div class="seihin-name-select">
              <el-form ref="seihinNameObjName" :model="seihinNameObjName" :rules="seihinNameRules" :validate-on-rule-change="false">
                <el-form-item :label="$t('messages.addproductName')" prop="seihinName">
                  <el-select v-model="seihinNameObjName.seihinName" size="small" class="select-item" filterable :disabled="!statusDisabled" @change="choseSeihinName">
                    <el-option
                      v-for="item in filterSeihinNameList"
                      :key="item.seihincode"
                      :label="item.seihinname"
                      :value="item.seihinname"
                    />
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div class="seihin-unit">
              <el-form>
                <el-form-item :label="$t('messages.unit')">
                  <el-select v-model="choseSeihinUnit" size="small" disabled>
                    <el-option
                      v-for="item in seihinUnitOptions"
                      :key="item.unitValue"
                      :label="item.unitLabel"
                      :value="item.unitValue"
                    />
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div class="seihin-version">
              <el-form ref="seihinNameObjVersion" :model="seihinNameObjVersion" :rules="seihinNameRules" :validate-on-rule-change="false">
                <el-form-item :label="$t('messages.version')" prop="seihinversion">
                  <el-input v-model="seihinNameObjVersion.seihinversion" :disabled="!statusDisabled" size="small" />
                </el-form-item>
              </el-form>
            </div>
            <div class="seihin-btn">
              <el-form>
                <el-form-item class="padding-top-20">
                  <el-button class="btn-color" size="small" type="primary" @click="editContent()">{{$t('messages.checkRegister')}}</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="edit-icon">
              <el-popover placement="right" :disabled="statusDisabled">
                  <textarea v-model="simulateNote" cols="40" rows="10" maxlength="3000" :placeholder="$t('messages.note')" />
                  <span slot="reference" class="icon-size">
                    <img src="@/assets/static/icon/noteAdd.svg" width="16" :style="{cursor: statusDisabled? 'not-allowed' : 'pointer'}"></span>
              </el-popover>
            </div>
            <div v-if="buttonFlag != 2" @click="triggerHelpDialog()" class="edit-icon" :style="{cursor: statusDisabled? 'not-allowed' : 'pointer'}">
               <span 
               slot="reference" 
               class="icon-size">
               <img src="@/assets/static/icon/addMenu.svg" height="16"></span>
            </div>
            <el-dialog
              :title="$t('messages.entryAssistMenu')"
              :visible.sync="dialogVisible"
              width="30%"
            >
              <!-- story17 start -->
              <el-dialog
                width="85%"
                :title="$t('messages.utilityDistribution')"
                :visible.sync="innerVisibleSort1"
                center
                append-to-body
              >
                <el-form ref="utilityApportionmentForm" :model="utilityApportionmentForm" :rules="utilityApportionmentRules" :validate-on-rule-change="false">
                  <el-row :gutter="10">
                    <el-col :span="2">
                      <el-form-item :label="$t('messages.itemName')" prop="selectIdeaInfo">
                        <el-select v-model="utilityApportionmentForm.selectIdeaInfo" size="small" class="width-100" placeholder="項目名を選択してください" @change="(val)=>getSelectIdeaInfo('utility', val)">
                          <el-option :label="$t('messages.electricity')" value="1" />
                          <el-option :label="$t('messages.waterSuplly')" value="2" />
                          <el-option :label="$t('messages.fuel')" value="3" />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-form-item :label="$t('messages.efName')" prop="ideaName">
                        <el-select v-model="utilityApportionmentForm.ideaName" filterable size="small" class="width-100" placeholder="原単位名を選択してください" @change="utilityApportionmentNameChange">
                          <el-option
                            v-for="item in utilityApportionmentList"
                            :key="item.ideacode"
                            :label="item.name"
                            :value="item.ideacode"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="7">
                      <el-form-item :label="$t('messages.opuc')" prop="consumption">
                        <el-input v-model="utilityApportionmentForm.consumption" size="small" :placeholder="$t('messages.opuc')" @input="value => utilityApportionmentForm.consumption=value.replace(/(\-?)\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3$4').match(/^\-?\d*(\.?\d*)/g)[0] || ''"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="2">
                      <el-form-item :label="$t('messages.unit')" prop="unit">
                        <el-input v-model="utilityApportionmentForm.unit" size="small" disabled />
                      </el-form-item>
                    </el-col>
                    <el-col :span="4">
                      <el-form-item :label="$t('messages.overallPlantProduction')" prop="production">
                        <div class="product-value">
                          <el-input v-model="utilityApportionmentForm.production" size="small" :placeholder="$t('messages.overallPlantProduction')" @input="value => utilityApportionmentForm.production=value.replace(/\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3').match(/^\d*(\.?\d*)/g)[0] || ''"/>
                          <span>kg</span>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="4">
                      <el-form-item :label="$t('messages.productWeight')" prop="productWeight">
                        <div class="product-value">
                          <el-input v-model="utilityApportionmentForm.productWeight" size="small" :placeholder="$t('messages.productWeight')" @input="value => utilityApportionmentForm.productWeight=value.replace(/\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3').match(/^\d*(\.?\d*)/g)[0] || ''" />
                          <span>kg</span>
                        </div>
                      </el-form-item>
                    </el-col>              
                  </el-row>
                  <el-row :gutter="10">      
                    <el-col :span="3">
                      <el-form-item :label="$t('messages.amount')" prop="quantity">
                        <el-input v-model="utilityApportionmentForm.quantity" size="small" :placeholder="$t('messages.amount')" disabled />
                      </el-form-item>
                    </el-col>
                    <el-col :span="2">
                      <el-form-item :label="$t('messages.unit')" prop="unit">
                        <el-input v-model="utilityApportionmentForm.unit" size="small" disabled />
                      </el-form-item>
                    </el-col>                    
                  </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="toBackUtilityApportionment">{{$t('messages.back')}}</el-button>
                  <el-button type="primary" @click="insertUtilityApportionment">{{$t('messages.doregister')}}</el-button>
                </span>
              </el-dialog>
              <!-- story17 end -->
              <!-- story21 start -->
              <el-dialog
                width="85%"
                :title="$t('messages.productTransport')"
                :visible.sync="innerVisibleSort2"
                center
                append-to-body
                :close-on-click-modal="false"
                :close-on-press-escape="false"
              >
                <el-form ref="seihinyusoForm" :model="seihinyusoForm" :rules="seihinyusoRules" :validate-on-rule-change="false">
                  <el-row :gutter="20">
                    <el-col :span="3">
                      <el-form-item :label="$t('messages.itemName')" prop="itemName">
                        <el-input v-model="seihinyusoForm.itemName" :placeholder="$t('messages.productTransport')" size="small" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="3">
                      <el-form-item :label="$t('messages.transportMeans')" prop="trafficName">
                        <el-select v-model="seihinyusoForm.trafficName" class="width-100" :placeholder="$t('messages.petm')" size="small" @change="trafficNameChange">
                          <el-option :label="$t('messages.roadTransportOnly')" value="1" />
                          <el-option :label="$t('messages.includingSeaTransport')" value="2" />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item :label="$t('messages.ef')">
                        <el-input v-model="seihinyusoForm.originalUnit1" size="small" :title="seihinyusoForm.originalUnit1" disabled />
                      </el-form-item>
                      <div v-if="trafficSeaItemShow">
                        <el-form-item>
                          <el-input v-model="seihinyusoForm.originalUnit2" size="small" :title="seihinyusoForm.originalUnit2" disabled />
                        </el-form-item>
                        <el-form-item>
                          <el-input v-model="seihinyusoForm.originalUnit3" size="small" :title="seihinyusoForm.originalUnit3" disabled />
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item :label="$t('messages.transportScenario')" prop="transSenario1">
                        <el-select v-model="seihinyusoForm.transSenario1" size="small" class="select-item width-100" :placeholder="$t('messages.productTransport')" filterable>
                          <el-option
                            v-for="item in transSenarioOptions"
                            :key="item.senarioid"
                            :label="$t('messages.' + 'item' + item.senarioid)"
                            :value="item.senarioid + ''"
                          />
                        </el-select>
                      </el-form-item>
                      <div v-if="trafficSeaItemShow">
                        <el-form-item>
                          <el-input v-model="seihinyusoForm.transSenario2" size="small" disabled />
                        </el-form-item>
                        <el-form-item prop="transSenario3">
                          <el-select v-model="seihinyusoForm.transSenario3" size="small" class="select-item width-100" :placeholder="$t('messages.pets')" filterable>
                            <el-option
                              v-for="item in transSenarioOptions"
                              :key="item.senarioid"
                              :label="item.senarioname"
                              :value="item.senarioid"
                            />
                          </el-select>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <el-form-item :label="$t('messages.transportDistance')" class="is-required">
                        <div class="product-value">
                          <el-input v-model="seihinyusoForm.trafficDistance1" size="small" disabled />
                          <span>km</span>
                        </div>
                      </el-form-item>
                      <div v-if="trafficSeaItemShow">
                        <el-form-item prop="trafficDistance2">
                          <div class="product-value">
                            <el-input v-model="seihinyusoForm.trafficDistance2" size="small" @input="value => seihinyusoForm.trafficDistance2=value.replace(/\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3').match(/^\d*(\.?\d*)/g)[0] || ''"/>
                            <span>km</span>
                          </div>
                        </el-form-item>
                        <el-form-item>
                          <div class="product-value">
                            <el-input v-model="seihinyusoForm.trafficDistance3" size="small" disabled />
                            <span>km</span>
                          </div>
                        </el-form-item>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <el-form-item :label="$t('messages.amount')">
                        <div class="product-value">
                          <el-input v-model="seihinyusoForm.quantity1" placeholder="0" size="small" disabled />
                          <span>tkm</span>
                        </div>
                      </el-form-item>
                      <div v-if="trafficSeaItemShow">
                        <el-form-item>
                          <div class="product-value">
                            <el-input v-model="seihinyusoForm.quantity2" placeholder="0" size="small" disabled />
                            <span>tkm</span>
                          </div>
                        </el-form-item>
                        <el-form-item>
                          <div class="product-value">
                            <el-input v-model="seihinyusoForm.quantity3" placeholder="0" size="small" disabled />
                            <span>tkm</span>
                          </div>
                        </el-form-item>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">
                      <el-form-item :label="$t('messages.productWeight')" prop="productWeight">
                        <div class="product-value">
                          <el-input v-model="seihinyusoForm.productWeight" size="small" @input="value => seihinyusoForm.productWeight=value.replace(/\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3').match(/^\d*(\.?\d*)/g)[0] || ''"/>
                          <span>kg</span>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="doBackSeihinyuso()">{{$t('messages.back')}}</el-button>
                  <el-button type="primary" @click="insertSeihinyuso()">{{$t('messages.doregister')}}</el-button>
                </span>
              </el-dialog>
              <!-- story21 end -->
              <div class="content-center">
                <el-button class="btn-color" size="small" type="primary" @click="innerVisibleSort1 = true">{{$t('messages.utilityDistribution')}}</el-button>
                <el-button class="btn-color" size="small" type="primary" @click="innerVisibleSort2 = true">{{$t('messages.productTransport')}}</el-button>
              </div>
              <span slot="footer" class="dialog-footer content-center">
                <el-button class="btn-plain" @click="dialogVisible = false">{{$t('messages.back')}}</el-button>
              </span>
            </el-dialog>
            <div class="emissionc-sum" v-if="parentFlag == 1 && !noTreeFromCsvOrApi">
              <div> {{$t('messages.sumemissionc')}} </div>
              <div style="font-size: 1.6rem !important; background-color: #E7E6E6; font-weight: 1000; display: inline-block; line-height: 2.5">{{sumProcessEmission[0]}}{{sumProcessEmission[1]}}<sup>{{sumProcessEmission[2]}}</sup></div>
            </div>
            <div class="emissionc-sum" v-if="parentFlag == 2 || noTreeFromCsvOrApi">
              <div> {{$t('messages.sumemissionc')}} </div>
              <div style="font-size: 1.6rem !important; background-color: #E7E6E6; font-weight: 1000; display: inline-block; line-height: 2.5">{{sumTreeEmission[0]}}{{sumTreeEmission[1]}}<sup>{{sumTreeEmission[2]}}</sup></div>
            </div>
            <div>
              <div class="bom-import rightBtn">
                <el-button :disabled="statusDisabled" type="primary" class="bomImport-button" size="small" @click="openpdf()">{{$t('messages.viewGuidelines')}}</el-button>
                <el-upload
                  ref="uploadButton"
                  :disabled="statusDisabled"
                  :show-file-list="false"
                  accept=".*"
                  name="csvFile"
                  :headers="headers"
                  :on-progress="showPageLoading"
                  :on-success="onSuccess"
                  :on-error="onError"
                  style="display: inline-flex;margin-left: 16px"
                  :action="actionup"
                  :data="uploadData"
                >
                  <el-button v-if="fromPage !== 'externalApiPage' && parentFlag !== 2" slot="tip" type="primary" class="bomImport-button" size="small" :disabled="statusDisabled" :icon="importDataBtnIcon" @click="checkExistData()">
                    {{$t('messages.uploadCSV')}}
                  </el-button>
                </el-upload>
                <el-upload
                  ref="treeUploadButton"
                  :disabled="statusDisabled"
                  :show-file-list="false"
                  accept=".*"
                  name="csvFile"
                  :headers="headers"
                  :on-progress="showPageLoading"
                  :on-success="treeOnSuccess"
                  :on-error="treeOnError"
                  style="display: inline-flex;"
                  :action="actionup"
                  :data="treeUploadData"
                >
                  <el-button v-if="fromPage !== 'externalApiPage' && parentFlag === 2" slot="tip" type="primary" class="bomImport-button" size="small" :disabled="statusDisabled" :icon="importDataBtnIcon" @click="treeCheckExistData()">
                    {{$t('messages.uploadCSV')}}
                  </el-button>
                </el-upload>
              </div>
              <div class="btn-function margin-top-5">
                <div @click="downloalNoParent" v-if="fromPage !== 'externalApiPage' && parentFlag !== 2"><img class="download-icon" src="@/assets/static/icon/download.svg"><span>{{$t('messages.formatNoParent')}}</span></div>
                <div @click="downloalParent" v-if="fromPage !== 'externalApiPage' && parentFlag === 2"><img class="download-icon" src="@/assets/static/icon/download.svg"><span>{{$t('messages.formatParent')}}</span></div>
              </div>
            </div>

            <!-- scv dialog visible start -->
            <el-dialog
              title="原単位と活動量の確認・修正"
              :visible.sync="resultsDialogVisible"
              :close-on-click-modal="false"
              width="97%"
              center
              :before-close="handleDialogClose"
              :lock-scroll="false"
              :append-to-body="true"
              :modal-append-to-body="false"
            >
              <!-- 登录后 -->
              <el-dialog
                width="85%"
                title=""
                :visible.sync="updateVisible"
                append-to-body
                center
              >
                <h6 class="content-title">{{$t('messages.tfihbl2anef')}}<br>{{$t('messages.pttiyw2urarutnef')}}</h6>
                <div class="table-border table margin-top-32">
                  <el-table
                    ref="multipleTable"
                    :data="changedCsvTableData"
                    :lock-scroll="false"
                    :append-to-body="true"
                    tooltip-effect="dark"
                    style="width: 100%"
                    height="380"
                    @selection-change="handleSelectionChange"
                  >
                    <el-table-column
                      type="selection"
                      width="90"
                      label="test"
                      :class-name="language === 'en'? 'enStyle':'jpStyle'"
                    />
                    <el-table-column prop="itemA" :label="$t('messages.itemA')" width="120">
                      <template slot-scope="scope">
                        <span>{{ scope.row.itemA }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="itemB" :label="$t('messages.itemB')" width="120">
                      <template slot-scope="scope">
                        <span>{{ scope.row.itemB }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="itemC" :label="$t('messages.itemC')" width="120">
                      <template slot-scope="scope">
                        <span>{{ scope.row.itemC }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="itemD" :label="$t('messages.itemD')" width="120">
                      <template slot-scope="scope">
                        <span>{{ scope.row.itemD }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="itemE" :label="$t('messages.itemE')" width="120">
                      <template slot-scope="scope">
                        <span>{{ scope.row.itemE }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="processCode" :label="$t('messages.processAfter')">
                      <template slot-scope="scope">
                        <span>{{ scope.row.processCode }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="processCodeOld" :label="$t('messages.processBefore')">
                      <template slot-scope="scope">
                        <span>{{ scope.row.processCodeOld }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="ideaName" :label="$t('messages.afterUpdate')">
                      <template slot-scope="scope">
                        <span>{{ scope.row.ideaName }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="ideaNameOld" :label="$t('messages.beforUpdate')">
                      <template slot-scope="scope">
                        <span>{{ scope.row.ideaNameOld }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="updateVisible = false">{{$t('messages.back')}}</el-button>
                  <el-button v-if="language ==='jp'" type="primary" @click="updateDataToAdd">{{$t('messages.onlyEmissions')}}{{$t('messages.register')}}</el-button>
                  <el-button v-if="language ==='en'" type="primary" @click="updateDataToAdd">{{$t('messages.reo1')}}</el-button>
                  <el-button type="primary" :disabled="updateRuleDisabled" @click="updateRule">{{$t('messages.addRuleButton')}}</el-button>
                </div>
              </el-dialog>
              <el-button type="primary" style="margin-bottom: 1%;" @click="rowToAdd">行追加</el-button>
              <div class="table-border table">
                <section class="header">
                  <el-row :gutter="0" class="table-header">
                    <el-col style="min-height: 1px;width: 4%;"><div class="blank-div" /></el-col>
                    <el-col :style="{width:parentFlag !== 2 ? '8%' : '6%'}"><span class="is-required">*</span>{{$t('messages.item')}}</el-col>
                    <el-col style="width: 4%">{{$t('messages.itemA')}}</el-col>
                    <el-col style="width: 4%">{{$t('messages.itemB')}}</el-col>
                    <el-col style="width: 4%">{{$t('messages.itemC')}}</el-col>
                    <el-col style="width: 4%">{{$t('messages.itemD')}}</el-col>
                    <el-col style="width: 4%">{{$t('messages.itemE')}}</el-col>
                    <el-col :style="{width:parentFlag !== 2 ? '8%' : '6%'}"><span class="is-required">*</span>{{$t('messages.process')}}</el-col>
                    <el-col style="width: 8%">{{$t('messages.efName')}}</el-col>
                    <el-col style="width: 8%"><span class="is-required">*</span>{{$t('messages.amount')}}<br>({{$t('messages.finalized')}})</el-col>
                    <el-col v-if="parentFlag !== 2" style="width: 8%">{{$t('messages.unit')}}<br>({{$t('messages.ef')}})</el-col>
                    <el-col v-if="parentFlag == 2" style="width: 7%">{{$t('messages.unit')}}<br>({{$t('messages.ef')}})</el-col>
                    <el-col :style="{width:parentFlag !== 2 ? '8%' : '7%'}">{{$t('messages.amount')}}<br>({{$t('messages.inputData')}})</el-col>
                    <el-col :style="{width:parentFlag !== 2 ? '8%' : '7%'}">{{$t('messages.unit')}}<br>({{$t('messages.inputData')}})</el-col>
                    <el-col :style="{width:parentFlag !== 2 ? '8%' : '7%'}">{{$t('messages.intermediateProduct')}}</el-col>
                    <el-col v-if="parentFlag === 2" style="width: 8%">{{$t('messages.parentItem')}}</el-col>
                    <el-col style="width: 8%">{{$t('messages.yieldRate')}}</el-col>
                    <el-col style="min-height: 1px;width: 4%;"><div class="blank-div" /></el-col>
                  </el-row>
                </section>
                <div class="table-body-scroll">
                    <el-form ref="csvTableDataForm" :model="csvTableDataForm" @submit.native.prevent>
                      <div v-for="(csvItem, csvItemIndex) in csvTableDataForm.csvTableData" :key="csvItemIndex">
                      <el-row :gutter="0" class="table-body">
                        <el-col style="width: 4%" class="table-col col-width">
                         <div class="padding-top-14"><el-checkbox v-if="!(csvItemIndex !== 0 && csvItem.index === csvTableData[csvItemIndex - 1].index)" style="margin-right: 4px;" v-model="csvItem.checkCsvFlag" @change="checked=>checkCsvListChange(checked, csvItemIndex)"></el-checkbox></div>
                        </el-col>
                        <el-col style="width: 8%" :class="parentFlag !== 2 ? 'table-bg-white col-flex' : 'table-bg-white'">
                          <div>
                            <el-form-item>
                              <el-input v-if="!(csvItemIndex !== 0 && csvItem.index === csvTableData[csvItemIndex - 1].index)" v-model.number="csvItem.itemName" :placeholder="$t('messages.item')" size="small" @change="inputItemNameChange(csvItem.itemName, csvItemIndex)"/>
                            </el-form-item>
                            <div v-if="!(csvItemIndex !== 0 && csvItem.index === csvTableData[csvItemIndex - 1].index) && csvItem.itemName === ''" style="color: #F56C6C; font-size: 12px;">管理項目を設定してください</div>
                        </div>
                        </el-col>
                        <el-col class="table-col col-width" style="width: 4%">
                          <div class="padding-top-14" v-if="!(csvItemIndex !== 0 && csvItem.index === csvTableData[csvItemIndex - 1].index)">{{ csvItem.itemA }}</div>
                        </el-col>
                        <el-col class="table-col col-width" style="width: 4%">
                          <div class="padding-top-14" v-if="!(csvItemIndex !== 0 && csvItem.index === csvTableData[csvItemIndex - 1].index)">{{ csvItem.itemB }}</div>
                        </el-col>
                        <el-col class="table-col col-width" style="width: 4%">
                          <div class="padding-top-14" v-if="!(csvItemIndex !== 0 && csvItem.index === csvTableData[csvItemIndex - 1].index)">{{ csvItem.itemC }}</div>
                        </el-col>
                        <el-col class="table-col col-width" style="width: 4%">
                          <div class="padding-top-14" v-if="!(csvItemIndex !== 0 && csvItem.index === csvTableData[csvItemIndex - 1].index)">{{ csvItem.itemD }}</div>
                        </el-col>
                        <el-col class="table-col col-width" style="width: 4%">
                          <div class="padding-top-14" v-if="!(csvItemIndex !== 0 && csvItem.index === csvTableData[csvItemIndex - 1].index)">{{ csvItem.itemE }}</div>
                        </el-col>
                        <el-col style="width: 8%" class="table-col special">
                          <el-form-item 
                            :key="csvItemIndex"
                            :prop="'csvTableData.' + csvItemIndex + '.processCode'"
                            :rules="{ required: true, message: 'プロセスを選択してください', trigger: ['blur', 'change'] }">
                            <el-select v-model="csvItem.processCode"  size="small">
                              <el-option
                                v-for="(item, i) in csvProcessNameListOptions"
                                :key="item.processCode + i"
                                :label="item.processCode"
                                :value="item.processCode"
                              />
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col style="width: 8%" class="table-bg-white">
                          <el-form-item 
                            :key="csvItemIndex"
                            :prop="'csvTableData.' + csvItemIndex + '.ideaName'">
                            <el-autocomplete
                              v-model="csvItem.ideaName"
                              size="small"
                              class="genntani-input"
                              :fetch-suggestions="querySearchFile"
                              :placeholder="$t('messages.efName')"
                              :trigger-on-focus="false"
                              :title="csvItem.ideaName"
                              @select="((item) => {handleSelectFile(item, csvItemIndex)})"
                              @change="inputChangeFile(csvItem.ideaName, csvItemIndex)"
                            >
                              <template v-slot="{ item }">
                                <el-tooltip
                                  class="item"
                                  effect="dark"
                                  placement="top"
                                  :content="item.name"
                                >
                                <span>{{ item.name }}</span>
                                </el-tooltip>
                              </template>
                            </el-autocomplete>
                            <div style="color: #F56C6C;font-size: 12px;line-height: 1;" v-if="csvItem.delflg == 1 && csvItem.ideaName !== '' && ideaNameDataList[csvItemIndex] === csvItem.ideaName">この原単位は削除されています</div>
                          </el-form-item>
                        </el-col>
                        <el-col style="width: 8%" class="table-bg-white col-flex">
                          <el-form-item 
                            :key="csvItemIndex"
                            :prop="'csvTableData.' + csvItemIndex + '.quantity'"
                            :rules="[{ required: true, message: $t('messages.peaam'), trigger: ['blur', 'change']}, { validator: checkInput01, trigger: ['blur', 'change'] }]">
                            <el-input v-model="csvItem.quantity" :placeholder="$t('messages.amount')" size="small" @input="handleQuantityChange(csvItem, csvItemIndex, $event)" @blur="changeChackStatus(csvItemIndex)"/>
                            <div v-if="(!csvItem.chackStatus) && csvItem.quantity != '' && csvItem.ideaName != '' && csvItem.hasChangeIdeaName" style="color: #E6A23C; font-size: 12px; line-height: 1; padding-top: 4px;">{{$t('messages.checkAmount')}}</div>
                            <div v-else-if="(!csvItem.chackStatus) && !(csvItem.unitList || []).some(item => {return csvItem.importUnit === item.tounit}) && csvItem.ideaName && csvItem.quantity != ''" class="txt" style="color: #E6A23C; line-height: 1; padding-top: 4px;">{{$t('messages.unitMismatched')}}</div>
                          </el-form-item>
                        </el-col>
                        <el-col style="width: 8%" class="table-col special">
                          <div v-if="csvItem.ideaName">
                            <el-form-item>
                              <el-select v-model="csvItem.unit" @change="unitCsvChange(csvItem.unit, csvItem.unitList, csvItemIndex)" size="small">
                                <el-option v-for="(item,i) in csvItem.unitList" :value="item.percent + '/unitlabel/' + item.tounit" :label="item.tounit" :key="i"/>
                              </el-select>
                            </el-form-item>
                          </div>
                          <div v-if="!csvItem.ideaName">
                            <el-form-item>
                              <el-select v-model="csvItem.unit" size="small">
                                <el-option value="" label="" />
                              </el-select>
                            </el-form-item>
                          </div>
                        </el-col>
                        <el-col style="width: 8%" class="table-col">
                          <div class="padding-top-14">{{ csvItem.importQuantity }}</div>
                        </el-col>
                        <el-col style="width: 8%" class="table-col">
                          <div class="padding-top-14">{{ csvItem.importUnit }}</div>
                        </el-col>
                        <el-col style="width: 8%" class="table-bg-white">
                          <el-form-item prop="interimProduct">
                            <el-input v-model.number="csvItem.interimProduct" :placeholder="$t('messages.intermediateProduct')" size="small" />
                          </el-form-item>
                        </el-col>
                        <el-col v-if="parentFlag === 2" style="width: 8%" class="table-bg-white">
                          <el-form-item 
                            :key="csvItemIndex"
                            :prop="'csvTableData.' + csvItemIndex + '.parentNode'"
                            :rules="[{ required: false, trigger: 'blur'}, { validator: checkCsvParentnode1, trigger: 'blur'}]">
                            <el-input v-model.number="csvItem.parentNode" :placeholder="$t('messages.parentItem')" size="small" @change="changeCsvTempArr(csvItem)" />
                          </el-form-item>
                        </el-col>
                        <el-col style="width: 8%" v-if="csvItem.processCode === 'A1' || csvItem.processCode === 'A2' || csvItem.processCode === 'A3' || csvItem.genProcess === 'A1' || csvItem.genProcess === 'A2' || csvItem.genProcess === 'A3'" class="table-bg-white">
                          <el-form-item 
                            :key="csvItemIndex"
                            :prop="'csvTableData.' + csvItemIndex + '.budomari'"
                            :rules="[{ required: false, trigger: 'blur'}, { validator: checkBudomari1, trigger: 'blur'}]">
                            <el-input v-model="csvItem.budomari" @input="value => csvItem.budomari=value.replace(/\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3').match(/^\d*(\.?\d*)/g)[0] || ''" :placeholder="$t('messages.yieldRate')" size="small">
                              <i slot="suffix" style="font-style:normal;margin-right:5px">%</i>
                            </el-input>
                          </el-form-item>
                        </el-col>
                        <el-col v-else style="border-bottom: 1px solid #EBEEF5;width: 8%;" class="table-bg-white">
                          <el-form-item 
                            :key="csvItemIndex"
                            :prop="'csvTableData.' + csvItemIndex + '.budomari'"
                            :rules="[{ required: false, trigger: 'blur'}, { validator: checkBudomari1, trigger: 'blur'}]">
                            <el-input v-model="csvItem.budomari" @input="value => csvItem.budomari=value.replace(/\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3').match(/^\d*(\.?\d*)/g)[0] || ''" :placeholder="$t('messages.yieldRate')" size="small" style="visibility: hidden;">
                              <i slot="suffix" style="font-style:normal;margin-right:5px">%</i>
                            </el-input>
                          </el-form-item>
                        </el-col>
                        <el-col class="table-col col-width" style="cursor: auto;width: 4%;">
                         <div class="padding-top-14"><i class="el-icon-delete" @click="rowToDelete(csvItemIndex)"/></div> 
                        </el-col>
                      </el-row>
                      </div>
                    </el-form>
                </div>

              </div>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" :disabled="saveCsvDisable" @click="toRuleDialog('csvItem')">{{$t('messages.register')}}</el-button>
              </span>
            </el-dialog>
          </div>
          <div v-if="(parentFlag !== 2 && !noTreeFromCsvOrApi)">
            <div v-for="(subProcess, subProcessIndex, processListIndex) in processList" :id="setId(subProcessIndex)" :key="subProcessIndex" class="process-item">
              <div :id="subProcessIndex" class="process-icon">
                <img src="@/assets/static/icon/icon24_delete.svg" class="delete" @click="deleteProcess(subProcessIndex)">
                <img v-if="subProcess && subProcess[0].icon" :src="getIconUrl(subProcess)">
                <div v-if="buttonFlag === 2" class="icon-font">{{ subProcess[0].processcode }}: {{ subProcess[0].processname }}</div>
                <div v-if="buttonFlag === 1" class="icon-font">{{ subProcess[0].processcode }}: {{$t('messages.' + subProcess[0].processcode)}}</div>
              </div>
              <div class="process-body">
                <div v-for="(subProcessItem, subProcessItemIndex) in subProcess" :key="subProcessItemIndex" class="process-input">
                  <el-form ref="subProcessItem" :model="subProcessItem" :rules="rules" class="subProcessForm" :validate-on-rule-change="false">
                    <div class="komoku-div">
                      <div v-if="subProcessItemIndex !== 0" class="blank-div" />
                      <div v-if="subProcessItemIndex === 0" class="item-title">
                        {{$t('messages.itemName')}}
                        <el-popover
                          v-if="processListIndex === 0"
                          placement="right-start"
                          class="suggestion-icon"
                          width="200"
                          trigger="hover"
                          :content="$t('messages.itemContent')"
                        >
                          <i slot="reference" class="el-icon-question" />
                        </el-popover>
                      </div>
                      <el-form-item prop="itemname">
                        <el-input v-model="subProcessItem.itemname" :placeholder="$t('messages.itemName')" size="small" class="komoku-input" :disabled="statusDisabled" />
                      </el-form-item>
                      <div :class="subProcessItem.itemname ? 'surmise-process-enable' : 'surmise-process-disable'" class="surmise-process" @click="surmiseIdeaName(subProcessItem.itemname, subProcessIndex, subProcessItemIndex)">{{$t('messages.EFAnalogy')}}</div>
                    </div>
                    <div class="genntani-div">
                      <div v-if="subProcessItemIndex !== 0" class="blank-div" />
                      <div v-if="subProcessItemIndex === 0 && subProcess[0].processcode !== 'Z'" class="item-title">
                        {{$t('messages.efName')}}
                        <el-popover
                          v-if="processListIndex === 0"
                          class="suggestion-icon"
                          placement="right-start"
                          width="200"
                          trigger="hover"
                          :content="$t('messages.efContent')"
                        >
                          <i slot="reference" class="el-icon-question" />
                        </el-popover>
                      </div>
                      <el-form-item v-if="subProcess[0].processcode !== 'Z'" prop="name">
                        <el-autocomplete
                          v-model="subProcessItem.displayname"
                          size="small"
                          class="genntani-input"
                          :fetch-suggestions="querySearch"
                          :placeholder="$t('messages.peefn')"
                          :trigger-on-focus="false"
                          :disabled="statusDisabled"
                          @select="((item) => {handleSelect(item, subProcessIndex, subProcessItemIndex)})"
                          @change="inputChange(subProcessItem.displayname, subProcessIndex, subProcessItemIndex)"
                          @focus="focusChange(subProcessIndex, subProcessItemIndex)"
                        >
                          <template v-slot="{ item }">
                            <span>{{ item.displayname }}</span>
                          </template>
                        </el-autocomplete>
                        <div v-if ="subProcessItem.isDeleted" style="line-height: 20px;"><span style="color: #F56C6C;font-size: 12px;">{{$t('messages.EFDelete')}}</span></div>
                        <div v-if ="!subProcessItem.isDeleted && subProcessItem.generationBoolean" style="line-height: 20px;"><span style="color: orange;font-size: 12px;">{{$t('messages.EFChanged')}}</span></div>
                        <div v-if ="!subProcessItem.isDeleted && !subProcessItem.generationBoolean && subProcessItem.unitDeleted" style="line-height: 20px;"><span style="color: #F56C6C;font-size: 12px;">{{$t('messages.subunitDelete')}}</span></div>
                        <div v-if ="!subProcessItem.isDeleted && !subProcessItem.generationBoolean && !subProcessItem.unitDeleted && subProcessItem.unitgenerationBoolean" style="line-height: 20px;"><span style="color: orange;font-size: 12px;">{{$t('messages.subunitChanged')}}</span></div>
                      </el-form-item>
                    </div>
                    <div class="katsudo-div">
                      <div :class="subProcessItemIndex === 0 ? 'katsudo-hasLabel-div' : 'katsudo-noLabel-div'">
                        <div v-if="subProcessItemIndex === 0 " class="item-title">
                          <span v-if="subProcess[0].processcode !== 'Z'">{{$t('messages.amount')}}</span>
                          <span v-if="subProcess[0].processcode === 'Z'">{{$t('messages.emission')}}</span>
                          <el-popover
                            v-if="processListIndex === 0"
                            placement="right-start"
                            class="suggestion-icon"
                            width="200"
                            trigger="hover"
                            :content="$t('messages.amountContent')"
                          >
                            <i slot="reference" class="el-icon-question" />
                          </el-popover>
                        </div>
                      </div>
                      <div v-if="subProcessItemIndex !== 0" class="blank-div" />
                      <el-form-item prop="quantity">
                        <el-input v-model="subProcessItem.quantity" :disabled="statusDisabled" size="small" :placeholder="subProcess[0].processcode === 'Z' ? $t('messages.emission') : $t('messages.amount')" class="katsudo-input" @change="test1(subProcessItem)" @input="value => subProcessItem.quantity=value.replace(/(\-?)\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3$4').match(/^\-?\d*(\.?\d*)/g)[0] || ''"/>
                      </el-form-item>
                      <div v-if="subProcess[0].processcode === 'A1'" :class="(subProcessItem.quantity && subProcessItem.displayname) ? 'surmise-process-enable' : 'surmise-process-disable'" class="surmise-process" @click="trafficCalculator(subProcessItem.itemname, subProcessItem.quantity, subProcessItem.unit, subProcessItem.unitList, subProcessItem.displayname, 0)">{{$t('messages.A2Calculate')}}</div>
                    </div>
                    <div class="tanni-div">
                      <div v-if="subProcessItemIndex !== 0" class="blank-div" />
                      <div v-if="subProcessItemIndex === 0" class="item-title">
                        {{$t('messages.unit')}}
                        <el-popover
                          v-if="processListIndex === 0"
                          placement="right-start"
                          class="suggestion-icon"
                          width="200"
                          trigger="hover"
                          :content="$t('messages.unitContent')"
                        >
                          <i slot="reference" class="el-icon-question" />
                        </el-popover>
                      </div>
                      <el-form-item prop="unit">
                        <el-select v-model="subProcessItem.unit" :placeholder="$t('messages.unit')" size="small"  @change="unitChange(subProcessItem.unit, subProcessItem.unitList, subProcessIndex, subProcessItemIndex)" :disabled="statusDisabled">
                          <el-option v-for="(item,i) in subProcessItem.unitList" :value="item.percent + '/unitlabel/' + item.tounit" :label="item.tounit" :key="i"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <!-- story32 start-->
                    <div class="katsudo-div">
                      <div v-if="subProcessItemIndex !== 0" class="blank-div" />
                      <div v-if="subProcessItemIndex === 0" class="item-title">
                        {{$t('messages.intermediateProduct')}}
                      </div>
                      <el-form-item prop="interimproduct">
                        <el-input v-model="subProcessItem.interimproduct" :disabled="statusDisabled" size="small" class="katsudo-input" maxlength="40" />
                      </el-form-item>
                    </div>
                    <!-- story32 end-->
                    <div class="rate-div">
                      <div v-if="subProcessItemIndex !== 0 && ((subProcess[0].processcode === 'A1' || subProcess[0].processcode === 'A2' || subProcess[0].processcode === 'A3') || (subProcess[0].genProcess === 'A1' || subProcess[0].genProcess === 'A2' || subProcess[0].genProcess === 'A3')) " class="blank-div" />
                      <div v-if="subProcessItemIndex === 0 && ((subProcess[0].processcode === 'A1' || subProcess[0].processcode === 'A2' || subProcess[0].processcode === 'A3') || (subProcess[0].genProcess === 'A1' || subProcess[0].genProcess === 'A2' || subProcess[0].genProcess === 'A3')) " class="item-title">
                        {{$t('messages.yieldRate')}}
                      </div>
                      <el-form-item prop="budomari">
                        <el-input v-if="subProcess[0].processcode === 'A1' || subProcess[0].processcode === 'A2' || subProcess[0].processcode === 'A3' || subProcess[0].genProcess === 'A1' || subProcess[0].genProcess === 'A2' || subProcess[0].genProcess === 'A3'" v-model="subProcessItem.budomari" :disabled="statusDisabled" size="small" class="katsudo-input" 
                          @change="test1(subProcessItem, subProcessItemIndex)" @input="value => subProcessItem.budomari=value.replace(/\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3').match(/^\d*(\.?\d*)/g)[0] || ''" maxlength="40">
                          <i slot="suffix" style="font-style:normal;margin-right:5px">%</i>
                        </el-input>
                      </el-form-item>
                    </div>
                    <div class="emission-div">
                      <div v-if="subProcessItemIndex !== 0" class="blank-div" style="width: 13rem;" />
                      <div v-if="subProcessItemIndex === 0" class="item-title" style="width: 12rem;">
                        {{$t('messages.emissionc')}}
                      </div>
                      <el-form-item prop="emissionc">
                        <span v-if="subProcessItem.coefficient && (!subProcessItem.isDeleted) && (!subProcessItem.unitDeleted) && subProcessItem.budomari && subProcessItem.budomari !== ''" style="background-color: #E7E6E6; display: block; padding-left: 1rem;">{{toScientificNum( subProcessItem.quantity * (subProcessItem.coefficient / (parseFloat(subProcessItem.budomari === '0' ? '' : subProcessItem.budomari) / 100))  * ((isNaN(Number(subProcessItem.transferUnitPercent)) ? 1 : Number(subProcessItem.transferUnitPercent))))[0]}}{{toScientificNum( subProcessItem.quantity * (subProcessItem.coefficient / (parseFloat(subProcessItem.budomari === '0' ? '' : subProcessItem.budomari) / 100))  * ((isNaN(Number(subProcessItem.transferUnitPercent)) ? 1 : Number(subProcessItem.transferUnitPercent))))[1]}} <sup> {{toScientificNum( subProcessItem.quantity * (subProcessItem.coefficient / (parseFloat(subProcessItem.budomari === '0' ? '' : subProcessItem.budomari) / 100))  * ((isNaN(Number(subProcessItem.transferUnitPercent)) ? 1 : Number(subProcessItem.transferUnitPercent))))[2]}}</sup></span>
                        <span v-else-if="subProcessItem.coefficient && (!subProcessItem.isDeleted) && (!subProcessItem.unitDeleted) && !subProcessItem.budomari" style="background-color: #E7E6E6; display: block; padding-left: 1rem;">{{toScientificNum( subProcessItem.quantity * (subProcessItem.coefficient)  * ((isNaN(Number(subProcessItem.transferUnitPercent)) ? 1 : Number(subProcessItem.transferUnitPercent))))[0]}}{{toScientificNum( subProcessItem.quantity * (subProcessItem.coefficient )  * ((isNaN(Number(subProcessItem.transferUnitPercent)) ? 1 : Number(subProcessItem.transferUnitPercent))))[1]}} <sup> {{toScientificNum( subProcessItem.quantity * (subProcessItem.coefficient)  * ((isNaN(Number(subProcessItem.transferUnitPercent)) ? 1 : Number(subProcessItem.transferUnitPercent))))[2]}}</sup></span>
                        <span v-else-if="!subProcessItem.coefficient || subProcessItem.isDeleted || subProcessItem.unitDeleted" style="background-color: #E7E6E6; display: block; padding-left: 1rem;">{{toScientificNum(subProcessItem.emissionc)[0]}}{{toScientificNum(subProcessItem.emissionc)[1]}} <sup> {{toScientificNum(subProcessItem.emissionc)[2]}}</sup></span>
                      </el-form-item>
                    </div>
                    <div class="delete-icon-div">
                      <i class="el-icon-delete" :style="{cursor: statusDisabled? 'not-allowed' : 'pointer'}" @click="deleteSubProcess(subProcessIndex, subProcessItemIndex)" />
                    </div>
                  </el-form>
                </div>
                <div class="item-button">
                  <div class="new-item-button" :style="{cursor: statusDisabled? 'not-allowed' : 'pointer'}" @click="addProcessSub(subProcessIndex, subProcess)">{{$t('messages.addNewItem')}}&nbsp;+</div>
                </div>
              </div>
            </div>
            <el-button v-if="parentFlag !== 2" :disabled="statusDisabled" type="primary" class="btn-color new-process-button" @click="addProcess(true)">{{$t('messages.addNewProcess')}} +</el-button>
          </div>
        </div>
        <!-- Tree -->
        <div id="treeForm" v-if="(parentFlag === 2 || noTreeFromCsvOrApi || copyFlag)">
              <!--TODO-->
              <el-form ref="treeDataForm" :model="treeDataForm" class="subProcessForm-parentmode">
                <div v-for="(treeDataItem, index) in treeDataForm.treeDataItem" id="treeData" :key="treeDataItem.key" style="width:100%;" class="process-input">
                  <div v-if="!treeDataItem.subassyFromId" class="process-body" style="width:100%; display: flex;">
                    <div class="komoku-div-parentmode" :style="{width: noTreeFromCsvOrApi ? '15%' : '12%'}">
                      <div class="item-title">
                        {{$t('messages.managedItem')}}
                        <el-popover
                              v-if="index === 0"
                              placement="right-start"
                              class="suggestion-icon"
                              width="200"
                              trigger="hover"
                              :content="$t('messages.itemContent')">
                          <i slot="reference" class="el-icon-question" />
                        </el-popover>
                      </div>
                      <el-form-item 
                            :key="index"
                            :prop="`treeDataItem.${index}.itemname`"
                            :rules="newRules.itemname">
                        <el-input v-model="treeDataItem.itemname" :placeholder="$t('messages.itemName')" size="small" class="komoku-input" @blur="itemNameFocus(treeDataItem.itemname, index)" :disabled="statusDisabled" />
                      </el-form-item>
                      <div :class="treeDataItem.itemname !== '' ? 'surmise-process-enable' : 'surmise-process-disable'" class="surmise-process" @click="surmiseTreeIdeaName(treeDataItem.itemname, index)">{{$t('messages.EFAnalogy')}}</div>
                    </div>
                    <div class="komoku-div-parentmode" :style="{width: noTreeFromCsvOrApi ? '15%' : '12%'}">
                      <div class="item-title">
                        {{$t('messages.process')}}
                        <el-popover
                              v-if="index === 0"
                              placement="right-start"
                              class="suggestion-icon"
                              trigger="hover"
                              width="200"
                              :content="$t('messages.processContent')">
                          <i slot="reference" class="el-icon-question" />
                        </el-popover>
                      </div>
                      <el-form-item
                        :key="index"
                        :prop="`treeDataItem.${index}.processcode`"
                        :rules="newRules.processcode"
                      >
                        <el-select v-model="treeDataItem.processcode" size="small" class="select-item" filterable :disabled="statusDisabled" @change="addtreeDataGenprocess(treeDataItem, index)">
                          <el-option
                                v-for="(item, i) in processnameList"
                                :key="item.processCode + i"
                                :label="language === 'en' && buttonFlag == 1 && parentFlag == 2 ? $t('messages.' + item.processCode)+'('+item.processCode+')' : item.processName+'('+item.processCode+')'"
                                :value="item.processCode" />
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="genntani-div-parentmode" :style="{width: noTreeFromCsvOrApi ? '15%' : '12%'}">
                      <div class="item-title">
                        {{$t('messages.efName')}}
                        <el-popover
                              v-if="index === 0"
                              class="suggestion-icon"
                              placement="right-start"
                              width="200"
                              trigger="hover"
                              :content="$t('messages.efContent')">
                          <i slot="reference" class="el-icon-question" />
                        </el-popover>
                      </div>
                      <el-form-item v-if="treeDataItem.processcode !== 'Z'"
                        :key="index"
                        :prop="`treeDataItem.${index}.displayname`"
                        :rules="newRules.displayname"
                      >
                        <el-autocomplete
                                v-model="treeDataItem.displayname"
                                size="small"
                                class="genntani-input"
                                :fetch-suggestions="querySearch"
                                :placeholder="$t('messages.peefn')"
                                :trigger-on-focus="false"
                                :disabled="statusDisabled"
                                @select="((item) => {newhandleSelect(item, index)})"
                                @change="newInputChange(treeDataItem.displayname, index)"
                                @focus="newFocusChange(index)">
                          <template v-slot="{ item }">
                            <span>{{ item.displayname }}</span>
                          </template>
                        </el-autocomplete>
                        <div v-if="treeDataItem.isDeleted" style="line-height: 20px;">
                          <span style="color: #F56C6C;font-size: 12px;">{{$t('messages.EFDelete')}}</span>
                        </div>
                        <div v-if="!treeDataItem.isDeleted && treeDataItem.generationBoolean" style="line-height: 20px;">
                          <span style="color: orange;font-size: 12px;">{{$t('messages.EFChanged')}}</span>
                        </div>
                        <div v-if="!treeDataItem.isDeleted && !treeDataItem.generationBoolean && treeDataItem.unitDeleted" style="line-height: 20px;">
                          <span style="color: #F56C6C;font-size: 12px;">{{$t('messages.subunitDelete')}}</span>
                        </div>
                        <div v-if="!treeDataItem.isDeleted && !treeDataItem.generationBoolean && !treeDataItem.unitDeleted && treeDataItem.unitgenerationBoolean" style="line-height: 20px;">
                          <span style="color: orange;font-size: 12px;">{{$t('messages.subunitChanged')}}</span>
                        </div>
                      </el-form-item>
                    </div>
                    <div class="katsudo-div-parentmode">
                      <div class="katsudo-hasLabel-div">
                        <div class="item-title">
                          <span v-if="treeDataItem.processcode !== 'Z'">{{$t('messages.amount')}}</span>
                          <span v-if="treeDataItem.processcode === 'Z'">{{$t('messages.emission')}}</span>
                          <el-popover
                                v-if="index === 0"
                                placement="right-start"
                                class="suggestion-icon"
                                width="200"
                                trigger="hover"
                                :content="$t('messages.amountContent')">
                            <i slot="reference" class="el-icon-question" />
                          </el-popover>
                        </div>
                      </div>
                      <el-form-item
                        :key="index"
                        :prop="`treeDataItem.${index}.quantity`"
                        :rules="newRules.quantity"
                      >
                        <el-input @blur="test2(treeDataItem)" v-model.lazy="treeDataItem.quantity" :disabled="statusDisabled" size="small" :placeholder="treeDataItem.processcode === 'Z' ? $t('messages.emission') : $t('messages.amount')" class="katsudo-input" title="" @input="value => treeDataItem.quantity=value.replace(/(\-?)\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3$4').match(/^\-?\d*(\.?\d*)/g)[0] || ''" />
                      </el-form-item>
                      <div v-if="treeDataItem.processcode === 'A1'" :class="(treeDataItem.quantity && treeDataItem.displayname) ? 'surmise-process-enable' : 'surmise-process-disable'" class="surmise-process" @click="trafficCalculator(treeDataItem.itemname, treeDataItem.quantity, treeDataItem.unit, treeDataItem.unitList, treeDataItem.displayname, index)">{{$t('messages.A2Calculate')}}</div>
                    </div>
                    <div class="tanni-div-parentmode">
                      <div class="item-title">
                        {{$t('messages.unit')}}
                        <el-popover
                              v-if="index === 0"
                              placement="right-start"
                              class="suggestion-icon"
                              width="200"
                              trigger="hover"
                              :content="$t('messages.unitContent')">
                          <i slot="reference" class="el-icon-question" />
                        </el-popover>
                      </div>
                      <el-form-item prop="unit">
                        <el-select v-model="treeDataItem.unit" :placeholder="$t('messages.unit')" size="small" @change="unittreeChange(treeDataItem.unit, treeDataItem.unitList, index)" :disabled="statusDisabled">
                          <el-option v-for="(item,i) in treeDataItem.unitList" :value="item.percent + '/unitlabel/' + item.tounit" :label="item.tounit" :key="i"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <!-- story32 start-->
                    <div class="katsudo-div-parentmode">
                      <div class="item-title">
                        {{$t('messages.intermediateProduct')}}
                      </div>
                      <el-form-item
                        :key="index"
                        :prop="`treeDataItem.${index}.interimproduct`"
                        :rules="newRules.interimproduct"
                      >
                        <el-input v-model="treeDataItem.interimproduct" :disabled="statusDisabled" size="small" class="katsudo-input" maxlength="40" />
                      </el-form-item>
                    </div>
                    <!-- story32 end-->
                    <div v-if="!noTreeFromCsvOrApi" class="katsudo-div-parentmode">
                      <div class="item-title">
                        {{$t('messages.parentItem')}}
                      </div>
                      <el-form-item
                        :key="index"
                        :prop="`treeDataItem.${index}.parentnode`"
                        :rules="newRules.parentnode"
                      >
                        <el-input v-model="treeDataItem.parentnode" :disabled="statusDisabled || !treeDataItem.itemname" size="small" class="katsudo-input" maxlength="99" @blur="changeTempArr(treeDataItem)" />
                      </el-form-item>
                    </div>
                    <div class="rate-div-parentmode">
                      <div class="item-title">
                        {{$t('messages.yieldRate')}}
                      </div>
                      <el-form-item
                        :key="index"
                        :prop="`treeDataItem.${index}.budomari`"
                        :rules="newRules.budomari"
                      >
                        <el-input @blur="test2(treeDataItem)" v-model="treeDataItem.budomari" @input="value => treeDataItem.budomari=value.replace(/\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3').match(/^\d*(\.?\d*)/g)[0] || ''" :disabled="statusDisabled || !(treeDataItem.processcode === 'A1' || treeDataItem.processcode === 'A2' || treeDataItem.processcode === 'A3' || treeDataItem.genProcess === 'A1' || treeDataItem.genProcess === 'A2' || treeDataItem.genProcess === 'A3')" size="small" class="katsudo-input" maxlength="40">
                          <i slot="suffix" style="font-style:normal;margin-right:5px">%</i>
                        </el-input>
                      </el-form-item>
                    </div>
                    <!-- story 34 start -->
                    <div class="emission-div-parentmode">
                      <div class="item-title">
                        {{$t('messages.emissionc')}}
                      </div>
                      <el-form-item prop="emissionc">
                        <span v-if="treeDataItem.coefficient && treeDataItem.budomari && treeDataItem.budomari != '' && (!treeDataItem.isDeleted) &&(!treeDataItem.unitDeleted)" style="background-color: #E7E6E6; display: block; padding-left: 1rem;">{{toScientificNum(treeDataItem.quantity * (treeDataItem.coefficient / (parseFloat(treeDataItem.budomari === '0' ? '' : treeDataItem.budomari) / 100))  * (isNaN(Number(treeDataItem.transferUnitPercent)) ? 1 : Number(treeDataItem.transferUnitPercent)))[0]}}{{toScientificNum(treeDataItem.quantity * (treeDataItem.coefficient / (parseFloat(treeDataItem.budomari === '0' ? '' : treeDataItem.budomari) / 100))  * (isNaN(Number(treeDataItem.transferUnitPercent)) ? 1 : Number(treeDataItem.transferUnitPercent)))[1]}}
                          <sup>{{toScientificNum(treeDataItem.quantity * (treeDataItem.coefficient / (parseFloat(treeDataItem.budomari === '0' ? '' : treeDataItem.budomari) / 100))  * (isNaN(Number(treeDataItem.transferUnitPercent)) ? 1 : Number(treeDataItem.transferUnitPercent)))[2]}}</sup>
                        </span>
                        <span v-else-if="treeDataItem.coefficient && (!treeDataItem.isDeleted) &&(!treeDataItem.unitDeleted) && (!treeDataItem.budomari)" style="background-color: #E7E6E6; display: block; padding-left: 1rem;">{{toScientificNum(treeDataItem.quantity * (treeDataItem.coefficient)  * (isNaN(Number(treeDataItem.transferUnitPercent)) ? 1 : Number(treeDataItem.transferUnitPercent)))[0]}}{{toScientificNum(treeDataItem.quantity * (treeDataItem.coefficient)  * (isNaN(Number(treeDataItem.transferUnitPercent)) ? 1 : Number(treeDataItem.transferUnitPercent)))[1]}}
                          <sup>{{toScientificNum(treeDataItem.quantity * (treeDataItem.coefficient)  * (isNaN(Number(treeDataItem.transferUnitPercent)) ? 1 : Number(treeDataItem.transferUnitPercent)))[2]}}</sup>
                        </span>
                        <span v-else-if="!treeDataItem.coefficient || treeDataItem.isDeleted || treeDataItem.unitDeleted" style="background-color: #E7E6E6; display: block; padding-left: 1rem;">{{toScientificNum(treeDataItem.emissionc)[0]}}{{toScientificNum(treeDataItem.emissionc)[1]}}
                          <sup>{{toScientificNum(treeDataItem.emissionc)[2]}}</sup>
                        </span>
                      </el-form-item>
                    </div>
                    <!-- story 34 end -->
                    <div class="delete-icon-div-parentmode">
                      <i class="el-icon-delete" style="margin-top: 3.5rem" :style="{cursor: statusDisabled? 'not-allowed' : 'pointer'}" @click="deleteTreeProcess(false, index)" />
                    </div>
                  </div>
                  <div v-if="treeDataItem.subassyFromId && !copyFlag" style="width: 100%">
                    <div v-for="(callDataItem, callIndex) in callDataList" :key="callIndex" class="process-input" style="width:100%;">
                      <div v-if="treeDataItem.topSubassyId === callDataItem.topSubassyId" class="process-body" style="width:100%; display: flex; background-color: #EBF9F5;">
                        <div class="katsudo-div-getdatamode">
                          <div class="item-title">
                            <el-link @click="callBackTreePop(callDataItem)" style="color:#409EFF">{{$t('messages.getData')}}</el-link>
                          </div>
                        </div>
                        <div class="katsudo-div-getdatamode">
                          <div class="item-title">{{$t('messages.firstManagedProgram')}}</div>
                          <el-form-item prop="topItemName">
                            <span> {{ callDataItem.topItemName }}</span>
                          </el-form-item>
                        </div>
                        <div class="katsudo-div-getdatamode">
                          <div class="item-title">{{$t('messages.managedProgramCount')}}</div>
                          <el-form-item prop="callNum">
                            <span> {{ callDataItem.callNum }}</span>
                          </el-form-item>
                        </div>
                        <div class="katsudo-div-getdatamode">
                          <div class="item-title">{{$t('messages.updateUser')}}</div>
                          <el-form-item prop="callUpdaterName">
                            <span> {{ callDataItem.callUpdaterName }}</span>
                          </el-form-item>
                        </div>
                        <div class="katsudo-div-getdatamode">
                          <div class="item-title">{{$t('messages.updateDate')}}</div>
                          <el-form-item prop="callUpddate">
                            <span>{{ formatDate(callDataItem.callUpddate) }} </span>
                          </el-form-item>
                        </div>
                        <div class="katsudo-div-getdatamode">
                          <div class="item-title">
                            {{$t('messages.parentItem')}}
                          </div>
                          <el-form-item prop="callParentNode">
                            <span> {{ callDataItem.callParentNode }}</span>
                          </el-form-item>
                        </div>
                        <div class="katsudo-div-getdatamode">
                          <div class="item-title">
                            {{$t('messages.intermediateProduct')}}
                          </div>
                          <el-form-item prop="callName">
                            <span> {{ callDataItem.seihinCallName + ' ' + callDataItem.callVersion}}</span>
                          </el-form-item>
                        </div>
                        <div class="katsudo-div-getdatamode">
                          <div class="item-title">
                            {{$t('messages.emissionc')}}
                          </div>
                          <el-form-item prop="callEmisSum">
                            <span style="display: block; padding-left: 1rem;">{{toScientificNum(callDataItem.callEmisSum)[0]}}{{toScientificNum(callDataItem.callEmisSum)[1]}}
                              <sup>{{toScientificNum(callDataItem.callEmisSum)[2]}}</sup>
                            </span>
                          </el-form-item>
                        </div>
                        <div class="delete-icon-div-parentmode">
                          <i class="el-icon-delete" style="margin-top: 3.5rem" :style="{cursor: statusDisabled? 'not-allowed' : 'pointer'}" @click="deleteTreeProcess(true, callDataItem.topSubassyId, callIndex)" />
                        </div>
                      </div>
                      <div v-if="treeDataItem.topSubassyId === callDataItem.topSubassyId" class="item-button-parentmode">
                        <div class="new-item-button" @click="callNewAddClick(callDataItem, index)">{{$t('messages.addNewItem')}}&nbsp;+</div>
                        <div v-if="parentFlag === 2" :class="treeDataItem.itemname ? 'data-call-enable' : 'surmise-process-disable'" class="new-item-button" @click="subDataCallClick(callDataItem, index, callIndex)">{{$t('messages.callParts')}}&nbsp;+</div>
                        <div v-if="parentFlag === 2" class="new-item-button" @click="callDataCopyClick(callDataItem, index, callIndex)">{{$t('messages.copyLine')}}&nbsp;+</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="treeDataItem.subassyFromId && copyFlag" style="width: 100%; display: flex; border-left: 5px solid #BFEBFA;">
                    <div class="komoku-div-parentmode" :style="{width: noTreeFromCsvOrApi ? '15%' : '12%'}">
                    <div class="item-title">
                      {{$t('messages.managedItem')}}
                      <el-popover
                          v-if="index === 0"
                          placement="right-start"
                          class="suggestion-icon"
                          width="200"
                          trigger="hover"
                          :content="$t('messages.itemContent')"
                        >
                          <i slot="reference" class="el-icon-question" />
                        </el-popover>
                      </div>
                      <el-form-item 
                      :key="index"
                      :prop="`treeDataItem.${index}.itemname`"
                      :rules="newRules.itemname">
                        <el-input v-model="treeDataItem.itemname" :placeholder="$t('messages.itemName')" size="small" class="komoku-input" @blur="itemNameFocus(treeDataItem.itemname, index)" :disabled="statusDisabled" />
                      </el-form-item>
                      <div :class="treeDataItem.itemname !== '' ? 'surmise-process-enable' : 'surmise-process-disable'" class="surmise-process" @click="surmiseTreeIdeaName(treeDataItem.itemname, index)">{{$t('messages.EFAnalogy')}}</div>
                    </div>
                    <div class="komoku-div-parentmode" :style="{width: noTreeFromCsvOrApi ? '15%' : '12%'}">
                      <div class="item-title">
                        {{$t('messages.process')}}
                        <el-popover
                          v-if="index === 0"
                          placement="right-start"
                          class="suggestion-icon"
                          trigger="hover"
                          width="200"
                          :content="$t('messages.processContent')"
                      >
                        <i slot="reference" class="el-icon-question" />
                      </el-popover>
                      </div>
                      <el-form-item 
                      :key="index"
                      :prop="`treeDataItem.${index}.processcode`"
                      :rules="newRules.processcode">
                        <el-select v-model="treeDataItem.processcode" size="small" class="select-item" filterable :disabled="statusDisabled" @change="addtreeDataGenprocess(treeDataItem, index)">
                          <el-option
                            v-for="(item, i) in processnameList"
                            :key="item.processCode + i"
                            :label="language === 'en' && buttonFlag == 1 && parentFlag == 2 ? $t('messages.' + item.processCode)+'('+item.processCode+')' : item.processName+'('+item.processCode+')'"
                            :value="item.processCode"
                          />
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="genntani-div-parentmode" :style="{width: noTreeFromCsvOrApi ? '15%' : '12%'}">
                      <div class="item-title">
                        {{$t('messages.efName')}}
                        <el-popover
                          v-if="index === 0"
                          class="suggestion-icon"
                          placement="right-start"
                          width="200"
                          trigger="hover"
                          :content="$t('messages.efContent')"
                        >
                          <i slot="reference" class="el-icon-question" />
                        </el-popover>
                      </div>
                      <el-form-item v-if="treeDataItem.processcode !== 'Z'" 
                        :key="index"
                        :prop="`treeDataItem.${index}.displayname`"
                        :rules="newRules.displayname">
                        <el-autocomplete
                          v-model="treeDataItem.displayname"
                          size="small"
                          class="genntani-input"
                          :fetch-suggestions="querySearch"
                          :placeholder="$t('messages.peefn')"
                          :trigger-on-focus="false"
                          :disabled="statusDisabled"
                          @select="((item) => {newhandleSelect(item, index)})"
                          @change="newInputChange(treeDataItem.displayname, index)"
                          @focus="newFocusChange(index)"
                        >
                          <template v-slot="{ item }">
                            <span>{{ item.displayname }}</span>
                          </template>
                        </el-autocomplete>
                        <div v-if ="treeDataItem.isDeleted" style="line-height: 20px;"><span style="color: #F56C6C;font-size: 12px;">{{$t('messages.EFDelete')}}</span></div>
                        <div v-if ="!treeDataItem.isDeleted && treeDataItem.generationBoolean" style="line-height: 20px;"><span style="color: orange;font-size: 12px;">{{$t('messages.EFChanged')}}</span></div>
                        <div v-if ="!treeDataItem.isDeleted && !treeDataItem.generationBoolean && treeDataItem.unitDeleted" style="line-height: 20px;"><span style="color: #F56C6C;font-size: 12px;">{{$t('messages.subunitDelete')}}</span></div>
                        <div v-if ="!treeDataItem.isDeleted && !treeDataItem.generationBoolean && !treeDataItem.unitDeleted && treeDataItem.unitgenerationBoolean" style="line-height: 20px;"><span style="color: orange;font-size: 12px;">{{$t('messages.subunitChanged')}}</span></div>
                      </el-form-item>
                    </div>
                    <div class="katsudo-div-parentmode">
                      <div class="katsudo-hasLabel-div">
                        <div class="item-title">
                          <span v-if="treeDataItem.processcode !== 'Z'">{{$t('messages.amount')}}</span>
                          <span v-if="treeDataItem.processcode === 'Z'">{{$t('messages.emission')}}</span>
                          <el-popover
                            v-if="index === 0"
                            placement="right-start"
                            class="suggestion-icon"
                            width="200"
                            trigger="hover"
                            :content="$t('messages.amountContent')"
                          >
                            <i slot="reference" class="el-icon-question" />
                          </el-popover>
                        </div>
                      </div>
                      <el-form-item 
                        :key="index"
                        :prop="`treeDataItem.${index}.quantity`"
                        :rules="newRules.quantity">
                        <el-input @change="test2(treeDataItem)" v-model="treeDataItem.quantity" :disabled="statusDisabled" size="small" :placeholder="treeDataItem.processcode === 'Z' ? $t('messages.emission') : $t('messages.amount')" class="katsudo-input" title="" type="number" />
                      </el-form-item>
                    </div>
                    <div class="tanni-div-parentmode">
                      <div class="item-title">
                        {{$t('messages.unit')}}
                        <el-popover
                          v-if="index === 0"
                          placement="right-start"
                          class="suggestion-icon"
                          width="200"
                          trigger="hover"
                          :content="$t('messages.unitContent')"
                        >
                          <i slot="reference" class="el-icon-question" />
                        </el-popover>
                      </div>
                      <el-form-item prop="unit">
                        <el-select v-model="treeDataItem.unit" :placeholder="$t('messages.unit')" size="small" @change="unittreeChange(treeDataItem.unit, treeDataItem.unitList, index)" :disabled="statusDisabled">
                          <el-option v-for="(item,i) in treeDataItem.unitList" :value="item.percent + '/unitlabel/' + item.tounit" :label="item.tounit" :key="i"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="katsudo-div-parentmode">
                      <div class="item-title">
                        {{$t('messages.intermediateProduct')}}
                      </div>
                      <el-form-item 
                        :key="index"
                        :prop="`treeDataItem.${index}.interimproduct`"
                        :rules="newRules.interimproduct">
                        <el-input v-model="treeDataItem.interimproduct" :disabled="statusDisabled" size="small" class="katsudo-input" maxlength="40" />
                      </el-form-item>
                    </div>
                    <div v-if="!noTreeFromCsvOrApi" class="katsudo-div-parentmode">
                      <div class="item-title">
                        {{$t('messages.parentItem')}}
                      </div>
                      <el-form-item
                        :key="index"
                        :prop="`treeDataItem.${index}.parentnode`"
                        :rules="newRules.parentnode">
                        <el-input v-model="treeDataItem.parentnode" :disabled="statusDisabled || !treeDataItem.itemname" size="small" class="katsudo-input" maxlength="99" @change="changeCallTempArr(treeDataItem)" @focus="focusTempArr(treeDataItem)" />
                        <div v-if ="treeDataItem.callParentShow" style="line-height: 20px;"><span style="color: orange;font-size: 12px;">親管理項目が正しいことを確認してください</span></div>                                          
                      </el-form-item>
                    </div>                    
                    <div class="rate-div-parentmode">
                      <div class="item-title">
                        {{$t('messages.yieldRate')}}
                      </div>
                      <el-form-item 
                        :key="index"
                        :prop="`treeDataItem.${index}.budomari`"
                        :rules="newRules.budomari">
                        <el-input @change="test2(treeDataItem)"  v-model="treeDataItem.budomari" :disabled="statusDisabled || !(treeDataItem.processcode === 'A1' || treeDataItem.processcode === 'A2' || treeDataItem.processcode === 'A3' || treeDataItem.genProcess === 'A1' || treeDataItem.genProcess === 'A2' || treeDataItem.genProcess === 'A3')" size="small" class="katsudo-input" maxlength="40">
                          <i slot="suffix" style="font-style:normal;margin-right:5px">%</i>
                        </el-input>
                      </el-form-item>
                    </div>
                    <div class="emission-div-parentmode">
                      <div class="item-title">
                        {{$t('messages.emissionc')}}
                      </div>
                      <el-form-item prop="emissionc">
                        <span v-if="treeDataItem.coefficient && treeDataItem.budomari && treeDataItem.budomari != '' && (!treeDataItem.isDeleted) &&(!treeDataItem.unitDeleted)" style="background-color: #E7E6E6; display: block; padding-left: 1rem;">{{toScientificNum(treeDataItem.quantity * (treeDataItem.coefficient / (parseFloat(treeDataItem.budomari === '0' ? '' : treeDataItem.budomari) / 100))  * (isNaN(Number(treeDataItem.transferUnitPercent)) ? 1 : Number(treeDataItem.transferUnitPercent)))[0]}}{{toScientificNum(treeDataItem.quantity * (treeDataItem.coefficient / (parseFloat(treeDataItem.budomari === '0' ? '' : treeDataItem.budomari) / 100))  * (isNaN(Number(treeDataItem.transferUnitPercent)) ? 1 : Number(treeDataItem.transferUnitPercent)))[1]}}<sup>{{toScientificNum(treeDataItem.quantity * (treeDataItem.coefficient / (parseFloat(treeDataItem.budomari === '0' ? '' : treeDataItem.budomari) / 100))  * (isNaN(Number(treeDataItem.transferUnitPercent)) ? 1 : Number(treeDataItem.transferUnitPercent)))[2]}}</sup></span>
                        <span v-else-if="treeDataItem.coefficient && (!treeDataItem.isDeleted) &&(!treeDataItem.unitDeleted) && (!treeDataItem.budomari)" style="background-color: #E7E6E6; display: block; padding-left: 1rem;">{{toScientificNum(treeDataItem.quantity * (treeDataItem.coefficient)  * (isNaN(Number(treeDataItem.transferUnitPercent)) ? 1 : Number(treeDataItem.transferUnitPercent)))[0]}}{{toScientificNum(treeDataItem.quantity * (treeDataItem.coefficient)  * (isNaN(Number(treeDataItem.transferUnitPercent)) ? 1 : Number(treeDataItem.transferUnitPercent)))[1]}}<sup>{{toScientificNum(treeDataItem.quantity * (treeDataItem.coefficient)  * (isNaN(Number(treeDataItem.transferUnitPercent)) ? 1 : Number(treeDataItem.transferUnitPercent)))[2]}}</sup></span>
                        <span v-else-if="!treeDataItem.coefficient || treeDataItem.isDeleted || treeDataItem.unitDeleted" style="background-color: #E7E6E6; display: block; padding-left: 1rem;">{{toScientificNum(treeDataItem.emissionc)[0]}}{{toScientificNum(treeDataItem.emissionc)[1]}}<sup>{{toScientificNum(treeDataItem.emissionc)[2]}}</sup></span>
                      </el-form-item>
                    </div>
                    <div class="delete-icon-div-parentmode">
                      <i class="el-icon-delete" style="margin-top: 3.5rem" :style="{cursor: statusDisabled? 'not-allowed' : 'pointer'}" @click="deleteTreeProcess(false, index)" />
                    </div>
                  </div>
                  <div v-if="!treeDataItem.subassyFromId" class="item-button-parentmode">
                    <div class="new-item-button" @click="newAddClick(treeDataItem, index)">{{$t('messages.addNewItem')}}&nbsp;+</div>
                    <div v-if="parentFlag === 2" :class="treeDataItem.itemname ? 'data-call-enable' : 'surmise-process-disable'" class="new-item-button" @click="dataCallClick(treeDataItem.itemname, index)">{{$t('messages.callParts')}}&nbsp;+</div>
                    <div v-if="parentFlag === 2" class="new-item-button" @click="newCopyClick(treeDataItem, index)">{{$t('messages.copyLine')}}&nbsp;+</div>
                  </div>
                  <div v-if="treeDataItem.subassyFromId && copyFlag" class="item-button-parentmode">
                    <div class="new-item-button" @click="newAddClick(treeDataItem, index)">{{$t('messages.addNewItem')}}&nbsp;+</div>
                    <div v-if="callShow(treeDataItem.subassyFromId, index)" :class="treeDataItem.itemname ? 'data-call-enable' : 'surmise-process-disable'" class="new-item-button" @click="dataCallClick(treeDataItem.itemname, index)">{{$t('messages.callParts')}}&nbsp;+</div>
                    <div class="new-item-button" @click="newCopyClick(treeDataItem, index)">{{$t('messages.copyLine')}}&nbsp;+</div>
                  </div>
                </div>
              </el-form>
        </div>
        <div class="bottom-button-box padding-top-24 bom-import">
          <el-button class="btn-plain" :disabled="statusDisabled" @click="toBack">{{$t('messages.back')}}</el-button>
          <el-button class="btn-color" v-if="(parentFlag !== 2 && !noTreeFromCsvOrApi)" type="primary" :disabled="statusDisabled" @click="saveTemp">{{$t('messages.saveTemp')}}</el-button>
          <el-button class="btn-color" v-if="(parentFlag === 2 || noTreeFromCsvOrApi)" type="primary" :disabled="statusDisabled" @click="saveTreeTemp">{{$t('messages.saveTemp')}}</el-button>
          <el-button class="btn-color" v-if="(parentFlag !== 2 && !noTreeFromCsvOrApi)" type="primary" :disabled="statusDisabled" @click="doSave('subProcessItem', 'seihinNameObj')">{{$t('messages.calculate')}}</el-button>
          <el-button class="btn-color" v-if="(parentFlag === 2 || noTreeFromCsvOrApi)" type="primary" :disabled="statusDisabled"  @click="doSaveTree">{{$t('messages.calculate')}}</el-button>
        </div>
        <!-- Tree -->
        <el-dialog
          title=""
          :visible.sync="saveTempDialogVisible"
          width="30%"
          center
        >
          <p class="text-line" v-if="language === 'jp'">指定された製品・バージョンは既に一時保存状態で登録済みのデータがあります。<br>
            製品一覧画面上の一時保存状態のデータを編集するプルダウンから選択し、排出量データの登録をしてください。
          </p>
          <p class="text-line" v-if="language === 'en'">The entered product/version already has data registered in the finalized saved state.<br>
            Please open the edit screen from the reference screen of the product and edit the emissions data.
          </p>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveTempDialogVisible = false">OK</el-button>
          </span>
        </el-dialog>
        <el-dialog
          title=""
          :visible.sync="saveFinalDialogVisible"
          width="30%"
          center
        >
          <p class="text-line">{{$t('messages.tepvahdritfss')}}<br>
            {{$t('messages.potesftrsofpaeted')}}
          </p>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveFinalDialogVisible = false">OK</el-button>
          </span>
        </el-dialog>
        <el-dialog
          title=""
          :visible.sync="deleteFinalDialogVisible"
          width="30%"
          center
        >
          <p class="text-line">同一製品・バージョンの削除データがあります。<br>
            同一製品の排出量を登録するには、別のバージョンを指定してください。
          </p>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="deleteFinalDialogVisible = false">OK</el-button>
          </span>
        </el-dialog>
        <el-dialog
          :title="$t('messages.calQualityList')"
          :visible.sync="centerDialogVisible"
          :lock-scroll="false"
          :append-to-body="true"
          @open="handleOpen()"
          width="70%"
          center
        >
          <div class="popup-height">
            <div class="check-note">{{$t('messages.check')}}</div>
            <div v-for="(item, index) in checkList" :key="index" class="popup-area">
              <div class="check-area"><el-checkbox v-model="item.checkflag"/></div>
              <div class="check-content">
                <el-collapse>
                  <div class="check-list-title check-break-line">{{ item.checkitem.replaceAll('\\n', '\n') }}</div>
                  <el-collapse-item name="1">
                    <div class="font-color check-break-line">{{ item.checkitemComment.replaceAll('\\n', '\n') }}</div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button class="btn-plain" @click="centerDialogVisible = false">{{$t('messages.back')}}</el-button>
            <el-button class="btn-color" type="primary" @click="closeDialog">{{$t('messages.done')}}</el-button>
          </span>
        </el-dialog>
        <el-dialog
          width="85%"
          :title="$t('messages.EFAnalogy')"
          :visible.sync="surmiseShow"
          center
          append-to-body
        >
          <span class="text-line1" v-if="unregisteredEFShow">過去にこの管理項目名で登録された原単位はありません</span>
          <el-table :data="surmiseIdeaData" style="width: 100%" class="border-1 margin-top-20" height="261">
            <el-table-column prop="name" :label="$t('messages.eF')">
              <template slot-scope="scope">
                <span class="colum-width surmise-idea-name" @click="selectSurmiseIdea(scope.row)">{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="seihinnameVersion" :label="$t('messages.abProductVersion')">
              <template slot-scope="scope">
                <span class="colum-width">{{ scope.row.seihinname }} {{ scope.row.version }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="updatername" :label="$t('messages.lastUpdatedBy1')" width="260">
              <template slot-scope="scope">
                <span class="colum-width">{{ scope.row.updatername }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="upddate" :label="$t('messages.lastUpdate')" width="130">
              <template slot-scope="scope">
                <span class="colum-width">{{ formatDate(scope.row.upddate) }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-dialog>
        <el-dialog
          width="30%"
          :visible.sync="isShowNoTrafficPopup"
          center
          append-to-body
        >
          <span class="text-line2">{{$t('messages.EFUnconvertible')}}</span>
          <span slot="footer" class="dialog-footer">
            <el-button class="btn-plain" @click="isShowNoTrafficPopup = false">{{$t('messages.backName')}}</el-button>
          </span>
        </el-dialog>
        <trafficPopup
          v-if="isShowTrafficPopup"
          :button-name="buttonName"
          :language="language"
          :backName="backName"
          :transportMeans="transportMeans"
          :transportDistance="transportDistance"
          :name-array="nameArray"
          :tkm-name-list="tkmNameList"
          :traffic-item-weight="trafficItemWeight"
          :traffic-unit-list="trafficUnitList"
          :traffic-unit="trafficUnit"
          @childFn="parentFn4TrafficCalculator"
        />
        <utilityPopup
          v-if="isShowUtilityPopup"
          :button-name="buttonName"
          @childFn="parentFn4UtilityCalculator"
        />
        <popup
          v-if="isShowPopup"
          :is-show-popup="isShowPopup"
          :processlist4select="processList4select"
          @showPopupEvent="addProcess"
          @getAddProcessDataEvent="getAddProcessFromPopup"
        />
        <confirmPopup
          v-if="isShowComfirmPopup"
          :contentmessage="popupMessage"
          :button-name="buttonName"
          :cancelName="cancelName"
          @childFn="parentFn"
        />
        <alertPopup
          v-if="isShowAlertPopup"
          :contentmessage="popupMessage"
          :button-name="buttonName"
          @childFn="parentFn4Alert"
        />
        <callPopup
          v-show="isShowCallPopup"
          ref="callBackPopup"          
          :kigyouCode="kigyouCode"
          :seihin-call-list="seihinCallList"
          :callOriprocessFlg="callOriprocessFlg"
          :back-flag='backFlag'
          :temp-call-data-list='tempCallDataList'
          @childFn="parentFnCallData"
        />
      </el-card>
    </div>
  </div>
</template>
<script>
import '@/api/update'
import './update_blue.scss'
import '../idea/override.scss'
import '../ideaRule/rule.scss'
import { getIdeaListAPI, saveAndUpdateGentaniLinkAPI } from '@/api/genTani'
import { getNameListAPI, uploadCountSumAPI, getProcessListAPI, getCopyAPI, getParentCopyAPI, saveAPI, getCheckListAPI, saveCheckListAPI, saveTempAPI, checkExistAPI, getSelectIdeaInfoAPI, treeDateSave, saveTreeTempAPI,getUnitListAPI, getLikeItemListAPI, tkmideaCacheListAPI, getTreeListAPI, getDetailBySubassyIdAPI, checkGenerationAPI } from '@/api/input'
import { getOrignalProcessList, removeIdeaCacheAPI, checkTreeSeihin } from '@/api/browser'
import commonJs from '@/mixins/commonJs'
import FileSaver from 'file-saver'
import { EventBus } from '@/main'
import { Popup, ConfirmPopup, AlertPopup, TrafficPopup, UtilityPopup, CallPopup } from '@/layout/components'
import { getTableSeihinAPI } from '@/api/productMaster'
// story21 jin add
import { getTransSenarioAPI } from '@/api/transSenario'
import { v4 as uuidv4 } from 'uuid'
import {times, divide} from '../../utils/number'

export default {
  components: {
    'popup': Popup,
    'confirmPopup': ConfirmPopup,
    'alertPopup': AlertPopup,
    'trafficPopup': TrafficPopup,
    'utilityPopup': UtilityPopup,
    'callPopup': CallPopup
  },
  mixins: [commonJs],
  data() {
    var checknumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('messages.peaam')))
      }
      setTimeout(() => {
        if (!isNumber(value)) {
          callback(new Error('半角数値を入力してください'))
        } else {
          callback()
        }
      }, 500)
    }

    var newcheckgentani = (rule, value, callback) => {
      let suc = false
      for (let i = 0; i < this.filerIdeaData.length; i++) {
        if (this.filerIdeaData[i].name === value) {
          suc = true
        }
      }
      if (!this.filerIdeaData || this.filerIdeaData.length === 0) {
        return callback()
      }
      if (value && !suc) {
        return callback(new Error('正しい原単位を入力してください'))
      }
      return callback()
    }

    var newprocesscheck = (rule, value, callback) => {
      if(value) {
        if (!value || value == '') {
          return callback(new Error('プロセスを選択してください'))
        }
      }
      return callback()
    }

    var checkgentani = (rule, value, callback) => {
      let suc = false
      for (let i = 0; i < this.filerIdeaData.length; i++) {
        if (this.filerIdeaData[i].name === value) {
          suc = true
        }
      }
      if (!this.firstCheckFlag) {
        return callback()
      }
      if (value && !suc) {
        return callback(new Error('正しい原単位を入力してください'))
      }
      return callback()
    }

    var checkInput0 = (rule, value, callback) => {
      if (parseFloat(value) === 0) {
        return callback(new Error('0を入力しないでください'))
      }
      if (value === '-') {
        return callback(new Error('半角数値を入力してください'))
      }            
    }

    var checkBudomari = (rule, value, callback) => {
      if (value === '') {
        return callback()
      }
      if (value) {
        if (parseFloat(value) > 0 && parseFloat(value) < 100) {
          return callback()
        }else{
         return callback(new Error(this.$t('messages.peangt0alt100')))
        }
      }
    }
    var isNumber = (val) => {
      var regPos = /^\d+(\.\d+)?$/
      var regNeg =
        /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/
      if (regPos.test(val) || regNeg.test(val)) {
        return true
      } else {
        return false
      }
    }

    var validateConsumption = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('messages.peopuca')))
      } else {
        if (!isNumber(value)) {
          callback(new Error('半角数値を入力してください'))
        } else {
          callback()
        }
      }
    }

    var validateProduction = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('messages.penopp')))
      } else {
        if (!isNumber(value)) {
          callback(new Error('半角数値を入力してください'))
        } else if (value <= 0) {
          callback(new Error('ゼロ以上の数字のみ入力できます'))
        } else {
          callback()
        }
      }
    }

    var validateItemnameSeihin = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('messages.peain')))
      } else {
        callback()
      }
    }
    var validateTransSenario1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('messages.pets')))
      } else {
        for (var item of this.transSenarioOptions) {
          if (item.senarioid == value) {
            this.seihinyusoForm.trafficDistance1 = item.trans_distance
          }
        }
        callback()
      }
    }
    var validateTrafficDistance2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('messages.petd')))
        // callback()
      } else {
        if (!isNumber(value)) {
          callback(new Error('半角数値を入力してください'))
        } else if (value < 0) {
          callback(new Error('ゼロ以上の数字のみ入力できます'))
        } else {
          callback()
        }
      }
    }

    var validateTransSenario3 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('messages.pets')))
      } else {
        for (var item of this.transSenarioOptions) {
          if (item.senarioid == value) {
            this.seihinyusoForm.trafficDistance3 = item.trans_distance
          }
        }
        callback()
      }
    }
    var validateProductWeight = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('messages.pepw')))
      } else {
        if (!isNumber(value)) {
          callback(new Error('半角数値を入力してください'))
        } else if (value < 0) {
          callback(new Error('ゼロ以上の数字のみ入力できます'))
        } else {
          callback()
        }
      }
    }
    var checkInterimproduct = (rule, value, callback) => {
      if (value !== undefined && value !== null && value !== '' && value.length > 40) {
        return callback(new Error('40バイト以下を入力してください'))
      }
    }

    var checkParentnode = (rule, value, callback) => {
      if (!value || value === '') {
        return callback()
      }
      const hasParentnodeArr = this.treeDataList.filter(item => item.itemname === value)
      const selfParentnodeArr = this.tempValidateTree.filter(item => item.itemname === value && item.parentnode === value)
      if (hasParentnodeArr.length < 1) {
        this.alterType = 1
        return callback(new Error(this.$t('messages.addCheckParNo')))
      }
      if (selfParentnodeArr.length > 0) {
        this.alterType = 3
        return callback(new Error(this.$t('messages.addCheckParSame')))
      }
    }

    var checkCsvParentnode = (rule, value, callback) => {
      if (!value || value === '') {
        return callback()
      }
      const hasParentnodeArr = this.csvTableData.filter(item => item.itemName == value)
      const selfParentnodeArr = this.csvTempValidateTree.filter(item => item.itemName == value && item.parentNode == value)
      if (hasParentnodeArr.length < 1) {
        return callback(new Error(this.$t('messages.addCheckParNo')))
      }
      if (selfParentnodeArr.length > 0) {
        return callback(new Error(this.$t('messages.addCheckParSame')))
      }
    }
    return {
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(this.$store.state.user).access_token,
        'refresh_token': JSON.parse(this.$store.state.user).refresh_token
      },
      tempValidateTree: [],
      csvTempValidateTree: [],
      superAdmin: false,
      dataId: '',
      unitOptions: [{
        unitValue: 'kg',
        unitLabel: 'kg'
      }, {
        unitValue: '個',
        unitLabel: '個'
      }, {
        unitValue: 'm²',
        unitLabel: 'm²'
      }, {
        unitValue: 'm³',
        unitLabel: 'm³'
      }],
      csvTableData: [],
      changedCsvTableData: [],
      csvTableDataBackup: [],
      basiRules: {
        itemName: [
          { required: true, message: this.$t('messages.peain'), trigger: ['blur', 'change'] }
        ],
        processCode: [
          { required: true, message: "プロセスを入力してください", trigger: ['blur', 'change'] }
        ],
        ideaName: [
          { required: true, message: this.$t('messages.peefn'), trigger: ['blur', 'change'] },
          { validator: checkgentani, trigger: ['blur', 'change'] }
        ],
        importQuantity: [
          { required: true, message: this.$t('messages.peaam'), trigger: ['blur', 'change'] },
          { validator: checkInput0, trigger: ['blur', 'change'] }
        ],
        budomari: [
          { required: false, trigger: 'blur' },
          { validator: checkBudomari, trigger: 'blur' }
        ],
        parentNode: [
          { required: false, trigger: 'blur' },
          { validator: checkCsvParentnode, trigger: 'blur' }
        ]
      },
      updateVisible: false,
      resultsDialogVisible: false,
      buttonFlag: 1, // 標準プロセス 1 オリジナルプロセス 2
      innerVisibleSort1: false,
      innerVisibleSort2: false,
      dialogVisible: false,
      isShowAlertPopup: false,
      isShowComfirmPopup: false,
      isComfirm: false,
      isShowTrafficPopup: false,
      isShowNoTrafficPopup: false,
      isShowUtilityPopup: false,
      centerDialogVisible: false,
      saveTempDialogVisible: false,
      saveFinalDialogVisible: false,
      deleteFinalDialogVisible: false,
      surmiseShow: false,
      surmiseIdeaData: [],
      surmiseSubProcessIndex: '',
      surmiseSubProcessItemIndex: '',
      surmiseTreeIndex: '',
      popupMessage: '',
      simulateNote: '',
      buttonName: '',
      buttonType: '',
      popupType: '',
      isShowPopup: false,
      subProcessIndex4Delete: '',
      subProcessItemIndex4Delete: '',
      allProcess: [],
      existProcessList: [],
      processList4select: [],
      loading: false,
      kigyouCode: '',
      seihinCode: '',
      statusDisabled: true,
      seihinNameObj: {
        seihinName: '',
        budomari: '',
        seihinversion: ''
      },
      utilityApportionmentForm: {
        selectIdeaInfo: '1',
        ideaName: '',
        consumption: '',
        unit: '',
        production: '',
        quantity: '',
        productWeight: ''
      },
      utilityApportionmentList: [],
      utilityApportionmentData: {},
      seihinNameObjName: {
        seihinName: ''
      },
      seihinNameObjVersion: {
        seihinversion: ''
      },
      seihinUnit: '',
      choseSeihinUnit: '',
      seihinUnit4Back: '',
      upduser: JSON.parse(this.$store.state.user).userid,
      username: JSON.parse(this.$store.state.user).fullName,
      processList: { 'A1': [
        {
          'index': 0,
          'kigyoucode': '',
          'seihincode': '',
          'seihinname': '',
          'processcode': 'A1',
          'processname': '原材料・部材調達',
          'processname4display': '',
          'itemname': '',
          'ideacode': '',
          'ideaVersion': '',
          'coefficient': 0,
          'quantity': '',
          'emissionc': 0,
          'percentage': '',
          'color': '',
          'delFlg': 0,
          'upduser': '',
          'upddate': '',
          'value': '',
          'name': '',
          'icon': 'icon48_a1material.svg',
          'unit': '',
          'budomari': ''
        }
      ] },
      processListBackup: {},
      processList4Back: {},
      trafficItemname: '',
      trafficItemWeight: 0,
      trafficUnitList: [],
      trafficUnit: '',
      utilityInputIndex: '',
      buttonSwitch: true,
      nameList: [],
      tkmNameList: [],
      newOriginalNameList: [],
      nameArray: [],
      seihinNameRules: {
        seihinName: [
          { required: true, message: this.$t('messages.peapn'), trigger: ['blur', 'change'] }
        ],
        seihinversion: [
          { required: true, message: this.$t('messages.peavn'), trigger: ['blur', 'change'] }
        ]
      },
      seihinNameOptions: [],
      seihinUnitOptions: [{
        unitValue: 'kg',
        unitLabel: 'kg'
      }, {
        unitValue: '個',
        unitLabel: '個'
      }, {
        unitValue: 'm²',
        unitLabel: 'm²'
      }, {
        unitValue: 'm³',
        unitLabel: 'm³'
      }],
      form: {
        projectName: '',
        name: '',
        dateTime: '',
        unit: '',
        productValue: 0,
        coefficient: '',
        productWeight: 0

      },
      // story21 jin add
      seihinyusoForm: {
        itemName: this.$t('messages.productTransport'),
        trafficName: '1',
        originalUnit1: '',
        originalUnit2: '',
        originalUnit3: '',
        transSenario1: '',
        transSenario3: '',
        trafficDistance1: '',
        trafficDistance2: '',
        trafficDistance3: '',
        productWeight: '',
        quantity1: 0,
        quantity2: 0,
        quantity3: 0,
        unit: 'tkm',
        ideacode1: '',
        ideacode2: '',
        coefficient1: '',
        coefficient2: ''
      },
      actionup: process.env.VUE_APP_BASE_API + '/api/v1/genTaniLink/importShow',
      importDataBtnText: '',
      importDataBtnIcon: '',
      importDataDisabled: true,
      errorList: [],
      uploadData: {},
      treeUploadData: {},
      csvHeader: ['cell', 'value'],
      csvHeaderJap: {
        'cell': this.$t('messages.csvTitle1'),
        'value': this.$t('messages.csvTitle2')
      },
      rules: {
        itemname: [
          { required: true, message: this.$t('messages.peain'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('messages.peefn'), trigger: 'blur' },
          { validator: checkgentani, trigger: ['blur', 'change'] }
        ],
        quantity: [
          { required: true, message: this.$t('messages.peaam'), trigger: 'blur' },
          { validator: checkInput0, trigger: ['blur'] }
        ],
        interimproduct: [
          { required: false, trigger: 'blur' },
          { validator: checkInterimproduct, trigger: 'blur' }
        ],
        budomari: [
          { required: false, trigger: 'blur' },
          { validator: checkBudomari, trigger: 'blur' }
        ]
      },
      newRules: {
        itemname: [
          { required: true, message: this.$t('messages.peain'), trigger: 'blur' }
        ],
        displayname: [
          { required: true, message: this.$t('messages.peefn'), trigger: 'blur' },
          { validator: newcheckgentani, trigger: ['blur', 'change'] }
        ],
        processcode: [
          { validator: newprocesscheck, trigger: ['blur', 'change'] }
        ],
        quantity: [
          { required: true, message: this.$t('messages.peaam'), trigger: 'blur' },
          { validator: checkInput0, trigger: ['blur'] }
        ],
        interimproduct: [
          { required: false, trigger: 'blur' },
          { validator: checkInterimproduct, trigger: 'blur' }
        ],
        parentnode: [
          { required: false, trigger: 'blur' },
          { validator: checkParentnode, trigger: 'blur' }
        ],
        budomari: [
          { required: false, trigger: 'blur' },
          { validator: checkBudomari, trigger: 'blur' }
        ]
      },
      utilityApportionmentRules: {
        selectIdeaInfo: [
          { required: true, message: this.$t('messages.peain'), trigger: 'blur' }
        ],
        ideaName: [
          { required: true, message: this.$t('messages.peefn'), trigger: 'blur' }
        ],
        consumption: [
          { required: true, message: this.$t('messages.peopuca'), trigger: 'blur' },
          { validator: validateConsumption, trigger: 'blur' }
        ],
        production: [
          { required: true, message: this.$t('messages.penopp'), trigger: 'blur' },
          { validator: validateProduction, trigger: 'blur' }
        ],
        productWeight: [
          { required: true, message: this.$t('messages.pepw'), trigger: 'blur' },
          { validator: validateProductWeight, trigger: 'blur' }
        ]
      },
      // story21 jin add
      seihinyusoRules: {
        itemName: [
          { required: true, message: this.$t('messages.peain'), trigger: 'blur' },
          { validator: validateItemnameSeihin, trigger: 'blur' }
        ],
        trafficName: [
          { required: true, message: '輸送手段を入力してください', trigger: 'change' }
        ],
        transSenario1: [
          { required: true, message: this.$t('messages.pets'), trigger: 'change' },
          { validator: validateTransSenario1, trigger: 'change' }
        ],
        transSenario3: [
        { required: true, message: this.$t('messages.pets'), trigger: 'change' },
          { validator: validateTransSenario3, trigger: 'change' }
        ],
        trafficDistance2: [
          { required: true, message: '輸送距離を入力してください', trigger: 'blur' },
          { validator: validateTrafficDistance2, trigger: 'blur' }
        ],
        productWeight: [
          { required: true, message: this.$t('messages.pepw'), trigger: 'blur' },
          { validator: validateProductWeight, trigger: 'blur' }
        ]
      },
      checkList: [],
      checklistversion: '',
      checkFlagList: {},
      selectIdeaInfoEnum: {
        1: this.$t('messages.electricity'),
        2: this.$t('messages.waterSuplly'),
        3: this.$t('messages.fuel'),
      },
      // story21 jin add
      trafficSeaItemShow: false,
      transSenarioOptions: [],
      transSenarioOptions2: [],
      originalUnitOptions: [],
      originalUnitOptions2: [],
      allOrignProcessList: [],
      allNormalProcess: [],
      csvProcessNameListOptions: [],
      normalOrignProcessList: [],
      selectionChangeRuleList: [],
      saveCsvDisable: true,
      updateRuleDisabled: true,
      fromPage: '',
      // NodeParent
      parentFlag: 1,
      noTreeFromCsvOrApi: false,
      tree: false,
      treeBreadcrumb: [],
      allTreeBreadcrumb: [],
      treeDataList: [
        {
          "itemName": "",
          "processcode": "",
          "ideacode": "",
          "quantity": "",
          "unit": "",
          "interimproduct": "",
          "parentnode": "",
          "budomari": "",
          "emissionc": 0,
          "coefficient": 0
        }
      ],
      getDataList: [
        {
          "firstManagedProgram": "",
          "managedProgramCount": "",
          "updateUser": "",
          "updateDate": "",
          "intermediateProduct": "",
          "interimproduct": "",
          "parentItem": "",
          "budomari": "",
          "emissionc": 0,
          "coefficient": 0
        }
      ],
      treeData: {
        'index': 0,
        "itemName": "",
        "processcode": "",
        "ideacode": "",
        "quantity": "",
        "unit": "",
        "interimproduct": "",
        "parentnode": "",
        "budomari": "",
        "emissionc": 0,
        "coefficient": 0,
        "subassyId": "",
        "subassyFromId": ""
      },
      processnameList: [],
      language: this.$i18n.locale,
      cancelName: '',
      backName: '',
      transportMeans: '',
      transportDistance: '',
      allDataList: [],
      filerIdeaData: [],
      ideaNameDataList: [],
      filterStandProcessList:[],
      filterOriProcessList: [],
      sumEmissionc1: 0,
      sumEmissionc2: 0,
      sumProcessEmission: 0,
      sumTreeEmission: 0,
      filterSeihinNameList: [],
      sortData : {}, 
      checked: true,
      filterList : {},
      tempTreeDataList: [],
      delayShowFlag: false,
      onlyAddFlag: false,
      ruleAndAllFlag: false,
      stimestamp: 0,
      etimestamp: 0,
      deleteProcessFlag: false,
      treeDataForm: {
        treeDataItem: [
          {
            "key": 0,
            'index': 0,
            "itemname": "",
            "processcode": "",
			      "displayname": "",
            "ideacode": "",
            "quantity": "",
            "unit": "",
            "interimproduct": "",
            "parentnode": "",
            "budomari": "",
            "emissionc": 0,
            "coefficient": 0,
            "subassyId": "",
            "subassyFromId": "",
            "topSubassyId": "",
            "callEmisSum": "",
			      "callName": "",
            "seihinCallName": "",
            "callVersion": "",
            "callParentNode": "",
            "callUpddate": "",
            "callUpdaterName": "",
            "callDataItem": "",
            "topItemName": ""
          }
        ]
      },
      csvTableDataForm: {
        csvTableData: [
          {
            itemName: '',
            itemA: '',
            itemB: '',
            itemC: '',
            itemD: '',
            itemE: '',
            processCode: '',
            coefficient: 1,
            genProcess: '',
            ideaCode: '',
            ideaVersion: '',
            ideageneration: 0,
            unitgeneration: 0,
            ideaName: '',
            delflg: 0,
            quantity: '',
            unitList: [],
            unit: '',
            importQuantity: '',
            importUnit: '',
            interimProduct: '',
            parentNode: '',
            budomari: ''
          }
        ]
      },
      unregisteredEFShow: false,
      isShowCallPopup: false,
      callItemname: '',
      callItemIndex: 0,
      seihinCallList: [],
      callDataList: [],
      tempCallDataList: [],
      callOriprocessFlg: false,
      copyFlag: false,
      backFlag: '',
      originalProcessList: [],
      pageLoading: {},
      buildAddFlag: false,
      csvSumFlag: false,
      uploadCreateFlag: false,
      parentName: '',
    }
  },
  watch: {
    //インポートされたファイルデータの変化のリスニング
    csvTableData: {
      handler(newVal, oldVal) {
        const watchIdeaNameValid = []
        const watchImportQuantityValid = []
        const watchBudomariValid = []
        const watchParentnodeValid = []
        const watchProcessCodeValid = []
        const watchItemNameValid = []
        const watchQuantityValid = []
        newVal.forEach(element => {
          if (element.parentNode) {
            const hasParentnodeArr = this.csvTableData.filter(item => item.itemName == element.parentNode)
            const selfParentnodeArr = this.csvTempValidateTree.filter(item => item.itemName == element.parentNode && item.parentNode == element.parentNode)
            if (hasParentnodeArr.length < 1) {
              watchParentnodeValid.push('parentnodeError')
            }
            if (selfParentnodeArr.length > 0) {
              watchParentnodeValid.push('parentnodeError')
            }
          }
          if (!element.importQuantity || Number(element.importQuantity) === 0 || element.importQuantity === '-') {
            watchImportQuantityValid.push('importQuantityError')
          }
          if (String(element.budomari).includes('e') || String(element.budomari).includes('E') || parseFloat(element.budomari) <= 0 || parseFloat(element.budomari) >= 100) {
            watchBudomariValid.push('budomariError')
          }
          if (!element.processCode) {
            watchProcessCodeValid.push('processCodeError')
          }
          if (!element.itemName) {
            watchItemNameValid.push('itemNameError')
          }            
          if (!element.quantity || Number(element.quantity) === 0 || element.quantity === '-') {
            watchQuantityValid.push('quantityError')
          }
        })
        if (watchImportQuantityValid.length === 0 && watchBudomariValid.length === 0 && watchProcessCodeValid.length === 0 && watchParentnodeValid.length === 0 && watchItemNameValid.length === 0 && watchQuantityValid.length === 0) {
          this.saveCsvDisable = false
        } else {
          this.saveCsvDisable = true
        }
        this.$nextTick(() => {
          this.$refs['csvTableDataForm'].validate((valid) => {
            if (!valid) {
              return false
            }
          })
        })
      },
      deep: true
    },
    processList: {
      handler(newVal, oldVal) {
        if (Object.keys(oldVal).length !== 0) {
          this.buttonSwitch = false
        }
      },
      deep: true
    },
    seihinUnit: {
      handler(newVal, oldVal) {
        if (oldVal) {
          this.buttonSwitch = false
        }
      }
    },
    utilityApportionmentForm: {
      handler(val) {
        if (val.productWeight && val.consumption && val.production) {
          if (Number(val.productWeight) === 0 || Number(val.production) === 0 ) {
            this.$set(this.utilityApportionmentForm, 'quantity', 0)
          } else {
            if (val.consumption !== '-') {
              this.$set(this.utilityApportionmentForm, 'quantity', (val.consumption / val.production * val.productWeight).toFixed(2))              
            }
          }
        } else {
          this.$set(this.utilityApportionmentForm, 'quantity', '')
        }
      },
      deep: true
    },
    // story21 jin add
    // 活動量の計算 デフォルト値を割り当てる
    seihinyusoForm: {
      handler(val) {
        if (val.productWeight) {
          if (val.trafficDistance1) {
            if (Number(val.productWeight) === 0 || Number(val.trafficDistance1) === 0) {
              this.$set(this.seihinyusoForm, 'quantity1', 0)
            } else {
              this.$set(this.seihinyusoForm, 'quantity1', val.trafficDistance1 * val.productWeight / 1000)
            }
          }
          if (val.trafficDistance2) {
            if (Number(val.productWeight) === 0 || Number(val.trafficDistance2) === 0) {
              this.$set(this.seihinyusoForm, 'quantity2', 0)
            } else {
              this.$set(this.seihinyusoForm, 'quantity2', val.trafficDistance2 * val.productWeight / 1000)
            }
          } else {
            this.$set(this.seihinyusoForm, 'quantity2', 0)
          }
          if (val.trafficDistance3) {
            if (Number(val.productWeight) === 0 || Number(val.trafficDistance3) === 0) {
              this.$set(this.seihinyusoForm, 'quantity3', 0)
            } else {
              this.$set(this.seihinyusoForm, 'quantity3', val.trafficDistance3 * val.productWeight / 1000)
            }
          }
        } else {
          this.$set(this.seihinyusoForm, 'quantity1', 0)
          this.$set(this.seihinyusoForm, 'quantity2', 0)
          this.$set(this.seihinyusoForm, 'quantity3', 0)
        }
      },
      deep: true
    },
    // 国際化言語切り替え begin
    '$i18n.locale'(){
        this.language = this.$i18n.locale
        this.csvHeader= ['cell', 'value']
        this.csvHeaderJap= {
          'cell': this.$t('messages.csvTitle1'),
          'value': this.$t('messages.csvTitle2')
        }
      this.uploadData = { 'kigyouCode': this.kigyouCode, 'upduser': this.upduser, 'seihinCode': this.seihinCode, 'version': this.seihinNameObjVersion.seihinversion, 'parentFlag': false, 'language': this.language }
      this.treeUploadData = { 'kigyouCode': this.kigyouCode, 'upduser': this.upduser, 'seihinCode': this.seihinCode, 'version': this.seihinNameObjVersion.seihinversion, 'parentFlag': true, 'language': this.language }
       this.seihinNameRules= {
        seihinName: [
          { required: true, message: this.$t('messages.peapn'), trigger: ['blur', 'change'] }
        ],
        seihinversion: [
          { required: true, message: this.$t('messages.peavn'), trigger: ['blur', 'change'] }
        ]
      }
      this.rules = {
        itemname: [
          { required: true, message: this.$t('messages.peain'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('messages.peefn'), trigger: 'blur' },
          { validator: this.checkgentani1, trigger: ['blur', 'change'] }
        ],
        quantity: [
          { required: true, message: this.$t('messages.peaam'), trigger: 'blur' },
          { validator: this.checkInput01, trigger: ['blur'] }
        ],
        interimproduct: [
          { required: false, trigger: 'blur' },
          { validator: this.checkInterimproduct1, trigger: 'blur' }
        ],
        budomari: [
          { required: false, trigger: 'blur' },
          { validator: this.checkBudomari1, trigger: 'blur' }
        ]
      }
      this.basiRules = {
        itemName: [
          { required: true, message: this.$t('messages.peain'), trigger: ['blur', 'change'] }
        ],
        processCode: [
          { required: true, message: "プロセスを入力してください", trigger: ['blur', 'change'] }
        ],
        ideaName: [
          { required: true, message: this.$t('messages.peefn'), trigger: ['blur', 'change'] },
          { validator: this.checkgentani1, trigger: ['blur', 'change'] }
        ],
        importQuantity: [
          { required: true, message: this.$t('messages.peaam'), trigger: ['blur', 'change'] },
          { validator: this.checkInput01, trigger: ['blur', 'change'] }
        ],
        budomari: [
          { required: false, trigger: 'blur' },
          { validator: this.checkBudomari1, trigger: 'blur' }
        ],
        parentNode: [
          { required: false, trigger: 'blur' },
          { validator: this.checkCsvParentnode1, trigger: 'blur' }
        ]
      }
      this.newRules= {
        itemname: [
          { required: true, message: this.$t('messages.peain'), trigger: 'blur' }
        ],
        displayname: [
          { required: true, message: this.$t('messages.peefn'), trigger: 'blur' },
          { validator: this.newcheckgentani1, trigger: ['blur', 'change'] }
        ],
        processcode: [
          { validator: this.newprocesscheck1, trigger: ['blur', 'change'] }
        ],
        quantity: [
          { required: true, message: this.$t('messages.peaam'), trigger: 'blur' },
          { validator: this.checkInput01, trigger: ['blur'] }
        ],
        interimproduct: [
          { required: false, trigger: 'blur' },
          { validator: this.checkInterimproduct1, trigger: 'blur' }
        ],
        parentnode: [
          { required: false, trigger: 'blur' },
          { validator: this.checkParentnode1, trigger: 'blur' }
        ],
        budomari: [
          { required: false, trigger: 'blur' },
          { validator: this.checkBudomari1, trigger: 'blur' }
        ]
      },
      this.utilityApportionmentRules= {
        selectIdeaInfo: [
          { required: true, message: this.$t('messages.peain'), trigger: 'blur' }
        ],
        ideaName: [
          { required: true, message: this.$t('messages.peefn'), trigger: 'blur' }
        ],
        consumption: [
          { required: true, message: this.$t('messages.peopuca'), trigger: 'blur' },
          { validator: this.validateConsumption1, trigger: 'blur' }
        ],
        production: [
          { required: true, message: this.$t('messages.penopp'), trigger: 'blur' },
          { validator: this.validateProduction1, trigger: 'blur' }
        ],
        productWeight: [
          { required: true, message: this.$t('messages.pepw'), trigger: 'blur' },
          { validator: this.validateProductWeight1, trigger: 'blur' }
        ]
      },
      // story21 jin add
      this.seihinyusoRules= {
        itemName: [
          { required: true, message: this.$t('messages.peain'), trigger: 'blur' },
          { validator: this.validateItemnameSeihin1, trigger: 'blur' }
        ],
        trafficName: [
          { required: true, message: '輸送手段を入力してください', trigger: 'change' }
        ],
        transSenario1: [
          { required: true, message: this.$t('messages.pets'), trigger: 'change' },
          { validator: this.validateTransSenario11, trigger: 'change' }
        ],
        transSenario3: [
        { required: true, message: this.$t('messages.pets'), trigger: 'change' },
          { validator: this.validateTransSenario31, trigger: 'change' }
        ],
        trafficDistance2: [
          { required: true, message: '輸送距離を入力してください', trigger: 'blur' },
          { validator: this.validateTrafficDistance21, trigger: 'blur' }
        ],
        productWeight: [
          { required: true, message: this.$t('messages.pepw'), trigger: 'blur' },
          { validator: this.validateProductWeight1, trigger: 'blur' }
        ]
      }
      // end
     },
     buildAddFlag: {
        handler(newVal) {
          setTimeout(() => {
            if (newVal) {
              this.buildAddData()
            }
          }, 10);
        }
     },
  },
  async mounted() {
    this.seihinUnit4Back = ''
    this.processListBackup = {}
    this.seihinUnit = this.seihinUnitOptions[0].unitValue
    this.kigyouCode = JSON.parse(this.$store.state.user).kigyoucode
    this.superAdmin = JSON.parse(this.$store.state.role).userRole === 'SuperAdmin'
    this.fromPage = this.$route.params.fromPage
    // checkListデータの取得
    this.getCheckListData(false)
    // 原単位データJavaキャッシュの削除
    removeIdeaCacheAPI()
    // カスタムプロセスデータの取得
    getOrignalProcessList(this.kigyouCode)
                .then(res => {
                  if (res.code === 200) {
                    res.data.forEach(element => {
                      this.originalProcessList.push({ 'processCode': element.orignProcessCode, 'processName': element.orignProcessName, 'genProcess': element.genProcess })
                    })
                  }
                })
    // プロセスデータの取得
    getProcessListAPI()
            .then(res => {
              if (res.code === 200) {
                res.data.forEach(element => {
                  this.filterStandProcessList.push({ 'processCode': element.processCode, 'processName': element.processName })
                  this.allNormalProcess.push({ 'processCode': element.processCode, 'processName': element.processName })
                  this.csvProcessNameListOptions.push({ 'processCode': element.processCode, 'processName': element.processName })
                })
              }
            })
    // プロセスデータの取得 (削除データを含む)
    getOrignalProcessList(this.kigyouCode)
            .then(res => {
              if (res.code === 200) {
                res.data.forEach(element => {
                  this.filterOriProcessList.push({ 'processCode': element.orignProcessCode, 'processName': element.orignProcessName, 'genProcess': element.genProcess })
                  this.allOrignProcessList.push({ 'processCode': element.orignProcessCode, 'processName': element.orignProcessName, 'genProcess': element.genProcess })
                })
              }
            })
    // 入力補助メニュー(入力補助メニュー) 原単位のデフォルト値の設定
    this.getSelectIdeaInfo('utility', 1)

    // 製品マスターデータの取得
    getTableSeihinAPI(this.kigyouCode, this.sortData, this.filterData)
      .then(res => {
        if (res.code === 200) {
          this.seihinNameOptions = res.data
          this.filterSeihinNameList = res.data.filter(item => item.delflg == 0)
        } else {
          this.rejectMsg()
        }
      }).catch((error) => {
        console.log('error', error)
      })

    // story21 jin add start
    // 輸送距離シナリオの取得(1:陸送)
    getTransSenarioAPI(1)
      .then(res => {
        if (res.code === 200) {
          this.transSenarioOptions = res.data
        } else {
          this.rejectMsg()
        }
      }).catch((error) => {
        console.log('error', error)
      })

    // 輸送距離シナリオの取得(2:海運)
    getTransSenarioAPI(2)
      .then(res => {
        if (res.code === 200) {
          this.transSenarioOptions2 = res.data
          if (this.transSenarioOptions2.length > 0) {
            this.seihinyusoForm.transSenario2 = this.transSenarioOptions2[0].senarioname
          }
        } else {
          this.rejectMsg()
        }
      }).catch((error) => {
        console.log('error', error)
      })

    // 原単位名の取得 陸送のみ
    this.getOriginalUnit('1')
    // 原単位名の取得 海運含む
    this.getOriginalUnit('2')
    // story21 jin add end

    // ファイルアップロードパラメータの設定(親子関係なし)
    this.uploadData = { 'kigyouCode': this.kigyouCode, 'upduser': this.upduser, 'parentFlag': false, 'language': this.language }
     // ファイルアップロードパラメータの設定(親子関係あり)
    this.treeUploadData = { 'kigyouCode': this.kigyouCode, 'upduser': this.upduser, 'parentFlag': true, 'language': this.language }
    // 原単位データの取得
    await getNameListAPI(this.kigyouCode)
      .then(res => {
        if (res.code === 200) {
          this.nameArray = res.data.map(listObj => listObj.name.replace('a3b523ac16674572848091098e', ' '))
          this.allDataList = res.data
          // データ削除後の構造のフィルタリング
          this.nameList = res.data.filter(item => item.delflg == 0)
          this.nameList.forEach(element => {
            if (element.name.indexOf('a3b523ac16674572848091098e') > -1) {
              element.displayname = element.name.replace('a3b523ac16674572848091098e', ' ')
              element.name = element.name.replace('a3b523ac16674572848091098e', ' ')
            } else {
              element.displayname = element.name
            }
          })
        } else {
          this.rejectMsg()
        }
      })
      .catch(error => {
        if (error.response.status != 202 && error.response.status != 401 && error.response.status != 403) {
          this.$alert('ネットワークエラーが発生した。ログイン画面へ遷移する。', 'システムエラー', {
            confirmButtonText: this.$t('messages.yes')
          }).then(() => {
            return this.$router.push({ path: '/' })
          })
        }
      })
    tkmideaCacheListAPI(this.kigyouCode)
    .then(res => {
      if (res.code === 200) {
        this.tkmNameList = res.data.filter(item => item.delflg == 0)
        this.tkmNameList.forEach(element => {
          element.displayname = element.name
        })
      } else {
        this.rejectMsg()
      }
    })
    .catch(error => {
      if (error.response.status != 202 && error.response.status != 401 && error.response.status != 403) {
        this.$alert('ネットワークエラーが発生した。ログイン画面へ遷移する。', 'システムエラー', {
          confirmButtonText: this.$t('messages.yes')
        }).then(() => {
          return this.$router.push({ path: '/' })
        })
      }
    })  
    getNameListAPI(this.kigyouCode)
      .then(res => {
        if (res.code === 200) {
          this.filerIdeaData = res.data.filter(item => item.delflg === 0)
          this.newOriginalNameList = res.data
          this.filerIdeaData.forEach(element => {
            if (element.name.indexOf('a3b523ac16674572848091098e') > -1) {
              element.displayname = element.name.replace('a3b523ac16674572848091098e', ' ')
              element.name = element.name.replace('a3b523ac16674572848091098e', ' ')
            }
          })
        } else {
          this.rejectMsg()
        }
      })
      .catch(error => {
        if (error.response.status != 202 && error.response.status != 401 && error.response.status != 403) {
          this.$alert('ネットワークエラーが発生した。ログイン画面へ遷移する。', 'システムエラー', {
            confirmButtonText: this.$t('messages.yes')
          }).then(() => {
            return this.$router.push({ path: '/' })
          })
        }
      })
      // 製品一覧画面から入るかどうかを判断する （copy功能）
    if (this.fromPage === 'allPage') {
      // データに親子関係があるかどうかをチェック
      await checkTreeSeihin(this.$route.params.seihinCode, this.$route.params.seihinVersion, this.kigyouCode)
        .then(res => {
          if (res.code === 200) {
            // 親子関係あり treeFlg = 2, 親子関係なし treeFlg = 1
            this.parentFlag = res.data.treeFlg ? 2 : 1
          } else {
            this.rejectMsg()
          }
        }).catch((error) => {
          console.log('error', error)
        })
      // データが標準プロセスであるかどうかを判断する
      this.buttonFlag = Number(this.$route.params.oriprocessFlg) + 1
      // 親子関係なし
      if (this.parentFlag === 1) {
        if (this.buttonFlag === 1) {
          // 標準プロセス init
          this.staticProcessInit()
        } else if (this.buttonFlag === 2) {
          // オリジナルプロセス init
          this.customerProcessInit()
        }
        // 親子関係あり
      } else if (this.parentFlag === 2) {
        if (this.buttonFlag === 1) {
          // 標準プロセス init
          this.standardProcessInit()
        } else if (this.buttonFlag === 2) {
          // オリジナルプロセス init
          this.originalProcessInit()
        }
      }
      // loadingアニメーションを表示
      this.showPageLoading()
      // 製品詳細データの取得
      await this.getDetailData(this.kigyouCode, this.$route.params.seihinVersion, this.$route.params.seihinCode, this.$route.params.oriprocessFlg === 1)
    }
    // 自社システム連携
    if (this.fromPage === 'externalApiPage') {
      this.showPageLoading()
      // 製品変数へのAPIデータの割り当て
      this.parentFlag = this.$route.params.parentFlag
      this.buttonFlag = Number(this.$route.params.buttonFlag)
      this.seihinCode = this.$route.params.seihinCode
      this.seihinNameObjName.seihinName = this.$route.params.seihinName
      this.seihinNameObjVersion.seihinversion = this.$route.params.seihinVersion
      this.dataId = this.$route.params.dataId
      this.choseSeihinUnit = this.$route.params.choseSeihinUnit
      this.simulateNote = this.$route.params.seihinNote
      this.statusDisabled = false
      this.importDataDisabled = false
      this.saveTempDialogVisible = false
      this.saveFinalDialogVisible = false
      // 親子関係があるかどうかを判断する
      if (this.parentFlag !== 2) {
          this.noTreeFromCsvOrApi = true
        } else {
          this.noTreeFromCsvOrApi = false
          this.tree = true
        }
        let type = 0;
        if (this.buttonFlag === 1) {
          type = 1
          // 標準プロセス init
          this.standardProcessInit()
          // 標準プロセスデータの取得
          getProcessListAPI()
            .then(res => {
              if (res.code === 200) {
                res.data.forEach(element => {
                  this.processnameList.push({ 'processCode': element.processCode, 'processName': element.processName })
                })
              }
            })
        } else if (this.buttonFlag === 2) {
          type = 2
          // オリジナルプロセス init
          this.originalProcessInit()
          // オリジナルプロセスデータの取得
          getOrignalProcessList(this.kigyouCode)
            .then(res => {
              if (res.code === 200) {
                res.data.forEach(element => {
                  this.processnameList.push({ 'processCode': element.orignProcessCode, 'processName': element.orignProcessName, 'genProcess': element.genProcess })
                })
              }
            })
        }
        this.processList = {}
        // APIデータを製品要件を満たすデータ構造に変換する
        this.treeDataList = this.handleCsvTableData(JSON.parse(this.$route.params.externalApiTabledata))
        // 世代論理を処理し、総排除量を計算する
        this.unitTreeGengrate(this.treeDataList, true)
        this.tempTreeDataList = JSON.parse(JSON.stringify(this.treeDataList))
        if (this.parentFlag === 2) {
            for (let index = 0; index < this.treeDataList.length; index++) {
              // ナビゲーションの生成
              this.getBreadcrumb(this.treeDataList[index].processcode, index, type)
            }
        }
        this.treeDataForm.treeDataItem = this.treeDataList
    }
  },
  created() {
    EventBus.$emit('toFloatContainer', this.processList)
  },
  updated() {
    if (this.tree) {
      EventBus.$emit('toFloatContainer', this.treeBreadcrumb)
    } else {
      EventBus.$emit('toFloatContainer', this.processList)
    }
    EventBus.$emit('toTreeFloatContainer', this.tree)
  },
  beforeDestroy() {
    if (typeof(this.pageLoading.close) == 'function') {
      this.pageLoading.close()
    }
  },
  destroyed() {
    this.processList = null
    EventBus.$emit('toFloatContainer', this.processList)
  },
  methods: {
    parentFn4TrafficCalculator(valueFromChild) {
      this.isComfirm = valueFromChild['isComfirm']
      this.isShowTrafficPopup = valueFromChild['isShowTrafficPopup']
      if (valueFromChild.itemName) {
        if (valueFromChild['itemName'].indexOf('a3b523ac16674572848091098e') > -1) {
          valueFromChild.displayname = valueFromChild['itemName'].replace('a3b523ac16674572848091098e', ' ')
        } else {
          valueFromChild.displayname = valueFromChild['itemName']
        }
        let childEmissionc = valueFromChild['itemCoefficient'] * valueFromChild['itemQuantity']
        if (this.parentFlag === 2 || this.uploadCreateFlag) {
          var processSubA2 = {
            'index': 0,
            'kigyoucode': '',
            'seihincode': '',
            'seihinname': '',
            'processcode': 'A2',
            'processname': '工場への輸送',
            'processname4display': '',
            'itemname': this.trafficItemname,
            'ideacode': valueFromChild['itemIdeacode'],
            'ideaVersion': valueFromChild['itemVersion'] || '',
            'coefficient': valueFromChild['itemCoefficient'],
            'quantity': valueFromChild['itemQuantity'],
            'emissionc': childEmissionc,
            'percentage': '',
            'delFlg': 0,
            'unit': valueFromChild['itemUnit'],
            'unitList': [],
            'budomari': '',
            'parentnode': this.parentFlag === 2 ? this.parentName : '',
            'interimproduct': '',
            'name': valueFromChild['itemName'],
            'displayname': valueFromChild['displayname'],
            'subassyId': '',
            'subassyFromId': '',
            'ideageneration': 0,
            'generationBoolean': false,
            'unitgeneration': 0,
            'unitgenerationBoolean': false
          }
           if (this.isComfirm) {
              let arrayAdd = JSON.parse(JSON.stringify(processSubA2))
              arrayAdd.index = this.treeIndex++
              this.treeDataList.splice(this.treeIndex, 0, arrayAdd)
              for (let i = 0; i < this.treeDataList.length; i++) {
                this.treeDataList[i].index = i
                this.treeDataList[i].key = i
              }
             this.unittreeTransfer(valueFromChild['itemUnit'], valueFromChild['itemIdeacode'], this.treeIndex, processSubA2.ideaVersion)
          }
          this.sum2()
        } else {
          var processSubA2 = {
              'index': 0,
              'kigyoucode': '',
              'seihincode': '',
              'seihinname': '',
              'processcode': 'A2',
              'processname': '工場への輸送',
              'processname4display': '',
              'itemname': this.trafficItemname,
              'ideacode': valueFromChild['itemIdeacode'],
              'coefficient': valueFromChild['itemCoefficient'],
              'quantity': valueFromChild['itemQuantity'],
              'ideaVersion': valueFromChild['itemVersion'] || '',
              'emissionc': childEmissionc,
              'percentage': '',
              'color': '',
              'delFlg': '',
              'upduser': '',
              'upddate': '',
              'value': '',
              'name': valueFromChild['itemName'],
              'displayname': valueFromChild['displayname'],
              'icon': 'icon48_a2ftransfatory.svg',
              'unit': valueFromChild['itemUnit'],
              'seihinUnit': '',
              'ideageneration': 0,
              'generationBoolean': false,
              'unitgenerationBoolean': false
            }
            if (this.isComfirm) {
            if (this.processList['A2']) {
              this.processList['A2'].push(processSubA2)
              this.unitTransfer(valueFromChild['itemUnit'], valueFromChild['itemIdeacode'], 'A2', this.processList['A2'].length-1, processSubA2.ideaVersion)
            } else {
              this.$set(this.processList, 'A2', [])
              this.processList['A2'].push(processSubA2)
              this.unitTransfer(valueFromChild['itemUnit'], valueFromChild['itemIdeacode'], 'A2', this.processList['A2'].length-1, processSubA2.ideaVersion)
            }
          }
           this.sum1()
          }
        }
    },
    parentFn4UtilityCalculator(valueFromChild) {
      this.isComfirm = valueFromChild['isComfirm']
      this.isShowUtilityPopup = valueFromChild['isShowUtilityPopup']

      if (this.isComfirm) {
        this.processList['A3'][this.utilityInputIndex].quantity = valueFromChild['itemQuantity']
      }
    },
    // loadingスタイルの設定
    showPageLoading() {
      this.pageLoading = this.$loading({
         lock: true,
         text: 'Loading',
         spinner: 'el-icon-loading',
         background: 'rgba(0, 0, 0, 0.7)'
     })
    },
    parentFn4Alert(valueFromChild) {
      this.isShowAlertPopup = valueFromChild['isShowAlertPopup']
    },
    // 算出方法フロー判定
    parentFn(valueFromChild) {
      this.isComfirm = valueFromChild['isComfirm']
      this.isShowComfirmPopup = valueFromChild['isShowComfirmPopup']
      if (this.isComfirm) {
        if (this.buttonType === 'delete') {
          // プロセス削除
          this.delete()
        } else if (this.buttonType === 'update') {
          this.showPageLoading()
          // 親子関係なし算定
          this.save()
        } else if (this.buttonType === 'treeupdate') {
          this.showPageLoading()
          // 親子関係あり算定
          this.savetree()
        } else if (this.buttonType === 'deleteSubItem') {
          // 管理項目名削除
          this.deleteSubItem()
        } else if (this.buttonType === 'download') {
          // エラーメッセージのダウンロード
          const objArray = JSON.parse(JSON.stringify(this.errorList))
          const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
          let csvData = ''
          let title = ''
          array.filter(item => {
            for (var key in item) {
              if (this.csvHeader.indexOf(key) < 0) {
                delete item[key]
              }
            }
          })
          for (const key in array[0]) {
            title += '"' + this.csvHeaderJap[key] + '",'
          }
          title = title.substr(0, title.length - 1) + '\r'
          for (let i = 0; i < array.length; i++) {
            let line = ''
            for (const index in array[i]) {
              if (array[i][index] === null || array[i][index] === '') {
                line += ','
              } else {
                line += '"' + array[i][index] + '",'
              }
            }
            line.slice(0, line.Length - 1)
            csvData += line.substr(0, line.length - 1) + '\r'
          }
          csvData = csvData.substr(0, csvData.length - 1)
          csvData = title + csvData
          const blob = new Blob(['\uFEFF' + csvData], {
            type: 'text/csv;charset=utf-8;'
          })
          const filename = 'error_' + this.getCurrentDate() + '.csv'
          FileSaver.saveAs(blob, filename)
        }
      }
    },
    // ファイルのアップロードを開始する前の方法
    beforeUpload() {
      // loadingスタイルの設定
      this.importDataBtnIcon = 'el-icon-loading'
      this.loading = true
      this.uploadData['seihinCode'] = this.seihinCode
      this.treeUploadData['seihinCode'] = this.seihinCode
      return true
    },
    onError(error) {
      console.log('onError:::', error)
      this.$alert('ログイン有効期限が切れましたので、再ログインしてください', 'ログイン有効期限', {
        confirmButtonText: '確定',
        callback: action => {
          this.$router.push('/')
        }
      })
    },
    treeOnError(error) {
      console.log('treeOnError:::', error)
      this.$alert('ログイン有効期限が切れましたので、再ログインしてください', 'ログイン有効期限', {
        confirmButtonText: '確定',
        callback: action => {
          this.$router.push('/')
        }
      })
    },
    // PDFメソッドを開く
    openpdf() {
      if (this.language === 'jp') {
        const routeUrl = this.$router.resolve({
          path: '/static/pdfjs/web/viewer.html?file=/static/Guideline-JP.pdf'
        })
        window.open(routeUrl.href, '_blank')
      } else if (this.language === 'en') {
         const routeUrl = this.$router.resolve({
          path: '/static/pdfjs/web/viewer.html?file=/static/Guideline-EN.pdf'
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    //  親子関係なしアップロードに成功しました
    onSuccess(response) {
      if (response.code === 200) {
        this.pageLoading.close()
        if (response.data && response.data.length && response.data[0].value) {
          this.errorList = response.data
          this.popupMessage = this.$t('messages.tifiiacbr')
          this.buttonName = this.$t('messages.download')
          this.cancelName = this.$t('messages.buttonCancel')
          this.isShowComfirmPopup = true
          this.buttonType = 'download'
        } else if (response.data && response.data.length) {
          this.uploadCountSumAPI(this.upduser, false)
          response.data.forEach(element => {
            if (element.ideaCode && element.importUnit === element.unit) {
              element.importQuantity = element.importQuantity
            }
            // 他社または自社のスペル文字を削除する
            if (element.ideaName.indexOf('a3b523ac16674572848091098e') > -1) {
              element.ideaName = element.ideaName.replace('a3b523ac16674572848091098e', ' ')
            }
            // process情報の取得 標準プロセスかどうかを判断する buttonFlag = 1 標準プロセス
            if (element.processCode) {
              this.csvProcessNameListOptions = this.allOrignProcessList.some(processItem => processItem.processCode === element.processCode) ? this.allOrignProcessList : this.allNormalProcess
              this.buttonFlag = this.allOrignProcessList.some(processItem => processItem.processCode === element.processCode) ? 2 : 1
            }
            element.delflg = this.formatDelflg(element.ideaCode, element.ideaVersion)
          })
          this.csvTableData = response.data
          // デフォルトの主単位と係数の設定
          this.csvTableData.map(item => {
            if (item && item.unitList && item.unitList.length > 0) {
              let unitPercent = item.unitList.filter(unitItem => unitItem.tounit == item.unit)
              if (unitPercent.length > 0) {
                item.transferUnitPercent = unitPercent[0].percent
              } else {
                item.transferUnitPercent = 1
              }
            } else {
              item.transferUnitPercent = 1
            }
          })
          // 原単位名litsの取得
          this.ideaNameDataList = response.data.map(listObj => listObj.ideaName)
          this.csvTableDataBackup = JSON.parse(JSON.stringify(response.data))
          this.csvTableDataForm.csvTableData = this.csvTableData
          this.$nextTick(() => {
            this.$refs['csvTableDataForm'].validate((valid) => {
              if (!valid) {
                return false
              }
            })
          }) 
          this.resultsDialogVisible = true
          this.clearSelect()
        } else if (response.data.length === 0) {
          this.$message.error('ファイルにレコードが存在しません。')
        } else {
          this.rejectMsg()
        }
      } else {
        this.pageLoading.close()
      }
    },
    // 親子関係ありアップロードに成功しました
    treeOnSuccess(response) {
      if (response.code === 200) {
        this.pageLoading.close()
        if (response.data && response.data.length && response.data[0].value) {
          this.errorList = response.data
          this.popupMessage = this.$t('messages.tifiiacbr')
          this.buttonName = this.$t('messages.download')
          this.cancelName = this.$t('messages.buttonCancel')
          this.isShowComfirmPopup = true
          this.buttonType = 'download'
        } else if (response.data && response.data.length) {
          this.uploadCountSumAPI(this.upduser, false)
          response.data.forEach(element => {
            if (element.ideaCode && element.importUnit === element.unit) {
              element.importQuantity = element.importQuantity
            }
            // 他社または自社のスペル文字を削除する
            if (element.ideaName.indexOf('a3b523ac16674572848091098e') > -1) {
              element.ideaName = element.ideaName.replace('a3b523ac16674572848091098e', ' ')
            }
            if (element.processCode) {
              this.csvProcessNameListOptions = this.allOrignProcessList.some(processItem => processItem.processCode === element.processCode) ? this.allOrignProcessList : this.allNormalProcess
              this.buttonFlag = this.allOrignProcessList.some(processItem => processItem.processCode === element.processCode) ? 2 : 1
            }
            element.delflg = this.formatDelflg(element.ideaCode, element.ideaVersion)
          })
          let type = this.buttonFlag
          this.csvTableData = response.data
          // デフォルトの主単位と係数の設定
          this.csvTableData.map(item => {
            if (item && item.unitList && item.unitList.length > 0) {
              let unitPercent = item.unitList.filter(unitItem => unitItem.tounit == item.unit)
              if (unitPercent.length > 0) {
                item.transferUnitPercent = unitPercent[0].percent
              } else {
                item.transferUnitPercent = 1
              }
            } else {
              item.transferUnitPercent = 1
            }
          })
          for (let index = 0; index < this.csvTableData.length; index++) {
            this.csvTableData[index].key = index
            this.getBreadcrumb(this.csvTableData[index].processCode, index, type)
          }
          this.csvTableDataForm.csvTableData = this.csvTableData
          this.csvTableDataBackup = JSON.parse(JSON.stringify(response.data))
          this.$nextTick(() => {
            this.$refs['csvTableDataForm'].validate((valid) => {
              if (!valid) {
                return false
              }
            })
          }) 
          this.resultsDialogVisible = true
        } else if (response.data.length === 0) {
          this.$message.error('ファイルにレコードが存在しません。')
        } else {
          this.rejectMsg()
        }
      } else {
        this.pageLoading.close()
      }
    },

    change(e) {
      this.$forceUpdate()
    },
    // 標準プロセスicon
    getIconUrl(subProcess) {
      return require('@/assets/static/process/' + subProcess[0].icon)
    },
    // 原単位名検索
    querySearch(queryString, cb) {
      const result = this.nameList.filter(item => {
        if (item.displayname.indexOf(queryString) > -1) {
          return true
        }
      })

      cb(result)
    },
    setId(subProcessIndex) {
      return subProcessIndex
    },
    // 新規プロセス
    addProcess(isShow) {
      this.isShowPopup = isShow
      if (this.buttonFlag === 2) {
        this.existProcessList = []
        this.allProcess = this.allOrignProcessList.map(listObj => {
          return {
            color: '',
            icon: '',
            processCode: listObj.processCode,
            processName: listObj.processName,
            genProcess: listObj.genProcess
          }
        })
        for (var key in this.processList) {
          this.existProcessList.push(key)
        }
        this.processList4select = this.allProcess.filter(item => this.existProcessList.indexOf(item.processCode) < 0)
        for (const item in this.processList4select) {
          this.$set(this.processList4select[item], 'isActive', false)
          this.$set(this.processList4select[item], 'isShow', true)
        }
      } else {
        // 最新のプロセスデータの取得
        getProcessListAPI()
          .then(res => {
            this.existProcessList = []
            if (res.code === 200) {
              this.allProcess = res.data
              for (var key in this.processList) {
                this.existProcessList.push(key)
              }
              this.processList4select = this.allProcess.filter(item => this.existProcessList.indexOf(item.processCode) < 0)
              for (const item in this.processList4select) {
                this.$set(this.processList4select[item], 'isActive', false)
                this.$set(this.processList4select[item], 'isShow', true)
              }
            } else {
              this.rejectMsg()
            }
          })
          .catch(error => {
            if (error.response.status != 202 && error.response.status != 401 && error.response.status != 403) {
              this.$alert('ネットワークエラーが発生した。ログイン画面へ遷移する。', 'システムエラー', {
                confirmButtonText: this.$t('messages.yes')
              }).then(() => {
                return this.$router.push({ path: '/' })
              })
            }
          })
      }
    },
    // 
    addProcessSub(subProcessIndex, subProcess) {
      // disabled status
      if (this.statusDisabled) {
        return
      }
      const detail = {
        'index': 0,
        'budomari': '',
        'kigyoucode': '',
        'seihincode': '',
        'seihinname': '',
        'processcode': subProcess[0].processcode,
        'processname': subProcess[0].processname,
        'processname4display': '',
        'itemname': '',
        'ideacode': '',
        'ideaVersion': '',
        'coefficient': '',
        'quantity': '',
        'emissionc': '',
        'percentage': '',
        'color': '',
        'delFlg': '',
        'upduser': '',
        'upddate': '',
        'value': '',
        'name': '',
        'icon': subProcess[0].icon,
        'unit': '',
        'seihinUnit': ''
      }
      if (subProcessIndex === 'Z') {
        const result = this.nameList.filter(item => {
          if (item.ideacode.indexOf('Z') > -1) {
            return true
          }
        })
        detail.ideacode = result[0].ideacode
        detail.name = result[0].name
        detail.unit = result[0].unit
        detail.coefficient = result[0].coefficient
        detail.ideaVersion = result[0].version || ''
      }
      this.processList[subProcessIndex].push(detail)
    },
    // process削除時に最後のprocessかどうかを判断し、データが最後のprocessプロンプトである場合にプロンプト情報の削除を許可しない
    delete() {
      this.deleteProcessFlag = true
      this.timer = setTimeout(() => {
        if (Object.keys(this.processList).length === 1) {
          this.popupMessage = this.$t('messages.atLeastOneProcess')
          this.buttonName = this.$t('messages.atLeastOneProcessButton')
          this.isShowAlertPopup = true
          return false
        }
        delete this.processList[this.subProcessIndex4Delete]
        this.processList = JSON.parse(JSON.stringify(this.processList))
        this.unitStandGengrate(this.processList, true)
      }, 500)
    },
    // processをクリックしてトリガーされたリリースを削除します
    deleteProcess(subProcessIndex) {
      if (!this.statusDisabled) {
        this.popupMessage = this.$t('messages.confirmDeleteMessage')
        this.buttonName = this.$t('messages.buttonDeleteConfirm')
        this.cancelName = this.$t('messages.buttonCancel')
        this.isShowComfirmPopup = true
        this.subProcessIndex4Delete = subProcessIndex
        this.buttonType = 'delete'
      }      
    },
    // 管理項目削除
    deleteSubItem() {
      this.timer = setTimeout(() => {
        // データが1行しかないかどうかを判断する
        if (Object.keys(this.processList[this.subProcessIndex4Delete]).length === 1) {
          this.popupMessage = '項目は一つ以上してください。'
          this.buttonName = '了解'
          this.isShowAlertPopup = true
          return false
        }
        this.$delete(this.processList[this.subProcessIndex4Delete], this.subProcessItemIndex4Delete)
        // 総排出量算出方法
        this.sum1()
      }, 500)
    },
    deleteSubProcess(subProcessIndex, subProcessItemIndex) {
      // disabled status
      if (this.statusDisabled) {
        return
      }
      this.popupMessage = this.$t('messages.wibo2dtri')
      this.buttonName = this.$t('messages.deletButton')
      this.cancelName = this.$t('messages.buttonCancel')
      this.isShowComfirmPopup = true
      this.subProcessIndex4Delete = subProcessIndex
      this.subProcessItemIndex4Delete = subProcessItemIndex
      this.buttonType = 'deleteSubItem'
    },
    // ページリターン
    toBack() {
      this.$router.go(-1)
    },

    getAddProcessFromPopup(addProcess) {
      for (const index in addProcess) {
        if (addProcess[index].processcode === 'Z') {
          const result = this.nameList.filter(item => {
            if (item.ideacode.indexOf('Z') > -1) {
              return true
            }
          })
          addProcess[index].ideacode = result[0].ideacode
          addProcess[index].name = result[0].name
          addProcess[index].unit = result[0].unit
          addProcess[index].coefficient = result[0].coefficient
          addProcess[index].ideaVersion = result[0].version || ''
        }
        this.$set(this.processList, addProcess[index].processcode, [addProcess[index]])
      }
    },
    // 原単位の選択方法 
    handleSelect(item, subProcessIndex, subProcessItemIndex) {
      this.processList[subProcessIndex][subProcessItemIndex].unit = ''
      this.processList[subProcessIndex][subProcessItemIndex].name = item.name
      this.processList[subProcessIndex][subProcessItemIndex].displayname = item.displayname
      this.processList[subProcessIndex][subProcessItemIndex].ideacode = item.ideacode
      this.processList[subProcessIndex][subProcessItemIndex].unit = item.unit
      this.processList[subProcessIndex][subProcessItemIndex].coefficient = item.coefficient
      this.processList[subProcessIndex][subProcessItemIndex].ideaVersion = item.version || ''
      this.processList[subProcessIndex][subProcessItemIndex].ideageneration = item.generation
      this.processList[subProcessIndex][subProcessItemIndex].unitgeneration = 0
      // 単位換算方法
      this.unitTransfer(item.unit, item.ideacode, subProcessIndex, subProcessItemIndex, this.processList[subProcessIndex][subProcessItemIndex].ideaVersion)
      if (item.budomari && item.budomari !== '') {
        this.processList[subProcessIndex][subProcessItemIndex].emissionc = this.processList[subProcessIndex][subProcessItemIndex].quantity * (this.processList[subProcessIndex][subProcessItemIndex].coefficient / (parseFloat(this.processList[subProcessIndex][subProcessItemIndex].budomari) / 100))
      } else {
        this.processList[subProcessIndex][subProcessItemIndex].emissionc = this.processList[subProcessIndex][subProcessItemIndex].quantity * (this.processList[subProcessIndex][subProcessItemIndex].coefficient)
      }
      this.processList[subProcessIndex][subProcessItemIndex].transferUnitPercent = 1
      this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'isDeleted', false)
      this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'unitDeleted', false)
      this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'generationBoolean', false)
      this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'unitgenerationBoolean', false)
      this.sum1()
    },
    focusChange(subProcessIndex, subProcessItemIndex) {
      this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'isDeleted', false)
    },
    // 原単位選択change
    inputChange(displayname, subProcessIndex, subProcessItemIndex) {
      this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'isDeleted', false)
      this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'unitDeleted', false)
      this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'generationBoolean', false)
      this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'unitgenerationBoolean', false)
      this.processList[subProcessIndex][subProcessItemIndex].unit = ''
      const array = this.nameList.filter(item => {
        return item.displayname === displayname
      })
      if (array.length > 0) {
        this.processList[subProcessIndex][subProcessItemIndex].name = array[0].name
        this.processList[subProcessIndex][subProcessItemIndex].displayname = array[0].displayname
        this.processList[subProcessIndex][subProcessItemIndex].ideacode = array[0].ideacode
        this.processList[subProcessIndex][subProcessItemIndex].unit = array[0].unit
        this.processList[subProcessIndex][subProcessItemIndex].coefficient = array[0].coefficient
        this.processList[subProcessIndex][subProcessItemIndex].ideaVersion = array[0].version || ''
        this.processList[subProcessIndex][subProcessItemIndex].ideageneration = array[0].generation
        this.processList[subProcessIndex][subProcessItemIndex].unitgeneration = 0
        // 単位換算方法
        this.unitTransfer(array[0].unit, array[0].ideacode, subProcessIndex, subProcessItemIndex, this.processList[subProcessIndex][subProcessItemIndex].ideaVersion)
        if (array[0].budomari && array[0].budomari !== '') {
          this.processList[subProcessIndex][subProcessItemIndex].emissionc =this.processList[subProcessIndex][subProcessItemIndex].quantity * (this.processList[subProcessIndex][subProcessItemIndex].coefficient / (parseFloat(this.processList[subProcessIndex][subProcessItemIndex].budomari) / 100))
        } else {
          this.processList[subProcessIndex][subProcessItemIndex].emissionc = this.processList[subProcessIndex][subProcessItemIndex].quantity * (this.processList[subProcessIndex][subProcessItemIndex].coefficient)
        }
        this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'transferUnitPercent', 1)
        // 排出総量計算
        this.sum1()
      } else {
        this.processList[subProcessIndex][subProcessItemIndex].name = ''
        this.processList[subProcessIndex][subProcessItemIndex].displayname = ''
        this.processList[subProcessIndex][subProcessItemIndex].ideacode = ''
        this.processList[subProcessIndex][subProcessItemIndex].unit = ''
        this.processList[subProcessIndex][subProcessItemIndex].coefficient = 0
        this.processList[subProcessIndex][subProcessItemIndex].ideaVersion = ''
      }
    },
    // 行の排出量計算(親子関係なし)
    test1(subProcessItem, subProcessItemIndex) {
      if (subProcessItem.budomari && subProcessItem.budomari !== '') {
        subProcessItem.emissionc = subProcessItem.quantity * (subProcessItem.coefficient / (parseFloat(subProcessItem.budomari === '0' ? '' : subProcessItem.budomari) / 100) * (isNaN(Number(subProcessItem.unit.split('/unitlabel/')[0])) ? 1 : Number(subProcessItem.unit.split('/unitlabel/')[0])))
      } else {
        subProcessItem.emissionc = subProcessItem.quantity * (subProcessItem.coefficient)* (isNaN(Number(subProcessItem.unit.split('/unitlabel/')[0])) ? 1 : Number(subProcessItem.unit.split('/unitlabel/')[0]))
      }
      // 排出総量計算(親子関係なし)
      this.sum1()
    },
    // 行の排出量計算(親子関係あり)
    test2(treeDataItem) {
      if (treeDataItem.budomari && treeDataItem.budomari !== '') {
        if (treeDataItem.ideacode) {
          treeDataItem.emissionc = treeDataItem.quantity * (treeDataItem.coefficient / (parseFloat(treeDataItem.budomari === 0 ? '' : treeDataItem.budomari) / 100) * (isNaN(Number(treeDataItem.unit.split('/unitlabel/')[0])) ? 1 : Number(treeDataItem.unit.split('/unitlabel/')[0])))
        } else {
          treeDataItem.emissionc = treeDataItem.quantity
        }
      } else {
        if (treeDataItem.ideacode) {
          treeDataItem.emissionc = treeDataItem.quantity * (treeDataItem.coefficient) * (isNaN(Number(treeDataItem.unit.split('/unitlabel/')[0])) ? 1 : Number(treeDataItem.unit.split('/unitlabel/')[0]))
        } else {
          treeDataItem.emissionc = treeDataItem.quantity
        }
      }
      this.treeDataList = this.treeDataForm.treeDataItem
      // 排出総量計算(親子関係あり)
      this.sum2()
    },

    sum1() {
      this.sumEmissionc1 = 0
       for (const key in this.processList) {
        for (const item in this.processList[key]) {
          if (this.processList[key][item].emissionc && this.processList[key][item].emissionc !== '' && this.processList[key][item].processcode !== 'D') {
            let foundFlg = 0
              for (let i = 0; i < this.allDataList.length; i++) {
                if(this.allDataList[i].name === this.processList[key][item].displayname || this.allDataList[i].displayname === this.processList[key][item].displayname){
                  foundFlg = 1
                  let oriFlag = this.buttonFlag - 1
                  //オリジナルプロセス，Dプロセスは計算に関与せず、Dプロセスを排除する
                  if (oriFlag === 1) {
                    if (this.processList[key][item].genProcess != 'D') {
                      if(this.processList[key][item].budomari && this.processList[key][item].transferUnitPercent){
                        this.sumEmissionc1 += this.processList[key][item].quantity * this.allDataList[i].coefficient / this.processList[key][item].budomari * 100 * this.processList[key][item].transferUnitPercent
                      }else if (this.processList[key][item].transferUnitPercent){
                        this.sumEmissionc1 += this.processList[key][item].quantity * this.allDataList[i].coefficient * this.processList[key][item].transferUnitPercent
                      } else {
                        this.sumEmissionc1 += this.processList[key][item].emissionc
                      }
                    }
                  } else {
                    //標準プロセス，Dプロセスは計算に関与せず、Dプロセスを排除する
                    if (this.processList[key][item].processcode != 'D') {
                      if(this.processList[key][item].budomari && this.processList[key][item].transferUnitPercent){
                        this.sumEmissionc1 += this.processList[key][item].quantity * this.allDataList[i].coefficient / this.processList[key][item].budomari * 100 * this.processList[key][item].transferUnitPercent
                      }else if (this.processList[key][item].transferUnitPercent){
                        this.sumEmissionc1 += this.processList[key][item].quantity * this.allDataList[i].coefficient * this.processList[key][item].transferUnitPercent
                      } else {
                        this.sumEmissionc1 += this.processList[key][item].emissionc
                      }
                    }
                  }
                }
              }
              if (foundFlg == 0) {
                this.sumEmissionc1 += Number(this.processList[key][item].emissionc)
              }
            }
          }
        }
        // 排出総量科学計数法のフォーマット
        this.sumProcessEmission = this.toScientificNum(this.sumEmissionc1)
        if (this.csvSumFlag) {
          this.pageLoading.close()
          this.csvSumFlag = false
        }
      },
      // 原単位名フィルタ方法
    getIdeaData(arr, value) {
      return arr.find(obj => obj.name === value);  // <-- here
    },
    sum2(){
      this.sumEmissionc2 = 0
      for (const key in this.treeDataList) {
        if (this.treeDataList[key].emissionc && this.treeDataList[key].emissionc !== '' && this.treeDataList[key].processcode != 'D') {
          let foundFlg = 0
          let findResult = this.getIdeaData(this.allDataList, this.treeDataList[key].name)
          if (findResult) {

          // }
          // for (let i = 0; i < this.allDataList.length; i++) {
          //   if(this.allDataList[i].name === this.treeDataList[key].name){
              foundFlg = 1
              let oriFlag = this.buttonFlag - 1
              //オリジナルプロセス，Dプロセスは計算に関与せず、Dプロセスを排除する
              if (oriFlag === 1) {
                if (this.treeDataList[key].genProcess != 'D') {
                  if(this.treeDataList[key].budomari && this.treeDataList[key].transferUnitPercent){
                    this.sumEmissionc2 += this.treeDataList[key].quantity * findResult.coefficient / this.treeDataList[key].budomari * 100 * this.treeDataList[key].transferUnitPercent
                  }else if (this.treeDataList[key].transferUnitPercent){
                    this.sumEmissionc2 += this.treeDataList[key].quantity * findResult.coefficient * this.treeDataList[key].transferUnitPercent
                  } else {
                    this.sumEmissionc2 += this.treeDataList[key].emissionc
                  }
                }
              } else {
                 //標準プロセス，Dプロセスは計算に関与せず、Dプロセスを排除する
                if (this.treeDataList[key].processcode != 'D') {
                  if(this.treeDataList[key].budomari && this.treeDataList[key].transferUnitPercent){
                    this.sumEmissionc2 += this.treeDataList[key].quantity * findResult.coefficient / this.treeDataList[key].budomari * 100 * this.treeDataList[key].transferUnitPercent
                  }else if (this.treeDataList[key].transferUnitPercent){
                    this.sumEmissionc2 += this.treeDataList[key].quantity * findResult.coefficient * this.treeDataList[key].transferUnitPercent
                  } else {
                    this.sumEmissionc2 += this.treeDataList[key].emissionc
                  }
                }
              }
            }
          // }
          if (foundFlg == 0) {
            this.sumEmissionc2 += Number(this.treeDataList[key].emissionc)
          }
        }
      }
      // 排出総量科学計数法のフォーマット
      this.sumTreeEmission = this.toScientificNum(this.sumEmissionc2)
    },
    toScientificNum(num) {
      num = Number(num)
      if (!num) {
        num = 0
      }
      var eformat = num.toExponential()
      var tmpArray = eformat.match(/(.?\d\.?\d*)e(.?\d*)/)
      if (tmpArray[1]) {
        tmpArray[1] = Math.round(times(tmpArray[1],100))/100
        tmpArray[1] = parseFloat(tmpArray[1]).toFixed(2)
      }
      tmpArray[2] = tmpArray[2].replace('+', '')
      var ScientificNum
      if (tmpArray[2] !== '0') {
        ScientificNum = [tmpArray[1], 'x10', tmpArray[2]]
      } else {
        ScientificNum = [tmpArray[1]]
      }
      return ScientificNum
    },
    // 日付書式設定方法
    formatDate(dateTime) {
      const date = new Date(dateTime)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hour = date.getHours()
      let minute = date.getMinutes()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      hour = hour < 10 ? '0' + hour : hour
      minute = minute < 10 ? '0' + minute : minute
      const formatDate = year + '/' + month + '/' + day + ' ' + hour + ':' + minute
      return formatDate
    },

    trafficCalculator(itemname, weight ,unit, unitList, displayname, index) {
      if (this.statusDisabled) {
        return
      }
      if (unit.includes('/unitlabel/')) {
        unit = unit.split('/unitlabel/')[1]
      }
      if(weight !== '' && displayname !== '') {
        if (this.parentFlag === 2) {
          this.trafficItemname = itemname
          this.parentName = this.treeDataList[index].parentnode
        } else {
          this.trafficItemname = '[上流輸送]' + itemname
        }
        this.trafficItemWeight = Number(weight)
        this.trafficUnitList = unitList
        this.trafficUnit = unit
        this.treeIndex = index
        this.buttonName = this.$t('messages.ccr')
        this.backName = this.$t('messages.backName')
        this.transportMeans = this.$t('messages.transportMeans')
        this.transportDistance = this.$t('messages.transportDistance')
        this.buttonType = 'trafficCalculator'
        if(this.trafficUnitList.some(item => {return item.tounit === 'kg'})) {
          this.isShowTrafficPopup = true
          this.isShowNoTrafficPopup = false
        } else {
          this.isShowTrafficPopup = false
          this.isShowNoTrafficPopup = true
        }
      }
    },
    utilityCalculator(index) {
      this.buttonName = $t('messages.ccr')
      this.isShowUtilityPopup = true
      this.buttonType = 'utilityCalculator'
      this.utilityInputIndex = index
    },
    // 親子関係なし算定方法
    save() {
      // this.loading = true
      this.processList4Back = []
      // すべての製品詳細データをループ
      for (const key in this.processList) {
        for (const item in this.processList[key]) {
          if(this.processList[key][item].transferUnit){
            this.processList[key][item].unit = this.processList[key][item].transferUnit
          }
          // 最新の排出量値の算出
           if (this.processList[key][item].itemname && (this.processList[key][item].name || this.processList[key][item].processcode === 'Z') && this.processList[key][item].quantity && this.processList[key][item].quantity !== '-' && parseFloat(this.processList[key][item].quantity) !== 0 && (!this.processList[key][item].budomari || (this.processList[key][item].budomari && parseFloat(this.processList[key][item].budomari) > 0 && parseFloat(this.processList[key][item].budomari) < 100)) && this.processList[key][item].unit && (this.nameArray.includes(this.processList[key][item].name) || this.processList[key][item].processcode === 'Z')) {
            if (this.processList[key][item].budomari && this.processList[key][item].budomari !== '') {
              this.processList[key][item].emissionc = this.processList[key][item].quantity * (this.processList[key][item].coefficient / (parseFloat(this.processList[key][item].budomari) / 100)).toFixed(4) * (isNaN(Number(this.processList[key][item].unit.split('/unitlabel/')[0])) ? 1 : Number(this.processList[key][item].unit.split('/unitlabel/')[0]))
            } else {
              this.processList[key][item].emissionc = this.processList[key][item].quantity * this.processList[key][item].coefficient * (isNaN(Number(this.processList[key][item].unit.split('/unitlabel/')[0])) ? 1 : Number(this.processList[key][item].unit.split('/unitlabel/')[0]))
            }
          }
          let filterList = []
          if (this.processList[key][item].ideaVersion) {
            filterList = this.nameList.filter(nameItem => nameItem.ideacode === this.processList[key][item].ideacode && nameItem.version == this.processList[key][item].ideaVersion)
          } else {
            filterList = this.nameList.filter(nameItem => nameItem.ideacode === this.processList[key][item].ideacode)
          }
          // 世代の最新化
          if (filterList.length > 0) {
            this.processList[key][item].ideageneration = filterList[0].generation
          } else {
            this.processList[key][item].ideageneration = 0
          }
        }
      }
      this.judgeProcessListBudomari()
      const headObj = { kigyouCode: this.kigyouCode, dataId: this.dataId, seihinCode: this.seihinCode, seihinName: this.seihinNameObjName.seihinName, seihinversion: this.seihinNameObjVersion.seihinversion, simulateNote: this.simulateNote, seihinUnit: [this.seihinUnit4Back, this.choseSeihinUnit], upduser: this.upduser, oriprocessFlg: this.buttonFlag === 2, username: this.username }
      // merge two array
      const inputData = [headObj, { 'old': this.processListBackup, 'new': this.processList }]
      // send data to url
      if (inputData.length > 0) {
        // 呼び出し製品保存Javaインタフェース
        saveAPI(inputData)
          .then(res => {
            if (res.code === 200) {
              // アップロードflagがtrueの場合、アップロード形式でユーザーが作成した製品データを計算します
              if (this.uploadCreateFlag) {
                this.uploadCountSumAPI(this.upduser, this.uploadCreateFlag)
              }
              // checklistの結果を保存
              saveCheckListAPI(this.seihinCode, this.seihinNameObjVersion.seihinversion, this.checklistversion.replace('/', ''), this.upduser, this.checkFlagList)
                .then(res => {
                  if (res.code === 200) {
                    this.loading = false
                    this.nameList = []
                    this.nameArray = []
                    this.allDataList = []
                    this.filerIdeaData = []
                    this.newOriginalNameList = []
                    this.pageLoading.close()
                    this.$router.push(
                      { name: '/browser/referenceIndex',
                        params: {
                          seihinCode: this.seihinCode,
                          version: this.seihinNameObjVersion.seihinversion
                        }
                      })
                  } else {
                    this.pageLoading.close()
                    this.rejectMsg()
                  }
                })
            } else {
              this.pageLoading.close()
              this.rejectMsg()
            }
          })
          .catch(error => {
            this.pageLoading.close()
            alert('追加画面（save）のエラーが発生した。ログイン画面へ遷移する。')
            return this.$router.push({ path: '/' })
          })
      }
    },
    // 親子関係なしクリックによるタイマ呼び出しの計算方法
    async doSave(processForm, seihinNameForm) {
      await this.showPageLoading()
      setTimeout(() => {
        // 製品保存データ検証方法
        this.saveStandCheck(processForm)
      }, 500);
    },
    saveStandCheck(processForm) {
      // フォームに異なるフィールドがあることを確認します subProcessItem
      for (const key in this.$refs[processForm]) {
        this.$refs[processForm][key].validate((valid) => {
          if (!valid) {
            return false
          }
        })
      }
      if (!this.seihinNameObjName.seihinName) {
        return
      }

      // for (const key in this.processList) {
      //   this.processList[key] = this.processList[key].filter((item, index) => (item.itemname !== '' || item.name !== '' || item.quantity !== '' || item.unit !== '') || index === 0)
      // }
      let returnFlag = false
      // リサイクル製品のすべての詳細データ
      for (const key in this.processList) {
        for (const item in this.processList[key]) {
          let suc = false
          // suc 原単位が存在する場合はfalse、そうでない場合はtrue
          for (let l = 0; l < this.filerIdeaData.length; l++) {
             if (this.filerIdeaData[l].name === this.processList[key][item].name) {
                suc = true
            } else {
              if (this.filerIdeaData[l].name === this.processList[key][item].name) {
                suc = true
              }
            }
          }
          //return
          // 項目名を入力してください
          if (this.processList[key][item].itemname == null || this.processList[key][item].itemname == '') {
            returnFlag = true
            // 項目名を入力してください
            this.$alert(this.$t('messages.peain'), this.$t('messages.confirm'), {
              confirmButtonText: this.$t('messages.yes'),
              type: 'warning',
              center: true,
              onClose: () => {
                this.pageLoading.close()
              }
            }).then(() => {
              return
            }) 
          } else if (this.processList[key][item].name == null || this.processList[key][item].name == '') {
            // 原単位名がNULLの場合にプロンプト情報を表示する
            returnFlag = true
            this.$alert(this.$t('messages.peefn'), this.$t('messages.confirm'), {
              confirmButtonText: this.$t('messages.yes'),
              type: 'warning',
              center: true,
              onClose: () => {
                this.pageLoading.close()
              }
            }).then(() => {
              return
            })
          } else if (this.processList[key][item].quantity == null || this.processList[key][item].quantity == '') {
            // 活動量を入力してください
            returnFlag = true
            // 活動量を入力してください
            this.$alert(this.$t('messages.peaam'), this.$t('messages.confirm'), {
              confirmButtonText: this.$t('messages.yes'),
              type: 'warning',
              center: true,
              onClose: () => {
                this.pageLoading.close()
              }
            }).then(() => {
              return
            })
          } else if (this.processList[key][item].quantity === '-') {
             // 活動量半角数値を入力してください
            returnFlag = true
            this.$alert('半角数値を入力してください', this.$t('messages.confirm'), {
              confirmButtonText: this.$t('messages.yes'),
              type: 'warning',
              center: true,
              onClose: () => {
                this.pageLoading.close()
              }
            }).then(() => {
              return
            })
          } else if (parseFloat(this.processList[key][item].quantity) == 0) {
            returnFlag = true
            // 活動量0を入力しないでください
            this.$alert('0を入力しないでください', this.$t('messages.confirm'), {
              confirmButtonText: this.$t('messages.yes'),
              type: 'warning',
              center: true,
              onClose: () => {
                this.pageLoading.close()
              }
            }).then(() => {
              return
            })
          } else if (this.processList[key][item].budomari && this.processList[key][item].budomari != '' && (parseFloat(this.processList[key][item].budomari) <= 0 || parseFloat(this.processList[key][item].budomari) >= 100)) {
            returnFlag = true
            // 0 < 歩留まり率 < 100を入力しないでください
            this.$alert('正しい歩留まり率を入力してください', this.$t('messages.confirm'), {
              confirmButtonText: this.$t('messages.yes'),
              type: 'warning',
              center: true,
              onClose: () => {
                this.pageLoading.close()
              }
            }).then(() => {
              return
            })
          } else if (!suc) {
            returnFlag = true
            this.processList[key][item].isDeleted = false
            // 正しい原単位を入力してください
            this.$alert('正しい原単位を入力してください', this.$t('messages.confirm'), {
              confirmButtonText: this.$t('messages.yes'),
              type: 'warning',
              center: true,
              onClose: () => {
                this.pageLoading.close()
              }
            }).then(() => {
              return
            })
          } else if (this.processList[key][item].unitDeleted) {
            // 正しい原単位の単位を選択してください
            returnFlag = true
            this.$alert('正しい原単位の単位を選択してください', this.$t('messages.confirm'), {
              confirmButtonText: this.$t('messages.yes'),
              type: 'warning',
              center: true,
              onClose: () => {
                this.pageLoading.close()
              }
            }).then(() => {
              return
            })
          }
        }
      }
      // parentFn（）メソッド内のupdateブランチを呼び出す
      if (returnFlag !== true) {
        this.popupMessage = this.$t('messages.wyl2utd')
        this.buttonName = this.$t('messages.yes')
        this.cancelName = this.$t('messages.buttonCancel')
        this.centerDialogVisible = true
        this.buttonType = 'update'
      }
    },
    // 親子関係あり 算定 時呼び出し
    async doSaveTree() {
      await this.showPageLoading()
      setTimeout(() => {
        // 製品保存データ検証方法（親子関係あり）
        this.saveTreeCheck()
      }, 500);
    },
    handleOpen() {
       this.pageLoading.close()
    },
    saveTreeCheck() {
      this.treeDataList = this.treeDataForm.treeDataItem
      // フォームに異なるフィールドがあることを確認します
      this.$refs['treeDataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
      })
      if (!this.seihinNameObjName.seihinName) {
        return
      }
      let returnFlag = false
      for (let i = 0; i < this.treeDataList.length; i++) {
        if (this.parentFlag == 2) {
          this.$refs.treeDataForm.validateField(`treeDataItem.${i}.parentnode`, (parentnodeError) => {
            if (parentnodeError) {
              if (this.alterType === 1) {
                returnFlag = true
                this.$alert(this.$t('messages.addCheckParNo'), this.$t('messages.confirm'), {
                  confirmButtonText: this.$t('messages.yes'),
                  type: 'warning',
                  center: true,
                  onClose: () => {
                    this.pageLoading.close()
                  }
                }).then(() => {
                  return
                })
              }
              else if (this.alterType === 3) {
                returnFlag = true
                // 原単位名を入力してください
                this.$alert(this.$t('messages.addCheckParSame'), this.$t('messages.confirm'), {
                  confirmButtonText: this.$t('messages.yes'),
                  type: 'warning',
                  center: true,
                  onClose: () => {
                    this.pageLoading.close()
                  }
                }).then(() => {
                  return
                })
              }
            }
          })
        }
        if (this.treeDataList[i].itemname && (this.treeDataList[i].name || this.treeDataList[i].processcode === 'Z') && this.treeDataList[i].quantity && this.treeDataList[i].quantity !== '-' && parseFloat(this.treeDataList[i].quantity) !== 0 && (!this.treeDataList[i].budomari || (this.treeDataList[i].budomari && parseFloat(this.treeDataList[i].budomari) > 0 && parseFloat(this.treeDataList[i].budomari) < 100)) && this.treeDataList[i].unit) {
          if (!this.treeDataList[i].parentnode) {
            this.treeDataList[i].parentnode = ''
          }
        }

        let filterIdeaCheck = {}
        if (this.treeDataList[i].ideaVersion) {
         filterIdeaCheck = this.filerIdeaData.find(ideaItem => ideaItem.ideacode == this.treeDataList[i].ideacode && ideaItem.version == this.treeDataList[i].ideaVersion)
        } else {
         filterIdeaCheck = this.filerIdeaData.find(ideaItem => ideaItem.ideacode == this.treeDataList[i].ideacode)
        }
        if (!filterIdeaCheck) {
          returnFlag = true
          this.treeDataList[i].isDeleted = false
          // 正しい原単位を入力してください
          this.$alert('正しい原単位を入力してください', this.$t('messages.confirm'), {
            confirmButtonText: this.$t('messages.yes'),
            type: 'warning',
            center: true,
            onClose: () => {
              this.pageLoading.close()
            }
          }).then(() => {
            return
          })
        }
      }
      const processCheckList = this.treeDataList.filter(processItem => !processItem.processcode || processItem.processcode == '')
      if (processCheckList.length > 0) {
        returnFlag = true
        this.$alert('プロセスを選択してください', this.$t('messages.confirm'), {
          confirmButtonText: this.$t('messages.yes'),
          type: 'warning',
          center: true,
          onClose: () => {
            this.pageLoading.close()
          }
        }).then(() => {
          return
        })
      }
      const itemnameCheckList = this.treeDataList.filter(itemnameItem => itemnameItem.itemname == null || itemnameItem.itemname == '')
      if (itemnameCheckList.length > 0) {
        returnFlag = true
        // 項目名を入力してください
        this.$alert(this.$t('messages.peain'), this.$t('messages.confirm'), {
          confirmButtonText: this.$t('messages.yes'),
          type: 'warning',
          center: true,
          onClose: () => {
            this.pageLoading.close()
          }
        }).then(() => {
          return
        })
      }
      const nameCheckList = this.treeDataList.filter(nameItem => nameItem.name == null || nameItem.name == '')
      if (nameCheckList.length > 0) {
        returnFlag = true
        // 原単位名チェック
        this.$alert(this.$t('messages.peefn'), this.$t('messages.confirm'), {
          confirmButtonText: this.$t('messages.yes'),
          type: 'warning',
          center: true,
          onClose: () => {
            this.pageLoading.close()
          }
        }).then(() => {
          return
        })
      }
      const quantityCheckList = this.treeDataList.filter(quantityItem => quantityItem.quantity == null || quantityItem.quantity == '')
      if (quantityCheckList.length > 0) {
        // 活動量 null '' チェック
        returnFlag = true
        this.$alert(this.$t('messages.peaam'), this.$t('messages.confirm'), {
          confirmButtonText: this.$t('messages.yes'),
          type: 'warning',
          center: true,
          onClose: () => {
            this.pageLoading.close()
          }
        }).then(() => {
          return
        })
      }
      const quantityCheckList1 = this.treeDataList.filter(quantityItem => quantityItem.quantity === '-')
      if (quantityCheckList1.length > 0) {
        // 活動量 半角数値 チェック
        returnFlag = true
        this.$alert('半角数値を入力してください', this.$t('messages.confirm'), {
          confirmButtonText: this.$t('messages.yes'),
          type: 'warning',
          center: true,
          onClose: () => {
            this.pageLoading.close()
          }
        }).then(() => {
          return
        })
      }
      const quantityCheckList2 = this.treeDataList.filter(quantityItem => parseFloat(quantityItem.quantity) === 0)
      if (quantityCheckList2.length > 0) {
        returnFlag = true
        // // 活動量 0 チェック
        this.$alert('0を入力しないでください', this.$t('messages.confirm'), {
          confirmButtonText: this.$t('messages.yes'),
          type: 'warning',
          center: true,
          onClose: () => {
            this.pageLoading.close()
          }
        }).then(() => {
          return
        })
      }
      const budomariCheckList = this.treeDataList.filter(budomariItem => budomariItem.budomari && budomariItem.budomari != '' && (parseFloat(budomariItem.budomari) <= 0 || parseFloat(budomariItem.budomari) >= 100))
      if (budomariCheckList.length > 0) {
        // 0 < 歩留まり率 < 100 チェック
        returnFlag = true
        this.$alert('正しい歩留まり率を入力してください', this.$t('messages.confirm'), {
          confirmButtonText: this.$t('messages.yes'),
          type: 'warning',
          center: true,
          onClose: () => {
            this.pageLoading.close()
          }
        }).then(() => {
          return
        })
      }
      const unitDeletedCheckList = this.treeDataList.filter(unitDeletedItem => unitDeletedItem.unitDeleted)
      if (unitDeletedCheckList.length > 0) {
        returnFlag = true
        this.$alert('正しい原単位の単位を選択してください', this.$t('messages.confirm'), {
          confirmButtonText: this.$t('messages.yes'),
          type: 'warning',
          center: true,
          onClose: () => {
            this.pageLoading.close()
          }
        }).then(() => {
          return
        })
      }
      if (!returnFlag) {
        this.popupMessage = this.$t('messages.wyl2utd')
        this.buttonName = this.$t('messages.yes')
        this.cancelName = this.$t('messages.buttonCancel')
        this.centerDialogVisible = true
        this.buttonType = 'treeupdate'
        // this.pageLoading.close()
      }
    },
    // 親子関係あり 製品データ保存方法
    savetree() {
      for (const key in this.treeDataList) {
        if(this.treeDataList[key].transferUnit){
          this.treeDataList[key].unit = this.treeDataList[key].transferUnit
        }
        if (this.noTreeFromCsvOrApi) {
          delete this.treeDataList[key].parentnode
        }
        if (this.treeDataList[key].budomari && this.treeDataList[key].budomari !== '') {
          this.treeDataList[key].emissionc = this.treeDataList[key].quantity * (this.treeDataList[key].coefficient / (parseFloat(this.treeDataList[key].budomari) / 100)).toFixed(4) * (isNaN(Number(this.treeDataList[key].transferUnitPercent)) ? 1 : Number(this.treeDataList[key].transferUnitPercent))
        } else {
          this.treeDataList[key].emissionc = this.treeDataList[key].quantity * this.treeDataList[key].coefficient * (isNaN(Number(this.treeDataList[key].transferUnitPercent)) ? 1 : Number(this.treeDataList[key].transferUnitPercent))
        }
        let filterList = []
        if (this.treeDataList[key].ideaVersion) {
          filterList = this.nameList.filter(nameItem => nameItem.ideacode === this.treeDataList[key].ideacode && nameItem.version == this.treeDataList[key].ideaVersion)
        } else {
          filterList = this.nameList.filter(nameItem => nameItem.ideacode === this.treeDataList[key].ideacode)
        }
        if (filterList.length > 0) {
          this.treeDataList[key].ideageneration = filterList[0].generation
        } else {
          this.treeDataList[key].ideageneration = 0
        }
      }
      const reqData = {}
      reqData.kigyouCode = this.kigyouCode
      reqData.seihinCode = this.seihinCode
      reqData.seihinname = this.seihinNameObjName.seihinName
      reqData.seihinVersion = this.seihinNameObjVersion.seihinversion
      reqData.seihinunit = this.choseSeihinUnit
      reqData.dataId = this.dataId
      reqData.upduser = this.upduser
      reqData.updusername = this.username
      reqData.simulateNote = this.simulateNote
      reqData.oriprocessFlg = this.buttonFlag - 1
      reqData.dataList = this.treeDataList
      // データをデータベースに保存するためにJavaを呼び出す
      treeDateSave(reqData)
        .then(res => {
          if (res.code === 200) {
            if (this.uploadCreateFlag) {
              this.uploadCountSumAPI(this.upduser, this.uploadCreateFlag)
            }
            // checklistの結果をデータベースに保存
            saveCheckListAPI(this.seihinCode, this.seihinNameObjVersion.seihinversion, this.checklistversion.replace('/', ''), this.upduser, this.checkFlagList)
              .then(res => {
                if (res.code === 200) {
                  this.loading = false
                  this.pageLoading.close()
                  this.nameList = []
                  this.nameArray = []
                  this.allDataList = []
                  this.filerIdeaData = []
                  this.newOriginalNameList = []
                  this.$router.push(
                    { name: '/browser/referenceIndex',
                      params: {
                        seihinCode: this.seihinCode,
                        version: this.seihinNameObjVersion.seihinversion
                      }
                    })
                } else {
                  this.pageLoading.close()
                  this.rejectMsg()
                }
              })
          } else {
            this.pageLoading.close()
            alert('ツリー製品を保存し場合で、エラーがあります')
            this.rejectMsg()
          }
        }).catch((error) => {
          this.pageLoading.close()
          console.log('error', error)
        })
    },
    // 排出量算出方法
    judgeProcessListBudomari() {
      for (const key in this.processList) {
        for (const item in this.processList[key]) {
          if (this.processList[key][item].itemname && (this.processList[key][item].name || this.processList[key][item].processcode === 'Z') && this.processList[key][item].quantity && parseFloat(this.processList[key][item].quantity) !== 0 && this.processList[key][item].unit && (this.nameArray.includes(this.processList[key][item].name) || this.processList[key][item].processcode === 'Z')) {
            if (this.processList[key][item].budomari && this.processList[key][item].budomari !== '') {
              this.processList[key][item].emissionc = this.processList[key][item].quantity * (this.processList[key][item].coefficient / (parseFloat(this.processList[key][item].budomari) / 100)).toFixed(4) * (isNaN(Number(this.processList[key][item].transferUnitPercent)) ? 1 : Number(this.processList[key][item].transferUnitPercent))
            } else {
              this.processList[key][item].emissionc = this.processList[key][item].quantity * this.processList[key][item].coefficient * (isNaN(Number(this.processList[key][item].transferUnitPercent)) ? 1 : Number(this.processList[key][item].transferUnitPercent))
            }
          } else {
            return
          }
        }
      }
    },
    choseSeihinName(val) {
      const chosedSeihin = this.filterSeihinNameList.filter(item => {
        if (item.seihinname === val) {
          return true
        }
      })
      this.seihinCode = chosedSeihin[0].seihincode
      this.choseSeihinUnit = chosedSeihin[0].unit
    },
    // checklist表示データの取得
    getCheckListData(_centerDialogVisible) {
      if (this.checkList.length === 0) {
        getCheckListAPI(this.seihinCode, this.seihinNameObjVersion.seihinversion, this.language)
          .then(res => {
            if (res.code === 200) {
              this.checkList = res.data[0].checkListData.filter(item => {
                return item.checkitem !== ''
              })
              this.checklistversion = res.data[0].infoData.checklistversion
              this.centerDialogVisible = _centerDialogVisible
            } else {
              this.rejectMsg()
            }
          }).catch((error) => {
            console.log('error', error)
          })
      } else {
        this.centerDialogVisible = _centerDialogVisible
      }
    },
    // copy形式入力取得製品の詳細データ
    getDetailData(kigyouCode, seihinVersion, seihinCode, oriprocessFlg) {
      if (this.parentFlag === 1) {
        getCopyAPI(kigyouCode, seihinVersion, seihinCode, oriprocessFlg)
          .then(res => {
            if (res.code === 200) {
              for (const key in res.data) {
                if (Object.hasOwnProperty.call(res.data, key)) {
                  if (res.data[key].length > 0) {
                    res.data[key].forEach(element => {
                      let filterList = []
                      if (element.ideaVersion) {
                        filterList = this.nameList.filter(nameItem => nameItem.ideacode === element.ideacode && nameItem.version == element.ideaVersion)
                      } else {
                        filterList = this.nameList.filter(nameItem => nameItem.ideacode === element.ideacode)
                      }
                      element.isDeleted = false
                      element.quantity = this.transferToNumber(element.quantity)
                      if (filterList.length > 0) {
                        element.generationBoolean = (filterList[0].generation !== element.ideageneration)
                        element.generation = filterList[0].generation
                      } else {
                        element.isDeleted = true
                        element.generation = 0
                      }
                      element.index = 0
                      if (!element.budomari) {
                        element.budomari = ''
                      }
                      if (element.name && element.name.indexOf('a3b523ac16674572848091098e') > -1) {
                        element.displayname = element.name.replace('a3b523ac16674572848091098e', ' ')
                        element.name = element.name.replace('a3b523ac16674572848091098e', ' ')
                      } else {
                        element.displayname = element.name
                      }
                    })
                  } else {
                    delete res.data[key]
                  }
                }
              }
              if (Object.keys(res.data).length > 0) {
                this.processList = res.data
                this.unitStandGengrate(this.processList, true)
                const keys = Object.keys(this.processList)
                const keyX = parseInt(Math.random() * keys.length)
                this.seihinName = res.data[keys[keyX]][0]['seihinname']
                this.seihinUnit = res.data[keys[keyX]][0]['seihinunit']
                this.seihinUnit4Back = ''
                this.processListBackup = {}
              }
            } else {
              this.rejectMsg()
            }
          })
          .catch(error => {
            console.log('error:', error)
            if (error.response.status != 202 && error.response.status != 401 && error.response.status != 403) {
              this.$alert('ネットワークエラーが発生した。ログイン画面へ遷移する。', 'システムエラー', {
                confirmButtonText: 'はい'
              }).then(() => {
                return this.$router.push({ path: '/' })
              })
            }
          })
          .finally(() => {
            this.loading = false
            this.firstCheckFlag = true
          })
      } else if (this.parentFlag === 2) {
        if (this.buttonFlag == 1) {
          getProcessListAPI()
            .then(res => {
              if (res.code === 200) {
                res.data.forEach(element => {
                  this.processnameList.push({ 'processCode': element.processCode, 'processName': element.processName })
                  element.isDeleted = this.nameList.every(nameItem => nameItem.ideacode !== element.ideacode)
                  element.generationBoolean = this.nameList.every(nameItem => nameItem.generation !== element.ideageneration)
                })
              }
            })
        } else {
          getOrignalProcessList(this.kigyouCode)
            .then(res => {
              if (res.code === 200) {
                res.data.forEach(element => {
                  this.processnameList.push({ 'processCode': element.orignProcessCode, 'processName': element.orignProcessName, 'genProcess': element.genProcess })
                  element.isDeleted = this.nameList.every(nameItem => nameItem.ideacode !== element.ideacode)
                  element.generationBoolean = this.nameList.every(nameItem => nameItem.generation !== element.ideageneration)
                })
              }
            })
        }
        // 製品コピー時に呼び出される製品詳細データの取得方法
        getParentCopyAPI(kigyouCode, seihinVersion, seihinCode, oriprocessFlg)
          .then(res => {
            if (res.code === 200) {
              this.copyFlag = true
              res.data.forEach(element => {
                let filterList = []
                // 最新の原単位情報の取得
                if (element.ideaVersion) {
                  filterList = this.nameList.filter(nameItem => nameItem.ideacode === element.ideacode && nameItem.version == element.ideaVersion)
                } else {
                  filterList = this.nameList.filter(nameItem => nameItem.ideacode === element.ideacode)
                }
                element.isDeleted = false
                element.quantity = this.transferToNumber(element.quantity)
                // 世代変化の有無を判断する
                if (filterList.length > 0) {
                  element.generationBoolean = (filterList[0].generation !== element.ideageneration)
                  element.generation = filterList[0].generation
                } else {
                  element.isDeleted = true
                }
                element.index = 0
                if (!element.budomari) {
                  element.budomari = ''
                }
                // 原単位名のスペル文字を削除（他社、自社 a3b523ac16674572848091098e）
                element.parentnode = element.parentNode
                if (element.name && element.name.indexOf('a3b523ac16674572848091098e') > -1) {
                  element.displayname = element.name.replace('a3b523ac16674572848091098e', ' ')
                  element.name = element.name.replace('a3b523ac16674572848091098e', ' ')
                } else {
                  element.displayname = element.name
                }
              })
              this.treeDataList = res.data
              // 単位世代判定方法
              this.unitTreeGengrate(this.treeDataList, true)
              this.treeDataForm.treeDataItem = this.treeDataList
              this.seihinName = res.data[0]['seihinname']
              this.seihinUnit = res.data[0]['seihinunit']
              this.seihinUnit4Back = ''
            } else {
              this.rejectMsg()
            }
          })
          .catch(error => {
            console.log('error:', error)
            if (error.response.status != 202 && error.response.status != 401 && error.response.status != 403) {
              this.$alert('ネットワークエラーが発生した。ログイン画面へ遷移する。', 'システムエラー', {
                confirmButtonText: this.$t('messages.yes')
              }).then(() => {
                return this.$router.push({ path: '/' })
              })
            }
          })
          .finally(() => {
            this.loading = false
            this.firstCheckFlag = true
          })
      }
    },
    // checklist代入のチェック
    checkListChange(val, index) {
      this.checkList[index].checkflag = val
    },
    checkCsvListChange(val, csvItemIndex) {
      this.csvTableData.forEach(element => {
        if (element.index === this.csvTableData[csvItemIndex].index) {
          element.checkCsvFlag = val
        }
      })
    },
    //親子関係あり 行の削除
    rowToDelete(csvItemIndex){    
      this.$confirm('この操作はデータを完全に削除します。削除してよいですか？', '確認', {
        confirmButtonText: '削除する',
        cancelButtonText: 'キャンセル',
        type: 'warning',
        center: true
      }).then(() => {
        this.csvTableData.splice(csvItemIndex, 1)
        this.csvTableDataBackup.splice(csvItemIndex, 1)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '削除されませんでした'
        })
      })
    },
    // ファイルのアップロード時 行の追加
    rowToAdd() {
      for(let i = 0; i < this.csvTableData.length; i++) {
        if (i === this.csvTableData.length - 1) {
          if (this.csvTableData[i].checkCsvFlag) {
            this.csvTableData.push({
              ...this.csvTableData[i],
              'processCode': '',
              'processName': '',
              'ideaName': '',
              'ideaCode': '',
              'quantity': '',
              'unit': ''
            })
            this.csvTableDataBackup.push({
              ...this.csvTableData[i],
              'processCode': '',
              'processName': '',
              'ideaName': '',
              'ideaCode': '',
              'quantity': '',
              'unit': ''
            })
            i++
          }
        } else {
          if (this.csvTableData[i].checkCsvFlag && this.csvTableData[i]['index'] !== this.csvTableData[i + 1]['index']) {
            this.csvTableData.splice(i + 1, 0, {
              ...this.csvTableData[i],
              'processCode': '',
              'processName': '',
              'ideaName': '',
              'ideaCode': '',
              'quantity': '',
              'unit': ''
            })
            this.csvTableDataBackup.splice(i + 1, 0, {
              ...this.csvTableData[i],
              'processCode': '',
              'processName': '',
              'ideaName': '',
              'ideaCode': '',
              'quantity': '',
              'unit': ''
            })
            i++
          }
        }
      }
      for (let j = 0; j < this.csvTableData.length; j++) {
        this.$set(this.csvTableData[j], 'checkCsvFlag', false)
      }
    },
    closeDialog() {
      const _checkFlagList = this.checkList.map(listObj => {
        if (listObj.checkflag) {
          return 1
        } else {
          return 0
        }
      })
      _checkFlagList.forEach((item, index) => {
        this.checkFlagList[`checkitem${index + 1}`] = item
      })
      this.centerDialogVisible = false
      this.isShowComfirmPopup = true
    },
    // 一時保存方法（親子関係なし）
    saveTemp() {
      this.loading = true
      this.processList4Back = []
      for (const key in this.processList) {
        for (const item in this.processList[key]) {
          if(this.processList[key][item].transferUnit){
            this.processList[key][item].unit = this.processList[key][item].transferUnit
          }
        }
      }
      this.judgeProcessListBudomari()
      const headObj = { kigyouCode: this.kigyouCode, seihinCode: this.seihinCode, seihinName: this.seihinNameObjName.seihinName, seihinversion: this.seihinNameObjVersion.seihinversion, simulateNote: this.simulateNote, seihinUnit: [this.seihinUnit4Back, this.choseSeihinUnit], upduser: this.upduser, editFlag: false, oriprocessFlg: this.buttonFlag === 2, username: this.username }
      // merge two array
      const inputData = [headObj, { 'old': this.processListBackup, 'new': this.processList }]
      // send data to url
      if (inputData.length > 0) {
        // 一時保存方法 Javaインタフェースデータベースへのデータの保存
        saveTempAPI(inputData)
          .then(res => {
            if (res.code === 200) {
              this.$message({
                message: this.$t('messages.tsic'),
                type: 'success'
              })
              this.loading = false
            } else {
              this.rejectMsg()
            }
          })
          .catch(error => {
            alert('追加画面（save）のエラーが発生した。ログイン画面へ遷移する。')
            return this.$router.push({ path: '/' })
          })
      }
    },
    // 一時保存方法（親子関係あり）
    saveTreeTemp() {
      let returnFlag = false
      let checkProcessFlag = false
      for (let i = 0; i < this.treeDataList.length; i++) {
        if (this.treeDataList[i]) {
            if (this.treeDataList[i].budomari && this.treeDataList[i].budomari !== '') {
              if (!this.treeDataList[i].generationBoolean && !this.treeDataList[i].isDeleted && this.treeDataList[i].unitDeleted) {
              } else {
                if (this.treeDataList[i].ideacode) {
                   this.treeDataList[i].emissionc = this.treeDataList[i].quantity * (this.treeDataList[i].coefficient / (parseFloat(this.treeDataList[i].budomari) / 100)).toFixed(4) * (isNaN(Number(this.treeDataList[i].unit.split('/unitlabel/')[0])) ? 1 : Number(this.treeDataList[i].unit.split('/unitlabel/')[0]))
                } else {
                  this.treeDataList[i].emissionc = this.treeDataList[i].quantity
                  this.treeDataList[i].unit = this.treeDataList[i].unit == null ? '' : this.treeDataList[i].unit
                }
              }
            } else {
              if (!this.treeDataList[i].generationBoolean && !this.treeDataList[i].isDeleted && this.treeDataList[i].unitDeleted) {

              } else {
                if (this.treeDataList[i].ideacode) {
                  this.treeDataList[i].emissionc = this.treeDataList[i].quantity * this.treeDataList[i].coefficient * (isNaN(Number(this.treeDataList[i].unit.split('/unitlabel/')[0])) ? 1 : Number(this.treeDataList[i].unit.split('/unitlabel/')[0]))
                } else {
                  this.treeDataList[i].emissionc = this.treeDataList[i].quantity
                  this.treeDataList[i].unit = this.treeDataList[i].unit == null ? '' : this.treeDataList[i].unit
                }
              }
            }
          if (!this.treeDataList[i].parentnode && this.parentFlag === 2) {
            this.treeDataList[i].parentnode = ''
          }
          if(this.treeDataList[i].transferUnit){
            this.treeDataList[i].unit = this.treeDataList[i].transferUnit
          }
          if (!this.treeDataList[i].processcode && this.parentFlag != 2) {
            checkProcessFlag = true
            returnFlag = true
          }
        } else {
          returnFlag = true
        }
      }
      if (checkProcessFlag && returnFlag) {
        this.$alert('プロセスを選択してください', this.$t('messages.confirm'), {
          confirmButtonText: this.$t('messages.yes'),
          type: 'warning',
          center: true,
          onClose: () => {
            this.pageLoading.close()
          }
        }).then(() => {
          return
        })
      }
      if (!returnFlag) {
        const reqData = {}
        reqData.kigyouCode = this.kigyouCode
        reqData.seihinCode = this.seihinCode
        reqData.seihinname = this.seihinNameObjName.seihinName
        reqData.seihinVersion = this.seihinNameObjVersion.seihinversion
        reqData.seihinunit = this.choseSeihinUnit
        reqData.upduser = this.upduser
        reqData.updusername = this.username
        reqData.simulateNote = this.simulateNote
        reqData.oriprocessFlg = this.buttonFlag - 1
        reqData.dataList = this.treeDataList
        // 一時保存方法 Javaインタフェースデータベースへのデータの保存
        saveTreeTempAPI(reqData)
          .then(res => {
            if (res.code === 200) {
              this.$message({
                message: this.$t('messages.tsic'),
                type: 'success'
              })
              this.loading = false
            } else {
              this.rejectMsg()
            }
          })
          .catch(error => {
            alert('追加画面（save）のエラーが発生した。ログイン画面へ遷移する。')
            return this.$router.push({ path: '/' })
          })
      }
    },
    // 製品バージョン存在チェック
    editContent() {
      let flag = true
      this.$refs['seihinNameObjVersion'].validate((valid) => {
        if (!valid) {
          flag = false
        }
      })
      this.$refs['seihinNameObjName'].validate((valid) => {
        if (!valid) {
          flag = false
        }
      })
      if (flag) {
        // 製品バージョン存在チェック
        checkExistAPI(this.seihinCode, this.seihinNameObjVersion.seihinversion)
          .then(res => {
            if (res.code === 200) {
              this.uploadData = { 'kigyouCode': this.kigyouCode, 'upduser': this.upduser, 'seihinCode': this.seihinCode, 'version': this.seihinNameObjVersion.seihinversion, 'parentFlag': false, 'language': this.language }
              this.treeUploadData = { 'kigyouCode': this.kigyouCode, 'upduser': this.upduser, 'seihinCode': this.seihinCode, 'version': this.seihinNameObjVersion.seihinversion, 'parentFlag': true, 'language': this.language }
              if (res.data.tempExistFlag) {
                this.deleteFinalDialogVisible = false
                this.saveFinalDialogVisible = false
                this.saveTempDialogVisible = true
              } else if (res.data.tempFinalFlag) {
                this.deleteFinalDialogVisible = false
                this.saveTempDialogVisible = false
                this.saveFinalDialogVisible = true
              } else if (res.data.deleteFinalFlag) {
                this.deleteFinalDialogVisible = true
                this.saveTempDialogVisible = false
                this.saveFinalDialogVisible = false
              } else {
                this.statusDisabled = false
                this.importDataDisabled = false
                this.saveTempDialogVisible = false
                this.saveFinalDialogVisible = false
              }
            } else {
              this.rejectMsg()
            }
          }).catch((error) => {
            console.log('error', error)
          })
        if (this.buttonFlag == 1) {
          getProcessListAPI(this.kigyouCode, this.seihinNameObjVersion.seihinversion, this.seihinCode, this.orignalFlag)
            .then(res => {
              if (res.code === 200) {
                this.processnameList = res.data.map(item => {
                  return { 'processCode': item.processCode, 'processName': item.processName }
                })
              }
            })
        } else {
          getOrignalProcessList(this.kigyouCode)
            .then(res => {
              if (res.code === 200) {
                this.processnameList = res.data.map(item => {
                  return { 'processCode': item.orignProcessCode, 'processName': item.orignProcessName, 'genProcess': item.genProcess }
                })
              }
            })
        }
      }
    },
    triggerHelpDialog() {
      // disabled
      if (this.statusDisabled) {
        return
      }
      this.dialogVisible = true
    },
    getSelectIdeaInfo(type, typeId) {
      getSelectIdeaInfoAPI(type, Number(typeId))
        .then(res => {
          if (res.code === 200) {
            this.utilityApportionmentList = res.data
            if (this.utilityApportionmentList.length > 0) {
              for (let i = 0; i < this.utilityApportionmentList.length; i++) {
                if (Number(typeId) === 1) {
                  if (this.utilityApportionmentList[i].ideacode === "331111017") {
                    this.$set(this.utilityApportionmentForm, 'ideaName', this.utilityApportionmentList[i].name)
                    this.$set(this.utilityApportionmentForm, 'unit', this.utilityApportionmentList[i].unit)
                    this.utilityApportionmentData = this.utilityApportionmentList[i]
                  }
                } else if (Number(typeId) === 2) {
                  if (this.utilityApportionmentList[i].ideacode === "361111000") {
                    this.$set(this.utilityApportionmentForm, 'ideaName', this.utilityApportionmentList[i].name)
                    this.$set(this.utilityApportionmentForm, 'unit', this.utilityApportionmentList[i].unit)
                    this.utilityApportionmentData = this.utilityApportionmentList[i]
                  }
                } else {
                  if (this.utilityApportionmentList[i].ideacode === "341111801") {
                    this.$set(this.utilityApportionmentForm, 'ideaName', this.utilityApportionmentList[i].name)
                    this.$set(this.utilityApportionmentForm, 'unit', this.utilityApportionmentList[i].unit)
                    this.utilityApportionmentData = this.utilityApportionmentList[i]
                  }
                }
              }
            } else {
              this.$set(this.utilityApportionmentForm, 'ideaName', '')
              this.$set(this.utilityApportionmentForm, 'unit', '')
            }
          } else {
            this.rejectMsg()
          }
        }).catch((error) => {
          console.log('error', error)
        })
    },
    utilityApportionmentNameChange(val) {
      this.utilityApportionmentData = this.utilityApportionmentList.filter(item => (item.ideacode === val))[0]
      this.$set(this.utilityApportionmentForm, 'unit', this.utilityApportionmentData.unit)
    },
    toBackUtilityApportionment() {
      this.innerVisibleSort1 = false
      this.$refs['utilityApportionmentForm'].resetFields()
    },
    // 補助計算データ持ち込み製品
    insertUtilityApportionment() {
      this.$refs['utilityApportionmentForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.innerVisibleSort1 = false
        this.dialogVisible = false
        const tempInsertA3Data = {
          ...this.utilityApportionmentData,
          ...this.utilityApportionmentForm
        }
        if (tempInsertA3Data['name'].indexOf('a3b523ac16674572848091098e') > -1) {
          tempInsertA3Data.displayname = tempInsertA3Data['name'].replace('a3b523ac16674572848091098e', ' ')
        } else {
          tempInsertA3Data.displayname = tempInsertA3Data['name']
        }
        if (this.parentFlag === 2 || this.uploadCreateFlag) {
          var processSubA3 = {
            'index': 0,
            'kigyoucode': '',
            'seihincode': '',
            'seihinname': '',
            'processcode': 'A3',
            'processname': '生産',
            'processname4display': '',
            'itemname': this.selectIdeaInfoEnum[tempInsertA3Data.selectIdeaInfo],
            'ideacode': tempInsertA3Data.ideacode,
            'ideaVersion': '',
            'coefficient': tempInsertA3Data.coefficient,
            'quantity': tempInsertA3Data.quantity,
            'emissionc': tempInsertA3Data.coefficient * tempInsertA3Data.quantity,
            'percentage': '',
            'delFlg': 0,
            'unit': tempInsertA3Data.unit,
            'unitList': [],
            'budomari': '',
            'parentnode': '',
            'interimproduct': '',
            'name': tempInsertA3Data.name,
            'displayname': tempInsertA3Data.displayname,
            'subassyId': '',
            'subassyFromId': '',
            'ideageneration': 0,
            'generationBoolean': false,
            'unitgeneration': 0,
            'unitgenerationBoolean': false
          }
          this.treeDataList.push(processSubA3)
          this.unittreeTransfer(tempInsertA3Data.unit, tempInsertA3Data.ideacode, this.treeDataList.length-1, processSubA3.ideaVersion)
          this.$refs['utilityApportionmentForm'].resetFields()
          this.treeDataForm.treeDataItem = this.treeDataList
          this.sum2()
        } else {
          // 製品輸送A3データ書式設定
            var processSubA3 = {
              'index': 0,
              'kigyoucode': '',
              'seihincode': '',
              'seihinname': '',
              'processcode': 'A3',
              'processname': '生産',
              'processname4display': '',
              'itemname': this.selectIdeaInfoEnum[tempInsertA3Data.selectIdeaInfo],
              'ideacode': tempInsertA3Data.ideacode,
              'coefficient': tempInsertA3Data.coefficient,
              'quantity': tempInsertA3Data.quantity,
              'ideaVersion': '',
              'emissionc': tempInsertA3Data.coefficient * tempInsertA3Data.quantity,
              'percentage': '',
              'color': '',
              'delFlg': '',
              'upduser': '',
              'upddate': '',
              'value': '',
              'name': tempInsertA3Data.name,
              'displayname': tempInsertA3Data.displayname,
              'icon': 'icon48_a3product.svg',
              'unit': tempInsertA3Data.unit,
              'seihinUnit': '',
              'ideageneration': 0,
              'generationBoolean': false,
              'unitgeneration': 0,
              'unitgenerationBoolean': false
            }
            if (this.processList['A3']) {
              this.processList['A3'].push(processSubA3)
              this.unitTransfer(tempInsertA3Data.unit, tempInsertA3Data.ideacode, 'A3', this.processList['A3'].length-1, processSubA3.ideaVersion)
              this.$refs['utilityApportionmentForm'].resetFields()
            } else {
              this.$set(this.processList, 'A3', [])
              this.processList['A3'].push(processSubA3)
              this.unitTransfer(tempInsertA3Data.unit, tempInsertA3Data.ideacode, 'A3', this.processList['A3'].length-1, processSubA3.ideaVersion)
              this.$refs['utilityApportionmentForm'].resetFields()
            }
            this.sum1()
          }
        })
    },

    // story21 jin add start
    trafficNameChange(val) {
      if (val === '2') {
        this.trafficSeaItemShow = true
      } else {
        this.trafficSeaItemShow = false
      }
    },

    getOriginalUnit(val) {
      getSelectIdeaInfoAPI('trans', val)
        .then(res => {
          if (res.code === 200) {
            this.originalUnitOptions = res.data
            if (this.originalUnitOptions.length > 0) {
              if (val === '1') {
                this.seihinyusoForm.originalUnit1 = this.originalUnitOptions[0].name
                this.seihinyusoForm.originalUnit3 = this.originalUnitOptions[0].name
                this.seihinyusoForm.ideacode1 = this.originalUnitOptions[0].ideacode
                this.seihinyusoForm.coefficient1 = this.originalUnitOptions[0].coefficient
              } else if (val === '2') {
                this.seihinyusoForm.originalUnit2 = this.originalUnitOptions[0].name
                this.seihinyusoForm.ideacode2 = this.originalUnitOptions[0].ideacode
                this.seihinyusoForm.coefficient2 = this.originalUnitOptions[0].coefficient
              }
            }
          } else {
            this.rejectMsg()
          }
        }).catch((error) => {
          console.log('error', error)
        })
    },

    // 登録する(製品輸送)
    insertSeihinyuso() {
      this.$refs['seihinyusoForm'].validate((valid) => {
        if (!valid) {
          return false
        }

        const tempInsertA4Data = {
          ...this.seihinyusoForm
        }
        if (tempInsertA4Data['originalUnit1'].indexOf('a3b523ac16674572848091098e') > -1) {
          tempInsertA4Data.originalUnit1Displayname = tempInsertA4Data['originalUnit1'].replace('a3b523ac16674572848091098e', ' ')
        } else {
          tempInsertA4Data.originalUnit1Displayname = tempInsertA4Data['originalUnit1']
        }

        // originalUnit2
        if (tempInsertA4Data['originalUnit2'].indexOf('a3b523ac16674572848091098e') > -1) {
          tempInsertA4Data.originalUnit2Displayname = tempInsertA4Data['originalUnit2'].replace('a3b523ac16674572848091098e', ' ')
        } else {
          tempInsertA4Data.originalUnit2Displayname = tempInsertA4Data['originalUnit2']
        }

        // originalUnit3
        if (tempInsertA4Data['originalUnit3'].indexOf('a3b523ac16674572848091098e') > -1) {
          tempInsertA4Data.originalUnit3Displayname = tempInsertA4Data['originalUnit3'].replace('a3b523ac16674572848091098e', ' ')
        } else {
          tempInsertA4Data.originalUnit3Displayname = tempInsertA4Data['originalUnit3']
        }
         if (this.parentFlag === 2 || this.uploadCreateFlag) {
          var processSubA4R1 = {
            'index': 0,
            'kigyoucode': '',
            'seihincode': '',
            'seihinname': '',
            'processcode': 'A4',
            'processname': '製品の輸送',
            'processname4display': '',
            'itemname': tempInsertA4Data.itemName,
            'ideacode': tempInsertA4Data.ideacode1,
            'ideaVersion': '',
            'coefficient': tempInsertA4Data.coefficient1,
            'quantity': tempInsertA4Data.quantity1,
            'emissionc': tempInsertA4Data.coefficient1 * tempInsertA4Data.quantity1,
            'percentage': '',
            'delFlg': 0,
            'unit': tempInsertA4Data.unit,
            'unitList': [],
            'budomari': '',
            'parentnode': '',
            'interimproduct': '',
            'name': tempInsertA4Data.originalUnit1,
            'displayname': tempInsertA4Data.originalUnit1Displayname,
            'subassyId': '',
            'subassyFromId': '',
            'ideageneration': 0,
            'generationBoolean': false,
            'unitgeneration': 0,
            'unitgenerationBoolean': false
          }
           // 海運含むの場合
          if (this.trafficSeaItemShow) {
            // 製品輸送A4データ書式設定
            var processSubA4R2 = {
              'index': 0,
              'kigyoucode': '',
              'seihincode': '',
              'seihinname': '',
              'processcode': 'A4',
              'processname': '製品の輸送',
              'processname4display': '',
              'itemname': tempInsertA4Data.itemName,
              'ideacode': tempInsertA4Data.ideacode2,
              'coefficient': tempInsertA4Data.coefficient2,
              'quantity': tempInsertA4Data.quantity2,
              'ideaVersion': '',
              'emissionc': tempInsertA4Data.coefficient2 * tempInsertA4Data.quantity2,
              'unitList': [],
              'delFlg': 0,
              'percentage': '',
              'budomari': '',
              'parentnode': '',
              'interimproduct': '',
              'name': tempInsertA4Data.originalUnit2,
              'displayname': tempInsertA4Data.originalUnit2Displayname,
              'unit': tempInsertA4Data.unit,
              'subassyId': '',
              'subassyFromId': '',
              'ideageneration': 0,
              'generationBoolean': false,
              'unitgeneration': 0,
              'unitgenerationBoolean': false
            }
            var processSubA4R3 = {
              'index': 0,
              'kigyoucode': '',
              'seihincode': '',
              'seihinname': '',
              'processcode': 'A4',
              'processname': '製品の輸送',
              'processname4display': '',
              'itemname': tempInsertA4Data.itemName,
              'ideacode': tempInsertA4Data.ideacode1,
              'coefficient': tempInsertA4Data.coefficient1,
              'quantity': tempInsertA4Data.quantity3,
              'ideaVersion': '',
              'emissionc': tempInsertA4Data.coefficient1 * tempInsertA4Data.quantity3,
              'percentage': '',
              'delFlg': 0,
              'budomari': '',
              'parentnode': '',
              'interimproduct': '',
              'name': tempInsertA4Data.originalUnit3,
              'displayname': tempInsertA4Data.originalUnit3Displayname,
              'unit': tempInsertA4Data.unit,
              'subassyId': '',
              'subassyFromId': '',
              'ideageneration': 0,
              'generationBoolean': false,
              'unitgeneration': 0,
              'unitgenerationBoolean': false
            }
          }
          if (this.trafficSeaItemShow) {
            this.treeDataList.push(processSubA4R1)
            this.unittreeTransfer(tempInsertA4Data.unit, tempInsertA4Data.ideacode, this.treeDataList.length-1, processSubA4R1.ideaVersion)
            this.treeDataList.push(processSubA4R2)
            this.unittreeTransfer(tempInsertA4Data.unit, tempInsertA4Data.ideacode, this.treeDataList.length-1, processSubA4R2.ideaVersion)
            this.treeDataList.push(processSubA4R3)
            this.unittreeTransfer(tempInsertA4Data.unit, tempInsertA4Data.ideacode, this.treeDataList.length-1, processSubA4R3.ideaVersion)
          } else {
            this.treeDataList.push(processSubA4R1)
            this.unittreeTransfer(tempInsertA4Data.unit, tempInsertA4Data.ideacode, this.treeDataList.length-1, processSubA4R1.ideaVersion)
          }
          this.treeDataForm.treeDataItem = this.treeDataList
          this.sum2()
          this.innerVisibleSort2 = false
          this.doBackSeihinyuso()
          this.dialogVisible = false
        } else {
          // 製品輸送A4データ書式設定
        var processSubA4R1 = {
          'index': 0,
          'kigyoucode': '',
          'seihincode': '',
          'seihinname': '',
          'processcode': 'A4',
          'processname': '製品の輸送',
          'processname4display': '',
          'itemname': tempInsertA4Data.itemName,
          'ideacode': tempInsertA4Data.ideacode1,
          'coefficient': tempInsertA4Data.coefficient1,
          'quantity': tempInsertA4Data.quantity1,
          'ideaVersion': '',
          'emissionc': tempInsertA4Data.coefficient1 * tempInsertA4Data.quantity1,
          'percentage': '',
          'color': '',
          'delFlg': '',
          'upduser': '',
          'upddate': '',
          'value': '',
          'name': tempInsertA4Data.originalUnit1,
          'displayname': tempInsertA4Data.originalUnit1Displayname,
          'icon': 'icon48_a4transproduct.svg',
          'unit': tempInsertA4Data.unit,
          'seihinUnit': ''
        }

        // 海運含むの場合
        if (this.trafficSeaItemShow) {
          // 製品輸送A4データ書式設定
          var processSubA4R2 = {
            'index': 0,
            'kigyoucode': '',
            'seihincode': '',
            'seihinname': '',
            'processcode': 'A4',
            'processname': '製品の輸送',
            'processname4display': '',
            'itemname': tempInsertA4Data.itemName,
            'ideacode': tempInsertA4Data.ideacode2,
            'coefficient': tempInsertA4Data.coefficient2,
            'quantity': tempInsertA4Data.quantity2,
            'ideaVersion': '',
            'emissionc': tempInsertA4Data.coefficient2 * tempInsertA4Data.quantity2,
            'percentage': '',
            'color': '',
            'delFlg': '',
            'upduser': '',
            'upddate': '',
            'value': '',
            'name': tempInsertA4Data.originalUnit2,
            'displayname': tempInsertA4Data.originalUnit2Displayname,
            'icon': 'icon48_a4transproduct.svg',
            'unit': tempInsertA4Data.unit,
            'seihinUnit': ''
          }
          var processSubA4R3 = {
            'index': 0,
            'kigyoucode': '',
            'seihincode': '',
            'seihinname': '',
            'processcode': 'A4',
            'processname': '製品の輸送',
            'processname4display': '',
            'itemname': tempInsertA4Data.itemName,
            'ideacode': tempInsertA4Data.ideacode1,
            'coefficient': tempInsertA4Data.coefficient1,
            'quantity': tempInsertA4Data.quantity3,
            'ideaVersion': '',
            'emissionc': tempInsertA4Data.coefficient1 * tempInsertA4Data.quantity3,
            'percentage': '',
            'color': '',
            'delFlg': '',
            'upduser': '',
            'upddate': '',
            'value': '',
            'name': tempInsertA4Data.originalUnit3,
            'displayname': tempInsertA4Data.originalUnit3Displayname,
            'icon': 'icon48_a4transproduct.svg',
            'unit': tempInsertA4Data.unit,
            'seihinUnit': ''
          }
        }

        if (this.processList['A4']) {
          this.processList['A4'].push(processSubA4R1)
          this.unitTransfer(tempInsertA4Data.unit, tempInsertA4Data.ideacode1, 'A4', this.processList['A4'].length-1, processSubA4R1.ideaVersion)
          // 海運含むの場合
          if (this.trafficSeaItemShow) {
            this.processList['A4'].push(processSubA4R2)
            this.unitTransfer(tempInsertA4Data.unit, tempInsertA4Data.ideacode2, 'A4', this.processList['A4'].length-1, processSubA4R2.ideaVersion)
            this.processList['A4'].push(processSubA4R3)
            this.unitTransfer(tempInsertA4Data.unit, tempInsertA4Data.ideacode1, 'A4', this.processList['A4'].length-1, processSubA4R3.ideaVersion)
          }
        } else {
          this.$set(this.processList, 'A4', [])
          this.processList['A4'].push(processSubA4R1)
          this.unitTransfer(tempInsertA4Data.unit, tempInsertA4Data.ideacode1, 'A4', this.processList['A4'].length-1, processSubA4R1.ideaVersion)
          // 海運含むの場合
          if (this.trafficSeaItemShow) {
            this.processList['A4'].push(processSubA4R2)
            this.unitTransfer(tempInsertA4Data.unit, tempInsertA4Data.ideacode2, 'A4', this.processList['A4'].length-1, processSubA4R2.ideaVersion)
            this.processList['A4'].push(processSubA4R3)
            this.unitTransfer(tempInsertA4Data.unit, tempInsertA4Data.ideacode1, 'A4', this.processList['A4'].length-1, processSubA4R3.ideaVersion)
          }
        }
        this.sum1()
        this.innerVisibleSort2 = false
        this.doBackSeihinyuso()
        this.dialogVisible = false
        }
      })
    },

    doBackSeihinyuso() {
      this.$refs['seihinyusoForm'].resetFields()
      this.seihinyusoForm.trafficDistance1 = ''
      this.seihinyusoForm.trafficDistance2 = ''
      this.seihinyusoForm.trafficDistance3 = ''
      this.seihinyusoForm.transSenario3 = ''
      this.innerVisibleSort2 = false
      this.trafficSeaItemShow = false
    },
    // story21 jin add end
    formReset() {
      this.$refs['seihinNameObjName'].resetFields()
      this.choseSeihinUnit = ''
      this.$refs['seihinNameObjVersion'].resetFields()
      this.statusDisabled = true // 恢复初始值
    },
    staticProcessInit() {
      this.processList = { 'A1':
        [{
          'index': 0,
          'kigyoucode': '',
          'seihincode': '',
          'seihinname': '',
          'processcode': 'A1',
          'processname': '原材料・部材調達',
          'processname4display': '',
          'itemname': '',
          'ideacode': '',
          'ideaVersion': '',
          'coefficient': 0,
          'quantity': '',
          'emissionc': 0,
          'percentage': '',
          'color': '',
          'delFlg': 0,
          'upduser': '',
          'upddate': '',
          'value': '',
          'name': '',
          'icon': 'icon48_a1material.svg',
          'unit': '',
          'budomari': ''
        }]
      }
    },
    // オリジナルプロセスデータ取得
    customerProcessInit() {
      getOrignalProcessList(this.kigyouCode)
        .then(res => {
          if (res.code === 200) {
            if (res.data && res.data.length > 0) {
              this.allOrignProcessList = res.data.filter(item => item.delFlg === 0)
              this.allOrignProcessList = this.allOrignProcessList.map(listObj => {
                return {
                  color: '',
                  icon: '',
                  processCode: listObj.orignProcessCode,
                  processName: listObj.orignProcessName,
                  genProcess: listObj.genProcess
                }
              })
              this.normalOrignProcessList = res.data.filter(item => item.delFlg === 0)
              this.processList = { [this.normalOrignProcessList[0].orignProcessCode]:
                [{
                  'index': 0,
                  'kigyoucode': this.normalOrignProcessList[0].kigyouCode,
                  'seihincode': '',
                  'seihinname': '',
                  'genProcess': this.normalOrignProcessList[0].genProcess,
                  'processcode': this.normalOrignProcessList[0].orignProcessCode,
                  'processname': this.normalOrignProcessList[0].orignProcessName,
                  'processname4display': '',
                  'itemname': '',
                  'ideacode': '',
                  'ideaVersion': '',
                  'coefficient': 0,
                  'quantity': '',
                  'emissionc': 0,
                  'percentage': '',
                  'color': '',
                  'delFlg': this.normalOrignProcessList[0].delFlg,
                  'upduser': this.normalOrignProcessList[0].upduser,
                  'upddate': '',
                  'value': '',
                  'name': '',
                  'icon': '',
                  'unit': '',
                  'budomari': ''
                }]
              }
            } else {
              this.allOrignProcessList = res.data.filter(item => item.delFlg === 0)
              this.allOrignProcessList = this.allOrignProcessList.map(listObj => {
                return {
                  color: '',
                  icon: '',
                  processCode: listObj.orignProcessCode,
                  processName: listObj.orignProcessName,
                  genProcess: listObj.genProcess
                }
              })
              this.normalOrignProcessList = res.data.filter(item => item.delFlg === 0)
              this.processList = {}
            }
          } else {
            this.rejectMsg()
          }
        }).catch((error) => {
          console.log('error', error)
        })
    },
    checkExistData() {
      let hasExistData = true
      for (const key in this.processList) {
        for (const item in this.processList[key]) {
          if (Object.keys(this.processList).length === 1 && this.processList[key].length === 1 && this.processList[key][item].itemname === '' && this.processList[key][item].name === '' && this.processList[key][item].quantity === '') {
            hasExistData = false
          } else {
            hasExistData = true
          }
        }
      }
      if (hasExistData) {
        this.$confirm(this.$t('messages.changeButtonMessage'), this.$t('messages.confirm'), {
          confirmButtonText: this.$t('messages.yes'),
          cancelButtonText: this.$t('messages.back'),
          type: 'warning',
          center: true
        }).then(() => {
          this.$refs['uploadButton'].$children[0].$refs.input.click()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '削除されませんでした'
          })
        })
      } else {
        this.$refs['uploadButton'].$children[0].$refs.input.click()
      }
    },
    treeCheckExistData() {
      let hasTreeExistData = true
      if (this.treeDataList.length === 1 && (!this.treeDataList[0].itemname || this.treeDataList[0].itemname === '') && this.treeDataList[0].processcode === '' && (!this.treeDataList[0].displayname || this.treeDataList[0].displayname === '') && this.treeDataList[0].quantity === '' && this.treeDataList[0].interimproduct === '' && this.treeDataList[0].parentnode === '' && this.treeDataList[0].budomari === '') {
        hasTreeExistData = false
      } else {
        hasTreeExistData = true
      }
      if (hasTreeExistData) {
        this.$confirm(this.$t('messages.changeButtonMessage'), this.$t('messages.confirm'), {
          confirmButtonText: this.$t('messages.yes'),
          cancelButtonText: this.$t('messages.back'),
          type: 'warning',
          center: true
        }).then(() => {
          this.treeDataList = [
          {
            "itemName": "",
            "processcode": "",
            "ideacode": "",
            "quantity": "",
            "unit": "",
            'unitList': [],
            "interimproduct": "",
            "parentnode": "",
            "budomari": "",
            "emissionc": 0,
            "coefficient": 0
          }
        ]
          this.$refs['treeUploadButton'].$children[0].$refs.input.click()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '削除されませんでした'
          })
        })
      } else {
        this.$refs['treeUploadButton'].$children[0].$refs.input.click()
      }
    },
    getDiffList(oldList, newList) {
      const arr = []
      oldList.map((item, index) => {
        const newItem = newList[index]
        if (item.ideaName !== newItem.ideaName || item.processCode !== newItem.processCode) {
          arr.push({ ...newItem, ideaNameOld: item.ideaName || '-', processCodeOld: item.processCode || '-' })
        }
      })
      return arr
    },
    handleSelectionChange(val) {
      this.selectionChangeRuleList = val
      if (val && val.length !== 0) {
        this.updateRuleDisabled = false
      } else {
        this.updateRuleDisabled = true
      }
    },
    // アップロード時に登録画面にデータを持ち込む方法
    updateDataToAdd() {
      this.pageLoading.close()
      this.showPageLoading()
      this.onlyAddFlag = true
      let confirmText = this.$i18n.locale === 'en' ? this.$t('messages.reo') : this.$t('messages.onlyEmissions')+'\n'+this.$t('messages.whetherRegister')
      this.$confirm(confirmText, {
        confirmButtonText: this.$t('messages.doregister'),
        cancelButtonText: this.$t('messages.docancel'),
        center: true,
        customClass: 'update-csv-confirm'
      }).then(() => {
        this.buildAddFlag = true
      }).catch((error) => {
        this.pageLoading.close()
        console.log(error)
        this.$message({
          type: 'info',
          message: '登録されませんでした'
        })
      })
    },
    // アップロード時に更新原単位紐づけルール
    updateRule() {
      this.pageLoading.close()
      this.showPageLoading()
      const dataList = this.selectionChangeRuleList.map(item => {
        return {
          ...item,
          ideaRoleCode: item.id,
          kigyouCode: this.kigyouCode,
          updUser: this.upduser,
          username: JSON.parse(this.$store.state.user).fullName
        }
      })
      let confirmText = this.$i18n.locale === 'en' ? this.$t('messages.rear') : this.$t('messages.onlyEmissionsRule')+'\n'+this.$t('messages.whetherRegister')
      this.$confirm(confirmText, {
        confirmButtonText: this.$t('messages.doregister')+'✓',
        cancelButtonText: this.$t('messages.docancel'),
        center: true,
        customClass: 'update-csv-confirm'
      }).then(() => {
        if (dataList.length > 0) {
          saveAndUpdateGentaniLinkAPI(dataList)
            .then(res => {
              if (res.code === 200) {
                this.ruleAndAllFlag = true
                this.buildAddFlag = true
              } else {
                this.rejectMsg()
              }
            }).catch((error) => {
              this.pageLoading.close()
              console.log('error', error)
            })
        }
      }).catch(() => {
        this.pageLoading.close()
        this.$message({
          type: 'info',
          message: '登録されませんでした'
        })
      })
    },
    // バウンディングボックスを閉じる方法
    handleDialogClose() {
      this.$confirm(this.$t('messages.ruleCancelMessage'), this.$t('messages.confirm'), {
        confirmButtonText: this.$t('messages.yes'),
        cancelButtonText: this.$t('messages.cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.resetCsvFormValid()
        this.resultsDialogVisible = false
      }).catch(() => {
        this.resultsDialogVisible = true
      })
    },
    // フォームチェックのリセット
    resetCsvFormValid() {
      this.$nextTick(() => {
        this.$refs['csvTableDataForm'].validate((valid) => {
          if (!valid) {
            return false
          }
        })
      })
      setTimeout(() => {
        this.sum2()
      }, 1000);
    },
    toRuleDialog(formName) {
      this.showPageLoading()
      if (this.csvTableData.length != 0) {
        this.$confirm(this.$t('messages.uploadTitle'), {
          confirmButtonText: this.$t('messages.doregister'),
          cancelButtonText: this.$t('messages.docancel'),
          center: true
        }).then(() => {
          this.buildAddFlag = true
        }).catch(() => {
          this.pageLoading.close()
          console.log('キャンセル')
        })
      } else {
        this.pageLoading.close()
        this.$alert('項目は一つ以上してください。', this.$t('messages.confirm'), {
          confirmButtonText: this.$t('messages.yes'),
          type: 'warning',
          center: true
        }).then(() => {
          return
        })
      }
    },
    // アップロード後のpopupデータ構造を製品に必要なデータ構造に構築する
    buildAddData() {
      if (JSON.stringify(this.csvTableDataBackup) !== JSON.stringify(this.csvTableData)) {
          this.changedCsvTableData = this.getDiffList(this.csvTableDataBackup, this.csvTableData)
          for (let i = 0; i < this.changedCsvTableData.length; i++) {
            if (this.changedCsvTableData[i].ideaName === '') {
              this.changedCsvTableData.splice(i, 1)
              i--
            }
          }
          if (this.superAdmin && this.changedCsvTableData.length > 0) {
            this.updateVisible = true
          } else {
            if (this.parentFlag !== 2) {
              this.noTreeFromCsvOrApi = true
            } else {
              this.noTreeFromCsvOrApi = false
            }
            this.resultsDialogVisible = false
            for (const key in this.$refs['treeDataItem']) {
              this.$refs['treeDataItem'][key].resetFields()
            }
            this.treeDataList = this.handleCsvTableData(this.csvTableData)
          }
        } else {
          if (this.parentFlag !== 2) {
            this.noTreeFromCsvOrApi = true
          } else {
            this.noTreeFromCsvOrApi = false
          }
          this.resultsDialogVisible = false
          for (const key in this.$refs['treeDataItem']) {
            this.$refs['treeDataItem'][key].resetFields()
          }
          this.treeDataList = this.handleCsvTableData(this.csvTableData)
        }
        if (this.onlyAddFlag || this.ruleAndAllFlag) {
          this.onlyAddFlag = false
          this.ruleAndAllFlag = false
          this.updateVisible = false
          if (this.parentFlag !== 2) {
            this.noTreeFromCsvOrApi = true
          } else {
            this.noTreeFromCsvOrApi = false
          }
          this.resultsDialogVisible = false
          for (const key in this.$refs['treeDataItem']) {
            this.$refs['treeDataItem'][key].resetFields()
          }
          // 登録画面データアセンブリ
          this.treeDataList = this.handleCsvTableData(this.csvTableData)
        }
        this.csvSumFlag = true
        this.buildAddFlag = false
        if (this.csvSumFlag) {
          this.pageLoading.close()
          this.csvSumFlag = false
          this.sum2()
        }
        this.treeDataForm.treeDataItem = this.treeDataList
        this.$nextTick(function(){
         this.$refs.treeDataForm.validate()
          this.csvTableDataBackup = []
        })
        this.uploadCreateFlag =  true
    },
    // 登録画面データアセンブリ
    handleCsvTableData(csvTableData) {
      const tempCsvTableData = JSON.parse(JSON.stringify(csvTableData))
      tempCsvTableData.forEach(tempCsvElement => {
        tempCsvElement.index = 0
        tempCsvElement.key = tempCsvElement.key
        tempCsvElement.kigyoucode = this.kigyouCode
        tempCsvElement.seihincode = ''
        tempCsvElement.seihinname = ''
        tempCsvElement.processcode = tempCsvElement.processCode
        tempCsvElement.processname = tempCsvElement.processName.split(tempCsvElement.processCode + ':')[1] || tempCsvElement.processName
        tempCsvElement.processname4display = ''
        tempCsvElement.itemname = tempCsvElement.itemName
        tempCsvElement.ideacode = tempCsvElement.ideaCode ? tempCsvElement.ideaCode : tempCsvElement.ideacode
        tempCsvElement.ideaVersion = tempCsvElement.ideaVersion ? tempCsvElement.ideaVersion : tempCsvElement.ideaversion
        tempCsvElement.quantity = tempCsvElement.quantity
        tempCsvElement.emissionc = 0.1
        tempCsvElement.percentage = ''
        tempCsvElement.color = ''
        tempCsvElement.delFlg = 0
        tempCsvElement.upduser = this.upduser
        tempCsvElement.upddate = ''
        tempCsvElement.value = ''
        tempCsvElement.name = tempCsvElement.delflg == 1 ? '' : tempCsvElement.ideaName.replace('a3b523ac16674572848091098e', ' ')
        tempCsvElement.unit = tempCsvElement.unit
        tempCsvElement.budomari = tempCsvElement.budomari
        tempCsvElement.interimproduct = tempCsvElement.interimProduct
        tempCsvElement.displayname = tempCsvElement.delflg == 1 ? '' : tempCsvElement.ideaName.replace('a3b523ac16674572848091098e', ' ')
        if (this.parentFlag == 2) {
          tempCsvElement.parentnode = tempCsvElement.parentNode || ''
        } else {
          tempCsvElement.parentnode = null
        }
        if (this.buttonFlag === 2 && tempCsvElement.processCode) {
          let filterProcess = this.originalProcessList.filter(item => item.processCode === tempCsvElement.processCode)
          if (filterProcess[0].genProcess === 'A1' || filterProcess[0].genProcess === 'A2' || filterProcess[0].genProcess === 'A3') {
            tempCsvElement.budomari = tempCsvElement.budomari
          } else {
            tempCsvElement.budomari = ''
          }
        } else if (this.buttonFlag === 1) {
          if (tempCsvElement.processCode !== 'A1' && tempCsvElement.processCode !== 'A2' && tempCsvElement.processCode !== 'A3') {
            tempCsvElement.budomari = ''
          }
        }
        let filterList = {}
        if (tempCsvElement.ideaVersion) {
          filterList = this.nameList.find(nameItem => nameItem.ideacode === tempCsvElement.ideacode && nameItem.version == tempCsvElement.ideaVersion)
        } else {
          filterList = this.nameList.find(nameItem => nameItem.ideacode === tempCsvElement.ideacode)
        }
        if (filterList) {
          tempCsvElement.generationBoolean = (filterList.generation !== tempCsvElement.ideageneration)
          tempCsvElement.generation = filterList.generation
          tempCsvElement.isDeleted = false
        } else {
          if (tempCsvElement.ideacode) {
            tempCsvElement.isDeleted = true
          } else {
            tempCsvElement.isDeleted = false
            tempCsvElement.emissionc = 0
          }
        }
      })
      let tempProcessCodeList = []
      const tempProcessList = {}
      tempCsvTableData.forEach(element => {
        tempProcessCodeList.push(element.processCode)
      })
      tempProcessCodeList = [... new Set(tempProcessCodeList)]
      tempProcessCodeList.forEach(processCodeItem => {
        tempProcessList[processCodeItem] = tempCsvTableData.filter(item => item.processCode === processCodeItem)
      })
      return tempCsvTableData
    },
    querySearchFile(queryString, cb) {
      const result = this.filerIdeaData.filter(item => {
        if (item.name.indexOf(queryString) > -1) {
          return true
        }
      })
      cb(result)
    },
    // 選択ファイルをアップロードした後のデータ初期化方法
    handleSelectFile(item, csvItemIndex) {
      this.csvTableData[csvItemIndex].ideaName = item.name
      this.csvTableData[csvItemIndex].ideaCode = item.ideacode
      this.csvTableData[csvItemIndex].coefficient = item.coefficient
      this.csvTableData[csvItemIndex].unit = item.unit
      this.csvTableData[csvItemIndex].delflg = item.delflg
      this.csvTableData[csvItemIndex].ideaVersion = item.version || ''
      this.csvTableData[csvItemIndex].ideageneration = item.generation
      this.csvTableData[csvItemIndex].unitgeneration = 0
      this.csvTableData[csvItemIndex].hasChangeIdeaName = true
      this.$set(this.csvTableData[csvItemIndex], 'chackStatus', false)
      getUnitListAPI(this.kigyouCode, item.unit, item.ideacode, item.version || '')
        .then(res => {
          if(res.code === 200){
            const tempUnitCsvlist = [{'percent':1,'tounit':item.unit, 'generation':0, 'delflg': 0}, ...res.data.filter(item => item != null)]
            this.$set(this.csvTableData[csvItemIndex], 'unitList', tempUnitCsvlist)
            this.$set(this.csvTableData[csvItemIndex], 'transferUnitPercent', 1)
          }
        })
    },
    handleQuantityChange(item, csvItemIndex, event) {
      this.csvTableData[csvItemIndex].hasChangeIdeaName = false
      this.csvTableData[csvItemIndex].quantity = event.replace(/(\-?)\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3$4').match(/^\-?\d*(\.?\d*)/g)[0] || ''
      this.$set(this.csvTableData[csvItemIndex], 'chackStatus', false)
    },
    inputChangeFile(name, csvItemIndex) {
      const array = this.filerIdeaData.filter(item => {
        return item.name === name
      })
      if (array.length > 0) {
        this.csvTableData[csvItemIndex].ideaName = array[0].name
        this.csvTableData[csvItemIndex].ideaCode = array[0].ideacode
        this.csvTableData[csvItemIndex].coefficient = array[0].coefficient
        this.csvTableData[csvItemIndex].unit = array[0].unit
        this.csvTableData[csvItemIndex].ideaVersion = array[0].version || ''
        this.csvTableData[csvItemIndex].ideageneration = array[0].generation
        this.csvTableData[csvItemIndex].unitgeneration = 0
        getUnitListAPI(this.kigyouCode, array[0].unit, array[0].ideacode, array[0].version || '')
          .then(res => {
            if(res.code === 200){
              let per = 1
              if (res.data.length > 0) {
                for (var i = 0; i < res.data.length; i++) {
                  if (res.data[i].fromunit == this.csvTableData[csvItemIndex].unit) {
                    break
                  }
                  if (res.data[i].tounit == this.csvTableData[csvItemIndex].unit) {
                    per = res.data[i].percent
                  }
                }
              }
              const tempUnitCsvlist = [{'percent':per,'tounit':array[0].unit,'generation':0}, ...res.data.filter(item => item != null)]
              this.$set(this.csvTableData[csvItemIndex], 'unitList', tempUnitCsvlist)
            }
          })
      } else {
        this.csvTableData[csvItemIndex].ideaName = ''
        this.csvTableData[csvItemIndex].ideaCode = ''
        this.csvTableData[csvItemIndex].coefficient = ''
        this.csvTableData[csvItemIndex].unit = ''
        this.csvTableData[csvItemIndex].ideaVersion = ''
      }
    },
    toProcessRelationship(value) {
      if (value !== this.buttonFlag) {
        this.checkVersionEdit(value, 'seihinType')
      }
    },
    toParentchildRelationship(value) {
      if (value !== this.parentFlag) {
        if (value === 2) {
          this.noTreeFromCsvOrApi = false
        }
        // this.treeDataForm.treeDataItem = this.treeDataList
        this.checkVersionEdit(value, 'treeSeihin')
        if (value === 2) {
          this.processList = {}
          this.tree = true
        } else {
          this.tree = false
        }
      }
    },
    checkVersionEdit(val, buttonStyle) {
      let hasExistData = true
      this.treeBreadcrumb = []
      this.allTreeBreadcrumb = []
      if (this.parentFlag === 1) {
        for (const key in this.processList) {
          for (const item in this.processList[key]) {
            if (Object.keys(this.processList).length === 1 && this.processList[key].length === 1 && this.processList[key][item].itemname === '' && this.processList[key][item].name === '' && this.processList[key][item].quantity === '') {
              hasExistData = false
            } else {
              hasExistData = true
            }
          }
        }
      } else if (this.parentFlag === 2) {
        if (this.treeDataList.length === 1 && (!this.treeDataList[0].itemname || this.treeDataList[0].itemname === '') && this.treeDataList[0].processcode === '' && (!this.treeDataList[0].displayname || this.treeDataList[0].displayname === '') && this.treeDataList[0].quantity === '' && this.treeDataList[0].interimproduct === '' && this.treeDataList[0].parentnode === '' && this.treeDataList[0].budomari === '') {
          hasExistData = false
        } else {
          hasExistData = true
        }
      }
      if (this.choseSeihinUnit != '' || this.seihinNameObjVersion.seihinversion != '' || hasExistData) {
        this.$confirm(this.$t('messages.changeButtonMessage'), this.$t('messages.confirm'), {
          confirmButtonText: this.$t('messages.yes'),
          cancelButtonText: this.$t('messages.back'),
          type: 'warning',
          center: true
        }).then(() => {
          this.originalProcessInit()
          this.treeDataForm.treeDataItem = this.treeDataList
          this.sumProcessEmission = 0
          this.sumTreeEmission = 0
          this.simulateNote = ''
          if (this.parentFlag == 1) {
            this.noTreeFromCsvOrApi = false
          } 
          this.toProcessSort(val, buttonStyle)
        }).catch((e) => {
          if (e !== 'cancel') {
            console.error('システムエラー', e)
          }
        })
      } else {
        this.toProcessSort(val, buttonStyle)
      }
    },
    toProcessSort(val, buttonStyle) {
      this.processnameList = []
      if (buttonStyle === 'seihinType' && this.parentFlag === 1) {
        this.formReset()
        if (val === 1) {
          this.staticProcessInit()
        } else if (val === 2) {
          this.customerProcessInit()
        }
        setTimeout(() => {
          for (const key in this.$refs['subProcessItem']) {
            this.$refs['subProcessItem'][key].resetFields()
          }
        }, 0)
      } else if (buttonStyle === 'seihinType' && this.parentFlag === 2) {
        this.formReset()
        if (val === 1) {
          this.standardProcessInit()
        } else {
          this.originalProcessInit()
        }
        setTimeout(() => {
          for (const key in this.$refs['treeDataItem']) {
            this.$refs['treeDataItem'][key].resetFields()
          }
        }, 0)
      } else if (buttonStyle === 'treeSeihin' && val === 1) {
        if (this.buttonFlag === 1) {
          this.formReset()
          this.staticProcessInit()
        } else {
          this.formReset()
          this.customerProcessInit()
        }
        setTimeout(() => {
          for (const key in this.$refs['treeDataItem']) {
            this.$refs['treeDataItem'][key].resetFields()
          }
        }, 0)
      } else if (buttonStyle === 'treeSeihin' && val === 2) {
        this.formReset()
        if (this.buttonFlag === 1) {
          this.standardProcessInit()
        } else {
          this.originalProcessInit()
        }
        setTimeout(() => {
          for (const key in this.$refs['subProcessItem']) {
            this.$refs['subProcessItem'][key].resetFields()
          }
        }, 0)
      }
      if (buttonStyle === 'seihinType') {
        this.buttonFlag = val
      } else if (buttonStyle === 'treeSeihin') {
        this.parentFlag = val
      }
    },
    rejectMsg() {
      this.$message.error('ネットワークエラーが発生した。')
    },
    originalProcessInit() {
      this.treeDataList = 
      [
        {
          'index': 0,
          'kigyoucode': '',
          'seihincode': '',
          'seihinname': '',
          'processcode': '',
          'processname': '',
          'processname4display': '',
          'itemname': '',
          'ideacode': '',
          'ideaVersion': '',
          'coefficient': 0,
          'quantity': '',
          'emissionc': 0,
          'percentage': '',
          'delFlg': 0,
          'unit': '',
          'unitList': [],
          'budomari': '',
          'parentnode': '',
          'interimproduct': '',
          'displayname': '',
          'subassyId': '',
          'subassyFromId': ''
        }
      ]
      this.orignalFlag = true
    },
    standardProcessInit() {
      this.treeDataList = 
      [
        {
          'index': 0,
          'kigyoucode': '',
          'seihincode': '',
          'seihinname': '',
          'processcode': '',
          'processname': '',
          'processname4display': '',
          'itemname': '',
          'ideacode': '',
          'ideaVersion': '',
          'coefficient': 0,
          'quantity': '',
          'emissionc': 0,
          'percentage': '',
          'delFlg': 0,
          'unit': '',
          'unitList': [],
          'budomari': '',
          'parentnode': '',
          'interimproduct': '',
          'displayname': '',
          'subassyId': '',
          'subassyFromId': ''
        }
      ]
      this.orignalFlag = false
    },
    newAddClick(item, index) {
      if (this.statusDisabled) {
        return
      }
      let arrayAdd = JSON.parse(JSON.stringify(this.treeData))
      arrayAdd.index = index++
      this.treeDataList.splice(index, 0, arrayAdd)
      for (let i = 0; i < this.treeDataList.length; i++) {
        this.treeDataList[i].index = i
        this.treeDataList[i].key = i
      }
      this.treeDataForm.treeDataItem = this.treeDataList
      this.$forceUpdate()
    },
    callNewAddClick(callDataItem, index) {
      if (this.statusDisabled) {
        return
      }      
      let subindex = index + callDataItem.subassyIdArr.length - 1
      let arrayAdd = JSON.parse(JSON.stringify(this.treeData))
      arrayAdd.index = subindex + 1
      this.treeDataList.splice(subindex+1, 0, arrayAdd)
      for (let i = 0; i < this.treeDataList.length; i++) {
        this.treeDataList[i].index = i
        this.treeDataList[i].key = i
      }
      this.treeDataForm.treeDataItem = this.treeDataList
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    newCopyClick(treeDataItem, index) {
      if (this.statusDisabled) {
        return
      }
      const arrayAdd = {
        ...treeDataItem
      }
      arrayAdd.index = index++
      arrayAdd.subassyFromId = ''
      this.treeDataList.splice(index, 0, arrayAdd)
      for (let i = 0; i < this.treeDataList.length; i++) {
        this.treeDataList[i].index = i
        this.treeDataList[i].key = i
      }
      this.treeDataForm.treeDataItem = this.treeDataList
      this.sum2()
    },
    callDataCopyClick(callDataItem, index, callIndex) {
      if (this.statusDisabled) {
        return
      }
      let subindex = index + callDataItem.subassyIdArr.length - 1
      let newUuid = uuidv4()
      newUuid = newUuid.replace(/[-]/g, '')
      let tempCallDataItem = JSON.parse(JSON.stringify(callDataItem))
      tempCallDataItem.topSubassyId = newUuid
      this.callDataList.splice(callIndex + 1, 0, tempCallDataItem)
      let subTreeDataList = this.treeDataList.slice(index, subindex + 1);
      let tempsubTreeDataList = JSON.parse(JSON.stringify(subTreeDataList))
      let firstFlag1 = true
      tempsubTreeDataList.map(item => { 
        if (firstFlag1) {
          item.topSubassyId = newUuid
          firstFlag1 = false
        } else {
          item.topSubassyId = ""
        }
        this.treeDataList.splice(subindex+1, 0, item)
        subindex ++
      })
      for (let i = 0; i < this.treeDataList.length; i++) {
        this.treeDataList[i].index = i
        this.treeDataList[i].key = i
      }
      this.treeDataForm.treeDataItem = this.treeDataList
      this.sum2()
    },
    newhandleSelect(item, index) {
      this.treeDataList[index].name = item.name
      this.treeDataList[index].displayname = item.displayname
      this.treeDataList[index].ideacode = item.ideacode
      this.treeDataList[index].unit = item.unit
      this.treeDataList[index].coefficient = item.coefficient
      this.treeDataList[index].ideaVersion = item.version || ''
      this.treeDataList[index].ideageneration = item.generation
      this.treeDataList[index].unitgeneration = 0
      this.unittreeTransfer(item.unit, item.ideacode, index, this.treeDataList[index].ideaVersion)
      if (item.budomari && item.budomari !== '') {
        this.treeDataList[index].emissionc =this.treeDataList[index].quantity * (this.treeDataList[index].coefficient / (parseFloat(this.treeDataList[index].budomari) / 100))
      } else {
        this.treeDataList[index].emissionc = this.treeDataList[index].quantity * (this.treeDataList[index].coefficient)
      }
      this.treeDataList[index].transferUnitPercent = 1
      this.$set(this.treeDataList[index], 'isDeleted', false)
      this.$set(this.treeDataList[index], 'unitDeleted', false)
      this.$set(this.treeDataList[index], 'generationBoolean', false)
      this.$set(this.treeDataList[index], 'unitgenerationBoolean', false)
      this.treeDataForm.treeDataItem = this.treeDataList
      this.sum2()

    },
    newFocusChange(subProcessIndex) {
      this.$set(this.treeDataList[subProcessIndex], 'isDeleted', false)
      this.treeDataForm.treeDataItem = this.treeDataList
    },
    newInputChange(displayname, subProcessIndex) {
      this.$set(this.treeDataList[subProcessIndex], 'isDeleted', false)
      this.$set(this.treeDataList[subProcessIndex], 'unitDeleted', false)
      this.$set(this.treeDataList[subProcessIndex], 'generationBoolean', false)
      this.$set(this.treeDataList[subProcessIndex], 'unitgenerationBoolean', false)
      const array = this.nameList.filter(item => {
        return item.displayname === displayname
      })
      if (array.length > 0) {
        this.treeDataList[subProcessIndex].name = array[0].name
        this.treeDataList[subProcessIndex].displayname = array[0].displayname
        this.treeDataList[subProcessIndex].ideacode = array[0].ideacode
        this.treeDataList[subProcessIndex].unit = array[0].unit
        this.treeDataList[subProcessIndex].coefficient = array[0].coefficient
        this.treeDataList[subProcessIndex].ideaVersion = array[0].version || ''
        this.treeDataList[subProcessIndex].ideageneration = array[0].generation
        this.unittreeTransfer(array[0].unit, array[0].ideacode, subProcessIndex, this.treeDataList[subProcessIndex].ideaVersion)
        if (array[0].budomari && array[0].budomari !== '') {
          this.treeDataList[subProcessIndex].emissionc =this.treeDataList[subProcessIndex].quantity * (this.treeDataList[subProcessIndex].coefficient / (parseFloat(this.treeDataList[subProcessIndex].budomari) / 100))
          } else {
          this.treeDataList[subProcessIndex].emissionc = this.treeDataList[subProcessIndex].quantity * (this.treeDataList[subProcessIndex].coefficient)
          }
          this.treeDataList[subProcessIndex].transferUnitPercent = 1
        this.sum2()
      } else {
        this.treeDataList[subProcessIndex].name = ''
        this.treeDataList[subProcessIndex].displayname = ''
        this.treeDataList[subProcessIndex].ideacode = ''
        this.treeDataList[subProcessIndex].unit = ''
        this.treeDataList[subProcessIndex].coefficient = 0
        this.treeDataList[subProcessIndex].ideaVersion = ''
      }
      this.treeDataForm.treeDataItem = this.treeDataList
    },
    itemNameFocus(val, index) {
      this.treeDataList[index].itemname = val
      this.treeDataForm.treeDataItem = this.treeDataList
    },
    addtreeDataGenprocess(val, index) {
      if (this.buttonFlag === 2) {
        const type = 2
        const tempGenprocess = this.processnameList.filter(item => item.processCode === val.processcode)[0].genProcess
        this.treeDataList[index].genProcess = tempGenprocess
        this.getBreadcrumb(val.processcode, index, type)
      } else {
        this.treeDataList[index].genProcess = val.processcode
        const type = 1
        this.getBreadcrumb(val.processcode, index, type)
      }
      this.treeBreadcrumb.sort((a, b) => {return a.sortIndex - b.sortIndex})
      if (this.treeDataList[index].genProcess != 'A1' && this.treeDataList[index].genProcess != 'A2' && this.treeDataList[index].genProcess != 'A3') {
        this.treeDataList[index].budomari = ""
      }
      this.treeDataForm.treeDataItem = this.treeDataList
    },
    getBreadcrumb(value, index, type) {
      if (type === 1) {
        for (let i = 0; i < this.filterStandProcessList.length; i++) {
          if (this.filterStandProcessList[i].processCode === value) {
            var item = {
              'processcode': this.filterStandProcessList[i].processCode,
              'processname': this.filterStandProcessList[i].processName,
              'index': index,
              'sortIndex': i
            }
          }
        }
        if (item) {
          this.allTreeBreadcrumb.push(item)
          const tempTree = this.allTreeBreadcrumb.filter(item => item.index === index)
          if (tempTree.length > 1){
            this.allTreeBreadcrumb.splice(this.allTreeBreadcrumb.findIndex(e => e.index === index), 1)
          }
        }
      } else {
        for (let i = 0; i < this.filterOriProcessList.length; i++) {
          if (this.filterOriProcessList[i].processCode === value) {
              var item = {
              'processcode': this.filterOriProcessList[i].processCode,
              'processname': this.filterOriProcessList[i].processName,
              'index': index,
              'sortIndex': i
            }            
          }
        }
        if (item) {
          this.allTreeBreadcrumb.push(item)
          const tempTree = this.allTreeBreadcrumb.filter(item => item.index === index)
          if (tempTree.length > 1){
            this.allTreeBreadcrumb.splice(this.allTreeBreadcrumb.findIndex(e => e.index === index), 1)
          }
        }
      }
      this.treeBreadcrumb = []
      this.allTreeBreadcrumb.forEach(item1 => {
        if (!this.treeBreadcrumb.some(item2 => item2.processcode === item1.processcode)) {
          this.treeBreadcrumb.push(item1)
        }
      })
      this.treeBreadcrumb.sort((a, b) => {return a.sortIndex - b.sortIndex})
    },
    changeTempArr(treeDataItem) {
      this.tempValidateTree = []
      this.tempValidateTree.push(treeDataItem)
      this.treeDataList = this.treeDataForm.treeDataItem
    },
    changeCallTempArr(treeDataItem) {
      this.tempValidateTree = []
      treeDataItem.callParentShow = false
      this.tempValidateTree.push(treeDataItem)
      this.treeDataList = this.treeDataForm.treeDataItem
    },    
    changeCsvTempArr(csvItem) {
      this.csvTempValidateTree = []
      this.csvTempValidateTree.push(csvItem)
    },
    // 親子関係あり  項目は一つ以上してください
    deleteTreeProcess(dataFlag, deleteIndex, callIndex) {
      this.treeIndex4Delete = deleteIndex
      this.$confirm(this.$t('messages.callDataDelMsg'), this.$t('messages.confirm'), {
        confirmButtonText: 'OK',
        cancelButtonText: this.$t('messages.buttonCancel'),
        type: 'warning',
        center: true
      }).then(() => {
        if (this.treeDataList.length === 1) {
          this.treeIndex4Delete = ''
          this.$alert('項目は一つ以上してください。', this.$t('messages.confirm'), {
            confirmButtonText: this.$t('messages.yes'),
            type: 'warning',
            center: true
          }).then(() => {
            return
          })
        } else {
          // データ呼び出し 論理を削除
          if (dataFlag) {
            let deleteFlag = false
            let childdeleteFlag = false
            for (let l = 0; l < this.callDataList.length; l++) {
              if (this.callDataList[l].topSubassyId === deleteIndex) {
                this.callDataList.splice(l, 1)
              }
            }
            for (let i = 0; i < this.treeDataList.length; i++) {
              if (this.treeDataList[i].topSubassyId === deleteIndex) {
                if(!deleteFlag){
                  this.treeDataList.splice(i, 1)
                  deleteFlag = true
                } else {
                  childdeleteFlag = true
                }
              }
              if (deleteFlag) {
                if(this.treeDataList[i].subassyFromId && !this.treeDataList[i].topSubassyId){
                  if(!childdeleteFlag){
                    this.treeDataList.splice(i, 1)
                    i--
                  }
                }
              } 
            }
          } else {
            // 論理を削除
            this.treeDataList.splice(deleteIndex, 1)
            this.treeIndex4Delete = ''
            this.allTreeBreadcrumb = []
            for (let i = 0; i < this.treeDataList.length; i++) {
              this.treeDataList[i].index = i
              this.getBreadcrumb(this.treeDataList[i].processcode, this.treeDataList[i].index, this.buttonFlag)
            }
          }
          for (let i = 0; i < this.treeDataList.length; i++) {
            this.treeDataList[i].index = i
            this.treeDataList[i].key = i
          }
          this.treeDataForm.treeDataItem = this.treeDataList
          this.sum2()
          this.$forceUpdate()
        }
      }).catch(() => {
        this.treeIndex4Delete = ''
        this.$message({
          type: 'info',
          message: '削除されませんでした'
        })
      })
    },
    checknumber1(rule, value, callback){
      if (!value) {
        return callback(new Error(this.$t('messages.peaam')))
      }
      setTimeout(() => {
        if (this.isNumber1(value)) {
          callback(new Error('半角数値を入力してください'))
        } else {
          callback()
        }
      }, 500)
    },

    newcheckgentani1(rule, value, callback){
      if (value) {
        if (!this.nameArray.includes(value)) {
          return callback(new Error('正しい原単位を入力してください'))
        }
      }
      return callback()
    },

    newprocesscheck1(rule, value, callback){
      if(value) {
        if (!value || value == '') {
          return callback(new Error('プロセスを選択してください'))
        }
      }
      return callback()
    },

    checkgentani1(rule, value, callback){
      if (this.nameArray.indexOf(value) == -1) {
        return callback(new Error('正しい原単位を入力してください'))
      }
      return callback()
    },

    checkInput01(rule, value, callback){
      if (parseFloat(value) === 0) {
        return callback(new Error('0を入力しないでください'))
      }
      if (value === '-') {
        return callback(new Error('半角数値を入力してください'))
      }            
    },

    checkBudomari1(rule, value, callback){
      if (value === '') {
        return callback()
      }
      if (value) {
        if (parseFloat(value) > 0 && parseFloat(value) < 100) {
          return callback()
        }else{
         return callback(new Error(this.$t('messages.peangt0alt100')))
        }
      }
    },
    isNumber1 (val){
      var regPos = /^\d+(\.\d+)?$/
      var regNeg =
        /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/
      if (regPos.test(val) || regNeg.test(val)) {
        return true
      } else {
        return false
      }
    },

    validateConsumption1(rule, value, callback){
      if (value === '') {
        callback(new Error(this.$t('messages.peopuca')))
      } else {
        if (!this.isNumber1(value)) {
          callback(new Error('半角数値を入力してください'))
        } else {
          callback()
        }
      }
    },

    validateProduction1(rule, value, callback){
      if (value === '') {
        callback(new Error(this.$t('messages.penopp')))
      } else {
        if (!this.isNumber1(value)) {
          callback(new Error('半角数値を入力してください'))
        } else if (value <= 0) {
          callback(new Error('ゼロ以上の数字のみ入力できます'))
        } else {
          callback()
        }
      }
    },

    validateItemnameSeihin1(rule, value, callback){
      if (value === '') {
        callback(new Error(this.$t('messages.peain')))
      } else {
        callback()
      }
    },
    validateTransSenario11(rule, value, callback){
      if (value === '') {
        callback(new Error(this.$t('messages.pets')))
      } else {
        for (var item of this.transSenarioOptions) {
          if (item.senarioid === value) {
            this.seihinyusoForm.trafficDistance1 = item.trans_distance
          }
        }
        callback()
      }
    },
    validateTrafficDistance21(rule, value, callback) {
      if (value === '') {
        callback(new Error(this.$t('messages.petd')))
        // callback()
      } else {
        if (!this.isNumber1(value)) {
          callback(new Error('半角数値を入力してください'))
        } else if (value < 0) {
          callback(new Error('ゼロ以上の数字のみ入力できます'))
        } else {
          callback()
        }
      }
    },

    validateTransSenario31(rule, value, callback){
      if (value === '') {
        callback(new Error(this.$t('messages.pets')))
      } else {
        for (var item of this.transSenarioOptions) {
          if (item.senarioid === value) {
            this.seihinyusoForm.trafficDistance3 = item.trans_distance
          }
        }
        callback()
      }
    },
    validateProductWeight1(rule, value, callback){
      if (value === '') {
        callback(new Error(this.$t('messages.pepw')))
      } else {
        if (!this.isNumber1(value)) {
          callback(new Error('半角数値を入力してください'))
        } else if (value < 0) {
          callback(new Error('ゼロ以上の数字のみ入力できます'))
        } else {
          callback()
        }
      }
    },
    checkInterimproduct1(rule, value, callback) {
      if (value !== undefined && value !== null && value !== '' && value.length > 40) {
        return callback(new Error('40バイト以下を入力してください'))
      }
    },
    // 親子関係フィールドの検証
    checkParentnode1(rule, value, callback) {
      if (!value || value === '') {
        return callback()
      }
      const hasParentnodeArr = this.treeDataList.filter(item => item.itemname === value)
      const selfParentnodeArr = this.tempValidateTree.filter(item => item.itemname === value && item.parentnode === value)
      if (hasParentnodeArr.length < 1) {
        this.alterType = 1
        return callback(new Error(this.$t('messages.addCheckParNo')))
      }
      if (selfParentnodeArr.length > 0) {
        this.alterType = 3
        return callback(new Error(this.$t('messages.addCheckParSame')))
      }
    },
    // flagフォーマットの削除
    formatDelflg(dataIdeacode, dataIdeaVersion) {
      const tempIdeaArr = this.allDataList.find((item)=> {
        if (dataIdeaVersion && dataIdeaVersion != '') {
          if(item.ideacode == dataIdeacode && item.version == dataIdeaVersion) {
            return item.ideacode == dataIdeacode
          }
        } else {
          if(item.ideacode == dataIdeacode) {
            return item.ideacode == dataIdeacode
          }
        }
      })
      if (tempIdeaArr && tempIdeaArr.length > 0) {
        return tempIdeaArr[0].delflg
      }
    },
    surmiseTreeIdeaName(itemName, index) {
      if (itemName && itemName.trim() !== '') {
        getLikeItemListAPI(this.kigyouCode, itemName)
          .then(res => {
            if (res.code === 200) {
              res.data.forEach(element => {
                if (element.name.indexOf('a3b523ac16674572848091098e') > -1) {
                  element.name = element.name.replace('a3b523ac16674572848091098e', ' ')
                }
              })
              this.surmiseIdeaData = res.data
              if(this.surmiseIdeaData.length === 0) {
                this.unregisteredEFShow = true
              } else {
                this.unregisteredEFShow = false
              }
              this.surmiseTreeIndex = index
              this.surmiseShow = true
            } else {
              this.rejectMsg()
            }
          }).catch((error) => {
            console.log('error', error)
          })
      }
    },

    surmiseIdeaName(itemName, subProcessIndex, subProcessItemIndex) {
      if (itemName && itemName.trim() !== '') {
        getLikeItemListAPI(this.kigyouCode, itemName)
          .then(res => {
            if (res.code === 200) {
              res.data.forEach(element => {
                if (element.name && element.name.indexOf('a3b523ac16674572848091098e') > -1) {
                  element.name = element.name.replace('a3b523ac16674572848091098e', ' ')
                }
              })
              this.surmiseIdeaData = res.data
              if(this.surmiseIdeaData.length === 0) {
                this.unregisteredEFShow = true
              } else {
                this.unregisteredEFShow = false
              }
              this.surmiseSubProcessIndex = subProcessIndex
              this.surmiseSubProcessItemIndex = subProcessItemIndex
              this.surmiseShow = true
            } else {
              this.rejectMsg()
            }
          }).catch((error) => {
            console.log('error', error)
          })
      }
    },

    selectSurmiseIdea(selectedIdea) {
      const surmiseArray = this.nameList.filter(item => {
        if (item.version) {
          return item.ideacode === selectedIdea.ideacode && item.version === selectedIdea.ideaversion
        } else {
          return item.ideacode === selectedIdea.ideacode
        }
      })
      if (surmiseArray.length > 0) { 
        if (this.parentFlag == 1 && this.noTreeFromCsvOrApi == false) {
          this.inputChange(surmiseArray[0].displayname, this.surmiseSubProcessIndex, this.surmiseSubProcessItemIndex)
        } else if (this.parentFlag == 1 && this.noTreeFromCsvOrApi) {
          this.newInputChange(surmiseArray[0].displayname, this.surmiseTreeIndex)
        } else {
          this.newInputChange(surmiseArray[0].displayname, this.surmiseTreeIndex)
        }
      }
      this.surmiseShow = false
      this.unregisteredEFShow = false
    },

    formatDate(dateTime) {
      const date = new Date(dateTime)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      const formatDate = year + '/' + month + '/' + day
      return formatDate
    },

    checkCsvParentnode1(rule, value, callback){
      if (!value || value === '') {
        return callback()
      }
      const hasParentnodeArr = this.csvTableData.filter(item => item.itemName == value)
      const selfParentnodeArr = this.csvTempValidateTree.filter(item => item.itemName == value && item.parentNode == value)
      if (hasParentnodeArr.length < 1) {
        return callback(new Error(this.$t('messages.addCheckParNo')))
      }
      if (selfParentnodeArr.length > 0) {
        return callback(new Error(this.$t('messages.addCheckParSame')))
      }
    },
    // 親子関係なし単位変換方法
    unitTransfer(unit, ideaCode, subProcessIndex, subProcessItemIndex, version){
      getUnitListAPI(this.kigyouCode, unit, ideaCode, version)
      .then(res => {
        if(res.code === 200){
          let per = 1
          if (res.data.length > 0) {
            for (var i = 0; i < res.data.length; i++) {
              if (res.data[i].tounit == this.processList[subProcessIndex][subProcessItemIndex].unit) {
                if (res.data[i].generation !== this.processList[subProcessIndex][subProcessItemIndex].unitgeneration) {
                  if (!this.processList[subProcessIndex][subProcessItemIndex].generationBoolean) {
                    this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'unitgenerationBoolean', true)
                  }
                  this.processList[subProcessIndex][subProcessItemIndex].unitgeneration = res.data[i].generation
                } else {
                }
              }
              if (res.data[i].fromunit == this.processList[subProcessIndex][subProcessItemIndex].unit) {
                break
              }
              if (res.data[i].tounit == this.processList[subProcessIndex][subProcessItemIndex].unit) {
                per = res.data[i].percent
              }
            }
          }
          const tempUnitlist = [{'percent': per,'tounit':unit, 'generation':0, 'delflg': 0}, ...res.data.filter(item => item != null)]
          // 単位ドロップダウンコンテンツ代入
          this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'unitList', tempUnitlist)
          this.processList[subProcessIndex][subProcessItemIndex].unitDeleted = false
        }
      })
    },
    // 親子関係なし 単位世代処理ロジック
    unitStandGengrate(processList, callSum) {
      for (const subProcessIndex in processList) {
        for (const subProcessItemIndex in this.processList[subProcessIndex]) {
          let checkTemp = this.allDataList.filter(item => {
            if (item.version) {
              return item.ideacode === this.processList[subProcessIndex][subProcessItemIndex].ideacode && item.version === this.processList[subProcessIndex][subProcessItemIndex].ideaVersion
            } else {
              return item.ideacode === this.processList[subProcessIndex][subProcessItemIndex].ideacode
            }
          })
          if (checkTemp && checkTemp[0]) {
            this.processList[subProcessIndex][subProcessItemIndex].fromunit = checkTemp[0].unit
            this.processList[subProcessIndex][subProcessItemIndex].ideaUnitDelflg = checkTemp[0].delflg
          }
          // 最新の単位世代情報の取得
          let tempUnitList = this.processList[subProcessIndex][subProcessItemIndex].unitList
          if (tempUnitList.length > 0) {
            this.processList[subProcessIndex][subProcessItemIndex].unitList = this.processList[subProcessIndex][subProcessItemIndex].unitList.filter(item => item.delflg == 0)
            for (var i = 0; i < tempUnitList.length; i++) {
              if (tempUnitList[i].tounit == this.processList[subProcessIndex][subProcessItemIndex].unit) {
                this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'transferUnitPercent', tempUnitList[i].percent)
                // 現在の行の単位世代は最新の世代とは異なります
                if (tempUnitList[i].generation !== this.processList[subProcessIndex][subProcessItemIndex].unitgeneration) {
                  // 原単位発生世代なし
                  if (!this.processList[subProcessIndex][subProcessItemIndex].generationBoolean) {
                    // 単位世代フラグをtrueに設定
                    this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'unitgenerationBoolean', true)
                    // 副単位削除falseに設定
                    this.processList[subProcessIndex][subProcessItemIndex].unitDeleted = false
                  }
                  // 最新の世代を現在のデータに割り当てる
                  this.processList[subProcessIndex][subProcessItemIndex].unitgeneration = tempUnitList[i].generation
                } else {
                  if (tempUnitList.length === 1) {
                    // 最新の世代を現在のデータに割り当てる
                    this.processList[subProcessIndex][subProcessItemIndex].unitgeneration = tempUnitList[i].generation
                    this.processList[subProcessIndex][subProcessItemIndex].unitDeleted = false
                  } else {
                    if (!this.processList[subProcessIndex][subProcessItemIndex].generationBoolean) {
                      if (tempUnitList[i].delflg == 1) {
                        // 副単位削除フラグをtrueに設定
                        this.processList[subProcessIndex][subProcessItemIndex].unitDeleted = true
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (callSum) {
        // 最新の排出総量の計算
        this.sum1()
        if (!this.deleteProcessFlag) {
          this.pageLoading.close()
          this.deleteProcessFlag = false
        }
      }
    },
    // 単位変更時の代入方法のトリガ 親子関係なし
    unitChange(value, unitList, subProcessIndex, subProcessItemIndex) {
      this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'unitgenerationBoolean', false)
      this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'unitDeleted', false)
      let unitValue = value.split('/unitlabel/')[0]
      const unitLabel = value.split('/unitlabel/')[1]
      if (unitList && unitList.length > 1) {
        if (unitLabel == unitList[1].fromunit) {
          unitValue = '1'
        }
      }
      if (isNaN(Number(unitValue))) {
        unitValue = '1'
      }
      if (this.processList[subProcessIndex][subProcessItemIndex].budomari && this.processList[subProcessIndex][subProcessItemIndex].budomari !== '') {
        this.processList[subProcessIndex][subProcessItemIndex].emissionc = this.processList[subProcessIndex][subProcessItemIndex].quantity * (this.processList[subProcessIndex][subProcessItemIndex].coefficient / (parseFloat(this.processList[subProcessIndex][subProcessItemIndex].budomari) / 100) * Number(unitValue))
      } else {
        this.processList[subProcessIndex][subProcessItemIndex].emissionc = this.processList[subProcessIndex][subProcessItemIndex].quantity * (this.processList[subProcessIndex][subProcessItemIndex].coefficient * Number(unitValue))
      }
      this.processList[subProcessIndex][subProcessItemIndex].unitDeleted = false
      let obj = {};
      obj = unitList.find((item)=>{
      return item.tounit === unitLabel})
      this.processList[subProcessIndex][subProcessItemIndex].transferUnit = obj.tounit
      this.processList[subProcessIndex][subProcessItemIndex].transferUnitPercent = Number(unitValue)
      this.processList[subProcessIndex][subProcessItemIndex].unitgeneration = obj.generation
      this.$set(this.processList[subProcessIndex][subProcessItemIndex], 'generationBoolean', false)
      this.sum1()
    },
    // 単位変更時の代入方法のトリガ 親子関係あり
    unittreeChange(value, unitList, index){
      this.$set(this.treeDataList[index], 'unitgenerationBoolean', false)
      this.$set(this.treeDataList[index], 'unitDeleted', false)
      let unitValue = value.split('/unitlabel/')[0]
      const unitLabel = value.split('/unitlabel/')[1]
      if (unitList && unitList.length > 1) {
        if (unitLabel == unitList[1].fromunit) {
          unitValue = '1'
        }
      }
      if (isNaN(Number(unitValue))) {
        unitValue = '1'
      }
      if (this.treeDataList[index].budomari && this.treeDataList[index].budomari !== '') {
        this.treeDataList[index].emissionc = this.treeDataList[index].quantity * (this.treeDataList[index].coefficient / (parseFloat(this.treeDataList[index].budomari) / 100) * Number(unitValue))
      } else {
        this.treeDataList[index].emissionc = this.treeDataList[index].quantity * (this.treeDataList[index].coefficient) * Number(unitValue)
      }
      this.treeDataList[index].unitDeleted = false
      let obj = {};
      obj = unitList.find((item)=>{
      return item.tounit === unitLabel})
      this.treeDataList[index].transferUnit = obj.tounit
      this.treeDataList[index].transferUnitPercent = Number(unitValue)
      this.treeDataList[index].unitgeneration = obj.generation
      this.$set(this.treeDataList[index], 'generationBoolean', false)
      this.sum2()
    },
    // ファイルアップロード時にポップアップ中の単位が変化するとトリガーされる
    unitCsvChange(value, unitList, index){
      this.$set(this.csvTableData[index], 'unitgenerationBoolean', false)
      this.$set(this.csvTableData[index], 'unitDeleted', false)
      const unitValue = value.split('/unitlabel/')[0]
      const unitLabel = value.split('/unitlabel/')[1]
      let obj = {};
      obj = unitList.find((item)=>{
      return item.tounit === unitLabel})
      this.csvTableData[index].transferUnit = obj.tounit
      this.csvTableData[index].transferUnitPercent = obj.percent
      this.csvTableData[index].unitgeneration = obj.generation
      this.$set(this.csvTableData[index], 'generationBoolean', false)
    },
    
    unittreeTransfer(unit, ideacode, index, version){
      getUnitListAPI(this.kigyouCode, unit, ideacode, version)
      .then(res => {
        if(res.code === 200){
          let per = 1
            if (res.data.length > 0) {
              for (var i = 0; i < res.data.length; i++) {
                if (res.data[i].tounit == this.treeDataList[index].unit) {
                  // 現在の行の単位世代は最新の世代とは異なります
                  if (res.data[i].generation !== this.treeDataList[index].unitgeneration) {
                    // 原単位発生世代なし
                    if (!this.treeDataList[index].generationBoolean) {
                      // 単位世代フラグをtrueに設定
                      this.$set(this.treeDataList[index], 'unitgenerationBoolean', true)
                    }
                    // 最新の世代を現在のデータに割り当てる
                    this.treeDataList[index].unitgeneration = res.data[i].generation
                  } else {
                  }
                }
                if (res.data[i].fromunit == this.treeDataList[index].unit) {
                  break
                }
                if (res.data[i].tounit == this.treeDataList[index].unit) {
                  per = res.data[i].percent
                }
              }
            }
          let tempUnitTreelist = []
          if (this.treeDataList[index].subassyFromId) {
           tempUnitTreelist = [{'percent':1,'tounit':unit, 'delflg': 0, 'generation':0}, ...res.data.filter(item => item != null)]
          } else {
            tempUnitTreelist = [{'percent':per,'tounit':unit, 'delflg': 0, 'generation':0}, ...res.data.filter(item => item != null)]
          }
          // 代入単位ドロップダウン
          this.$set(this.treeDataList[index], 'unitList', tempUnitTreelist)
          if (this.treeDataList[index].subassyFromId) {
            let callTempUnitFilter = tempUnitTreelist.filter(usedItem => usedItem.tounit === this.treeDataList[index].unit)
            if (callTempUnitFilter.length === 0) {
              if (this.treeDataList[index].generationBoolean  || this.treeDataList[index].isDeleted) {
                this.treeDataList[index].unitDeleted = false
              } else {
                // 原単位に世代が発生しておらず、削除されていない場合。単位削除フラグはtrue
                this.treeDataList[index].unitDeleted = true
              }
            }
          } else {
            this.treeDataList[index].unitDeleted = false
          }
        }
      })
    },
    // 親子関係あり 単位世代処理ロジック
    unitTreeGengrate(treeDataList, callSum) {
      for(const index in treeDataList){
        let selectedDataArr = {}
        selectedDataArr = this.allDataList.find(item => {
          if (this.treeDataList[index].ideaVersion) {
            return item.ideacode === this.treeDataList[index].ideacode && item.version === this.treeDataList[index].ideaVersion
          } else {
            return item.ideacode === this.treeDataList[index].ideacode
          }
        })
        if (selectedDataArr) {
          this.treeDataList[index].fromunit = selectedDataArr.unit
          this.treeDataList[index].ideaUnitDelflg = selectedDataArr.delflg
        }
        let tempUnitList = this.treeDataList[index].unitList
        if (tempUnitList.length > 0) {
          this.treeDataList[index].unitList = this.treeDataList[index].unitList.filter(item => item.delflg == 0)
          for (var i = 0; i < tempUnitList.length; i++) {
            if (tempUnitList[i].tounit == this.treeDataList[index].unit) {
              this.$set(this.treeDataList[index], 'transferUnitPercent', tempUnitList[i].percent)
              if (tempUnitList[i].generation !== this.treeDataList[index].unitgeneration) {
                if (!this.treeDataList[index].generationBoolean) {
                  this.$set(this.treeDataList[index], 'unitgenerationBoolean', true)
                  this.treeDataList[index].unitDeleted = false
                }
                this.treeDataList[index].unitgeneration = tempUnitList[i].generation
              } else {
                if (tempUnitList.length === 1) {
                  this.treeDataList[index].unitgeneration = tempUnitList[i].generation
                  this.treeDataList[index].unitDeleted = false
                } else {
                   if (!this.treeDataList[index].generationBoolean) {
                     if (tempUnitList[i].delflg == 1) {
                       this.treeDataList[index].unitDeleted = true
                     }
                  }
                }
              }
            }
          }
        }
      }
      if (callSum) {
        this.sum2()
        this.pageLoading.close()
      }
    },
    clearSelect () {
      for (let i = 0; i < this.csvTableData.length; i++) {
        this.$set(this.csvTableData[i], 'checkCsvFlag', false)
      }
    },
    inputItemNameChange(itemName, csvItemIndex) {
      if (!itemName || itemName == '') {
        this.noItemNameFlag = 1;
      } else {
        this.noItemNameFlag = 0;
      }
      this.csvTableData.forEach((item, Index, arr) => {
        if(item.index === arr[csvItemIndex].index) {
          item.itemName = itemName
        }
      })
    },
    // アップロードテンプレートのダウンロード(親子関係なし)
    downloalNoParent() {
      let routeUrl = ''
      if (this.language === 'jp') {
        routeUrl = this.$router.resolve({
          path: '/static/LCA Plus 転記シート（親子関係なし）エクセルファイルフォーマット.xlsx'
        })
      } else {
        routeUrl = this.$router.resolve({
          path: '/static/LCA Plus Upload File Format(No parent-subsidiary relation).xlsx'
        })
      }
      window.open(routeUrl.href, '_blank')
    },
    // アップロードテンプレートのダウンロード(親子関係あり)
    downloalParent() {
      let routeUrl = ''
      if (this.language === 'jp') {
        routeUrl = this.$router.resolve({
          path: '/static/LCA Plus 転記シート（親子関係あり）エクセルファイルフォーマット.xlsx'
        })
      } else {
        routeUrl = this.$router.resolve({
          path: '/static/LCA Plus Upload File Format(Parent-subsidiary relation exists).xlsx'
        })
      }
      window.open(routeUrl.href, '_blank')
    },
    changeChackStatus(itemIndex) {
      this.$set(this.csvTableData[itemIndex], 'chackStatus', true)
    },
    dataCallClick(itemname, index) {
      this.$store.commit('SET_SUB_ID', '')
     if (this.statusDisabled) {
        return
      }
      if(this.buttonFlag === 1) {
        this.callOriprocessFlg = false
      } else {
        this.callOriprocessFlg = true
      }
      getTreeListAPI(this.kigyouCode, Number(this.callOriprocessFlg)).then(
        async res => {
          if (res.code === 200) {
            this.seihinCallList = res.data
          } else {
            this.rejectMsg()
          }
        }
      ).catch(
        (error) => {
          console.log('error', error)
        }
      )
      if(itemname !== '') {
        this.isShowCallPopup = true
        this.callItemname = itemname
        this.callItemIndex = index
        this.backFlag = ''
        this.tempCallDataList = this.callDataList
      }
    },
    subDataCallClick(callDataItem, index, callIndex) {
     this.$store.commit('SET_SUB_ID', '')
     if (this.statusDisabled) {
        return
      }
      if(this.buttonFlag === 1) {
        this.callOriprocessFlg = false
      } else {
        this.callOriprocessFlg = true
      }
      getTreeListAPI(this.kigyouCode, Number(this.callOriprocessFlg)).then(
        async res => {
          if (res.code === 200) {
            this.seihinCallList = res.data
          } else {
            this.rejectMsg()
          }
        }
      ).catch(
        (error) => {
          console.log('error', error)
        }
      )
      this.isShowCallPopup = true
      this.callItemname = this.treeDataList[index+callDataItem.subassyIdArr.length-1].itemname
      this.callItemIndex = index + callDataItem.subassyIdArr.length - 1      
      this.backFlag = ''
      this.tempCallDataList = this.callDataList    
    },
    // データ呼び出し ポップアップで選択したデータを製品画面に戻す
    parentFnCallData(valueFromChild) {
      this.isShowCallPopup = valueFromChild['isShowCallPopup']
      if(valueFromChild['subassyIdArr']) {
        let subassyIdArr = valueFromChild['subassyIdArr']
        this.callDataList = valueFromChild['callDataList']
        let index = this.callDataList.length - 1
        let seihinCode = this.callDataList[index].seihinCallCode
        let version = this.callDataList[index].callVersion
        let oneDetailLength = valueFromChild['oneDetailLength']
        let callIndex = this.callDataList.length - 1
        let newUuid = uuidv4()
        newUuid = newUuid.replace(/[-]/g, '')
        this.$set(this.callDataList[callIndex], 'callParentNode', this.callItemname)
        this.$set(this.callDataList[callIndex], 'topSubassyId', newUuid)
        this.$set(this.callDataList[callIndex], 'subassyIdArr', subassyIdArr)
        let firstFlag = true
        this.showPageLoading()
        // copy形式入力
        if(this.fromPage === 'allPage' && this.copyFlag) {
          // 選択したすべての製品詳細データを取得する
          getDetailBySubassyIdAPI(subassyIdArr, seihinCode, version).then(
            res => {
              if(res.code === 200) {
                let callTreeDataListTemp = res.data
                callTreeDataListTemp.map(item => { this.$set(item, 'callParentShow', false) })
                for (var i = 0; i < oneDetailLength; i++) {
                  callTreeDataListTemp[i].parentNode = this.callItemname
                  callTreeDataListTemp[i].callParentShow = true
                }
                callTreeDataListTemp.map(item => {
                  item.interimproduct = this.callDataList[callIndex].seihinCallName + ' ' + this.callDataList[callIndex].callVersion
                  item.subassyFromId = item.subassyId
                  item.subassyId = ''
                  if (firstFlag) {
                    item.topSubassyId = newUuid
                    firstFlag = false
                  } else {
                    item.topSubassyId = ""
                  }
                  this.$set(item, 'parentnode', item.parentNode)
                  this.allDataList.map(nameitem => {
                    if(nameitem.version) {
                      if(nameitem.version === item.ideaVersion && nameitem.ideacode === item.ideacode) {
                        this.$set(item, 'displayname', nameitem.name)
                        this.$set(item, 'name', nameitem.name)
                        this.$set(item, 'fromunit', nameitem.unit)
                        this.$set(item, 'coefficient', nameitem.coefficient)
                      }
                    } else {
                      if(nameitem.ideacode === item.ideacode) {
                        this.$set(item, 'displayname', nameitem.name)
                        this.$set(item, 'name', nameitem.name)
                        this.$set(item, 'fromunit', nameitem.unit)
                        this.$set(item, 'coefficient', nameitem.coefficient)
                      }                  
                    }
                  }) 
                  let filterList = []
                  if (item.ideaVersion) {
                    filterList = this.nameList.filter(nameItem => nameItem.ideacode === item.ideacode && nameItem.version == item.ideaVersion)
                  } else {
                    filterList = this.nameList.filter(nameItem => nameItem.ideacode === item.ideacode)
                  }
                  item.isDeleted = false
                  if (filterList.length > 0) {
                    item.generationBoolean = (filterList[0].generation !== item.ideageneration)
                    item.generation = filterList[0].generation
                  } else {
                    item.isDeleted = true
                  }             
                  this.treeDataList.splice(this.callItemIndex+1, 0, item)
                  this.callItemIndex++
                })
                this.treeDataList = JSON.parse(JSON.stringify(this.treeDataList))
                this.treeDataForm.treeDataItem = this.treeDataList
                this.unitTreeGengrate(this.treeDataList, true)
              } else {
                this.rejectMsg()
              }
            }
          ).catch(
            (error) => {
              console.log('error', error)
            }
          )
        } else {
          checkGenerationAPI(this.kigyouCode, subassyIdArr, seihinCode, version).then(
            res => {
              if(res.code === 200) {
                if(res.data) {
                  this.$alert('削除ないし見直された原単位が含まれているため呼び出しできません。編集画面で当該データを確認した後、再度呼び出しをしてください', this.$t('messages.confirm'), {
                    confirmButtonText: this.$t('messages.yes'),
                    type: 'warning',
                    center: true
                  }).then(() => {
                    this.pageLoading.close()
                    return
                  })              
                } else {
                  // 選択したすべての製品詳細データを取得する
                  getDetailBySubassyIdAPI(subassyIdArr, seihinCode, version).then(
                    res => {
                      if(res.code === 200) {
                        let callTreeDataListTemp1 = res.data
                        for (var i = 0; i < oneDetailLength; i++) {
                          callTreeDataListTemp1[i].parentNode = this.callItemname
                        }
                        callTreeDataListTemp1.map(item => {
                          item.interimproduct = this.callDataList[callIndex].seihinCallName + ' ' + this.callDataList[callIndex].callVersion
                          item.subassyFromId = item.subassyId
                          item.subassyId = ''
                          if (firstFlag) {
                            item.topSubassyId = newUuid
                            firstFlag = false
                          } else {
                            item.topSubassyId = ""
                          }
                          this.$set(item, 'parentnode', item.parentNode)
                          this.allDataList.map(nameitem => {
                            if(nameitem.version) {
                              if(nameitem.version === item.ideaVersion && nameitem.ideacode === item.ideacode) {
                                this.$set(item, 'displayname', nameitem.name)
                                this.$set(item, 'name', nameitem.name)
                                this.$set(item, 'fromunit', nameitem.unit)
                              }
                            } else {
                              if(nameitem.ideacode === item.ideacode) {
                                this.$set(item, 'displayname', nameitem.name)
                                this.$set(item, 'name', nameitem.name)
                                this.$set(item, 'fromunit', nameitem.unit)
                              }                  
                            }
                          }) 
                          let filterList = []
                          if (item.ideaVersion) {
                            filterList = this.nameList.filter(nameItem => nameItem.ideacode === item.ideacode && nameItem.version == item.ideaVersion)
                          } else {
                            filterList = this.nameList.filter(nameItem => nameItem.ideacode === item.ideacode)
                          }
                          item.isDeleted = false
                          if (filterList.length > 0) {
                            item.generationBoolean = (filterList[0].generation !== item.ideageneration)
                            item.generation = filterList[0].generation
                          } else {
                            item.isDeleted = true
                          }
                          this.treeDataList.splice(this.callItemIndex+1, 0, item)
                          this.callItemIndex++
                        })
                        this.treeDataList = JSON.parse(JSON.stringify(this.treeDataList))
                        this.treeDataForm.treeDataItem = this.treeDataList
                        this.unitTreeGengrate(this.treeDataList, true)
                      } else {
                        this.rejectMsg()
                      }
                    }
                  ).catch(
                    (error) => {
                      console.log('error', error)
                    }
                  )                
                } 
              } else {
                this.rejectMsg()
              }
            }
          ).catch(
            (error) => {
              console.log('error', error)
            }
          )          
        }
      }
    },
    // データ呼び出し表示制御
    callShow(subassyFromId, index) {
      if(this.treeDataList.length -1 === index) {
        return true
      } else {
        if(subassyFromId && this.treeDataList[index+1].subassyFromId) {
          return false
        } else {
          return true
      }        
      }     
    },    
    focusTempArr(treeDataItem) {
      this.tempValidateTree = []
      treeDataItem.callParentShow = false
      this.tempValidateTree.push(treeDataItem)
    },    
    // データ呼び出し ポップアップ表示制御
    callBackTreePop(callDataItem) {
      if (this.statusDisabled) {
        return
      }
      if(this.buttonFlag === 1) {
        this.callOriprocessFlg = false
      } else {
        this.callOriprocessFlg = true
      }
      this.isShowCallPopup = true
      this.backFlag = '1'
      let backCallVal = callDataItem.seihinCallName + ' ' + callDataItem.callVersion
      this.$nextTick(() => {
      this.$refs.callBackPopup.backChangeSeihin(callDataItem.subassyIdArr[0].subassyId, backCallVal, callDataItem.seihinCallUnit, callDataItem.seihinCallCode, callDataItem.callVersion)
      })
    },
    uploadCountSumAPI(upduser, uploadCreateFlag) {
      uploadCountSumAPI(upduser, uploadCreateFlag).then(
        res => {
          if(res.code === 200) {
          } else {
            this.rejectMsg()
          }
        }
      ).catch(
        (error) => {
          console.log('error', error)
        }
      )  
    },
    transferToNumber(inputNumber) {
      if (isNaN(inputNumber)) {
        return inputNumber
      }
      if (!inputNumber) {
        return inputNumber
      }
      inputNumber = '' + inputNumber
      inputNumber = parseFloat(inputNumber)
      let eformat = inputNumber.toExponential() // 转换为标准的科学计数法形式（字符串）
      let tmpArray = eformat.match(/\d(?:\.(\d*))?e([+-]\d+)/) // 分离出小数值和指数值
      let number = inputNumber.toFixed(Math.max(0, (tmpArray[1] || '').length - tmpArray[2]))
      return number 
    },
     getCurrentTime() {
        //获取当前时间并打印
        var _this = this;
        let yy = new Date().getFullYear();
        let mm = new Date().getMonth()+1;
        let dd = new Date().getDate();
        let hh = new Date().getHours();
        let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
        let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
        _this.gettime = yy+'/'+mm+'/'+dd+' '+hh+':'+mf+':'+ss;
    }
  }
}
</script>
<style lang="scss" scoped>
.button-flag {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
}
.el-form-item {
  margin-bottom: 2rem;
}
.button-group {
  display: flex;
  align-items: center;
  border: 2px solid rgba(232,232,232,0.53);
  box-shadow: inset 0 0 0.8rem 0 rgba(0,0,0,0.25);
  border-radius: 0.4rem;
  font-size: 1.4rem;
  color: #333;
  justify-content: center;
  span {
    display: inline-block;
    padding:0.8rem 1.6rem;
    background-color: rgba(232,232,232,0.53);
    border-radius: 0.4rem;
    font-size: 1.4rem;
    cursor: pointer;
  }
}
.active {
    color: #409EFF;
    background-color: #FFF!important;
    border-color: #FFF;
    font-weight: bold;
    box-shadow: inset 0 0 0.8rem 0 #FFF;
    border-radius: 0.4rem;
}
.margin-btm-20 {
  margin-bottom: 2rem;
}
.edit-icon {
  margin:2.6rem 0 0 1.6rem;
  font-size: 1.6rem;
  .icon-size {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    background: #E4F1F8;
    border-radius: 0.4rem;
    align-items: center;
    justify-content: center;
  }
}
.seihin-name-select{
  font-family: 'NotoSansCJKjp-Medium';
  font-size: 1.4rem;
  color: #666666;
  letter-spacing: 0;
  font-weight: 500;
  margin-bottom: 0.5rem;
  width: 25%;
  .select-item{
    width: 100%;
  }
}
.rightBtn {
    display: flex;
    width: 90%;
    font-size: 1.4rem;
}
.seihin-version{
  width: 14%;
  font-family: 'NotoSansCJKjp-Medium';
  font-size: 1.4rem;
  color: #666666;
  letter-spacing: 0;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-left: 1.6rem;
}
.seihin-btn{
  width: 6%;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  margin-left: 1.6rem;
}
.content-center {
  text-align: center;
  display: block;
}
.emissionc-sum{
  flex-direction: column;
  width: 18%;
  font-family: 'NotoSansCJKjp-Medium';
  font-size: 1.4rem;
  color: #666666;
  letter-spacing: 0;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-left: 1.6rem;
}
.margin-right-16 {
  margin-right:1.6rem;
}
.padding-top-24 {
  padding-top:2.4rem;
}
.padding-top-20 {
  padding-top:2rem;
}
.padding-top-14 {
  padding-top:0.75rem;
}
.check-note {
  position:absolute;
  top:5.6rem;
}
.popup-area {
  display: flex;
  align-items:flex-start;
  width: 100%;
  border-top:1px solid #eee;
  .check-area {
    width:3.2rem;
    margin-top:1.6rem;
  }
  .check-content {
    width: calc( 100% - 3.2rem );
    .el-collapse {
      border-top:none;
      border-bottom: none;
    }
    .font-color {
      color:#555;
    }
  }
}
.popup-height {
  max-height: 45vh;
  overflow-y: scroll;
}
.text-line {
  line-height: 1.4;
  margin: 0;
}
.text-line1 {
  font-size: 2rem;
}
.text-line2 {
  font-size: 1.6rem;
}
.check-list-title {
  margin: 1.6rem 0;
  font-size: 1.6rem;
  color: #171717;
}
.check-break-line {
  white-space: pre-line;
}
.width-100 {
    width: 100%;
  }
  .product-value {
    display: flex;
    width: 100%;
    & span {
      display: inline-block;
      min-width: 3.2rem;
      margin-left: 1rem;
      font-weight: 400;
    }
  }
  .el-select {
    width: 100%
  }
  .komoku-div-parentmode {
    margin: 0.5rem 0.5rem 0.5rem 0;
    width: 12%;
  }
  .genntani-div-parentmode {
    margin: 0.5rem;
    width: 12%;

    .genntani-input {
      width: 100%;
    }
  }

  .katsudo-div-tempmode {
    margin: 0.5rem;
    width: 1.8%;
  }
  .katsudo-div-parentmode {
    margin: 0.5rem;
    width: 9%;

    .katsudo-hasLabel-div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .traffic-div {
        cursor: pointer;
        font-size: 1.2rem;
      }
    }      

    .katsudo-noLabel-div {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .traffic-div {
        cursor: pointer;
        font-size: 1.2rem;
      }
    }
  }
  .katsudo-div-getdatamode {
    margin: 0.5rem;
    width: 10.5%;
  }
  .tanni-div-parentmode {
    margin: 0.5rem 0.5rem 0 0.5rem;
    width: 8%;
  }
  .rate-div-parentmode {
    margin: 0.5rem 0.5rem 0 0.5rem;
    width: 8%;
  }
  .emission-div-parentmode {
    margin: 0.5rem 0.5rem 0 0.5rem;
    width: 9%;
    white-space: nowrap;
  }
  .delete-icon-div-parentmode {
    width: 4%;
    height: 66%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;

    .el-icon-delete {
      cursor: pointer;
    }
  }
.item-button-parentmode {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  .new-item-button {
    font-family: 'NotoSansCJKjp-Medium';
    font-size: 1.6rem;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    margin: 1.6rem;
    cursor: pointer;
  }
}
.subProcessForm-parentmode {
  display: flex;
  content-visibility: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  .item-title {
    font-family: 'NotoSansCJKjp-Medium';
    font-size: 1.4rem;
    color: #666666;
    letter-spacing: 0;
    font-weight: 500;
    margin: 0.5rem;
  }
  .suggestion-icon {
    color: #52C433;
  }
}

.subProcessForm-newdatamode {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  border-left:5px solid #BFEBFA;
  .item-title {
    font-family: 'NotoSansCJKjp-Medium';
    font-size: 1.4rem;
    color: #666666;
    letter-spacing: 0;
    font-weight: 500;
    margin: 0.5rem;
  }
  .suggestion-icon {
    color: #52C433;
  }
}
.subProcessForm-getdatamode {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  background-color:#EBF9F5;
  .item-title {
    font-family: 'NotoSansCJKjp-Medium';
    font-size: 1.4rem;
    color: #666666;
    letter-spacing: 0;
    font-weight: 500;
    margin: 0.5rem;
  }
  .suggestion-icon {
    color: #52C433;
  }
}
.vertical-line {
    color:  rgba(0,0,0,0.25);
    font-size: 2.4em;
    margin-left: 3rem;
    margin-right: 3rem;
}
.surmise-process {
  display: flex;
  flex-direction: row;
  justify-content: end;
  text-decoration: underline;
  cursor: pointer;
}

.surmise-process-enable {
  color: #63a0ff;
}
.surmise-process-disable {
  color: #c0c4cc;
}

.surmise-idea-name {
  text-decoration: underline;
  color: #63a0ff;
  cursor: pointer;
}
.col-flex {
  flex-direction: column;
}
.btn-function {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.4rem;
    text-decoration: underline;
    cursor: pointer;
@media screen and (min-width:1200px) {
  .btn-function {
    font-size: 1.2rem;
  }
}
    .download-icon {
        height: 1.4rem;
        width: 1.4rem;
        margin-right:0.8rem;
    }
}
.margin-top-5 {
    display: flex;
    justify-content: end;
    margin-top: 0.5rem;
}
.txt {
  transform: scale(0.8);
}
</style>
