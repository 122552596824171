export default {
  components: {},
  methods: {
    getCurrentDate() {
      const date = new Date()
      const year = date.getFullYear() * 10000
      const month = (date.getMonth() + 1) * 100
      const day = date.getDate()
      const hour = date.getHours() * 10000
      const minute = date.getMinutes() * 100
      const second = date.getSeconds()
      const totalTimeStr = (year + month + day) * 1000000 + (hour + minute + second)
      const time = totalTimeStr.toString()
      return time
    },
    confirm(message, buttonName, callback, cancel) {
      this.$confirm(
        '<p style="font-family: PingFangSC-Semibold;font-size: 18px; color: #000000;">' + message + '</p><p>&nbsp</p>',
        {
          confirmButtonText: buttonName,
          cancelButtonText: 'キャンセル',
          dangerouslyUseHTMLString: true,
          confirmButtonClass: 'confirmButton',
          center: true,
          showClose: false
        }
      ).then(() => {
        callback()
      }).catch(() => {
        cancel()
      })
    },
    error2login() {
      this.$alert(
        '<p style="font-family: PingFangSC-Semibold;font-size: 18px; color: #ffffff;">システムエラーが発生しました、管理者にご連絡ください</p><p>&nbsp</p>',
        {
          confirmButtonText: 'はい',
          dangerouslyUseHTMLString: true,
          center: true,
          showClose: false
        }
      ).then(() => {
        // let cookie = this.$cookies.keys()
        // for (let i = 0 ; i < cookie.length; i ++) {
        //   this.$cookies.remove(cookie[i])
        // }
        this.$router.push({
          path: '/'
        })
      })
    },
    alert(message) {
      this.$alert(
        '<p style="font-family: PingFangSC-Semibold;font-size: 18px; color: #000000;">' + message + '</p><p>&nbsp</p>',
        {
          confirmButtonText: 'はい',
          confirmButtonClass: 'confirmButton',
          dangerouslyUseHTMLString: true,
          center: true,
          showClose: false
        }
      )
    },
    isBlank(val) {
      if (val === null || val === '' || val === 'null' || val === undefined || val === 'undefined') {
        return true
      }
      return false
    },
    pitInit(val) {
      return this.isBlank(val) ? '' : val
    }
  }
}
