var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "margin-lr-24 main-dashboard" },
    [
      _c(
        "el-row",
        { staticClass: "margin-top-24", attrs: { gutter: 16 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              { staticClass: "card-box" },
              [
                _c(
                  "h5",
                  { staticClass: "about-title content-layout" },
                  [
                    _c("span", { staticClass: "left-line margin-right-20" }, [
                      _vm._v(_vm._s(_vm.$t("messages.kpiTitle"))),
                    ]),
                    _c(
                      "el-upload",
                      {
                        staticStyle: {
                          display: "inline-flex",
                          "margin-right": "0",
                        },
                        attrs: {
                          "show-file-list": false,
                          accept: ".csv",
                          name: "csvFile",
                          headers: _vm.headers,
                          "on-success": _vm.onSuccess,
                          "on-error": _vm.onError,
                          action: _vm.actionup,
                          data: _vm.uploadData,
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "height-28",
                            attrs: { type: "primary", plain: "" },
                          },
                          [_vm._v(_vm._s(_vm.$t("messages.importName")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticClass: "line-24", attrs: { gutter: 16 } },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("h6", { staticClass: "sub-title" }, [
                        _vm._v(_vm._s(_vm.$t("messages.companyAll"))),
                      ]),
                      _c("div", { staticClass: "content-layout" }, [
                        _c("div", { staticClass: "production-item" }, [
                          _c("span", { staticClass: "des-title" }, [
                            _vm._v(
                              _vm._s(_vm.$t("messages.calculatedProduct1"))
                            ),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.$t("messages.calculatedProduct2"))
                            ),
                          ]),
                          _c("span", { staticClass: "des-number" }, [
                            _vm._v(
                              _vm._s(_vm.goalBoardData.total.finshSeihinTotal)
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "production-item" }, [
                          _c("span", { staticClass: "des-title" }, [
                            _vm._v(_vm._s(_vm.$t("messages.target"))),
                          ]),
                          _c("span", { staticClass: "des-number" }, [
                            _vm._v(_vm._s(_vm.goalBoardData.total.goalTotal)),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c(
                      "el-col",
                      {
                        staticClass: "echart-container-130",
                        attrs: { span: 12 },
                      },
                      [
                        _c("div", {
                          staticClass: "refer-chart",
                          attrs: { id: "pieChart" },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticClass: "margin-top-8",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.goalBoardData.dataList, height: "254" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "bumonName",
                        label: _vm.$t("messages.division"),
                        width: "65",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.bumonName))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "finshSeihinCount",
                        label: _vm.$t("messages.calculatedProduct"),
                        width: "70",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.finshSeihinCount)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "goalCount",
                        label: _vm.$t("messages.target"),
                        width: "50",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.goalCount))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "pre",
                        label: _vm.$t("messages.progress"),
                        width: "65",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.tag && scope.row.tag < 4
                                ? _c(
                                    "div",
                                    { staticClass: "tag-bg tag-bg-blue" },
                                    [_c("sup", [_vm._v(_vm._s(scope.row.tag))])]
                                  )
                                : _vm._e(),
                              scope.row.tag && scope.row.tag > 3
                                ? _c(
                                    "div",
                                    { staticClass: "tag-bg tag-bg-gray" },
                                    [_c("sup", [_vm._v(_vm._s(scope.row.tag))])]
                                  )
                                : _vm._e(),
                              scope.row.pre !== "-"
                                ? _c("span", [
                                    _vm._v(_vm._s(scope.row.pre) + "%"),
                                  ])
                                : _c("span", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              { staticClass: "card-box" },
              [
                _c("h5", { staticClass: "about-title" }, [
                  _c("span", { staticClass: "left-line margin-right-16" }, [
                    _vm._v(_vm._s(_vm.$t("messages.ideaUserRate"))),
                  ]),
                ]),
                _c(
                  "el-row",
                  { staticClass: "line-24", attrs: { gutter: 16 } },
                  [
                    _c("el-col", { attrs: { span: 13 } }, [
                      _c("h6", { staticClass: "sub-title" }, [
                        _vm._v(_vm._s(_vm.$t("messages.companyAll"))),
                      ]),
                      _c("div", { staticClass: "content-layout" }, [
                        _c(
                          "div",
                          { staticClass: "production-item margin-right-16" },
                          [
                            _c("span", { staticClass: "des-title" }, [
                              _vm._v(_vm._s(_vm.$t("messages.usage"))),
                            ]),
                            _c("span", { staticClass: "des-number" }, [
                              _vm._v(
                                _vm._s(_vm.ideaUseData.total.totalIdeaCount)
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "production-item" }, [
                          _c("span", { staticClass: "des-title width-60" }, [
                            _vm._v(_vm._s(_vm.$t("messages.totalItemsA11"))),
                            _c("br"),
                            _vm._v(_vm._s(_vm.$t("messages.totalItemsA12"))),
                          ]),
                          _c("span", { staticClass: "des-number" }, [
                            _vm._v(
                              _vm._s(_vm.ideaUseData.total.totalProcessA1Count)
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 11 } }, [
                      _c("div", { staticClass: "production-item" }, [
                        _c("span", { staticClass: "ratio" }, [
                          _vm._v(_vm._s(_vm.$t("messages.ratio"))),
                        ]),
                        _c("div", { staticClass: "progress-bar-outer" }, [
                          _c(
                            "div",
                            {
                              staticClass: "progress-bar-inner",
                              style: {
                                height:
                                  (Number(_vm.ideaUseData.total.totalPre) > 100
                                    ? 100
                                    : _vm.ideaUseData.total.totalPre) + "%",
                              },
                            },
                            [
                              _c("span", { staticClass: "progress-text" }, [
                                _vm._v(
                                  _vm._s(_vm.ideaUseData.total.totalPre) + "%"
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticClass: "margin-top-8",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.ideaUseData.dataList, height: "254" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "bumonName",
                        label: _vm.$t("messages.division"),
                        width: "60",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.bumonName))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ideaCount",
                        label: _vm.$t("messages.usage"),
                        width: "50",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.ideaCount))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "processA1Count",
                        label: _vm.$t("messages.totalItemsA1"),
                        width: "80",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.processA1Count)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "pre",
                        label: _vm.$t("messages.percentage"),
                        width: "65",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.tag && scope.row.tag < 4
                                ? _c(
                                    "div",
                                    { staticClass: "tag-bg tag-bg-blue" },
                                    [_c("sup", [_vm._v(_vm._s(scope.row.tag))])]
                                  )
                                : _vm._e(),
                              scope.row.tag && scope.row.tag > 3
                                ? _c(
                                    "div",
                                    { staticClass: "tag-bg tag-bg-gray" },
                                    [_c("sup", [_vm._v(_vm._s(scope.row.tag))])]
                                  )
                                : _vm._e(),
                              scope.row.pre !== "-"
                                ? _c("span", [
                                    _vm._v(_vm._s(scope.row.pre) + "%"),
                                  ])
                                : _c("span", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "card-box" },
              [
                _c("h5", { staticClass: "about-title" }, [
                  _c("span", { staticClass: "left-line margin-right-20" }, [
                    _vm._v(_vm._s(_vm.$t("messages.checkPerf"))),
                  ]),
                ]),
                _c(
                  "el-row",
                  { staticClass: "line-24", attrs: { gutter: 16 } },
                  [
                    _c("el-col", { attrs: { span: 18 } }, [
                      _c("h6", { staticClass: "sub-title" }, [
                        _vm._v(_vm._s(_vm.$t("messages.companyAll"))),
                      ]),
                      _c("div", { staticClass: "content-layout" }, [
                        _c("div", { staticClass: "production-item" }, [
                          _c("span", { staticClass: "des-title width-60" }, [
                            _vm._v(
                              _vm._s(_vm.$t("messages.totalNumberCalculated"))
                            ),
                          ]),
                          _c("span", { staticClass: "des-number" }, [
                            _vm._v(
                              _vm._s(
                                _vm.checkListBoardData.total.finshSeihinTotal
                              )
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "production-item margin-right-16" },
                          [
                            _c(
                              "span",
                              { staticClass: "des-title width-auto" },
                              [_vm._v(_vm._s(_vm.$t("messages.averagePoints")))]
                            ),
                            _c("span", { staticClass: "des-number" }, [
                              _vm._v(
                                _vm._s(_vm.checkListBoardData.total.totalAvg) +
                                  "/17"
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "production-item margin-right-30" },
                          [
                            _c("span", { staticClass: "des-title width-72" }, [
                              _vm._v(_vm._s(_vm.$t("messages.fullPoint"))),
                            ]),
                            _c("span", { staticClass: "des-number" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.checkListBoardData.total.fullPointTotal
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _c(
                      "el-col",
                      {
                        staticClass: "echart-container-130",
                        attrs: { span: 6 },
                      },
                      [
                        _c("div", {
                          staticClass: "refer-chart margin-top-4",
                          attrs: { id: "pieChart3" },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    staticClass: "margin-top-8",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.checkListBoardData.dataList,
                      height: "254",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "bumonName",
                        label: _vm.$t("messages.division"),
                        width: "200",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: scope.row.bumonName,
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "colum-width" }, [
                                    _vm._v(
                                      " " + _vm._s(scope.row.bumonName) + " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "finshSeihinCount",
                        label: _vm.$t("messages.totalNumberCalculated"),
                        width: "95",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.finshSeihinCount)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "avg",
                        label: _vm.$t("messages.averagePoints"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.avgTag && scope.row.avgTag < 4
                                ? _c(
                                    "div",
                                    { staticClass: "tag-bg tag-bg-blue" },
                                    [
                                      _c("sup", [
                                        _vm._v(_vm._s(scope.row.avgTag)),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.avgTag && scope.row.avgTag > 3
                                ? _c(
                                    "div",
                                    { staticClass: "tag-bg tag-bg-gray" },
                                    [
                                      _c("sup", [
                                        _vm._v(_vm._s(scope.row.avgTag)),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _c("span", [
                                _vm._v(_vm._s(scope.row.avg) + " / 17"),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "fullPoint",
                        label: _vm.$t("messages.fullPoint"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.fullPoint))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "fullPointPre",
                        label: _vm.$t("messages.fullPointPercentage"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.tag && scope.row.tag < 4
                                ? _c(
                                    "div",
                                    { staticClass: "tag-bg tag-bg-blue" },
                                    [_c("sup", [_vm._v(_vm._s(scope.row.tag))])]
                                  )
                                : _vm._e(),
                              scope.row.tag && scope.row.tag > 3
                                ? _c(
                                    "div",
                                    { staticClass: "tag-bg tag-bg-gray" },
                                    [_c("sup", [_vm._v(_vm._s(scope.row.tag))])]
                                  )
                                : _vm._e(),
                              scope.row.fullPointPre !== "-"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(scope.row.fullPointPre) + "%"
                                    ),
                                  ])
                                : _c("span", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "margin-tb-24", attrs: { gutter: 16 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "card-box" }, [
              _c("h5", { staticClass: "about-title" }, [
                _c("span", { staticClass: "left-line margin-right-20" }, [
                  _vm._v(_vm._s(_vm.$t("messages.responses2Partners"))),
                ]),
              ]),
              _c("div", { staticClass: "content-layout-hor margin-top-68" }, [
                _c("div", { staticClass: "production-item" }, [
                  _c("span", { staticClass: "upstream-22" }, [
                    _vm._v(_vm._s(_vm.$t("messages.response2Customers"))),
                  ]),
                  _vm.answerCount == 0
                    ? _c("div", { staticClass: "upstream-60-0" }, [
                        _vm._v(_vm._s(_vm.answerCount)),
                      ])
                    : _c(
                        "div",
                        {
                          staticClass: "upstream-60",
                          on: { click: _vm.upstreamToProductList },
                        },
                        [_vm._v(_vm._s(_vm.answerCount))]
                      ),
                ]),
                _c("div", { staticClass: "upstream-line" }, [_vm._v("/")]),
                _c("div", { staticClass: "production-item" }, [
                  _c("span", { staticClass: "upstream-22" }, [
                    _vm._v(_vm._s(_vm.$t("messages.receivedNumber"))),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "upstream-60",
                      on: { click: _vm.toUpstream },
                    },
                    [_vm._v(_vm._s(_vm.dependencedCount))]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "card-box" }, [
              _c("h5", { staticClass: "about-title" }, [
                _c("span", { staticClass: "left-line margin-right-20" }, [
                  _vm._v(_vm._s(_vm.$t("messages.partnersResponses"))),
                ]),
              ]),
              _c("div", { staticClass: "content-layout-hor margin-top-68" }, [
                _c("div", { staticClass: "production-item" }, [
                  _c("span", { staticClass: "upstream-22" }, [
                    _vm._v(_vm._s(_vm.$t("messages.response2Supliers"))),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "upstream-60",
                      on: { click: _vm.downstreamToProductList },
                    },
                    [_vm._v(_vm._s(_vm.answerAcceptCount))]
                  ),
                ]),
                _c("div", { staticClass: "upstream-line" }, [_vm._v("/")]),
                _c("div", { staticClass: "production-item" }, [
                  _c("span", { staticClass: "upstream-22" }, [
                    _vm._v(_vm._s(_vm.$t("messages.requestNumber"))),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "upstream-60",
                      on: { click: _vm.toDownstream },
                    },
                    [_vm._v(_vm._s(_vm.dependenceCount))]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "card-box" }, [
              _c("h5", { staticClass: "about-title" }, [
                _c("span", { staticClass: "left-line margin-right-20" }, [
                  _vm._v(_vm._s(_vm.$t("messages.checkStatus"))),
                ]),
                _c(
                  "div",
                  { staticClass: "check-select" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: _vm.$t("messages.unknownTip"),
                          size: "mini",
                        },
                        on: { change: _vm.changeBumonName },
                        model: {
                          value: _vm.chosedBumonName,
                          callback: function ($$v) {
                            _vm.chosedBumonName = $$v
                          },
                          expression: "chosedBumonName",
                        },
                      },
                      _vm._l(_vm.bumonNameList, function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: item, value: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.checkListItemsBoardLoading,
                      expression: "checkListItemsBoardLoading",
                    },
                  ],
                  staticClass: "content-layout margin-top-24",
                },
                [
                  _c("div", { staticClass: "left-bar width-100" }, [
                    _c(
                      "div",
                      { staticClass: "height-128" },
                      _vm._l(_vm.checkList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "content-layout-center" },
                          [
                            _c("span", { staticClass: "progress-index" }, [
                              _vm._v(_vm._s(item.checkNumber)),
                            ]),
                            _c("div", { staticClass: "progress-horizontal" }, [
                              _c("div", {
                                staticClass: "progress-horizontal-inner",
                                style: { width: item.percentageH + "%" },
                              }),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "content-layout progress-footer" },
                      [
                        _c("span", { staticClass: "width-30" }, [_vm._v("0%")]),
                        _c("span", { staticClass: "width-30" }, [
                          _vm._v("50%"),
                        ]),
                        _c("span", { staticClass: "width-30" }, [
                          _vm._v("100%"),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "right-bar width-100 margin-left-32" },
                    [
                      _c(
                        "div",
                        { staticClass: "height-128" },
                        _vm._l(_vm.checkList1, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "content-layout-center",
                            },
                            [
                              _c("span", { staticClass: "progress-index" }, [
                                _vm._v(_vm._s(item.checkNumber)),
                              ]),
                              _c(
                                "div",
                                { staticClass: "progress-horizontal" },
                                [
                                  _c("div", {
                                    staticClass: "progress-horizontal-inner",
                                    style: { width: item.percentageH + "%" },
                                  }),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "content-layout progress-footer" },
                        [
                          _c("span", { staticClass: "width-30" }, [
                            _vm._v("0%"),
                          ]),
                          _c("span", { staticClass: "width-30" }, [
                            _vm._v("50%"),
                          ]),
                          _c("span", { staticClass: "width-30" }, [
                            _vm._v("100%"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }