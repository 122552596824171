var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "popup-fade" } }, [
    _c("div", { staticClass: "popup-body" }, [
      _c("div", { staticClass: "popup-content-wrap" }, [
        _c("div", { staticClass: "popup" }, [
          _c("div", { staticClass: "title-box" }, [
            _c("i", {
              staticClass: "el-icon-close",
              on: { click: _vm.doClose },
            }),
          ]),
          _c("div", { staticClass: "process-list-box" }, [
            _vm._v(" " + _vm._s(_vm.contentmessage) + " "),
          ]),
          _c(
            "div",
            { staticClass: "tab-button-box" },
            [
              _c(
                "el-button",
                {
                  staticClass: "calculator-button",
                  on: { click: _vm.doComfirm },
                },
                [
                  _vm._v(" " + _vm._s(_vm.buttonName) + " "),
                  _c("i", { staticClass: "el-icon-check" }),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }