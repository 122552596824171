import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
import { MessageBox } from 'element-ui'
import store from '@/store'
// import { getToken } from '@/utils/auth'
import router from '../router'

// create an axios instance
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? window.location.hostname + process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: process.env.API_TIMEOUT // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.state.user) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.user).access_token
      config.headers['refresh_token'] = JSON.parse(store.state.user).refresh_token
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    store.commit('SET_RETURN_LOGIN', false)
    store.commit('SET_HEADERS', response.headers)
    return res
  },
  error => {
    if (!store.state.returnFlag) {
      store.commit('SET_RETURN_LOGIN', true)
      // eslint-disable-next-line eqeqeq
      if (error.response.status == 0 || error.response.status == 401 || error.response.status == 202) {
        MessageBox.alert('ログイン有効期限が切れましたので、再ログインしてください', 'ログイン有効期限', {
          confirmButtonText: '確定',
          callback: action => {
            store.commit('DELETE_RETURN_LOGIN')
            router.push('/')
          }
        })
      }
    }

    // switch (error.response.status) {
    //   case 401:
    //     MessageBox.alert(error.response.data.msg, '标题名称', {
    //       confirmButtonText: '确定',
    //       callback: action => {
    //         store.commit('RETURN_LOGIN', true)
    //         router.push('/')
    //       }
    //     });
    //   case 403:
    //     MessageBox.alert(error.response.data.msg, '标题名称', {
    //       confirmButtonText: '确定',
    //       callback: action => {
    //         router.push('/')
    //       }
    //     });
    //   case 202:
    //     MessageBox.alert(error.response.data.msg, '标题名称', {
    //       confirmButtonText: '确定',
    //       callback: action => {
    //         router.push('/')
    //       }
    //     });
    // }
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
