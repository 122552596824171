import request from '@/utils/request'

export function requestAPI(requestUrl, requestMethod, requestData) {
  const data = {
    requestData
  }
  console.log('call requestAPI')
  return request({
    url: requestUrl,
    method: requestMethod,
    data
  })
}
