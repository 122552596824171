import request from '@/utils/request'

export function getNameListAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getNameListAPI')
  return request({
    url: '/api/v1/input/ideaCache',
    method: 'post',
    data
  })
}

export function getProcessListAPI(kigyouCode) {
  console.log('call getProcessListAPI')
  return request({
    url: '/api/v1/input/processCache',
    method: 'post'
  })
}

export function getDetailAPI(kigyouCode, seihinVersion, seihinCode, oriprocessFlg) {
  const data = {
    kigyouCode,
    seihinVersion,
    seihinCode,
    oriprocessFlg
  }
  console.log('call getDetailAPI')
  return request({
    url: '/api/v1/input/query',
    method: 'post',
    data
  })
}

export function getParentDetailAPI(kigyouCode, seihinVersion, seihinCode, oriprocessFlg) {
  const data = {
    kigyouCode,
    seihinVersion,
    seihinCode,
    oriprocessFlg
  }
  console.log('call getParentDetailAPI')
  return request({
    url: '/api/v1/input/datequery',
    method: 'post',
    data
  })
}

export function getCopyAPI(kigyouCode, seihinVersion, seihinCode, oriprocessFlg) {
  const data = {
    kigyouCode,
    seihinVersion,
    seihinCode,
    oriprocessFlg
  }
  console.log('call getCopyAPI')
  return request({
    url: '/api/v1/input/copy',
    method: 'post',
    data
  })
}

export function getParentCopyAPI(kigyouCode, seihinVersion, seihinCode, oriprocessFlg) {
  const data = {
    kigyouCode,
    seihinVersion,
    seihinCode,
    oriprocessFlg
  }
  console.log('call getParentCopyAPI')
  return request({
    url: '/api/v1/input/parentCopy',
    method: 'post',
    data
  })
}

export function saveAPI(data) {
  console.log('call saveAPI')
  return request({
    url: '/api/v1/input/save',
    method: 'post',
    data
  })
}

export function getCheckListAPI(seihincode, version, language) {
  const data = {
    seihincode,
    version,
    language
  }
  console.log('call getCheckListAPI')
  return request({
    url: '/api/v1/checkList/query',
    method: 'post',
    data
  })
}

export function saveCheckListAPI(seihincode, version, checklistversion, upduser, checkListData) {
  const data = {
    ...checkListData,
    seihincode,
    version,
    checklistversion,
    upduser
  }
  console.log('call saveCheckListAPI')
  return request({
    url: '/api/v1/checkList/save',
    method: 'post',
    data
  })
}

export function saveTempAPI(data) {
  console.log('call saveTempAPI')
  return request({
    url: '/api/v1/input/saveTemp',
    method: 'post',
    data
  })
}

export function updateTempAPI(data) {
  console.log('call updateTempAPI')
  return request({
    url: '/api/v1/input/updateTemp',
    method: 'post',
    data
  })
}

export function checkExistAPI(seihincode, version) {
  const data = {
    seihincode,
    version
  }
  console.log('call checkExistAPI')
  return request({
    url: '/api/v1/checkList/checkExist',
    method: 'post',
    data
  })
}

export function getTempDetailAPI(kigyouCode, seihinVersion, seihinCode, oriprocessFlg) {
  const data = {
    kigyouCode,
    seihinVersion,
    seihinCode,
    oriprocessFlg
  }
  console.log('call getTempDetailAPI')
  return request({
    url: '/api/v1/input/queryTemp',
    method: 'post',
    data
  })
}

export function getParentTempDetailAPI(kigyouCode, seihinVersion, seihinCode, oriprocessFlg) {
  const data = {
    kigyouCode,
    seihinVersion,
    seihinCode,
    oriprocessFlg
  }
  console.log('call getParentTempDetailAPI')
  return request({
    url: '/api/v1/input/queryParentTemp',
    method: 'post',
    data
  })
}

export function getSelectIdeaInfoAPI(type, typeId) {
  const data = {
    type,
    typeId
  }
  console.log('call getSelectIdeaInfoAPI')
  return request({
    url: '/api/v1/idea/getSelectIdeaInfo',
    method: 'post',
    data
  })
}

export function treeDateSave(treeData) {
  const data = {
    treeData
  }
  console.log('call treeDateSave API')
  return request({
    url: '/api/v1/input/dateSave',
    method: 'post',
    data
  })
}

export function saveTreeTempAPI(treeData) {
  const data = {
    treeData
  }
  console.log('call saveTreeTempAPI')
  return request({
    url: '/api/v1/input/saveDateTemp',
    method: 'post',
    data
  })
}

export function getLikeItemListAPI(kigyouCode, itemName) {
  const data = {
    kigyouCode,
    itemName
  }
  console.log('call getLikeItemListAPI')
  return request({
    url: '/api/v1/input/getLikeItemList',
    method: 'post',
    data
  })
}

export function getUnitListAPI(kigyoucode,unit,ideacode,version) {
  const data = {
    kigyoucode,
    unit,
    ideacode,
    version
  }
  console.log('call getUnitListAPI')
  return request({
    url: '/api/v1/input/unitlist',
    method: 'post',
    data
  })
}

export function tkmideaCacheListAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call tkmideaCacheListAPI')
  return request({
    url: '/api/v1/input/tkmideaCache',
    method: 'post',
    data
  })
}

export function getTreeListAPI(kigyouCode,oriProcessFlg) {
  const data = {
    kigyouCode,
    oriProcessFlg
  }
  console.log('call getTreeListAPI')
  return request({
    url: '/api/v1/version/getTreeList',
    method: 'post',
    data
  })
}

export function getTreeDetailAPI(kigyouCode,seihinVersion,seihinCode,oriprocessFlg) {
  const data = {
    kigyouCode,
    seihinVersion,
    seihinCode,
    oriprocessFlg
  }
  console.log('call getTreeDetailAPI')
  return request({
    url: '/api/v1/input/getTreeDetail',
    method: 'post',
    data
  })
}

export function getDetailBySubassyIdAPI(subassyIdArr, seihinCode, version) {
  const data = {
    subassyIdArr,
    seihinCode, 
    version
  }
  console.log('call getDetailBySubassyIdAPI')
  return request({
    url: '/api/v1/input/getDetailBySubassyId',
    method: 'post',
    data
  })
}

export function uploadCountSumAPI(upduser, uploadCreateFlag) {
  const data = {
    upduser,
    uploadCreateFlag
  }
  console.log('call uploadCountSumAPI')
  return request({
    url: '/api/v1/genTaniLink/sumUploadCount',
    method: 'post',
    data
  })
}

export function checkGenerationAPI(kigyouCode,subassyIdArr, seihinCode, version) {
  const data = {
    kigyouCode,
    subassyIdArr,
    seihinCode,
    version
  }
  console.log('call checkGenerationAPI')
  return request({
    url: '/api/v1/input/checkGeneration',
    method: 'post',
    data
  })
}