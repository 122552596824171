import request from '@/utils/request'

export function removeIdeaCacheAPI(kigyouCode) {
  console.log('call removeIdeaCacheAPI')
  return request({
    url: '/api/v1/browser/removeIdeaCache',
    method: 'post'
  })
}

export function saveSeihinTempAPI(data) {
  console.log('call saveSeihinTempAPI')
  return request({
    url: '/api/v1/subunitemission/saveversionunit',
    method: 'post',
    data
  })
}

export function searchSeihinTempAPI(data) {
  console.log('call searchSeihinTempAPI')
  return request({
    url: '/api/v1/subunitemission/query',
    method: 'post',
    data
  })
}

export function getSeihinListAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getSeihinListAPI')
  return request({
    url: '/api/v1/browser/seihin',
    method: 'post',
    data
  })
}

export function getTreeSeihinListAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getTreeSeihinListAPI')
  return request({
    url: '/api/v1/version/getTreeSeihinList',
    method: 'post',
    data
  })
}

export function getTreeDetailAPI(kigyouCode, seihinVersion, seihinCode, oriprocessFlg) {
  const data = {
    kigyouCode,
    seihinVersion,
    seihinCode,
    oriprocessFlg
  }
  console.log('call getTreeDetailAPI')
  return request({
    url: '/api/v1/input/getDetail',
    method: 'post',
    data
  })
}

export function getTreeDetailWithQuantityAPI(kigyouCode, seihinVersion, seihinCode, oriprocessFlg, quantity) {
  const data = {
    kigyouCode,
    seihinVersion,
    seihinCode,
    oriprocessFlg,
    quantity
  }
  console.log('call getTreeDetailWithQuantityAPI')
  return request({
    url: '/api/v1/input/getDetailWithQuantity',
    method: 'post',
    data
  })
}

export async function getPieAPI(kigyouCode, seihinCode, seihinAuth, version = null, urlPath = 'browser/pie') {
  const data = {
    kigyouCode,
    seihinCode,
    seihinAuth,
    version
  }
  console.log('call getPieAPI')
  return request({
    url: `/api/v1/${urlPath}`,
    method: 'post',
    data
  })
}

export function getTableAPI(kigyouCode, seihinCode, seihinAuth) {
  const data = {
    kigyouCode,
    seihinCode,
    seihinAuth
  }
  console.log('call getTableAPI')
  return request({
    url: '/api/v1/browser/tableWithGroup',
    method: 'post',
    data
  })
}

export function getTableAllAPI(kigyouCode, seihinCode, seihinAuth) {
  const data = {
    kigyouCode,
    seihinCode,
    seihinAuth
  }
  console.log('call getTableAllAPI')
  return request({
    url: '/api/v1/browser/tableWithoutGroup',
    method: 'post',
    data
  })
}

export function getTableFoldAPI(kigyouCode, seihinCode, seihinAuth, version, urlPath = 'browser/tableFold') {
  const data = {
    kigyouCode,
    seihinCode,
    seihinAuth,
    version
  }
  console.log('call getTableFoldAPI')
  return request({
    url: `/api/v1/${urlPath}`,
    method: 'post',
    data
  })
}

export function getTableFoldWithQuantityAPI(kigyouCode, seihinCode, seihinAuth, version, quantity, oriprocessFlg) {
  const data = {
    kigyouCode,
    seihinCode,
    seihinAuth,
    version,
    quantity,
    oriprocessFlg
  }
  console.log('call getTableFoldWithQuantityAPI')
  return request({
    url: '/api/v1/browser/tableFoldWithQuantity',
    method: 'post',
    data
  })
}

export function getCsvAPI(kigyouCode, seihinCode, seihinAuth) {
  const data = {
    kigyouCode,
    seihinCode,
    seihinAuth
  }
  console.log('call getCsvAPI')
  return request({
    url: '/api/v1/browser/csv',
    method: 'post',
    data
  })
}

export function getVersionMasterAPI(seihinCode, seihinVersion) {
  const data = {
    seihinCode,
    seihinVersion
  }
  console.log('call getVersionMasterAPI')
  return request({
    url: '/api/v1/version/getVersionMaster',
    method: 'post',
    data
  })
}

export function deleteSeihinAPI(seihinCode, version, upduser, username) {
  const data = {
    seihinCode,
    version,
    upduser,
    username
  }
  console.log('call deleteSeihinAPI')
  return request({
    url: '/api/v1/input/deleteSeihin',
    method: 'post',
    data
  })
}

export function deleteTempAPI(seihinVersion, seihinCode) {
  const data = {
    seihinVersion,
    seihinCode
  }
  console.log('call deleteTempAPI')
  return request({
    url: '/api/v1/seihin/deleteTemp',
    method: 'post',
    data
  })
}

export function getExternalSeihinListAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getExternalSeihinListAPI')
  return request({
    url: '/api/v1/input/getSeihinList',
    method: 'post',
    data
  })
}

export function deleteApiDataAPI(kigyouCode, dataId) {
  const data = {
    kigyouCode,
    dataId
  }
  console.log('call deleteApiDataAPI')
  return request({
    url: '/api/v1/input/deleteApiData',
    method: 'post',
    data
  })
}

export function updateApiVersionAPI(seihinCode, version, upduser, dataId) {
  const data = {
    seihinCode,
    version,
    upduser,
    dataId
  }
  console.log('call updateApiVersionAPI')
  return request({
    url: '/api/v1/input/updateApiVersion',
    method: 'post',
    data
  })
}

export function getApiDataAPI(seihinCode, version, dataId, kigyouCode) {
  const data = {
    seihinCode,
    version,
    dataId,
    kigyouCode
  }
  console.log('call getApiDataAPI')
  return request({
    url: '/api/v1/input/getApiData',
    method: 'post',
    data
  })
}

export function getTempInfoAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getTempInfoAPI')
  return request({
    url: '/api/v1/browser/getTempInfo',
    method: 'post',
    data
  })
}

export function getFinshCountAPI(seihincode, version) {
  const data = {
    seihincode,
    version
  }
  console.log('call getFinshCountAPI')
  return request({
    url: '/api/v1/checkList/getFinshCount',
    method: 'post',
    data
  })
}

export function getOrignalProcessList(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getOrignalProcessList API')
  return request({
    url: '/api/v1/orignalProcess/query',
    method: 'post',
    data
  })
}

export function SaveOrignalProcessList(data) {
  console.log('call SaveOrignalProcessList API')
  return request({
    url: '/api/v1/orignalProcess/save',
    method: 'post',
    data
  })
}

export function checkSeihinOrgProcessAPI(seihinCode, version) {
  const data = {
    seihinCode,
    version
  }
  console.log('call checkSeihinOrgProcessAPI')
  return request({
    url: '/api/v1/orignalProcess/checkSeihinOrgProcess',
    method: 'post',
    data
  })
}

export function getProcessTableAPI(seihinCode, version, seihinAuth, oriprocessFlg) {
  const data = {
    seihinCode,
    version,
    seihinAuth,
    oriprocessFlg
  }
  console.log('call getProcessTableAPI')
  return request({
    url: '/api/v1/seihinOutLine/getProcessTable',
    method: 'post',
    data
  })
}

export function getSeihinPieDataWithQuantityAPI(seihinCode, version, seihinAuth, quantity, oriprocessFlg) {
  const data = {
    seihinCode,
    version,
    seihinAuth,
    quantity,
    oriprocessFlg
  }
  console.log('call getSeihinPieDataWithQuantityAPI')
  return request({
    url: '/api/v1/seihinOutLine/getSeihinPieDataWithQuantity',
    method: 'post',
    data
  })
}

export function getTopVAPI(seihinCode, version, seihinAuth, oriprocessFlg) {
  const data = {
    seihinCode,
    version,
    seihinAuth,
    oriprocessFlg
  }
  console.log('call getTopVAPI')
  return request({
    url: '/api/v1/seihinOutLine/getTop',
    method: 'post',
    data
  })
}

export function getSumEmissioncAPI(seihinCode, version, seihinAuth, oriprocessFlg) {
  const data = {
    seihinCode,
    version,
    seihinAuth,
    oriprocessFlg
  }
  console.log('call getSumEmissioncAPI')
  return request({
    url: '/api/v1/seihinOutLine/getSumEmissionc',
    method: 'post',
    data
  })
}

export function getIdeaAndPreAPI(seihinCode, version, oriprocessFlg) {
  const data = {
    seihinCode,
    version,
    oriprocessFlg
  }
  console.log('call getIdeaAndPreAPI')
  return request({
    url: '/api/v1/seihinOutLine/getIdeaAndPre',
    method: 'post',
    data
  })
}

export function getCheckListReportAPI(seihinCode, version) {
  const data = {
    seihinCode,
    version
  }
  console.log('call getCheckListReportAPI')
  return request({
    url: '/api/v1/seihinOutLine/getCheckListReport',
    method: 'post',
    data
  })
}

// story32 start
export function getTableProcessAPI(kigyouCode, seihinCode, seihinAuth, version, oriprocessFlg) {
  const data = {
    kigyouCode,
    seihinCode,
    seihinAuth,
    version,
    oriprocessFlg
  }
  console.log('call getTableProcessAPI')
  return request({
    url: '/api/v1/browser/getTableProcess',
    method: 'post',
    data
  })
}

export function getTableOrignalProcessAPI(kigyouCode, seihinCode, seihinAuth, version) {
  const data = {
    kigyouCode,
    seihinCode,
    seihinAuth,
    version
  }
  console.log('call getTableOrignalProcessAPI')
  return request({
    url: '/api/v1/browser/getTableOrignalProcess',
    method: 'post',
    data
  })
}

export function getTableInterimproductAPI(kigyouCode, seihinCode, seihinAuth, version, oriprocessFlg) {
  const data = {
    kigyouCode,
    seihinCode,
    seihinAuth,
    version,
    oriprocessFlg
  }
  console.log('call getTableInterimproductAPI')
  return request({
    url: '/api/v1/browser/getTableInterimproduct',
    method: 'post',
    data
  })
}

export function getTableDetailAPI(kigyouCode, seihinCode, seihinAuth, version, oriprocessFlg) {
  const data = {
    kigyouCode,
    seihinCode,
    seihinAuth,
    version,
    oriprocessFlg
  }
  console.log('call getTableDetailAPI')
  return request({
    url: '/api/v1/browser/getTableDetail',
    method: 'post',
    data
  })
}

export function getPieDataNewAPI(kigyouCode, seihinCode, seihinAuth, version, oriprocessFlg) {
  const data = {
    kigyouCode,
    seihinCode,
    seihinAuth,
    version,
    oriprocessFlg
  }
  console.log('call getPieDataNewAPI')
  return request({
    url: '/api/v1/browser/getPieDataNew',
    method: 'post',
    data
  })
}
// story32 end

export function getProductListAPI(kigyouCode, isSelf, sortData = { bumonname: 0, seihinname: 0, username: 0, authname: 0, productno: 0, seihinbunrui: 0, shagaiproductno: 0 }, filterData = { bumonname: [], seihinname: [], username: [], authname: [], productno: [], seihinbunrui: [], shagaiproductno: [] }) {
  const data = {
    kigyouCode,
    isSelf,
    sortData,
    filterData
  }
  console.log('call getProductListAPI')
  return request({
    url: '/api/v1/seihin/browser',
    method: 'post',
    data
  })
}

export function getGoalBoardAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getGoalBoardAPI')
  return request({
    url: '/api/v1/seihin/getGoalBoard',
    method: 'post',
    data
  })
}

export function getIdeaUseBoardAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getIdeaUseBoardAPI')
  return request({
    url: '/api/v1/seihin/getIdeaUseBoard',
    method: 'post',
    data
  })
}

export function getCheckListBoardAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getCheckListBoardAPI')
  return request({
    url: '/api/v1/seihin/getCheckListBoard',
    method: 'post',
    data
  })
}

export function goalImportAPI(kigyouCode, updUser) {
  const data = {
    kigyouCode,
    updUser
  }
  console.log('call goalImportAPI')
  return request({
    url: '/api/v1/seihin/goalImport',
    method: 'post',
    data
  })
}

export function getBumonNameListAPI(kigyouCode) {
  const data = {
    kigyouCode
  }
  console.log('call getBumonNameListAPI')
  return request({
    url: '/api/v1/seihin/getBumonNameList',
    method: 'post',
    data
  })
}

export function getCheckListItemsBoardAPI(kigyouCode, bumonName) {
  const data = {
    kigyouCode,
    bumonName
  }
  console.log('call getCheckListItemsBoardAPI')
  return request({
    url: '/api/v1/seihin/getCheckListItemsBoard',
    method: 'post',
    data
  })
}

export function getKigyouBySeihinCodeAPI(seihinCode) {
  const data = {
    seihinCode
  }
  console.log('call getKigyouBySeihinCodeAPI')
  return request({
    url: 'api/v1/seihin/getKigyouBySeihincode',
    method: 'post',
    data
  })
}

export function checkTreeSeihin(seihinCode, seihinVersion, kigyouCode) {
  const data = {
    seihinCode,
    seihinVersion,
    kigyouCode
  }
  console.log('call checkTreeSeihin API')
  return request({
    url: '/api/v1/version/checkTreeSeihin',
    method: 'post',
    data
  })
}

export function setSelfIdea(version, seihinCode, upduser) {
  const data = {
    version,
    seihinCode,
    upduser
  }
  console.log('call setSelfIdea API')
  return request({
    url: '/api/v1/seihinOutLine/setSelfIdeaCode',
    method: 'post',
    data
  })
}

export function checkSelfIdea(seihinCode, version) {
  const data = {
    seihinCode,
    version
  }
  console.log('call checkSelfIdea API')
  return request({
    url: '/api/v1/seihinOutLine/checkSelfIdeaCode',
    method: 'post',
    data
  })
}

export function checkLoopQuote(seihinCode, version) {
  const data = {
    seihinCode,
    version
  }
  console.log('call checkLoopQuote API')
  return request({
    url: '/api/v1/input/checkLoopQuote',
    method: 'post',
    data
  })
}

export function exportExcelAPI(seihincode, version, kigyoucode, seihinName) {
  const data = {
    seihincode,
    version,
    kigyoucode,
    seihinName
  }
  console.log('call exportExcelAPI')
  return request({
    url: '/api/v1/input/getExcelData',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
