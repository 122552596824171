<template>
  <transition name="popup-fade">
    <div
      class="popup-body"
    >
      <div class="popup-content-wrap">
        <div class="popup">
          <div class="title-box">
            <i class="el-icon-close" @click="doClose" />
          </div>
          <div class="process-list-box">
            {{ contentmessage }}
          </div>
          <div class="tab-button-box">
            <el-button class="back-button" size="small" @click="doClose"> {{ cancelName }}</el-button>
            <el-button
              class="calculator-button"
              size="small"
              @click="doComfirm"
            >
              {{ buttonName }}
              <i class="el-icon-check" />
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script type="text/ecmascript-6">
export default {
  name: 'Popup2',
  props: {
    isShowComfirmPopup: Boolean,
    isComfirm: Boolean,
    contentmessage: {
      type: String,
      default: ''
    },
    buttonName: {
      type: String,
      default: ''
    },
    cancelName: {
      type: String,
      default: 'キャンセル'
    }
  },
  methods: {
    doComfirm() {
      this.$emit('childFn', { 'isComfirm': true, 'isShowComfirmPopup': false })
    },
    doClose() {
      this.$emit('childFn', { 'isComfirm': false, 'isShowComfirmPopup': false })
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .popup-content-wrap {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    // height: 30%;
    width: 30%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    z-index: 999;
    user-select: none;

    .popup {
      display: flex;
      flex-direction: column;
      padding:24px;
      .title-box {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        // margin-top: 3%;
        // margin-bottom: 1%;
        // margin-left: 5%;

        .el-icon-close {
          cursor: pointer;
        }

        .title-font {
          font-family: 'NotoSansCJKjp-Medium';
          font-size: 20px;
          color: #333333;
          letter-spacing: 0;
          text-align: center;
          font-weight: 500;
          margin-left: 3%;
        }
      }

      .process-list-box {
        margin:24px 0 32px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // margin-top: 10%;
        height: 100%;
        min-height: 40px;
        // width: 95%;
        font-size: 14px;
        line-height: 1.4;

        .process-list {
          max-height: 200px;
          display: flex;
          flex-wrap: wrap;
          overflow-y: auto;
          overflow-x: auto;
          width: 100%;

          .process-item {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start;
              margin-top: 4px;
              height: 100%;
              width: 25%;

            .process-icon {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100px;
              width: 100%;
              background: none repeat scroll 0 0 white;
              cursor: pointer;
              border: 1px solid #D6D6D6;
              border-radius: 8px;
              margin-right: 0.3rem;
              margin-bottom: 0.3rem;
              font-family: 'NotoSansCJKjp-Regular';
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;

              .process-icon:focus {
                background-color: #FAFAFA;
              }

              .el-icon-success {
                position: absolute;
                top: 10%;
                left: 10%;
              }

              .icon-font {
                margin-top: 4%;
                font-family: 'NotoSansCJKjp-Regular';
                font-size: 14px;
                color: #333333;
                letter-spacing: 0;
                font-weight: 400;
              }
            }
          }
        }
      }

      .tab-button-box{
        // margin-top: 5%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // opacity: 0.85;
        // background: #FFFFFF;

        .back-button {
          font-family: 'NotoSansCJKjp-Regular';
          font-size: 12px;
          // color: #333333;
          letter-spacing: 0;
          font-weight: 400;
          // border: 1px solid #333333;
          border-radius: 4px;
          // margin-top: 24px;
        }

        .calculator-button {
          font-family: 'NotoSansCJKjp-Regular';
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          font-weight: 400;
          background-color: #1D97D8;
          border-color: #1D97D8;
          border-radius: 4px;
          // margin-top: 24px;
          margin-left:16px;

          &:hover {
            color: #FFF;
            background-color: #0087CF;
            border-color: #0087CF;
          }
        }
      }
    }
  }
}

/* 进入和出去的动画 */
.popup-fade-enter-active {
  animation: opacity 0.3s;
}
.popup-fade-enter-active .popup-content-wrap {
  animation: scale 0.3s;
}
.popup-fade-leave-active {
  animation: outOpacity 0.2s;
}

/*滚动条样式*/
.process-list::-webkit-scrollbar {
  width: 10px;
  /*height: 4px;*/
}
.process-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);

  background: #CCCCCC;
  border-radius: 4px;
}
.process-list::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 0;
  background: rgba(0,0,0,0.1);

}

/* 进来的动画 */
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scale {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes outOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
