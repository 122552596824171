<template>
  <div class="margin-lr-24 main-dashboard">
    <el-row :gutter="16" class="margin-top-24">
      <el-col :span="6">
        <div class="card-box">
          <h5 class="about-title content-layout">
            <span class="left-line margin-right-20">{{$t('messages.kpiTitle')}}</span>
            <!-- <el-button type="primary" class="height-28" plain>CSVインポート</el-button> -->
            <el-upload
              :show-file-list="false"
              accept=".csv"
              name="csvFile"
              :headers="headers"
              :on-success="onSuccess"
              :on-error="onError"
              style="display: inline-flex;margin-right: 0"
              :action="actionup"
              :data="uploadData"
            >
              <el-button type="primary" class="height-28" plain>{{$t('messages.importName')}}</el-button>
            </el-upload>
          </h5>
          <el-row :gutter="16" class="line-24">
            <el-col :span="12">
              <h6 class="sub-title">{{$t('messages.companyAll')}}</h6>
              <div class="content-layout">
                <div class="production-item">
                  <span class="des-title">{{$t('messages.calculatedProduct1')}}<br>{{$t('messages.calculatedProduct2')}}</span>
                  <span class="des-number">{{ goalBoardData.total.finshSeihinTotal }}</span>
                </div>
                <div class="production-item">
                  <span class="des-title">{{$t('messages.target')}}</span>
                  <span class="des-number">{{ goalBoardData.total.goalTotal }}</span>
                </div>
              </div>
            </el-col>
            <el-col :span="12" class="echart-container-130">
              <div id="pieChart" class="refer-chart" />
            </el-col>
          </el-row>
          <el-table :data="goalBoardData.dataList" style="width: 100%" class="margin-top-8" height="254">
            <el-table-column prop="bumonName" :label="$t('messages.division')" width="65">
              <template slot-scope="scope">
                <span>{{ scope.row.bumonName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="finshSeihinCount" :label="$t('messages.calculatedProduct')" width="70">
              <template slot-scope="scope">
                <span>{{ scope.row.finshSeihinCount }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="goalCount" :label="$t('messages.target')" width="50">
              <template slot-scope="scope">
                <span>{{ scope.row.goalCount }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="pre" :label="$t('messages.progress')" width="65">
              <template slot-scope="scope">
                <div v-if="scope.row.tag && scope.row.tag < 4" class="tag-bg tag-bg-blue"><sup>{{ scope.row.tag }}</sup></div>
                <div v-if="scope.row.tag && scope.row.tag > 3" class="tag-bg tag-bg-gray"><sup>{{ scope.row.tag }}</sup></div>
                <span v-if="scope.row.pre !== '-'">{{ scope.row.pre }}%</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="card-box">
          <h5 class="about-title">
            <span class="left-line margin-right-16">{{$t('messages.ideaUserRate')}}</span>
          </h5>
          <el-row :gutter="16" class="line-24">
            <el-col :span="13">
              <h6 class="sub-title">{{$t('messages.companyAll')}}</h6>
              <div class="content-layout">
                <div class="production-item margin-right-16">
                  <span class="des-title">{{$t('messages.usage')}}</span>
                  <span class="des-number">{{ ideaUseData.total.totalIdeaCount }}</span>
                </div>
                <div class="production-item">
                  <span class="des-title width-60">{{$t('messages.totalItemsA11')}}<br>{{$t('messages.totalItemsA12')}}</span>
                  <span class="des-number">{{ ideaUseData.total.totalProcessA1Count }}</span>
                </div>
              </div>
            </el-col>
            <el-col :span="11">
              <div class="production-item">
                <span class="ratio">{{$t('messages.ratio')}}</span>
                <div class="progress-bar-outer">
                  <div class="progress-bar-inner" :style="{height: (Number(ideaUseData.total.totalPre) > 100 ? 100 : ideaUseData.total.totalPre) + '%'}">
                    <span class="progress-text">{{ ideaUseData.total.totalPre }}%</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-table :data="ideaUseData.dataList" style="width: 100%" class="margin-top-8" height="254">
            <el-table-column prop="bumonName" :label="$t('messages.division')" width="60">
              <template slot-scope="scope">
                <span>{{ scope.row.bumonName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ideaCount" :label="$t('messages.usage')" width="50">
              <template slot-scope="scope">
                <span>{{ scope.row.ideaCount }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="processA1Count" :label="$t('messages.totalItemsA1')" width="80">
              <template slot-scope="scope">
                <span>{{ scope.row.processA1Count }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="pre" :label="$t('messages.percentage')" width="65">
              <template slot-scope="scope">
                <div v-if="scope.row.tag && scope.row.tag < 4" class="tag-bg tag-bg-blue"><sup>{{ scope.row.tag }}</sup></div>
                <div v-if="scope.row.tag && scope.row.tag > 3" class="tag-bg tag-bg-gray"><sup>{{ scope.row.tag }}</sup></div>
                <span v-if="scope.row.pre !== '-'">{{ scope.row.pre }}%</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="card-box">
          <h5 class="about-title">
            <span class="left-line margin-right-20">{{$t('messages.checkPerf')}}</span>
          </h5>
          <el-row :gutter="16" class="line-24">
            <el-col :span="18">
              <h6 class="sub-title">{{$t('messages.companyAll')}}</h6>
              <div class="content-layout">
                <div class="production-item">
                  <span class="des-title width-60">{{$t('messages.totalNumberCalculated')}}</span>
                  <span class="des-number">{{ checkListBoardData.total.finshSeihinTotal }}</span>
                </div>
                <div class="production-item margin-right-16">
                  <span class="des-title width-auto">{{$t('messages.averagePoints')}}</span>
                  <span class="des-number">{{ checkListBoardData.total.totalAvg }}/17</span>
                </div>
                <div class="production-item margin-right-30">
                  <span class="des-title width-72">{{$t('messages.fullPoint')}}</span>
                  <span class="des-number">{{ checkListBoardData.total.fullPointTotal }}</span>
                </div>
              </div>
            </el-col>
            <el-col :span="6" class="echart-container-130">
              <div id="pieChart3" class="refer-chart margin-top-4" />
            </el-col>
          </el-row>
          <el-table :data="checkListBoardData.dataList" style="width: 100%" class="margin-top-8" height="254">
            <el-table-column prop="bumonName" :label="$t('messages.division')" width="200">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="scope.row.bumonName" placement="top">
                  <span class="colum-width">
                    {{ scope.row.bumonName }}
                  </span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="finshSeihinCount" :label="$t('messages.totalNumberCalculated')" width="95">
              <template slot-scope="scope">
                <span>{{ scope.row.finshSeihinCount }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="avg" :label="$t('messages.averagePoints')">
              <template slot-scope="scope">
                <div v-if="scope.row.avgTag && scope.row.avgTag < 4" class="tag-bg tag-bg-blue"><sup>{{ scope.row.avgTag }}</sup></div>
                <div v-if="scope.row.avgTag && scope.row.avgTag > 3" class="tag-bg tag-bg-gray"><sup>{{ scope.row.avgTag }}</sup></div>
                <span>{{ scope.row.avg }} / 17</span>
              </template>
            </el-table-column>
            <el-table-column prop="fullPoint" :label="$t('messages.fullPoint')">
              <template slot-scope="scope">
                <span>{{ scope.row.fullPoint }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="fullPointPre" :label="$t('messages.fullPointPercentage')">
              <template slot-scope="scope">
                <div v-if="scope.row.tag && scope.row.tag < 4" class="tag-bg tag-bg-blue"><sup>{{ scope.row.tag }}</sup></div>
                <div v-if="scope.row.tag && scope.row.tag > 3" class="tag-bg tag-bg-gray"><sup>{{ scope.row.tag }}</sup></div>
                <span v-if="scope.row.fullPointPre !== '-'">{{ scope.row.fullPointPre }}%</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="16" class="margin-tb-24">
      <el-col :span="6">
        <div class="card-box">
          <h5 class="about-title">
            <span class="left-line margin-right-20">{{$t('messages.responses2Partners')}}</span>
          </h5>
          <div class="content-layout-hor margin-top-68">
            <div class="production-item">
              <span class="upstream-22">{{$t('messages.response2Customers')}}</span>
              <div v-if="answerCount == 0" class="upstream-60-0">{{ answerCount }}</div>
              <div v-else class="upstream-60" @click="upstreamToProductList">{{ answerCount }}</div>
            </div>
            <div class="upstream-line">/</div>
            <div class="production-item">
              <span class="upstream-22">{{$t('messages.receivedNumber')}}</span>
              <div class="upstream-60" @click="toUpstream">{{ dependencedCount }}</div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="card-box">
          <h5 class="about-title">
            <span class="left-line margin-right-20">{{$t('messages.partnersResponses')}}</span>
          </h5>
          <div class="content-layout-hor margin-top-68">
            <div class="production-item">
              <span class="upstream-22">{{$t('messages.response2Supliers')}}</span>
              <div class="upstream-60" @click="downstreamToProductList">{{ answerAcceptCount }}</div>
            </div>
            <div class="upstream-line">/</div>
            <div class="production-item">
              <span class="upstream-22">{{$t('messages.requestNumber')}}</span>
              <div class="upstream-60" @click="toDownstream">{{ dependenceCount }}</div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="card-box">
          <h5 class="about-title">
            <span class="left-line margin-right-20">{{$t('messages.checkStatus')}}</span>
            <div class="check-select">
              <el-select v-model="chosedBumonName" :placeholder="$t('messages.unknownTip')" size="mini" @change="changeBumonName">
                <el-option
                  v-for="item in bumonNameList"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </div>
          </h5>
          <div v-loading="checkListItemsBoardLoading" class="content-layout margin-top-24">
            <div class="left-bar width-100">
              <div class="height-128">
                <div v-for="(item,index) in checkList" :key="index" class="content-layout-center">
                  <span class="progress-index">{{ item.checkNumber }}</span>
                  <div class="progress-horizontal">
                    <div class="progress-horizontal-inner" :style="{width: item.percentageH + '%'}" />
                  </div>
                </div>
              </div>
              <div class="content-layout progress-footer">
                <span class="width-30">0%</span><span class="width-30">50%</span><span class="width-30">100%</span>
              </div>
            </div>
            <!-- <div class="right-bar width-100 margin-left-32" v-if="checkList1.length == 0 ? false : true"> -->
            <div class="right-bar width-100 margin-left-32">
              <div class="height-128">
                <div v-for="(item,index) in checkList1" :key="index" class="content-layout-center">
                  <span class="progress-index">{{ item.checkNumber }}</span>
                  <div class="progress-horizontal">
                    <div class="progress-horizontal-inner" :style="{width: item.percentageH + '%'}" />
                  </div>
                </div>
              </div>
              <div class="content-layout progress-footer">
                <span class="width-30">0%</span><span class="width-30">50%</span><span class="width-30">100%</span>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import './dashboard.scss'
import * as echarts from 'echarts'
import { getGoalBoardAPI, getIdeaUseBoardAPI, getCheckListBoardAPI, getBumonNameListAPI, getCheckListItemsBoardAPI, getProductListAPI } from '@/api/browser'
import { getTotalBoardAPI } from '@/api/iraiAuth'
export default {
  data() {
    return {
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(this.$store.state.user).access_token,
        'refresh_token': JSON.parse(this.$store.state.user).refresh_token
      },
      checkListItemsBoardLoading: true,
      chosedBumonName: '全社',
      bumonNameList: [],
      checkList: [],
      checkList1: [],

      goalBoardData: {
        total: {
          finshSeihinTotal: '',
          goalTotal: '',
          totalPre: ''
        },
        dataList: []
      },
      ideaUseData: {
        total: {
          totalIdeaCount: '',
          totalProcessA1Count: '',
          totalPre: ''
        },
        dataList: []
      },
      checkListBoardData: {
        total: {
          finshSeihinTotal: '',
          fullPointTotal: '',
          fullPointTotalPre: '',
          totalAvg: ''
        },
        dataList: []
      },
      actionup: process.env.VUE_APP_BASE_API + '/api/v1/seihin/goalImport',
      uploadData: {},
      kigyouCode: '',
      updUser: '',
      answerAcceptCount: '',
      dependenceCount: '',
      answerCount: '',
      dependencedCount: '',
      filterAuthcountMethod: [],
      language: this.$i18n.locale
    }
  },
  watch: {
    '$i18n.locale'(newValue,oldValue){
      if (newValue != oldValue) {
        this.language = this.$i18n.locale
        this.initChart()
        this.initChart3()
        this.uploadData = { 'kigyouCode': this.kigyouCode, 'updUser': this.updUser, 'language': this.language }
      }
    }
  },
  mounted() {
    this.kigyouCode = JSON.parse(this.$store.state.user).kigyoucode
    this.updUser = JSON.parse(this.$store.state.user).userid
    this.loadGoalBoard(this.kigyouCode)
    this.loadIdeaUseBoard(this.kigyouCode)
    this.loadCheckListBoard(this.kigyouCode)
    this.getCheckListItemsBoard(this.kigyouCode, '')
    this.getTotalBoard(this.kigyouCode)
    this.uploadData = { 'kigyouCode': this.kigyouCode, 'updUser': this.updUser, 'language': this.language }
    getBumonNameListAPI(this.kigyouCode)
      .then(res => {
        if (res.code === 200) {
          this.bumonNameList = ['全社', ...res.data]
        } else {
          this.rejectMsg()
        }
      }).catch((error) => {
        console.log('error', error)
        // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
        // return this.$router.push({ path: '/' })
      })
    getProductListAPI(this.kigyouCode, true)
      .then(tableRes => {
        if (tableRes.code === 200) {
          const _tableDataAuthcountList = tableRes.data.map(item => {
            return item.authcount
          })
          this.filterAuthcountMethod = [...new Set(_tableDataAuthcountList)]
        } else {
          this.rejectMsg()
        }
      })
      .catch(error => {
        // alert(error)
        // eslint-disable-next-line eqeqeq
        if (error.response.status != 202 && error.response.status != 401 && error.response.status != 403) {
          this.$alert('ネットワークエラーが発生した。ログイン画面へ遷移する。', 'システムエラー', {
            confirmButtonText: 'はい'
          }).then(() => {
            return this.$router.push({ path: '/' })
          })
        }
        // return this.$router.push({ path: '/' })
      })
  },
  methods: {
    rejectMsg() {
      this.$message.error('ネットワークエラーが発生した。')
    },
    loadGoalBoard(kigyouCode) {
      getGoalBoardAPI(kigyouCode)
        .then(res => {
          if (res.code === 200) {
            this.goalBoardData = res.data
            const formattedArr = JSON.parse(JSON.stringify(res.data.dataList))
            formattedArr.map((item, i) => {
              if (item.pre === '-') {
                item.pre = -i
              }
            })
            const sortarr = formattedArr.sort(this.sortBy('pre'))
            sortarr.forEach((element, index) => {
              element.tag = index + 1
            })
            const firstThreeSortArr = sortarr.slice(0, 3)
            const lastThreeSortArr = sortarr.slice(-3)
            this.goalBoardData.dataList.forEach(goalBoardItem => {
              firstThreeSortArr.forEach(firstThreeElement => {
                if (firstThreeElement.bumonName === goalBoardItem.bumonName) {
                  goalBoardItem.tag = firstThreeElement.tag
                }
              })
              if (this.goalBoardData.dataList.length >= 6) {
                lastThreeSortArr.forEach(lastThreeElement => {
                  if (lastThreeElement.bumonName === goalBoardItem.bumonName) {
                    goalBoardItem.tag = lastThreeElement.tag
                  }
                })
              }
            })
            this.$nextTick(() => {
              this.initChart()
            })
          } else {
            this.rejectMsg()
          }
        }).catch((error) => {
          console.log('error', error)
          // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
          // return this.$router.push({ path: '/' })
        })
    },
    loadIdeaUseBoard(kigyouCode) {
      getIdeaUseBoardAPI(kigyouCode)
        .then(res => {
          if (res.code === 200) {
            this.ideaUseData = res.data
            const formattedArr = JSON.parse(JSON.stringify(res.data.dataList))
            formattedArr.map((item, i) => {
              if (item.pre === '-') {
                item.pre = -i
              }
            })
            const sortarr = formattedArr.sort(this.sortBy('pre'))
            sortarr.forEach((element, index) => {
              element.tag = index + 1
            })
            const firstThreeSortArr = sortarr.slice(0, 3)
            const lastThreeSortArr = sortarr.slice(-3)
            this.ideaUseData.dataList.forEach(ideaUseItem => {
              firstThreeSortArr.forEach(firstThreeElement => {
                if (firstThreeElement.bumonName === ideaUseItem.bumonName) {
                  ideaUseItem.tag = firstThreeElement.tag
                }
              })
              if (this.ideaUseData.dataList.length >= 6) {
                lastThreeSortArr.forEach(lastThreeElement => {
                  if (lastThreeElement.bumonName === ideaUseItem.bumonName) {
                    ideaUseItem.tag = lastThreeElement.tag
                  }
                })
              }
            })
          } else {
            this.rejectMsg()
          }
        }).catch((error) => {
          console.log('error', error)
          // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
          // return this.$router.push({ path: '/' })
        })
    },
    loadCheckListBoard(kigyouCode) {
      getCheckListBoardAPI(kigyouCode)
        .then(res => {
          if (res.code === 200) {
            this.checkListBoardData = res.data
            const formattedArr = JSON.parse(JSON.stringify(res.data.dataList))
            formattedArr.map((item, i) => {
              if (item.fullPointPre === '-') {
                item.fullPointPre = -i
              }
            })
            const sortarr = formattedArr.sort(this.sortBy('fullPointPre'))
            sortarr.forEach((element, index) => {
              element.tag = index + 1
            })
            const firstThreeSortArr = sortarr.slice(0, 3)
            const lastThreeSortArr = sortarr.slice(-3)

            // sort by avg
            const formattedAvgArr = JSON.parse(JSON.stringify(res.data.dataList))
            formattedAvgArr.map((item, i) => {
              if (item.avg === '-') {
                item.avg = -i
              }
            })
            const sortavgarr = formattedAvgArr.sort(this.sortBy('avg'))
            sortavgarr.forEach((element, index) => {
              element.avgTag = index + 1
            })
            const firstThreeSortAvgArr = sortavgarr.slice(0, 3)
            const lastThreeSortAvgArr = sortavgarr.slice(-3)
            this.checkListBoardData.dataList.forEach(checkListBoardItem => {
              firstThreeSortArr.forEach(firstThreeElement => {
                if (firstThreeElement.bumonName === checkListBoardItem.bumonName) {
                  checkListBoardItem.tag = firstThreeElement.tag
                }
              })

              // sort by avg
              firstThreeSortAvgArr.forEach(firstThreeElement => {
                if (firstThreeElement.bumonName === checkListBoardItem.bumonName) {
                  checkListBoardItem.avgTag = firstThreeElement.avgTag
                }
              })
              if (this.checkListBoardData.dataList.length >= 6) {
                lastThreeSortArr.forEach(lastThreeElement => {
                  if (lastThreeElement.bumonName === checkListBoardItem.bumonName) {
                    checkListBoardItem.tag = lastThreeElement.tag
                  }
                })
                // sort by avg
                lastThreeSortAvgArr.forEach(lastThreeElement => {
                  if (lastThreeElement.bumonName === checkListBoardItem.bumonName) {
                    checkListBoardItem.avgTag = lastThreeElement.avgTag
                  }
                })
              }
            })
            this.$nextTick(() => {
              this.initChart3()
            })
          } else {
            this.rejectMsg()
          }
        }).catch((error) => {
          console.log('error', error)
          // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
          // return this.$router.push({ path: '/' })
        })
    },
    getCheckListItemsBoard(kigyouCode, bumonName) {
      getCheckListItemsBoardAPI(kigyouCode, bumonName)
        .then(res => {
          if (res.code === 200) {
            const checkListItemsArr = []
            Object.keys(res.data).forEach(element => {
              checkListItemsArr.push({
                checkNumber: element.split('item')[1], percentageH: res.data[element]
              })
            })
            const sortCheckListItemsArr = checkListItemsArr.sort(function(a, b) { return a.checkNumber - b.checkNumber })
            this.checkList = sortCheckListItemsArr.slice(0, 10)
            this.checkList1 = sortCheckListItemsArr.slice(-7)
            this.checkListItemsBoardLoading = false
          } else {
            this.rejectMsg()
          }
        }).catch((error) => {
          this.checkListItemsBoardLoading = false
          console.log('error', error)
          // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
          // return this.$router.push({ path: '/' })
        })
    },
    getTotalBoard(kigyouCode) {
      getTotalBoardAPI(kigyouCode)
        .then(res => {
          if (res.code === 200) {
            this.answerAcceptCount = res.data.answerAcceptCount
            this.dependenceCount = res.data.dependenceCount
            this.answerCount = res.data.answerCount
            this.dependencedCount = res.data.dependencedCount
          } else {
            this.rejectMsg()
          }
        }).catch((error) => {
          this.checkListItemsBoardLoading = false
          console.log('error', error)
          // alert('ネットワークエラーが発生した。ログイン画面へ遷移する。')
          // return this.$router.push({ path: '/' })
        })
    },
    changeBumonName(val) {
      this.checkListItemsBoardLoading = true
      if (val === '全社') {
        this.getCheckListItemsBoard(this.kigyouCode, '')
      } else {
        this.getCheckListItemsBoard(this.kigyouCode, val)
      }
    },
    sortBy(field) {
      return (x, y) => {
        return y[field] - x[field]
      }
    },
    onError(error) {
      console.log('onError:::', error)
      this.$alert('ログイン有効期限が切れましたので、再ログインしてください', 'ログイン有効期限', {
        confirmButtonText: '確定',
        callback: action => {
          this.$router.push('/')
        }
      })
    },
    onSuccess(response) {
      if (response.code === 200) {
        if (response.data !== 'success') {
          this.$alert(response.data,  this.$t('messages.errorTitle'), {
            confirmButtonText: 'OK'
          })
        } else {
          this.$router.replace(
            { name: '/refresh'
            })
        }
      }
    },
    downstreamToProductList() {
      this.$router.push({
        name: '/browser/productList',
        params: {
          tab: '1'
        }
      })
    },
    toDownstream() {
      this.$router.push({ path: '/pf/requestManage/downstream' })
    },
    upstreamToProductList() {
      this.$router.push({
        name: '/browser/productList',
        params: {
          fromPage: 'upstream',
          filterAuthcountMethod: JSON.stringify(this.filterAuthcountMethod)
        }
      })
    },
    toUpstream() {
      this.$router.push({ path: '/pf/requestManage/upstream' })
    },
    initChart() {
      const pie_chart = echarts.init(document.getElementById('pieChart'))
      var option = {
        grid: {
          left: '1%',
          right: '1%',
          bottom: '3%',
          top: '70%',
          containLabel: true
        },
        title: {
          text: this.goalBoardData.total.totalPre + '%',
          left: 'center',
          top: '16%',
          textStyle: {
            textAlign: 'center',
            // lineHeight: 30,
            fill: '#161616',
            fontSize: 30,
            fontWeight: 400
          }
        },
        graphic: {
          type: 'text',
          left: '37%',
          bottom: '1%',
          silent: true,
          invisible: false,
          style: {
            text: this.$t('messages.progressRate'),
            textAlign: 'center',
            fill: '#333',
            fontSize: 12
          }
        },
        series: [
          {
            name: this.$t('messages.progress'),
            type: 'pie',
            silent: true,
            // 起始刻度的角度，默认为 90 度，即圆心的正上方。0 度为圆心的正右方。
            startAngle: 0,
            hoverAnimation: false,
            tooltip: {},
            radius: ['100%', '55%'],
            center: ['50%', '100%'],
            labelLine: {
              normal: {
                show: false
              }
            },
            emphasis: {
              scale: false,
              itemStyle: {
                color: 'inherit'

              }
            },

            data: [
              {
                value: 100,
                itemStyle: {
                  normal: {
                    color: 'rgba(80,150,224,0)'
                  }
                }
              },
              {
                value: this.goalBoardData.total.totalPre, // 渐变色部分
                itemStyle: {
                  color: '#1D97D8'
                }
              },
              {
                value: 100 - Number(this.goalBoardData.total.totalPre), // 右侧部分
                itemStyle: {
                  normal: {
                    color: '#E4F1F8'
                  }
                }
              }
            ]
          }
        ]
      }
      pie_chart.setOption(option)
      window.addEventListener('resize', function() {
        pie_chart.resize()
      })
    },
    initChart3() {
      const pie_chart = echarts.init(document.getElementById('pieChart3'))
      const option = {
        // tooltip: {
        //   trigger: 'item',
        //   position: 'right',
        //   formatter: function(params) {
        //     return 8
        //   }
        // },
        grid: {
          left: '1%',
          right: '1%',
          bottom: '3%',
          containLabel: true
        },
        title: {
          text: this.checkListBoardData.total.fullPointTotalPre + '%',
          left: 'center',
          top: '44%',
          textStyle: {
            textAlign: 'center',
            // lineHeight: 30,
            fill: '#161616',
            fontSize: 28,
            fontWeight: 400
          }
        },
        graphic: {
          type: 'text',
          left: 'center',
          top: '30%',
          silent: true,
          invisible: false,
          style: {
            text: this.$t('messages.perfectScore'),
            textAlign: 'center',
            fill: '#6f6f6f',
            fontSize: 12
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['100%', '65%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            labelLine: {
              normal: {
                show: false
              }
            },
            label: {
              normal: {
                show: false
              }
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 1
            },
            emphasis: {
              scale: false,
              itemStyle: {
                color: 'inherit'

              }
            },
            data: [
              {
                value: Number(this.checkListBoardData.total.fullPointTotalPre), // 渐变色部分
                itemStyle: {
                  color: '#1D97D8'
                }
              },
              {
                value: 100 - Number(this.checkListBoardData.total.fullPointTotalPre), // 右侧部分
                itemStyle: {
                  normal: {
                    color: '#E4F1F8'
                  }
                }
              }
            ]
          }
        ]
      }
      pie_chart.setOption(option)
      window.addEventListener('resize', function() {
        pie_chart.resize()
      })
    }

  }
}
</script>
