var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "float-container" }, [
    _vm.dashboard
      ? _c(
          "div",
          { staticClass: "title" },
          [
            _c("span", { staticClass: "input-span" }, [
              _c("strong", [_vm._v(_vm._s(_vm.$t("messages.dashboardTitle")))]),
            ]),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.toAll },
              },
              [_vm._v(_vm._s(_vm.$t("messages.productList")))]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.all
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c(
              "strong",
              { staticClass: "titleFont", on: { click: _vm.toDashboard } },
              [
                _c("i", {
                  staticClass: "el-icon-arrow-left",
                  staticStyle: { "padding-right": "6px" },
                }),
                _vm._v(" " + _vm._s(_vm.$t("messages.productListPage"))),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.sansyo
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c(
              "strong",
              { staticClass: "titleFont", on: { click: _vm.toBack } },
              [
                _c("i", {
                  staticClass: "el-icon-arrow-left",
                  staticStyle: { "padding-right": "6px" },
                }),
                _vm._v(_vm._s(_vm.$t("messages.prd"))),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.update
      ? _c(
          "div",
          { staticClass: "title" },
          [
            _c("span", { staticClass: "input-span" }, [
              _c(
                "strong",
                { staticClass: "titleFont", on: { click: _vm.toBack } },
                [
                  _c("i", {
                    staticClass: "el-icon-arrow-left",
                    staticStyle: { "padding-right": "6px" },
                  }),
                  _vm._v(_vm._s(_vm.$t("messages.updatePage"))),
                ]
              ),
            ]),
            !_vm.tree
              ? _c(
                  "el-breadcrumb",
                  { staticClass: "bread", attrs: { separator: "/" } },
                  _vm._l(
                    _vm.breadcrumbList,
                    function (subProcess, subProcessIndex) {
                      return _c(
                        "el-breadcrumb-item",
                        { key: subProcessIndex },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.jump(subProcess[0].processcode)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(subProcess[0].processcode) +
                                  ":" +
                                  _vm._s(subProcess[0].processname)
                              ),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  1
                )
              : _vm._e(),
            _vm.tree
              ? _c(
                  "el-breadcrumb",
                  { staticClass: "bread", attrs: { separator: "/" } },
                  _vm._l(
                    _vm.breadcrumbList,
                    function (subProcess, subProcessIndex) {
                      return _c(
                        "el-breadcrumb-item",
                        { key: subProcessIndex },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(subProcess.processcode) +
                                ":" +
                                _vm._s(subProcess.processname) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    }
                  ),
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.add
      ? _c(
          "div",
          { staticClass: "title" },
          [
            _c("span", { staticClass: "input-span" }, [
              _c(
                "strong",
                { staticClass: "titleFont", on: { click: _vm.toBack } },
                [
                  _c("i", {
                    staticClass: "el-icon-arrow-left",
                    staticStyle: { "padding-right": "6px" },
                  }),
                  _vm._v(_vm._s(_vm.$t("messages.addNewData"))),
                ]
              ),
            ]),
            !_vm.tree
              ? _c(
                  "el-breadcrumb",
                  { staticClass: "bread", attrs: { separator: "/" } },
                  _vm._l(
                    _vm.breadcrumbList,
                    function (subProcess, subProcessIndex) {
                      return _c(
                        "el-breadcrumb-item",
                        { key: subProcessIndex },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.jump(subProcess[0].processcode)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(subProcess[0].processcode) +
                                  ":" +
                                  _vm._s(subProcess[0].processname) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  1
                )
              : _vm._e(),
            _vm.tree
              ? _c(
                  "el-breadcrumb",
                  { staticClass: "bread", attrs: { separator: "/" } },
                  _vm._l(
                    _vm.breadcrumbList,
                    function (subProcess, subProcessIndex) {
                      return _c(
                        "el-breadcrumb-item",
                        { key: subProcessIndex },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(subProcess.processcode) +
                                ":" +
                                _vm._s(subProcess.processname) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    }
                  ),
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.operate
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c(
              "strong",
              { staticClass: "titleFont", on: { click: _vm.toBack } },
              [
                _c("i", {
                  staticClass: "el-icon-arrow-left",
                  staticStyle: { "padding-right": "6px" },
                }),
                _vm._v(_vm._s(_vm.$t("messages.disclosureMgmt"))),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.simulate
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c(
              "strong",
              { staticClass: "titleFont", on: { click: _vm.toBack } },
              [
                _c("i", {
                  staticClass: "el-icon-arrow-left",
                  staticStyle: { "padding-right": "6px" },
                }),
                _vm._v(_vm._s(_vm.$t("messages.simulation"))),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.useradd
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c(
              "strong",
              { staticClass: "titleFont", on: { click: _vm.toBack } },
              [
                _c("i", {
                  staticClass: "el-icon-arrow-left",
                  staticStyle: { "padding-right": "6px" },
                }),
                _vm._v("新規ユーザー"),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.useredit
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c(
              "strong",
              { staticClass: "titleFont", on: { click: _vm.toBack } },
              [
                _c("i", {
                  staticClass: "el-icon-arrow-left",
                  staticStyle: { "padding-right": "6px" },
                }),
                _vm._v("ユーザー画面"),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.original
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c("strong", [_vm._v(_vm._s(_vm.$t("messages.originalEFMgmn")))]),
          ]),
        ])
      : _vm._e(),
    _vm.product
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.$t("messages.productMasterMgmt"))),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.process
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.$t("messages.processFormatMgmt"))),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.basicRule
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c("strong", [_vm._v(_vm._s(_vm.$t("messages.efRullMgmt")))]),
          ]),
        ])
      : _vm._e(),
    _vm.referenceIndex
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c(
              "strong",
              { staticClass: "titleFont", on: { click: _vm.toBack } },
              [
                _c("i", {
                  staticClass: "el-icon-arrow-left",
                  staticStyle: { "padding-right": "6px" },
                }),
                _vm._v(_vm._s(_vm.$t("messages.pro"))),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.productList
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c(
              "strong",
              { staticClass: "titleFont", on: { click: _vm.toBack } },
              [
                _c("i", {
                  staticClass: "el-icon-arrow-left",
                  staticStyle: { "padding-right": "6px" },
                }),
                _vm._v(_vm._s(_vm.$t("messages.productList"))),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.downstream
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.$t("messages.manageSentRequests"))),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.upstream
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.$t("messages.manageReceivedRequests"))),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.compareAbstract
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c(
              "strong",
              { staticClass: "titleFont", on: { click: _vm.toBack } },
              [
                _c("i", {
                  staticClass: "el-icon-arrow-left",
                  staticStyle: { "padding-right": "6px" },
                }),
                _vm._v(_vm._s(_vm.$t("messages.compareAbstract"))),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.compareExplicit
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c("strong", { staticClass: "titleFont" }, [
              _vm._v(_vm._s(_vm.$t("messages.compareDetails"))),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.referenceTree
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c(
              "strong",
              { staticClass: "titleFont", on: { click: _vm.toBack } },
              [
                _c("i", {
                  staticClass: "el-icon-arrow-left",
                  staticStyle: { "padding-right": "6px" },
                }),
                _vm._v(_vm._s(_vm.$t("messages.referenceTree"))),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.report
      ? _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "input-span" }, [
            _c(
              "strong",
              { staticClass: "titleFont", on: { click: _vm.toBack } },
              [
                _c("i", {
                  staticClass: "el-icon-arrow-left",
                  staticStyle: { "padding-right": "6px" },
                }),
                _vm._v("レポート作成"),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }