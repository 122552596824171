var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _c("div", { staticClass: "breadcrumb-container" }, [
      _c(
        "div",
        { staticClass: "top-menu" },
        [
          _c(
            "el-button",
            {
              staticClass: "bg-size margin-right-32",
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  _vm.drawer = true
                },
              },
            },
            [
              _c("span", [
                _c("img", {
                  attrs: { src: require("../../assets/static/icon/menu.svg") },
                }),
              ]),
            ]
          ),
          _c(
            "el-drawer",
            {
              attrs: {
                visible: _vm.drawer,
                direction: _vm.direction,
                modal: false,
                "with-header": false,
                size: "21%",
                "modal-append-to-body": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c("header", { staticClass: "menu-header" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "el-drawer__close-btn margin-left-12 margin-right-18",
                        attrs: { "aria-label": "close drawer", type: "button" },
                        on: { click: _vm.closeicon },
                      },
                      [
                        _c("i", {
                          staticClass: "el-dialog__close el-icon el-icon-close",
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "avatar-container",
                        attrs: { trigger: "click" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "avatar-wrapper",
                            on: { click: _vm.toAll },
                          },
                          [
                            _c("img", {
                              staticClass: "user-avatar",
                              attrs: {
                                src: require("../../assets/static/icon/logo.svg"),
                              },
                            }),
                            _c("span", { staticClass: "main-name" }, [
                              _vm._v("LCA Plus "),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "el-menu",
                    {
                      staticClass: "el-menu-vertical-demo",
                      attrs: {
                        "default-active": "",
                        "unique-opened": true,
                        "background-color": "#171717",
                        "text-color": "#fff",
                        "active-text-color": "#ffd04b",
                      },
                      on: { open: _vm.handleOpen, close: _vm.handleClose },
                    },
                    [
                      _c(
                        "el-menu-item",
                        { attrs: { index: "1" }, on: { click: _vm.toAll } },
                        [
                          _c("i", { staticClass: "img-icon margin-right-8" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/static/icon/nav-01.svg"),
                              },
                            }),
                          ]),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("messages.dashboardTitle")))]
                          ),
                        ]
                      ),
                      _c(
                        "el-menu-item",
                        {
                          attrs: { index: "2" },
                          on: { click: _vm.toProductList },
                        },
                        [
                          _c("i", { staticClass: "img-icon margin-right-8" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/static/icon/nav-02.svg"),
                              },
                            }),
                          ]),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("messages.calculationTitle"))
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "el-submenu",
                        { attrs: { index: "3" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "i",
                              { staticClass: "img-icon margin-right-8" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/static/icon/nav-03.svg"),
                                  },
                                }),
                              ]
                            ),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("messages.emissionsTitle"))),
                            ]),
                          ]),
                          _c(
                            "el-menu-item-group",
                            [
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "3-1" },
                                  on: { click: _vm.toAdd },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("messages.registerTitle"))
                                  ),
                                ]
                              ),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "3-2" },
                                  on: { click: _vm.toTempUpdate },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("messages.temporaryEditTitle")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "3-3" },
                                  on: { click: _vm.toRealUpdate },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("messages.finalizedEditTitle")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-menu-item",
                        {
                          attrs: { index: "4" },
                          on: { click: _vm.toReference },
                        },
                        [
                          _c("i", { staticClass: "img-icon margin-right-8" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/static/icon/nav-04.svg"),
                              },
                            }),
                          ]),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("messages.lookUpTitle")))]
                          ),
                        ]
                      ),
                      _c(
                        "el-menu-item",
                        {
                          attrs: { index: "5" },
                          on: { click: _vm.toSimulate },
                        },
                        [
                          _c("div", { staticClass: "border-bottom" }, [
                            _c(
                              "i",
                              { staticClass: "img-icon margin-right-8" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/static/icon/nav-05.svg"),
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("messages.simulationTitle"))
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-submenu",
                        { attrs: { index: "9" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "i",
                              { staticClass: "img-icon margin-right-8" },
                              [
                                _c("img", {
                                  staticStyle: { width: "13px" },
                                  attrs: {
                                    src: require("@/assets/static/icon/nav-09.svg"),
                                  },
                                }),
                              ]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("messages.emissionsCompareTitle"))
                              ),
                            ]),
                          ]),
                          _c(
                            "el-menu-item-group",
                            [
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "9-1" },
                                  on: { click: _vm.toCompareAbstract },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("messages.overviewCompareTitle")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "9-2" },
                                  on: { click: _vm.toCompareExplicit },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("messages.detailsCompareTitle")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-submenu",
                        { attrs: { index: "6" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "i",
                              { staticClass: "img-icon margin-right-8" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/static/icon/nav-06.svg"),
                                  },
                                }),
                              ]
                            ),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("messages.reportTitle"))),
                            ]),
                          ]),
                          _c(
                            "el-menu-item-group",
                            [
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "6-1" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toSeihin()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("messages.disclosureTitle"))
                                  ),
                                ]
                              ),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "6-2" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.todownstream()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("messages.requestTitle"))
                                  ),
                                ]
                              ),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "6-3" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toupstream()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("messages.receivedTitle"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-submenu",
                        { attrs: { index: "7" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "i",
                              { staticClass: "img-icon margin-right-8" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/static/icon/nav-07.svg"),
                                  },
                                }),
                              ]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("messages.manageMasterTitle"))
                              ),
                            ]),
                          ]),
                          _c(
                            "el-menu-item-group",
                            [
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "7-1" },
                                  on: { click: _vm.toProduct },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("messages.productMasterTitle")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "7-2" },
                                  on: { click: _vm.toOriginal },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("messages.originalTitle"))
                                  ),
                                ]
                              ),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "7-3" },
                                  on: { click: _vm.toProcess },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("messages.processTitle"))
                                  ),
                                ]
                              ),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "7-4" },
                                  on: { click: _vm.toRule },
                                },
                                [_vm._v(_vm._s(_vm.$t("messages.ruleTitle")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-submenu",
                        { attrs: { index: "8" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "i",
                              { staticClass: "img-icon margin-right-8" },
                              [
                                _c("img", {
                                  attrs: {
                                    width: "16",
                                    src: require("@/assets/static/icon/nav-08.svg"),
                                  },
                                }),
                              ]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("messages.guidelinesTitle"))
                              ),
                            ]),
                          ]),
                          _c(
                            "el-menu-item-group",
                            [
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "8-1" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openpdf()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("messages.caltGuidelinesTitle")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "8-2" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openvideo()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("messages.videoTitle")))]
                              ),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "8-3" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checklist()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("messages.checkListTitle"))
                                  ),
                                ]
                              ),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "8-4" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.systemManual()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("messages.uerManualTitle"))
                                  ),
                                ]
                              ),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "8-4" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openOperationVideo()
                                    },
                                  },
                                },
                                [_vm._v("操作説明動画")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-submenu",
                        { attrs: { index: "10" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "i",
                              { staticClass: "img-icon margin-right-8" },
                              [
                                _c("img", {
                                  attrs: {
                                    width: "16",
                                    src: require("@/assets/static/icon/nav-10.png"),
                                  },
                                }),
                              ]
                            ),
                            _c("span", [_vm._v("手続き関連")]),
                          ]),
                          _c(
                            "el-menu-item-group",
                            [
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "10-1" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openUsePdf()
                                    },
                                  },
                                },
                                [_vm._v("LCA Plus利用規約")]
                              ),
                              _c(
                                "el-menu-item",
                                {
                                  attrs: { index: "10-2" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openIdeaPdf()
                                    },
                                  },
                                },
                                [_vm._v("IDEAライセンス規約")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "avatar-container", attrs: { trigger: "click" } },
        [
          _c(
            "div",
            { staticClass: "avatar-wrapper", on: { click: _vm.toAll } },
            [
              _c("img", {
                staticClass: "user-avatar",
                attrs: { src: require("../../assets/static/icon/logo.svg") },
              }),
              _c("span", { staticClass: "main-name" }, [_vm._v("LCA Plus ")]),
            ]
          ),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        _c(
          "el-dropdown",
          { staticClass: "language-container", attrs: { trigger: "click" } },
          [
            _c("div", [
              _c("span", [_vm._v("Language: " + _vm._s(_vm.language))]),
              _c("i", { staticClass: "el-icon-caret-bottom" }),
            ]),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "languaeg-dropdown",
                attrs: { slot: "dropdown" },
                slot: "dropdown",
              },
              [
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.changeLanguage(1)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "block",
                          "text-align": "center",
                        },
                      },
                      [_vm._v("日本語")]
                    ),
                  ]
                ),
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.changeLanguage(2)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "block",
                          "text-align": "center",
                        },
                      },
                      [_vm._v("English")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-dropdown",
          { staticClass: "avatar-container", attrs: { trigger: "click" } },
          [
            _c("div", { staticClass: "avatar-wrapper" }, [
              _c("img", {
                staticClass: "user-avatar",
                attrs: {
                  src: require("../../assets/static/icon/userIcon.png"),
                },
              }),
              _c("i", { staticClass: "el-icon-caret-bottom" }),
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "1rem" },
                  attrs: { id: "company-name" },
                },
                [_vm._v(_vm._s(_vm.companyName))]
              ),
              _c("span", { attrs: { id: "user-name" } }, [
                _vm._v(
                  _vm._s(_vm.userName) + "（" + _vm._s(_vm.roleName) + "）"
                ),
              ]),
            ]),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "user-dropdown",
                attrs: { slot: "dropdown" },
                slot: "dropdown",
              },
              [
                false
                  ? _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.edituser($event)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "block",
                              "text-align": "center",
                            },
                          },
                          [_vm._v("ユーザー情報変更")]
                        ),
                      ]
                    )
                  : _vm._e(),
                false
                  ? _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.adduser($event)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "block",
                              "text-align": "center",
                            },
                          },
                          [_vm._v("新規ユーザー")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.logout($event)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "block",
                          "text-align": "center",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("messages.logout")))]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }