var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "margin-24 reference" },
    [
      _c("div", { staticClass: "card-box" }, [
        _c(
          "div",
          { staticClass: "header-layout" },
          [
            _c(
              "el-row",
              { staticClass: "width-33", attrs: { gutter: 16 } },
              [
                _c("el-col", { attrs: { span: 14 } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading4SeihinList,
                          expression: "loading4SeihinList",
                        },
                      ],
                      staticClass: "production-item",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content-layout-start" },
                        [
                          _c(
                            "span",
                            { staticClass: "margin-bot-8 margin-right-16" },
                            [_vm._v(_vm._s(_vm.$t("messages.productVersion")))]
                          ),
                          _vm.seihinAuth === 0
                            ? _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "right",
                                    trigger: "click",
                                  },
                                },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.simulateNote,
                                        expression: "simulateNote",
                                      },
                                    ],
                                    attrs: {
                                      readonly: "true",
                                      cols: "40",
                                      rows: "10",
                                      maxlength: "3000",
                                      placeholder: _vm.$t("messages.note"),
                                    },
                                    domProps: { value: _vm.simulateNote },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.simulateNote = $event.target.value
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "icon-note",
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/static/icon/note.svg"),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showIcon && _vm.seihinAuth === 0,
                                  expression: "showIcon && seihinAuth === 0",
                                },
                              ],
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-price-tag",
                                staticStyle: {
                                  "margin-left": "1rem",
                                  "padding-top": "3px",
                                  transform: "rotate(315deg)",
                                  color: "#1d97d8",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showSeihinMasterPopup(true)
                                  },
                                },
                              }),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showFlag,
                                  expression: "showFlag",
                                },
                              ],
                              staticClass: "margin-left-16",
                              staticStyle: {
                                color: "#F56C6C",
                                "font-size": "12px",
                                "line-height": "1",
                                width: "50%",
                              },
                            },
                            [
                              _vm._v(
                                "* 該当製品は製品マスタから削除されています"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form",
                        {
                          ref: "selectValueDefaultForm",
                          attrs: {
                            model: _vm.selectValueDefaultForm,
                            rules: _vm.selectValueDefaultRules,
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "selectValueDefault" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "seihin-select",
                                  attrs: {
                                    title: _vm.temptitle,
                                    placeholder: _vm.$t(
                                      "messages.checkProductName"
                                    ),
                                    size: "mini",
                                    filterable: "",
                                  },
                                  on: { change: _vm.changeSeihin },
                                  model: {
                                    value:
                                      _vm.selectValueDefaultForm
                                        .selectValueDefault,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectValueDefaultForm,
                                        "selectValueDefault",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectValueDefaultForm.selectValueDefault",
                                  },
                                },
                                _vm._l(_vm.seihinList, function (item) {
                                  return _c("el-option", {
                                    key: item.seihincode + "/" + item.version,
                                    attrs: {
                                      label: item.version
                                        ? item.seihinname + " " + item.version
                                        : item.seihinname,
                                      value:
                                        item.seihincode + "/" + item.version,
                                      disabled: item.disabled,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 3 } }, [
                  _c(
                    "div",
                    { staticClass: "production-item" },
                    [
                      _c("span", { staticClass: "margin-bot-8" }, [
                        _vm._v(_vm._s(_vm.$t("messages.unit"))),
                      ]),
                      _c("el-input", {
                        attrs: {
                          value: _vm.seihinUnit,
                          size: "mini",
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 7 } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isSelfIdea,
                          expression: "isSelfIdea",
                        },
                      ],
                      staticClass: "production-item",
                    },
                    [
                      _c("span", { staticClass: "self-idea" }, [
                        _vm._v(_vm._s(_vm.$t("messages.ownEFRegComplete"))),
                      ]),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _vm.seihinAuth === 0
              ? _c(
                  "div",
                  { staticClass: "infoAndhensyu header_button_area" },
                  [
                    _c(
                      "div",
                      { staticClass: "self_idea" },
                      [
                        _c("div", { staticClass: "button_top_block" }, [
                          _c("i", {
                            staticClass: "el-icon-question hidden_block",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          }),
                        ]),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.canEdit,
                                expression: "canEdit",
                              },
                            ],
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.toReport()
                              },
                            },
                          },
                          [_vm._v("レポート")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-dialog",
                      {
                        staticClass: "dialog-control",
                        attrs: {
                          top: "32vh",
                          visible: _vm.dialog_flag,
                          "close-on-click-modal": false,
                          "close-on-press-escape": false,
                          width: "30%",
                          center: "",
                          "append-to-body": "",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.dialog_flag = $event
                          },
                        },
                      },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.dialog_index == 1
                                ? "レポート作成機能は、管理者権限の方のみご利用できます。<br>貴社内でご確認ください。"
                                : "レポート作成機能を利用するにはご契約が必要です。<br>LCA Plus担当者へお問い合わせください。"
                            ),
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.dialog_flag = false
                                  },
                                },
                              },
                              [_vm._v("閉じる")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.dialog_index == 2,
                                    expression: "dialog_index == 2",
                                  },
                                ],
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.reportPlan()
                                  },
                                },
                              },
                              [_vm._v("問合せ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "self_idea" },
                      [
                        _c(
                          "div",
                          { staticClass: "button_top_block" },
                          [
                            _c(
                              "el-popover",
                              {
                                staticClass: "suggestion-icon",
                                attrs: {
                                  placement: "top-end",
                                  width: "200",
                                  trigger: "hover",
                                  content: _vm.$t("messages.ownEFRegisterTip"),
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-question",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.canEdit,
                                expression: "canEdit",
                              },
                            ],
                            attrs: {
                              type: "primary",
                              size: "small",
                              disabled: _vm.isSelfIdea,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.makeSelfIdea()
                              },
                            },
                          },
                          [
                            _vm._v(_vm._s(_vm.$t("messages.ownEFRegister"))),
                            _c("i", {
                              staticClass:
                                "margin-left-8 img-size el-icon-refresh-right",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "self_idea" },
                      [
                        _c("div", { staticClass: "button_top_block" }, [
                          _c("i", {
                            staticClass: "el-icon-question hidden_block",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          }),
                        ]),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.canEdit,
                                expression: "canEdit",
                              },
                            ],
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.toCampare()
                              },
                            },
                          },
                          [
                            _vm._v(_vm._s(_vm.$t("messages.compare"))),
                            _c("i", { staticClass: "margin-left-8 img-size" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/static/icon/nav-09.svg"),
                                },
                              }),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "self_idea",
                        staticStyle: { "margin-right": "0px" },
                      },
                      [
                        _c("div", { staticClass: "button_top_block" }, [
                          _c("i", {
                            staticClass: "el-icon-question hidden_block",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          }),
                        ]),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.canEdit,
                                expression: "canEdit",
                              },
                            ],
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.toSimulate()
                              },
                            },
                          },
                          [
                            _vm._v(_vm._s(_vm.$t("messages.simulationTitle"))),
                            _c("i", { staticClass: "margin-left-8 img-size" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/static/icon/simulation.svg"),
                                },
                              }),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "margin-tb-24" }, [
        _c("div", { staticClass: "card-box" }, [
          _c(
            "div",
            {
              staticClass: "content-layout-noflex margin-bot-16",
              staticStyle: { width: "100%" },
            },
            [
              _c("div", [
                _c(
                  "h5",
                  { staticClass: "about-title content-layout-center-noflex" },
                  [
                    _c("span", { staticClass: "left-line margin-right-4" }, [
                      _vm._v(_vm._s(_vm.$t("messages.productOverview"))),
                    ]),
                    _c(
                      "span",
                      [
                        _vm.seihinAuth !== 0 &&
                        _vm.processTableData.noShow.emissionc > 0
                          ? _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "right",
                                  trigger: "hover",
                                  width: "230",
                                },
                              },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.$t("messages.triangleTip"))
                                  ),
                                ]),
                                _c(
                                  "i",
                                  {
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "icon-size",
                                      attrs: {
                                        src: require("@/assets/static/icon/explanation.svg"),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectValueDefaultForm.selectValueDefault,
                          expression:
                            "selectValueDefaultForm.selectValueDefault",
                        },
                      ],
                      staticClass: "btn-function margin-right-16",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.downLoadCSV()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "download-icon",
                        staticStyle: {
                          height: "1.4rem",
                          width: "1.4rem",
                          "margin-right": "0.8rem",
                        },
                        attrs: {
                          src: require("@/assets/static/icon/download.svg"),
                        },
                      }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("messages.downloadcsv"))),
                      ]),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isTreeSeihin,
                          expression: "isTreeSeihin",
                        },
                      ],
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.toTree()
                        },
                      },
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("messages.treeList"))),
                      _c("i", {
                        staticClass: "el-icon-s-fold margin-left-8 img-size",
                      }),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectValueDefaultForm.selectValueDefault,
                          expression:
                            "selectValueDefaultForm.selectValueDefault",
                        },
                      ],
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.toSingle()
                        },
                      },
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("messages.viewDetails"))),
                      _c("i", {
                        staticClass: "el-icon-view margin-left-8 img-size",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "content-layout" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading4Pie,
                    expression: "loading4Pie",
                  },
                ],
                staticClass: "echart-container",
              },
              [
                _c("div", {
                  staticClass: "refer-chart",
                  attrs: { id: "pieChart" },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.seihinVersion,
                        expression: "seihinVersion",
                      },
                    ],
                    staticClass: "totalNum",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.toScientificNum(_vm.processTableData.sum.sum)[0]
                      ) +
                        " " +
                        _vm._s(
                          _vm.toScientificNum(_vm.processTableData.sum.sum)[1]
                        )
                    ),
                    _c("sup", [
                      _vm._v(
                        _vm._s(
                          _vm.toScientificNum(_vm.processTableData.sum.sum)[2]
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "popbtn",
                    staticStyle: {
                      width: "90%",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                    },
                  },
                  [
                    _vm.seihinAuth == 0
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-popover",
                              {
                                ref: "popover",
                                attrs: {
                                  "popper-class": "pop",
                                  placement: "top-start",
                                  trigger: "manual",
                                },
                                model: {
                                  value: _vm.showPopover,
                                  callback: function ($$v) {
                                    _vm.showPopover = $$v
                                  },
                                  expression: "showPopover",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticStyle: {
                                    float: "right",
                                    "background-color": "#FFF",
                                    color: "#C0C4CC",
                                    border: "none",
                                  },
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-close",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancel()
                                    },
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "pop-table",
                                    staticStyle: {
                                      display: "flex",
                                      width: "100%",
                                      "padding-left": "1%",
                                    },
                                  },
                                  [
                                    _c(
                                      "table",
                                      {
                                        staticStyle: {
                                          "table-layout": "fixed",
                                        },
                                        attrs: { width: "100%" },
                                      },
                                      [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { staticStyle: { width: "6%" } },
                                            [
                                              _c("div", {
                                                staticClass: "table_headers",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticStyle: { width: "8%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "table_headers_input",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "title" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "messages.productUnit"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "table_headers_input",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "title" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "messages.totalEmissions1"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_headers",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/static/process/a1.svg"),
                                                      height: "50px",
                                                      width: "50px",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    { staticClass: "title" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("messages.rmp")
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "h6",
                                                    { staticClass: "flag" },
                                                    [_vm._v("（A1）")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "1px",
                                                        color: "darkgrey",
                                                      },
                                                    },
                                                    [_vm._v("(kg-CO₂eq)")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_headers",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/static/process/a2.svg"),
                                                      height: "50px",
                                                      width: "50px",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    { staticClass: "title" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "messages.transportation"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "h6",
                                                    { staticClass: "flag" },
                                                    [_vm._v("（A2・A4）")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "1px",
                                                        color: "darkgrey",
                                                      },
                                                    },
                                                    [_vm._v("(kg-CO₂eq)")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_headers",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/static/process/a3.svg"),
                                                      height: "50px",
                                                      width: "50px",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    { staticClass: "title" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "messages.production"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "h6",
                                                    { staticClass: "flag" },
                                                    [_vm._v("（A3）")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "1px",
                                                        color: "darkgrey",
                                                      },
                                                    },
                                                    [_vm._v("(kg-CO₂eq)")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_headers",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/static/process/b1.svg"),
                                                      height: "50px",
                                                      width: "50px",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    { staticClass: "title" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "messages.usage"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "h6",
                                                    { staticClass: "flag" },
                                                    [_vm._v("（B）")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "1px",
                                                        color: "darkgrey",
                                                      },
                                                    },
                                                    [_vm._v("(kg-CO₂eq)")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_headers",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/static/process/c1.svg"),
                                                      height: "50px",
                                                      width: "50px",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    { staticClass: "title" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "messages.disposalRecycling"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "h6",
                                                    { staticClass: "flag" },
                                                    [_vm._v("（C）")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "1px",
                                                        color: "darkgrey",
                                                      },
                                                    },
                                                    [_vm._v("(kg-CO₂eq)")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticStyle: { width: "8%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "table_headers_input",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "title" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "messages.conversionCoef"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("th", {
                                            staticStyle: {
                                              width: "10%",
                                              "white-space": "nowrap",
                                            },
                                          }),
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "td",
                                            { staticStyle: { width: "6%" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "title" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "messages.mainUnit"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "8%" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "table_input" },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      height: "35px",
                                                    },
                                                    attrs: {
                                                      size: "medium",
                                                      value: _vm.seihinUnit,
                                                      disabled: true,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.sum
                                                          .sum
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .sum.sum
                                                        )[1]
                                                      )
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .sum.sum
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.A1
                                                          .emissionc
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A1.emissionc
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A1.emissionc
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData
                                                          .A2A4.emissionc
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A2A4.emissionc
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A2A4.emissionc
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.A3
                                                          .emissionc
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A3.emissionc
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A3.emissionc
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.B
                                                          .emissionc
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.B
                                                            .emissionc
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.B
                                                            .emissionc
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.C
                                                          .emissionc
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.C
                                                            .emissionc
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.C
                                                            .emissionc
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("td", {
                                            staticStyle: { width: "8%" },
                                          }),
                                          _c("td", {
                                            staticStyle: {
                                              width: "10%",
                                              "white-space": "nowrap",
                                            },
                                          }),
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "td",
                                            { staticStyle: { width: "6%" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "title" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "messages.subunit1"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "8%" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "table_input" },
                                                [
                                                  _c("el-input", {
                                                    attrs: { size: "medium" },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.getInputValue()
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.unit[0],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.unit,
                                                          0,
                                                          $$v
                                                        )
                                                      },
                                                      expression: "unit[0]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.sum
                                                          .sum *
                                                          Number(_vm.percent[0])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .sum.sum *
                                                            Number(
                                                              _vm.percent[0]
                                                            )
                                                        )[1]
                                                      )
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .sum.sum *
                                                            Number(
                                                              _vm.percent[0]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.A1
                                                          .emissionc *
                                                          Number(_vm.percent[0])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A1.emissionc *
                                                            Number(
                                                              _vm.percent[0]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A1.emissionc *
                                                            Number(
                                                              _vm.percent[0]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData
                                                          .A2A4.emissionc *
                                                          Number(_vm.percent[0])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A2A4.emissionc *
                                                            Number(
                                                              _vm.percent[0]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A2A4.emissionc *
                                                            Number(
                                                              _vm.percent[0]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.A3
                                                          .emissionc *
                                                          Number(_vm.percent[0])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A3.emissionc *
                                                            Number(
                                                              _vm.percent[0]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A3.emissionc *
                                                            Number(
                                                              _vm.percent[0]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.B
                                                          .emissionc *
                                                          Number(_vm.percent[0])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.B
                                                            .emissionc *
                                                            Number(
                                                              _vm.percent[0]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.B
                                                            .emissionc *
                                                            Number(
                                                              _vm.percent[0]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.C
                                                          .emissionc *
                                                          Number(_vm.percent[0])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.C
                                                            .emissionc *
                                                            Number(
                                                              _vm.percent[0]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.C
                                                            .emissionc *
                                                            Number(
                                                              _vm.percent[0]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "8%" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "table_input" },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      size: "medium",
                                                      placeholder: "",
                                                      disabled: !_vm.unit[0],
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.validateCoefficient(
                                                          _vm.percent[0],
                                                          0
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.percent[0],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.percent,
                                                          0,
                                                          $$v
                                                        )
                                                      },
                                                      expression: "percent[0]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                width: "10%",
                                                "white-space": "nowrap",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.messWarn_0,
                                                      expression: "messWarn_0",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    color: "#F56C6C",
                                                    "font-size": "0.8rem",
                                                    "line-height": "1",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "半角数値を入力してください"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.messNull_0,
                                                      expression: "messNull_0",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    color: "#F56C6C",
                                                    "font-size": "0.8rem",
                                                    "line-height": "1",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "係数を記入してください"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "td",
                                            { staticStyle: { width: "6%" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "title" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "messages.subunit2"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "8%" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "table_input" },
                                                [
                                                  _c("el-input", {
                                                    attrs: { size: "medium" },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.getInputValue()
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.unit[1],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.unit,
                                                          1,
                                                          $$v
                                                        )
                                                      },
                                                      expression: "unit[1]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.sum
                                                          .sum *
                                                          Number(_vm.percent[1])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .sum.sum *
                                                            Number(
                                                              _vm.percent[1]
                                                            )
                                                        )[1]
                                                      )
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .sum.sum *
                                                            Number(
                                                              _vm.percent[1]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.A1
                                                          .emissionc *
                                                          Number(_vm.percent[1])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A1.emissionc *
                                                            Number(
                                                              _vm.percent[1]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A1.emissionc *
                                                            Number(
                                                              _vm.percent[1]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData
                                                          .A2A4.emissionc *
                                                          Number(_vm.percent[1])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A2A4.emissionc *
                                                            Number(
                                                              _vm.percent[1]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A2A4.emissionc *
                                                            Number(
                                                              _vm.percent[1]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.A3
                                                          .emissionc *
                                                          Number(_vm.percent[1])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A3.emissionc *
                                                            Number(
                                                              _vm.percent[1]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A3.emissionc *
                                                            Number(
                                                              _vm.percent[1]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.B
                                                          .emissionc *
                                                          Number(_vm.percent[1])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.B
                                                            .emissionc *
                                                            Number(
                                                              _vm.percent[1]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.B
                                                            .emissionc *
                                                            Number(
                                                              _vm.percent[1]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.C
                                                          .emissionc *
                                                          Number(_vm.percent[1])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.C
                                                            .emissionc *
                                                            Number(
                                                              _vm.percent[1]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.C
                                                            .emissionc *
                                                            Number(
                                                              _vm.percent[1]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "8%" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "table_input" },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      size: "medium",
                                                      placeholder: "",
                                                      disabled: !_vm.unit[1],
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.validateCoefficient(
                                                          _vm.percent[1],
                                                          1
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.percent[1],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.percent,
                                                          1,
                                                          $$v
                                                        )
                                                      },
                                                      expression: "percent[1]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                width: "10%",
                                                "white-space": "nowrap",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.messWarn_1,
                                                      expression: "messWarn_1",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    color: "#F56C6C",
                                                    "font-size": "0.8rem",
                                                    "line-height": "1",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "半角数値を入力してください"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.messNull_1,
                                                      expression: "messNull_1",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    color: "#F56C6C",
                                                    "font-size": "0.8rem",
                                                    "line-height": "1",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "係数を記入してください"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "td",
                                            { staticStyle: { width: "6%" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "title" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "messages.subunit3"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "8%" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "table_input" },
                                                [
                                                  _c("el-input", {
                                                    attrs: { size: "medium" },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.getInputValue()
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.unit[2],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.unit,
                                                          2,
                                                          $$v
                                                        )
                                                      },
                                                      expression: "unit[2]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.sum
                                                          .sum *
                                                          Number(_vm.percent[2])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .sum.sum *
                                                            Number(
                                                              _vm.percent[2]
                                                            )
                                                        )[1]
                                                      )
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .sum.sum *
                                                            Number(
                                                              _vm.percent[2]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.A1
                                                          .emissionc *
                                                          Number(_vm.percent[2])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A1.emissionc *
                                                            Number(
                                                              _vm.percent[2]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A1.emissionc *
                                                            Number(
                                                              _vm.percent[2]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData
                                                          .A2A4.emissionc *
                                                          Number(_vm.percent[2])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A2A4.emissionc *
                                                            Number(
                                                              _vm.percent[2]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A2A4.emissionc *
                                                            Number(
                                                              _vm.percent[2]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.A3
                                                          .emissionc *
                                                          Number(_vm.percent[2])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A3.emissionc *
                                                            Number(
                                                              _vm.percent[2]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData
                                                            .A3.emissionc *
                                                            Number(
                                                              _vm.percent[2]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.B
                                                          .emissionc *
                                                          Number(_vm.percent[2])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.B
                                                            .emissionc *
                                                            Number(
                                                              _vm.percent[2]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.B
                                                            .emissionc *
                                                            Number(
                                                              _vm.percent[2]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "table_contents",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toScientificNum(
                                                        _vm.processTableData.C
                                                          .emissionc *
                                                          Number(_vm.percent[2])
                                                      )[0]
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.C
                                                            .emissionc *
                                                            Number(
                                                              _vm.percent[2]
                                                            )
                                                        )[1]
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("sup", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.toScientificNum(
                                                          _vm.processTableData.C
                                                            .emissionc *
                                                            Number(
                                                              _vm.percent[2]
                                                            )
                                                        )[2]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "8%" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "table_input" },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      size: "medium",
                                                      placeholder: "",
                                                      disabled: !_vm.unit[2],
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.validateCoefficient(
                                                          _vm.percent[2],
                                                          2
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.percent[2],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.percent,
                                                          2,
                                                          $$v
                                                        )
                                                      },
                                                      expression: "percent[2]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                width: "10%",
                                                "white-space": "nowrap",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.messWarn_2,
                                                      expression: "messWarn_2",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    color: "#F56C6C",
                                                    "font-size": "0.8rem",
                                                    "line-height": "1",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "半角数値を入力してください"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.messNull_2,
                                                      expression: "messNull_2",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    color: "#F56C6C",
                                                    "font-size": "0.8rem",
                                                    "line-height": "1",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "係数を記入してください"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      "justify-content": "center",
                                      "padding-top": "10px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          color: "#FFF",
                                          "background-color": "#1D97D8",
                                          "border-color": "#1D97D8",
                                        },
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                          disabled: _vm.btnDisabled,
                                          plain: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toSave()
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("messages.save")))]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      color: "#FFF",
                                      "background-color": "#1D97D8",
                                      "border-color": "#1D97D8",
                                    },
                                    attrs: { slot: "reference", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.queryLastData()
                                      },
                                    },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("messages.referSubUnit"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "right-content" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 16 } },
                  [
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticClass: "block-content" }, [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/static/process/a1.svg"),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "card-right" }, [
                          _c("h6", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(_vm.$t("messages.rmp"))),
                            _c("span", { staticClass: "flag" }, [
                              _vm._v("（A1）"),
                            ]),
                          ]),
                          _c("div", { staticClass: "content-layout-start" }, [
                            _c("div", { staticClass: "width-60" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "direction-title margin-right-16",
                                },
                                [_vm._v(_vm._s(_vm.$t("messages.emissionc")))]
                              ),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.toScientificNum(
                                      _vm.processTableData.A1.emissionc
                                    )[0]
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.toScientificNum(
                                        _vm.processTableData.A1.emissionc
                                      )[1]
                                    ) +
                                    " "
                                ),
                                _c("sup", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.toScientificNum(
                                        _vm.processTableData.A1.emissionc
                                      )[2]
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", [
                              _c("span", { staticClass: "direction-title" }, [
                                _vm._v(_vm._s(_vm.$t("messages.percentage"))),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.processTableData.A1.percentage) +
                                    "%"
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticClass: "block-content" }, [
                        _c("div", { staticClass: "card-img" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/static/process/a2.svg"),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "card-right" }, [
                          _c("h6", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(_vm.$t("messages.transportation"))),
                            _c("span", { staticClass: "flag" }, [
                              _vm._v("（A2・A4）"),
                            ]),
                          ]),
                          _c("div", { staticClass: "content-layout-start" }, [
                            _c("div", { staticClass: "width-60" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "direction-title margin-right-16",
                                },
                                [_vm._v(_vm._s(_vm.$t("messages.emissionc")))]
                              ),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.toScientificNum(
                                      _vm.processTableData.A2A4.emissionc
                                    )[0]
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.toScientificNum(
                                        _vm.processTableData.A2A4.emissionc
                                      )[1]
                                    ) +
                                    " "
                                ),
                                _c("sup", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.toScientificNum(
                                        _vm.processTableData.A2A4.emissionc
                                      )[2]
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", [
                              _c("span", { staticClass: "direction-title" }, [
                                _vm._v(_vm._s(_vm.$t("messages.percentage"))),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.processTableData.A2A4.percentage) +
                                    "%"
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticClass: "block-content" }, [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/static/process/a3.svg"),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "card-right" }, [
                          _c("h6", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(_vm.$t("messages.production"))),
                            _c("span", { staticClass: "flag" }, [
                              _vm._v("（A3）"),
                            ]),
                          ]),
                          _c("div", { staticClass: "content-layout-start" }, [
                            _c("div", { staticClass: "width-60" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "direction-title margin-right-16",
                                },
                                [_vm._v(_vm._s(_vm.$t("messages.emissionc")))]
                              ),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.toScientificNum(
                                      _vm.processTableData.A3.emissionc
                                    )[0]
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.toScientificNum(
                                        _vm.processTableData.A3.emissionc
                                      )[1]
                                    ) +
                                    " "
                                ),
                                _c("sup", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.toScientificNum(
                                        _vm.processTableData.A3.emissionc
                                      )[2]
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", [
                              _c("span", { staticClass: "direction-title" }, [
                                _vm._v(_vm._s(_vm.$t("messages.percentage"))),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.processTableData.A3.percentage) +
                                    "%"
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticClass: "margin-top-16", attrs: { gutter: 16 } },
                  [
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticClass: "block-content" }, [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/static/process/b1.svg"),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "card-right" }, [
                          _c("h6", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(_vm.$t("messages.usage"))),
                            _c("span", { staticClass: "flag" }, [
                              _vm._v("（B）"),
                            ]),
                          ]),
                          _c("div", { staticClass: "content-layout-start" }, [
                            _c("div", { staticClass: "width-60" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "direction-title margin-right-16",
                                },
                                [_vm._v(_vm._s(_vm.$t("messages.emissionc")))]
                              ),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.toScientificNum(
                                      _vm.processTableData.B.emissionc
                                    )[0]
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.toScientificNum(
                                        _vm.processTableData.B.emissionc
                                      )[1]
                                    ) +
                                    " "
                                ),
                                _c("sup", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.toScientificNum(
                                        _vm.processTableData.B.emissionc
                                      )[2]
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", [
                              _c("span", { staticClass: "direction-title" }, [
                                _vm._v(_vm._s(_vm.$t("messages.percentage"))),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.processTableData.B.percentage) +
                                    "%"
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticClass: "block-content" }, [
                        _c("div", { staticClass: "card-img" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/static/process/c1.svg"),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "card-right" }, [
                          _c("h6", { staticClass: "card-title" }, [
                            _vm._v(
                              _vm._s(_vm.$t("messages.disposalRecycling"))
                            ),
                            _c("span", { staticClass: "flag" }, [
                              _vm._v("（C）"),
                            ]),
                          ]),
                          _c("div", { staticClass: "content-layout-start" }, [
                            _c("div", { staticClass: "width-60" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "direction-title margin-right-16",
                                },
                                [_vm._v(_vm._s(_vm.$t("messages.emissionc")))]
                              ),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.toScientificNum(
                                      _vm.processTableData.C.emissionc
                                    )[0]
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.toScientificNum(
                                        _vm.processTableData.C.emissionc
                                      )[1]
                                    ) +
                                    " "
                                ),
                                _c("sup", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.toScientificNum(
                                        _vm.processTableData.C.emissionc
                                      )[2]
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", [
                              _c("span", { staticClass: "direction-title" }, [
                                _vm._v(_vm._s(_vm.$t("messages.percentage"))),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.processTableData.C.percentage) +
                                    "%"
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm.seihinAuth !== 0
                      ? _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "block-content" }, [
                            _c("div", [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/static/process/a5.svg"),
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "card-right" }, [
                              _c("h6", { staticClass: "card-title" }, [
                                _vm._v(_vm._s(_vm.$t("messages.noShow"))),
                                _c("span", { staticClass: "flag" }),
                              ]),
                              _c(
                                "div",
                                { staticClass: "content-layout-start" },
                                [
                                  _c("div", { staticClass: "width-60" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "direction-title margin-right-16",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("messages.emissionc"))
                                        ),
                                      ]
                                    ),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.toScientificNum(
                                            _vm.processTableData.noShow
                                              .emissionc
                                          )[0]
                                        ) +
                                          " " +
                                          _vm._s(
                                            _vm.toScientificNum(
                                              _vm.processTableData.noShow
                                                .emissionc
                                            )[1]
                                          ) +
                                          " "
                                      ),
                                      _c("sup", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.toScientificNum(
                                              _vm.processTableData.noShow
                                                .emissionc
                                            )[2]
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "direction-title" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("messages.percentage"))
                                        ),
                                      ]
                                    ),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.processTableData.noShow.percentage
                                        ) + "%"
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "card-box padding-16 fixedtable" },
              [
                _c("h5", { staticClass: "about-title" }, [
                  _c("span", { staticClass: "left-line" }, [
                    _vm._v(_vm._s(_vm.$t("messages.hotspotRanking"))),
                  ]),
                ]),
                _c(
                  "el-table",
                  {
                    staticClass: "border-1 margin-top-20",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData, height: "261" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "processcode",
                        label: _vm.$t("messages.process"),
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "itemname",
                        label: _vm.$t("messages.managedItem"),
                        width: "230",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: scope.row.itemname,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "colum-width" },
                                        [_vm._v(_vm._s(scope.row.itemname))]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        label: _vm.$t("messages.ef"),
                        width: "230",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                { staticClass: "colum-width" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: scope.row.displayname,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "colum-width" },
                                        [_vm._v(_vm._s(scope.row.displayname))]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "emissionc",
                        label: _vm.$t("messages.emission"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.toScientificNum(
                                        scope.row.emissionc
                                      )[0]
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.toScientificNum(
                                        scope.row.emissionc
                                      )[1]
                                    ) +
                                    " "
                                ),
                                _c("sup", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.toScientificNum(
                                        scope.row.emissionc
                                      )[2]
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "pre", label: _vm.$t("messages.ratio") },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.processcode === "D"
                                ? _c("p", [_vm._v("-")])
                                : _c("p", [
                                    _vm._v(_vm._s(scope.row.pre) + "%"),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "card-box min-height" }, [
              _c("h5", { staticClass: "about-title margin-bot-16" }, [
                _c("span", { staticClass: "left-line" }, [
                  _vm._v(_vm._s(_vm.$t("messages.cwop"))),
                ]),
              ]),
              _c("div", { staticClass: "margin-bot-16" }, [
                _c("span", { staticClass: "sub-title margin-bot-4" }, [
                  _vm._v(_vm._s(_vm.$t("messages.eotpv"))),
                ]),
                _c("div", { staticClass: "font-18" }, [
                  _vm._v(
                    _vm._s(_vm.toScientificNum(_vm.sumEmissionc)[0]) +
                      " " +
                      _vm._s(_vm.toScientificNum(_vm.sumEmissionc)[1]) +
                      " "
                  ),
                  _c("sup", [
                    _vm._v(_vm._s(_vm.toScientificNum(_vm.sumEmissionc)[2])),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading4SeihinList,
                      expression: "loading4SeihinList",
                    },
                  ],
                  staticClass: "production-item margin-bot-24",
                },
                [
                  _c(
                    "div",
                    { staticClass: "content-layout" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "margin-bot-4 margin-right-16 sub-title",
                        },
                        [_vm._v(_vm._s(_vm.$t("messages.comparewith")))]
                      ),
                      _vm.otherSeihinAuth === 0
                        ? _c(
                            "el-popover",
                            { attrs: { placement: "right", trigger: "click" } },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.otherSimulateNote,
                                    expression: "otherSimulateNote",
                                  },
                                ],
                                attrs: {
                                  readonly: "true",
                                  cols: "40",
                                  rows: "10",
                                  maxlength: "3000",
                                  placeholder: _vm.$t("messages.note"),
                                },
                                domProps: { value: _vm.otherSimulateNote },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.otherSimulateNote = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/static/icon/note.svg"),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticClass: "seihin-select",
                      attrs: {
                        title: _vm.temptitle,
                        placeholder: _vm.$t("messages.checkProductName"),
                        size: "mini",
                      },
                      on: { change: _vm.changeOtherSeihin },
                      model: {
                        value: _vm.selectOtherValueDefault,
                        callback: function ($$v) {
                          _vm.selectOtherValueDefault = $$v
                        },
                        expression: "selectOtherValueDefault",
                      },
                    },
                    _vm._l(_vm.seihinList, function (item) {
                      return _c("el-option", {
                        key: item.seihincode + "/" + item.version,
                        attrs: {
                          label: item.version
                            ? item.seihinname + " " + item.version
                            : item.seihinname,
                          value: item.seihincode + "/" + item.version,
                          disabled: item.disabled,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "margin-bot-16" }, [
                _c("span", { staticClass: "sub-title margin-bot-8" }, [
                  _vm._v(_vm._s(_vm.$t("messages.emissionc"))),
                ]),
                _vm.sumOtherEmissionc !== ""
                  ? _c("div", { staticClass: "font-18" }, [
                      _vm._v(
                        _vm._s(_vm.toScientificNum(_vm.sumOtherEmissionc)[0]) +
                          " " +
                          _vm._s(
                            _vm.toScientificNum(_vm.sumOtherEmissionc)[1]
                          ) +
                          " "
                      ),
                      _c("sup", [
                        _vm._v(
                          _vm._s(_vm.toScientificNum(_vm.sumOtherEmissionc)[2])
                        ),
                      ]),
                    ])
                  : _c("div", { staticClass: "font-18" }, [_vm._v("-")]),
              ]),
              _c("div", [
                _c("span", { staticClass: "sub-title margin-bot-8" }, [
                  _vm._v(_vm._s(_vm.$t("messages.reduction"))),
                ]),
                _vm.sumOtherEmissionc !== ""
                  ? _c("div", { staticClass: "font-14" }, [
                      _c("strong", { staticClass: "weight-600" }, [
                        _vm._v(_vm._s(Math.abs(_vm.reduction))),
                      ]),
                      _vm._v("% "),
                      _vm.reduction > 0
                        ? _c("i", {
                            staticClass: "el-icon-bottom-right icon-size-green",
                          })
                        : _vm._e(),
                      _vm.reduction < 0
                        ? _c("i", {
                            staticClass: "el-icon-top-right icon-size-orange",
                          })
                        : _vm._e(),
                    ])
                  : _c("div", { staticClass: "font-14" }, [_vm._v("-")]),
              ]),
            ]),
          ]),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("div", { staticClass: "card-box margin-bot-16" }, [
                _c("h5", { staticClass: "about-title margin-bot-24" }, [
                  _c("span", { staticClass: "left-line" }, [
                    _vm._v(_vm._s(_vm.$t("messages.uopef"))),
                  ]),
                ]),
                _c("div", { staticClass: "content-layout" }, [
                  _c("div", { staticClass: "margin-right-8" }, [
                    _c("span", { staticClass: "sub-title margin-bot-16" }, [
                      _vm._v(_vm._s(_vm.$t("messages.usedef"))),
                    ]),
                    _c("div", { staticClass: "font-14" }, [
                      _c(
                        "strong",
                        { staticClass: "weight-600 margin-right-4" },
                        [_vm._v(_vm._s(_vm.originalUseData.count))]
                      ),
                      _vm._v(_vm._s(_vm.$t("messages.items"))),
                    ]),
                  ]),
                  _c("div", { staticClass: "margin-left-8" }, [
                    _c("span", { staticClass: "sub-title margin-bot-16" }, [
                      _vm._v(_vm._s(_vm.$t("messages.rwte"))),
                    ]),
                    _vm.originalUseData.pre !== 0 &&
                    !isNaN(_vm.originalUseData.pre)
                      ? _c("div", { staticClass: "font-14" }, [
                          _c(
                            "strong",
                            { staticClass: "weight-600 margin-right-4" },
                            [
                              _vm._v(
                                _vm._s(
                                  (_vm.originalUseData.pre * 100).toFixed(2)
                                )
                              ),
                            ]
                          ),
                          _vm._v("%"),
                        ])
                      : _c("div", { staticClass: "font-14" }, [
                          _c(
                            "strong",
                            { staticClass: "weight-600 margin-right-4" },
                            [_vm._v("-")]
                          ),
                        ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "card-box" }, [
                _c("h5", { staticClass: "about-title  margin-bot-24" }, [
                  _c("span", { staticClass: "left-line" }, [
                    _vm._v(_vm._s(_vm.$t("messages.car2c"))),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "content-layout" },
                  [
                    _c("div", { staticClass: "margin-right-8" }, [
                      _c("span", { staticClass: "sub-title margin-bot-16" }, [
                        _vm._v(_vm._s(_vm.$t("messages.checkItems"))),
                      ]),
                      _c("div", { staticClass: "font-16" }, [
                        _c(
                          "strong",
                          { staticClass: "weight-600 margin-right-4" },
                          [_vm._v(_vm._s(_vm.finishCount))]
                        ),
                        _vm._v("/" + _vm._s(_vm.totalCount) + " "),
                        _c("span", { staticClass: "margin-left-8" }, [
                          _c(
                            "span",
                            {
                              staticStyle: { cursor: "pointer" },
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/static/icon/17list.svg"),
                                },
                                on: { click: _vm.getCheckListData },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: _vm.$t("messages.calQualityList"),
                          visible: _vm.centerDialogVisible,
                          "lock-scroll": false,
                          "append-to-body": true,
                          width: "70%",
                          center: "",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.centerDialogVisible = $event
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "popup-height" },
                          [
                            _c("div", { staticClass: "check-note" }, [
                              _vm._v(_vm._s(_vm.$t("messages.check"))),
                            ]),
                            _vm._l(_vm.checkList, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "popup-area" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "check-area" },
                                    [
                                      _c("el-checkbox", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.checkflag,
                                          callback: function ($$v) {
                                            _vm.$set(item, "checkflag", $$v)
                                          },
                                          expression: "item.checkflag",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "check-content" },
                                    [
                                      _c(
                                        "el-collapse",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "check-list-title check-break-line",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.checkitem.replaceAll(
                                                    "\\n",
                                                    "\n"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-collapse-item",
                                            { attrs: { name: "1" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-color check-break-line",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.checkitemComment.replaceAll(
                                                        "\\n",
                                                        "\n"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "btn-plain",
                                on: {
                                  click: function ($event) {
                                    _vm.centerDialogVisible = false
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("messages.back")))]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "btn-color",
                                attrs: { type: "primary" },
                                on: { click: _vm.closeDialog },
                              },
                              [_vm._v("完了")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "margin-left-8" }, [
                      _c("span", { staticClass: "sub-title margin-bot-16" }, [
                        _vm._v(_vm._s(_vm.$t("messages.reported2Partners"))),
                      ]),
                      _vm.seihinAuth === 0
                        ? _c("div", { staticClass: "font-14" }, [
                            _c(
                              "strong",
                              { staticClass: "weight-600 margin-right-4" },
                              [_vm._v(_vm._s(_vm.companiesNum))]
                            ),
                            _c("span"),
                            _vm._v(_vm._s(_vm.$t("messages.companies"))),
                          ])
                        : _c("div", { staticClass: "font-14" }, [
                            _c(
                              "strong",
                              { staticClass: "weight-600 margin-right-4" },
                              [_vm._v("-")]
                            ),
                          ]),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm.isShowPopup
                ? _c("seihinPopup", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShowPopup,
                        expression: "isShowPopup",
                      },
                    ],
                    attrs: {
                      seihinMasterDataRe: _vm.seihinMasterData,
                      columnNameDataRe: _vm.columnNameData,
                    },
                    on: { seihinMasterCall: _vm.parentSeihinPopup },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }