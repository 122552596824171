<template>
  <transition name="popup-fade">
    <div
      class="popup-body"
    >
      <div class="popup-content-wrap">
        <div class="popup">
          <div class="title-box">
            <i class="el-icon-close" @click="doClose" />
          </div>
          <div class="traffic-body-box">
            <el-form ref="utilityItem" :model="utilityItem" :rules="rules" class="traffic-form">
              <div class="traffic-div">
                <div class="traffic-label">工場全体のユーティリティ消費量</div>
                <el-form-item prop="allUtility">
                  <el-input v-model="utilityItem.allUtility" class="traffic-input" size="small" placeholder="工場全体のユーティリティ消費量を入力してください" @input="value => utilityItem.allUtility=value.replace(/(\-?)\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3$4').match(/^\-?\d*(\.?\d*)/g)[0] || ''"/>
                  kWh
                </el-form-item>
              </div>
              <div class="traffic-div">
                <div class="traffic-label">工場全体の生産量</div>
                <el-form-item prop="allProduction">
                  <el-input v-model="utilityItem.allProduction" class="traffic-input" size="small" placeholder="工場全体の生産量を入力してください" @input="value => utilityItem.allProduction=value.replace(/\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3').match(/^\d*(\.?\d*)/g)[0] || ''"/>
                  Kg
                </el-form-item>
              </div>
              <div class="traffic-div">
                {{$t('messages.amount')}} {{ utilityItem.allUtility / utilityItem.allProduction ? utilityItem.allUtility / utilityItem.allProduction : '' }} kWh
              </div>
            </el-form>
          </div>
          <div class="tab-button-box">
            <el-button class="back-button" @click="doClose"> {{$t('messages.back')}}</el-button>
            <el-button
              class="calculator-button"
              @click="doComfirm('utilityItem')"
            >
              {{ buttonName }}
              <i class="el-icon-check" />
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script type="text/ecmascript-6">
export default {
  name: 'TrafficPopup',
  props: {
    isShowTrafficPopup: Boolean,
    isComfirm: Boolean,
    buttonName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      utilityItem: {
        allUtility: '',
        allProduction: ''
      },
      rules: {
        allUtility: [
          { required: true, message: '工場全体のユーティリティ消費量を入力してください', trigger: 'blur' }
        ],
        allProduction: [
          { required: true, message: this.$t('messages.penopp'), trigger: 'blur' }
        ]
      }
    }
  },
  // computed: {
  //   this.
  // },
  methods: {
    doComfirm(utilityForm) {
      this.$refs[utilityForm].validate((valid) => {
        if (!valid) {
          return false
        }
      })

      if (!(this.utilityItem.allUtility && this.utilityItem.allProduction)) {
        return
      }

      const itemQuantity = this.utilityItem.allUtility / this.utilityItem.allProduction
      this.$emit('childFn', {
        'isComfirm': true,
        'isShowTrafficPopup': false,
        'itemQuantity': itemQuantity
      })
      this.utilityItem.allUtility = ''
      this.utilityItem.allProduction = ''
    },
    doClose() {
      this.$emit('childFn', { 'isComfirm': false, 'isShowTrafficPopup': false })
    },
    handleSelect(item) {
      this.trafficItem.name = item.name
      this.ideacode = item.ideacode
      this.coefficient = item.coefficient
      this.unit = item.unit
    },
    querySearch(queryString, cb) {
      const result = this.nameList.filter(item => {
        if (item.name.indexOf(queryString) > -1) {
          return true
        }
      })

      cb(result)
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .popup-content-wrap {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    height: 70%;
    width: 40%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    z-index: 999;
    user-select: none;

    .popup {

      .title-box {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 3%;
        margin-bottom: 1%;
        margin-left: 3%;
        height: 10%;

        .el-icon-close {
          cursor: pointer;
        }

        .title-font {
          font-family: 'NotoSansCJKjp-Medium';
          font-size: 20px;
          color: #333333;
          letter-spacing: 0;
          text-align: center;
          font-weight: 500;
          margin-left: 3%;
        }
      }

      .traffic-body-box {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 60%;

        .traffic-form {
          height: 100%;
          width: 100%;

          .traffic-label {
            font-family: NotoSansCJKjp-Medium;
            font-size: 14px;
            color: #666666;
            letter-spacing: 0;
            font-weight: 500;
            margin-bottom: 0.4rem;
          }

          .traffic-div {
            margin-left: 5%;
            margin-bottom: 10%;
            width: 100%;

            .traffic-input {
              width: 85%;
            }
          }
        }

      }

      .tab-button-box{
        margin-top: 5%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // opacity: 0.85;
        background: #FFFFFF;
        height: 20%;

        .back-button {
          font-family: 'NotoSansCJKjp-Regular';
          font-size: 12px;
          // color: #333333;
          letter-spacing: 0;
          font-weight: 400;
          // border: 1px solid #333333;
          border-radius: 4px;
        }

        .calculator-button {
          font-family: 'NotoSansCJKjp-Regular';
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          font-weight: 400;
          background: #333333;
          border-radius: 4px;
        }
      }
    }
  }
}

/* 进入和出去的动画 */
.popup-fade-enter-active {
  animation: opacity 0.3s;
}
.popup-fade-enter-active .popup-content-wrap {
  animation: scale 0.3s;
}
.popup-fade-leave-active {
  animation: outOpacity 0.2s;
}

/*滚动条样式*/
.process-list::-webkit-scrollbar {
  width: 10px;
  /*height: 4px;*/
}
.process-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);

  background: #CCCCCC;
  border-radius: 4px;
}
.process-list::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 0;
  background: rgba(0,0,0,0.1);

}

/* 进来的动画 */
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scale {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes outOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
