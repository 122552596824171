<template>
  <div class="app-wrapper">
    <!-- <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" /> -->
    <div class="main-container">
      <div class="fixed-header">
        <navbar />
        <FloatContainer />
      </div>
      <app-main class="app-main" />
      <Footer />
    </div>

  </div>
</template>

<script>
import { Navbar, FloatContainer, AppMain, Footer } from './components'
import ResizeMixin from './mixin/ResizeHandler'

export default {
  name: 'Layout',
  components: {
    Navbar,
    FloatContainer,
    // Sidebar,
    AppMain,
    Footer
  },
  mixins: [ResizeMixin],
  computed: {
    // sidebar() {
    //   return this.$store.state.app.sidebar
    // },
    // device() {
    //   return this.$store.state.app.device
    // },
    // fixedHeader() {
    //   return this.$store.state.settings.fixedHeader
    // },
    // classObj() {
    //   return {
    //     // hideSidebar: !this.sidebar.opened,
    //     // openSidebar: this.sidebar.opened,
    //     // withoutAnimation: this.sidebar.withoutAnimation,
    //     // mobile: this.device === 'mobile'
    //   }
    // }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/mixin.scss";
  @import "~@/styles/variables.scss";

  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    &.mobile.openSidebar{
      position: fixed;
      top: 0;
    }
  }
  .main-container {
    height: 80%;
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    height: 100px;
    top: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    background-color: white;
  }

  .app-main {
    position: relative;
  }

  .hideSidebar .fixed-header {
    width: 100%
  }

  .mobile .fixed-header {
    width: 100%;
  }
</style>
