var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "tree-table-body" }, [
    _c(
      "table",
      {
        class: { "table-scroll": _vm.levelNum === 0 },
        staticStyle: { width: "100%" },
        attrs: { border: "0", cellpadding: "0", cellspacing: "0" },
      },
      [
        _c(
          "tr",
          { staticStyle: { "background-color": "#e8f1f9", height: "2rem" } },
          [
            _c(
              "td",
              {
                staticStyle: { width: "100%", display: "flex" },
                on: { click: _vm.getData },
              },
              [
                _c("div", {
                  staticStyle: { background: "white" },
                  style: _vm.getPaddingStyle,
                }),
                _c(
                  "div",
                  { staticStyle: { "overflow-x": "auto", display: "flex" } },
                  [
                    (_vm.row.detail && _vm.row.detail.length > 0) ||
                    (_vm.row.child && _vm.row.child.length > 0)
                      ? _c("i", {
                          class: {
                            "el-icon-arrow-down": _vm.isShow,
                            "el-icon-arrow-right": !_vm.isShow,
                          },
                          staticStyle: { "padding-top": "0.5rem" },
                        })
                      : _vm._e(),
                    _c("i", { staticStyle: { "padding-right": "1rem" } }),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.row.itemName,
                          placement: "top-start",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "colum-width-tree",
                            staticStyle: { "vertical-align": "bottom" },
                          },
                          [_vm._v(" " + _vm._s(_vm.row.itemName) + " ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c("td", { staticStyle: { width: "20%" } }),
            _c("td", { staticStyle: { width: "20%", "text-align": "center" } }),
            _c("td", { staticStyle: { width: "6%", "text-align": "center" } }),
            _c("td", { staticStyle: { width: "5%", "text-align": "center" } }),
            _c("td", { staticStyle: { width: "7%", "text-align": "center" } }, [
              _vm._v(
                _vm._s(_vm.toScientificNum(_vm.row.sum)[0]) +
                  " " +
                  _vm._s(_vm.toScientificNum(_vm.row.sum)[1]) +
                  " "
              ),
              _c("sup", [_vm._v(_vm._s(_vm.toScientificNum(_vm.row.sum)[2]))]),
            ]),
            _vm.row.sumPre !== "-"
              ? _c(
                  "td",
                  { staticStyle: { width: "6%", "text-align": "center" } },
                  [
                    _vm._v(
                      _vm._s((Number(_vm.row.sumPre) * 100).toFixed(2)) + "%"
                    ),
                  ]
                )
              : _c(
                  "td",
                  { staticStyle: { width: "6%", "text-align": "center" } },
                  [_vm._v("-")]
                ),
            _c("td", { staticStyle: { width: "6%" } }),
          ]
        ),
        _vm.isShow && _vm.row.detail && _vm.row.detail.length > 0
          ? _vm._l(_vm.row.detail, function (i, index) {
              return _c("tr", { key: index }, [
                _c("td", [
                  _c(
                    "div",
                    {
                      staticClass: "nosubassytr",
                      class: i.isGray
                        ? "backgroub-gray-class"
                        : "backgroub-class",
                      staticStyle: { "overflow-x": "auto", display: "flex" },
                      style: _vm.detailStyle,
                    },
                    [
                      _vm.row.detail[index].subassyFromId
                        ? _c("div", { staticClass: "subassytr" })
                        : _vm._e(),
                      _c("i", { staticStyle: { "padding-right": "0.4rem" } }),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: i.itemName,
                            placement: "top-start",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "colum-width-tree",
                              staticStyle: { "vertical-align": "bottom" },
                            },
                            [_vm._v(" " + _vm._s(i.itemName) + " ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "td",
                  {
                    class: i.isGray
                      ? "backgroub-gray-class"
                      : "backgroub-class",
                  },
                  [_vm._v(_vm._s(i.processName))]
                ),
                _c(
                  "td",
                  {
                    class: i.isGray
                      ? "backgroub-gray-class"
                      : "backgroub-class",
                  },
                  [
                    (i.isSelfCalcOriginIdea === true && i.auth === 0) ||
                    (i.isSelfCalcOriginIdea === false && i.auth > 1)
                      ? _c(
                          "div",
                          {
                            class: _vm.isLoop
                              ? "origin-idea-loop-style"
                              : "origin-idea-style",
                            on: {
                              click: function ($event) {
                                return _vm.showDetail(i)
                              },
                            },
                          },
                          [
                            _vm.isPopup
                              ? _c(
                                  "div",
                                  [
                                    _vm.formatIdeaName(i.ideaName).length > 24
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: _vm.formatIdeaName(
                                                i.ideaName
                                              ),
                                              placement: "top-start",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .formatIdeaName(i.ideaName)
                                                    .slice(0, 19)
                                                ) + "..."
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatIdeaName(i.ideaName)
                                            )
                                          ),
                                        ]),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.formatIdeaName(i.ideaName))
                                    ),
                                  ]),
                                ]),
                          ]
                        )
                      : _c("div", [
                          _vm.isPopup
                            ? _c(
                                "div",
                                [
                                  _vm.formatIdeaName(i.ideaName).length > 24
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: _vm.formatIdeaName(
                                              i.ideaName
                                            ),
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm
                                                  .formatIdeaName(i.ideaName)
                                                  .slice(0, 19)
                                              ) + "..."
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.formatIdeaName(i.ideaName))
                                        ),
                                      ]),
                                ],
                                1
                              )
                            : _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.formatIdeaName(i.ideaName))
                                  ),
                                ]),
                              ]),
                        ]),
                  ]
                ),
                _c(
                  "td",
                  {
                    class: i.isGray
                      ? "backgroub-gray-class"
                      : "backgroub-class",
                    staticStyle: { "text-align": "center" },
                  },
                  [_vm._v(_vm._s(_vm.formatQuantity(i.quantity)))]
                ),
                _c(
                  "td",
                  {
                    class: i.isGray
                      ? "backgroub-gray-class"
                      : "backgroub-class",
                    staticStyle: { "text-align": "center" },
                  },
                  [_vm._v(_vm._s(i.unit))]
                ),
                _c(
                  "td",
                  {
                    class: i.isGray
                      ? "backgroub-gray-class"
                      : "backgroub-class",
                    staticStyle: { "text-align": "center" },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.toScientificNum(i.emissionc)[0]) +
                        " " +
                        _vm._s(_vm.toScientificNum(i.emissionc)[1]) +
                        " "
                    ),
                    _c("sup", [
                      _vm._v(_vm._s(_vm.toScientificNum(i.emissionc)[2])),
                    ]),
                  ]
                ),
                i.pre !== "-"
                  ? _c(
                      "td",
                      {
                        class: i.isGray
                          ? "backgroub-gray-class"
                          : "backgroub-class",
                        staticStyle: { "text-align": "center" },
                      },
                      [_vm._v(_vm._s((Number(i.pre) * 100).toFixed(2)) + "%")]
                    )
                  : _c(
                      "td",
                      {
                        class: i.isGray
                          ? "backgroub-gray-class"
                          : "backgroub-class",
                        staticStyle: { "text-align": "center" },
                      },
                      [_vm._v("-")]
                    ),
                i.budomari
                  ? _c(
                      "td",
                      {
                        class: i.isGray
                          ? "backgroub-gray-class"
                          : "backgroub-class",
                        staticStyle: { "text-align": "center" },
                      },
                      [_vm._v(_vm._s(i.budomari) + "%")]
                    )
                  : _c("td", {
                      class: i.isGray
                        ? "backgroub-gray-class"
                        : "backgroub-class",
                    }),
              ])
            })
          : _vm._e(),
      ],
      2
    ),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShow && _vm.row.child && _vm.row.child.length > 0,
            expression: "isShow && row.child && row.child.length >0",
          },
        ],
      },
      _vm._l(_vm.row.child, function (i, index) {
        return _c("tree", {
          key: index,
          attrs: {
            "show-all": _vm.showAll,
            row: i,
            "level-num": _vm.levelNum + 1,
            "is-popup": _vm.isPopup,
            "is-loop": _vm.isLoop,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }